import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField/redux-form';
import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  change,
  Field,
  Form,
  formValueSelector,
  isSubmitting,
  reduxForm,
} from 'redux-form';
import { getProp } from '@sportnet/utilities';
import { __ } from '../../../../utilities';
import { required } from '../../../../validation';
import { sportsCodelistSelector } from '../../../App/selectors';
import AthletesForm from '../athletes/form';
import SportExpertForm from '../experts/form';
import Api from '../../../../Api';
import { useAuth } from '@sportnet/auth-react';

export const FORM_NAME = 'AFFILIATION_FORM';

const AffiliationForm = ({
  isOwner,
  data,
  category,
  handleSubmit,
  isFormSubmitting,
  dispatch,
  onDelete,
  isDeleting,
  deleteDisabled,
  sportCodelist,
}) => {
  const { ppo: appSpace } = useAuth();

  const [profiles, setProfiles] = React.useState([]);
  const [ppos, setPpos] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      // nacitame zoznam profiles
      const [{ items: _ppos }, selfppo] = await Promise.all([
        Api.organizationPPOListRelatedPPOs(appSpace),
        Api.organizationPPOProfile(appSpace),
      ]);
      _ppos.unshift(selfppo);
      _ppos.push(...(selfppo.parent_relations || []));
      setProfiles(
        _ppos.map(p => ({
          label: p.name || p._id,
          value: p._id,
        })),
      );
      setPpos(_ppos);
    })();
  }, [appSpace]);

  const renderFormByCategory = category => {
    switch (category) {
      case 'sport_orgs':
        return (
          <AthletesForm isOwner={isOwner} data={data} formName={FORM_NAME} />
        );
      case 'sport_expert_orgs':
        return (
          <SportExpertForm isOwner={isOwner} data={data} formName={FORM_NAME} />
        );
      default:
        return <div />;
    }
  };

  const getPPOSectorOptions = ppo => {
    if (!ppo) return [];
    return (ppo.sectors || []).map(
      ({ _id, sectorId, itemId, category, matricityPPO }) => {
        let label = _id;
        switch (category) {
          case 'sport':
            const labelItem = (sportCodelist[itemId] || {}).label || itemId;
            const labelSector = sportCodelist[itemId]
              ? (sportCodelist[itemId].sectors[sectorId] || {}).label ||
                sectorId
              : sectorId;
            label = `Šport / ${labelItem} / ${labelSector}`;
            break;
          default:
            label = _id;
        }
        return {
          value: _id,
          label,
          matricityPPO,
        };
      },
    );
  };

  return (
    <Segment>
      <Form onSubmit={handleSubmit}>
        <Field
          component={FormField}
          type="theselect"
          required
          disabled={!!data}
          label={__('Organizácia')}
          name="org_profile_id"
          options={profiles}
          parse={item => {
            return item ? item.value : '';
          }}
          format={value =>
            value ? profiles.find(o => o.value === value) || null : null
          }
          onChange={newValue => {
            const ppo = newValue
              ? ppos.find(p => p._id === newValue.value)
              : null;
            dispatch(
              change(FORM_NAME, 'sector', getProp(ppo, ['sectors', 0, '_id'])),
            );
          }}
          data-testid="CRM_FO_Input_Affiliations_Org"
        />
        <Field
          component={FormField}
          label={__('Sektor')}
          type="theselectsimple"
          name="sector"
          validate={[required]}
          required
          disabled={!!data}
          options={getPPOSectorOptions(ppos.find(p => p._id === appSpace))}
        />
        <Field
          component={FormField}
          type="theselectsimple"
          label={__('Kategória príslušnosti')}
          name="category"
          validate={[required]}
          disabled={!!data}
          required
          options={[
            {
              label: __('Športovec'),
              value: 'sport_orgs',
            },
            {
              label: __('Športový odborník'),
              value: 'sport_expert_orgs',
            },
          ]}
          data-testid="CRM_FO_Input_Affiliations_Category"
        />
        {category && renderFormByCategory(category)}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {data && isOwner && !deleteDisabled && (
            <Button
              type="button"
              danger
              disabled={isFormSubmitting || isDeleting}
              loading={isDeleting}
              onClick={() => onDelete(data)}
            >
              {__('Vymazať')}
            </Button>
          )}
          {(!data || (data && isOwner)) && (
            <Button
              type="submit"
              loading={isFormSubmitting}
              disabled={isFormSubmitting || isDeleting}
              primary
              data-testid="CRM_FO_Button_Affiliations_Save"
            >
              {__('Uložiť')}
            </Button>
          )}
        </div>
      </Form>
    </Segment>
  );
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(AffiliationForm);

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  return {
    category: selector(state, 'category'),
    sportCodelist: sportsCodelistSelector(state),
    isFormSubmitting: isSubmitting(FORM_NAME)(state),
    initialValues: props.data
      ? { ...props.data, sector: props.data.sector._id }
      : {},
  };
};

AffiliationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
  category: PropTypes.string,
  isOwner: PropTypes.bool.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
  sport_sector: PropTypes.string,
};

AffiliationForm.defaultProps = {
  data: null,
  sport_sector: undefined,
};

export default connect(mapStateToProps)(connected);
