import API from '../../Api';
import { getProp, __ } from '../../utilities';
import {
  RECEIVE_GROUPS_FAILURE,
  RECEIVE_GROUPS_SUCCESS,
  REQUEST_GROUPS,
  REQUEST_SAVE_GROUP,
  RECEIVE_SAVE_GROUP_FAILURE,
  RECEIVE_SAVE_GROUP_SUCCESS,
} from './constants';

const requestGroups = () => ({
  type: REQUEST_GROUPS,
});

const receiveGroupsSuccess = groups => ({
  type: RECEIVE_GROUPS_SUCCESS,
  payload: {
    groups,
  },
});

const receiveGroupsFailure = error => ({
  type: RECEIVE_GROUPS_FAILURE,
  payload: {
    error: error.description || error,
  },
});

export const getGroups = appspace => {
  return async dispatch => {
    dispatch(requestGroups());
    try {
      const response = await API.getPublicAppSpaceGroups(appspace);
      dispatch(receiveGroupsSuccess(response.groups));
      return response;
    } catch (e) {
      dispatch(receiveGroupsFailure(e.details || e));
    }
    return null;
  };
};

const requestSaveGroup = () => ({
  type: REQUEST_SAVE_GROUP,
});

const receiveSaveGroupSuccess = () => ({
  type: RECEIVE_SAVE_GROUP_SUCCESS,
});

const receiveSaveGroupFailure = error => ({
  type: RECEIVE_SAVE_GROUP_FAILURE,
  payload: {
    error: error.description || error,
  },
});

export const createNewGroup = (appSpace, data) => {
  return async dispatch => {
    try {
      dispatch(requestSaveGroup());
      await API.crmCreateAppSpaceGroup(appSpace, { data });
      dispatch(receiveSaveGroupSuccess());
      return true;
    } catch (e) {
      let error = e.details || e;
      if (
        getProp(e, ['details', 'name']) ===
        'USER_GROUP_ID_ALREADY_EXISTS_IN_APPSPACE'
      ) {
        error = __('Skupina s týmto ID už existuje');
      }
      dispatch(receiveSaveGroupFailure(error));
    }
    return false;
  };
};

export const saveGroup = (appSpace, id, data) => {
  return async dispatch => {
    try {
      dispatch(requestSaveGroup());
      await API.crmUpdateAppSpaceGroup(appSpace, id, { data });
      dispatch(receiveSaveGroupSuccess());
      return true;
    } catch (e) {
      const error = e.details || e;
      dispatch(receiveSaveGroupFailure(error));
    }
    return false;
  };
};
