import { createSelector } from 'reselect';
import getProp from '@sportnet/utilities/getProp';
import { codelistEntrySelector } from '../../../App/selectors';

export const licenseRequiredSelector = competenceType =>
  createSelector(
    codelistEntrySelector('sport-expert-competence-type', competenceType),
    competenceTypeCodelistItem => {
      return getProp(
        competenceTypeCodelistItem,
        ['payload', 'licenseRequired'],
        false,
      );
    },
  );
