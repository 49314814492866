import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Api from '../../../../Api';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import { aclSelector } from '../../../App/selectors';
import {
  orgEntitySelector,
  orgIsErrorSelector,
  orgIsFetchingSelector,
} from '../selectors';
import Tabber, { TABS } from '../Tabber';

export const Faded = styled.span`
  color: ${({ theme }) => theme.color.fadedText};
`;

const ContactPersons = ({
  org,
  match: {
    params: { appspace, organizationId, orgProfileId },
  },
  isFetching,
}) => {
  const [isFetchingContactPersons, setIsFetchingContactPersons] =
    useState(false);
  const [contactPersons, setContactPersons] = useState([]);

  useEffect(() => {
    setIsFetchingContactPersons(true);
    Api.crmPPOContactPersons(appspace, orgProfileId)
      .then(({ contactPersons }) => {
        setContactPersons(contactPersons);
      })
      .catch(e => {
        alert(
          e.details
            ? e.details.userinfo ||
                __('Pri získavaní kotaktných osôb nastala chyba')
            : e,
        );
      })
      .finally(() => {
        setIsFetchingContactPersons(false);
      });
  }, [appspace, organizationId, orgProfileId]);

  return (
    <Layout>
      <AppContext
        title={org && org.full_name}
        backUrl={`/admin/${appspace}/organizations`}
        breadcrumbs={[
          {
            name: __('Späť na zoznam'),
            url: `/admin/${appspace}/organizations`,
          },
        ]}
      />
      <Tabber activeTab={TABS.contactPersons} />
      <Segment>
        <Segment raised loading={isFetching || isFetchingContactPersons}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Osoba')}</Th>
                <Th>{__('Pozícia')}</Th>
                <Th>{__('Email')}</Th>
                <Th>{__('Telefón')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {contactPersons.map(item => {
                return (
                  <Tr key={item._id}>
                    <Td>
                      <strong>
                        {item.name} {item.surname}
                      </strong>
                      <br />
                      <Faded>{item.sportnetId}</Faded>
                    </Td>
                    <Td>{item.position}</Td>
                    <Td>
                      <a href={`mailto:${item.email}`}>{item.email}</a>
                    </Td>
                    <Td>
                      <a href={`tel:${item.phone}`}>{item.phone}</a>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Segment>
      </Segment>
    </Layout>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { organizationId },
    },
  },
) => ({
  org: orgEntitySelector(state, organizationId),
  didFailed: orgIsErrorSelector(state, organizationId),
  isFetching: orgIsFetchingSelector(state, organizationId),
  acl: aclSelector(state),
});

export default connect(mapStateToProps)(ContactPersons);
