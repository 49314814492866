/*
 * Komponent vytvara tabulku v style key-value zaznamov, t.j. tabulka nema
 * header, prvy stlpec je zvyrazneny (key) a za nim nasleduju stlpce hodnot
 * (values).
 *
 * Kedze `values` je array, pre jeden kluc je mozne zobrazit viacero hodnot
 * vedla seba.
 */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Table, Tbody, Thead, Tr, Th, Td } from '@sportnet/ui/Table';

const TKeyVal = styled(Table)``;

const TKey = styled(Td)`
  font-weight: bold;
  font-size: ${rem(12)};
`;

const TVal = styled(Td)`
  font-size: ${rem(12)};
`;

const KeyValueTable = ({ rows, header = [] }) => {
  return (
    <TKeyVal>
      <Thead>
        {header && header.length > 0 && (
          <Tr>
            {header.map((val, idx) => {
              return <Th key={idx}>{val}</Th>;
            })}
            {rows.reduce(
              (r, v) => (r === undefined ? false : r === v.value),
              undefined,
            )}
          </Tr>
        )}
      </Thead>
      <Tbody>
        {rows &&
          rows.length > 0 &&
          rows.map((row, idx) => {
            return (
              <Tr key={`tr-${row.key}-${idx}`}>
                <TKey>{row.key}</TKey>
                {row.values.map((val, i) => {
                  return <TVal key={`val-${row.key}-${i}`}>{val}</TVal>;
                })}
              </Tr>
            );
          })}
      </Tbody>
    </TKeyVal>
  );
};

KeyValueTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      ),
    }),
  ),
  // header: PropTypes.arrayOf(PropTypes.string),
};

KeyValueTable.defaultProps = {
  rows: [],
  header: [],
};

export default KeyValueTable;
