import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isSubmitting, submit } from 'redux-form';
import { getProp } from '@sportnet/utilities';
import Api from '../../../../Api';
import { getRequestStatusName, __ } from '../../../../utilities';
import { appspaceDefinition } from '../../../App/definitions';
import { appspaceSelector } from '../../../App/selectors';
import { receiveRequestDetailSuccess } from '../actions';
import { sportExpertOrgRequestDefinition } from '../../definitions';
import StatusManager from '../Universal/StatusManager';
import Form, { formName } from './form';
import { addYears } from 'date-fns';
import Message from '@sportnet/ui/Message';

const mapStateToProps = state => {
  return {
    appspace: appspaceSelector(state),
    isFormSubmitting: isSubmitting(formName)(state),
  };
};

const LicenseManager = ({ dispatch, request, isFormSubmitting, appspace }) => {
  const [loading, setLoading] = React.useState(false);

  const initialFormValues = React.useMemo(() => {
    const now = new Date();

    return {
      verifiedByPerson: appspace.display_name || '',
      validFrom: now,
      validTo: addYears(now, 2),
    };
  }, [appspace.display_name]);

  const changeStatus = async status => {
    const confirmMessage = `${__('Naozaj chcete zmeniť stav žiadosti')} ${__(
      'z',
    )} ${getRequestStatusName(request.status)} ${__(
      'na',
    )} ${getRequestStatusName(status)}?`;
    try {
      setLoading(true);
      let newRequest = {};
      if (status === 'REJECTED') {
        const noteText = __('Napíšte dôvod zamietnutia:');
        // eslint-disable-next-line
        const note = window.prompt(`${confirmMessage}
        
${noteText}`);
        if (!note) return;
        newRequest = await Api.crmChangeRequestStatus(
          appspace.app_space,
          request._id,
          {
            data: { status, note },
          },
        );
      } else {
        // eslint-disable-next-line
        if (!window.confirm(confirmMessage)) return;
        newRequest = await Api.crmChangeRequestStatus(
          appspace.app_space,
          request._id,
          {
            data: { status },
          },
        );
      }
      dispatch(receiveRequestDetailSuccess(newRequest._id, newRequest));
    } catch (e) {
      // failed
      const error = getProp(e, ['details'], {});
      if (error.name === 'INVALID_IDNR') {
        // eslint-disable-next-line
        window.alert(__('Neplatné Identifikačné číslo používateľa'));
      } else if (error.statusCode === 409 && error.name === 'DUPLICATED_IDNR') {
        // eslint-disable-next-line
        window.alert(
          __(
            'Rodné číslo už v systéme existuje. Nie je možné schváliť žiadosť.',
          ),
        );
      } else if (
        error.statusCode === 409 &&
        error.name === 'DUPLICATED_REGNR'
      ) {
        // eslint-disable-next-line
        window.alert(
          __(
            'Registračné číslo už v systéme existuje. Skontrolujte prosím nastavenie sekvencera pre generovanie registračných čísiel.',
          ),
        );
      } else if (
        error.statusCode === 403 &&
        error.name === 'GUARDIAN_FORBIDDEN'
      ) {
        // eslint-disable-next-line
        window.alert(
          __(
            'Zadávateľ žiadosti nie je oprávnená osoba na registráciu zadaného rodného čísla.',
          ),
        );
      } else {
        // eslint-disable-next-line
        window.alert(
          `${__('Došlo k neznámej chybe')} (${error.statusCode}:${error.name}:${
            error.description
          })`,
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    changeStatus('CANCELLED');
  };

  const handleNew = () => {
    changeStatus('NEW');
  };

  const handleReject = () => {
    changeStatus('REJECTED');
  };

  const handleConfirm = () => {
    dispatch(submit(formName));
  };

  const handleSubmit = async values => {
    const status = 'CONFIRMED';
    const confirmMessage = `${__('Naozaj chcete zmeniť stav žiadosti')} ${__(
      'z',
    )} ${getRequestStatusName(request.status)} ${__(
      'na',
    )} ${getRequestStatusName(status)}?`;
    // eslint-disable-next-line
    if (!window.confirm(confirmMessage)) return;
    try {
      const data = {
        ...values,
        licenseName: request?.data?.license?.licenseName,
        licenseType: request?.data?.license?.licenseType,
        licenseSubTypeId: request?.data?.license?.licenseSubTypeId,
        ownerUserSportnetId: request?.ownerUser?.sportnet_id,
      };

      const newRequest = await Api.crmConfirmLicenseRequest(
        appspace.app_space,
        request._id,
        {
          data,
        },
      );
      dispatch(receiveRequestDetailSuccess(newRequest._id, newRequest));
    } catch (e) {
      // failed
      const error = getProp(e, ['details'], {});
      if (error.name === 'INVALID_IDNR') {
        // eslint-disable-next-line
        window.alert(__('Neplatné Identifikačné číslo používateľa'));
      } else if (error.statusCode === 409 && error.name === 'DUPLICATED_IDNR') {
        // eslint-disable-next-line
        window.alert(__('Osoba so zadaným rodným číslom už existuje.'));
      } else if (
        error.statusCode === 403 &&
        error.name === 'GUARDIAN_FORBIDDEN'
      ) {
        // eslint-disable-next-line
        window.alert(
          __(
            'Zadávateľ žiadosti nie je oprávnená osoba na registráciu zadaného rodného čísla.',
          ),
        );
      } else {
        // eslint-disable-next-line
        window.alert(
          `${__('Došlo k neznámej chybe')} (${error.statusCode}:${error.name}:${
            error.description
          })`,
        );
      }
    }
  };

  const handleClose = () => {
    changeStatus('CLOSED');
  };

  return (
    <Fragment>
      <StatusManager
        request={request}
        loading={loading || isFormSubmitting}
        onCancel={handleCancel}
        onNew={handleNew}
        onReject={handleReject}
        onConfirm={handleConfirm}
        onClosed={handleClose}
      />

      {(request.status === 'APPROVED' ||
        request.status === 'PARTIALLY_CONFIRMED') && (
        <>
          <Message primary>
            Potvrdením tejto žiadosti sa žiadosť odošle do ISSF.
          </Message>
          <Form onSubmit={handleSubmit} initialValues={initialFormValues} />
        </>
      )}
    </Fragment>
  );
};

LicenseManager.propTypes = {
  request: sportExpertOrgRequestDefinition.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      appspace: PropTypes.string,
    }).isRequired,
  }).isRequired,
  appspace: appspaceDefinition.isRequired,
  isFormSubmitting: PropTypes.bool,
};

LicenseManager.defaultProps = {
  isFormSubmitting: false,
};

export default connect(mapStateToProps)(withRouter(LicenseManager));
