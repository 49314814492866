import API from '../../Api';
import { getProp } from '@sportnet/utilities';
import {
  REQUEST_TEMPLATES,
  RECEIVE_TEMPLATES_FAILURE,
  RECEIVE_TEMPLATES_SUCCESS,
} from './constants';
import { commit, getListParameters, CommitError } from '@sportnet/redux-list';

const requestTemplates = () => ({
  type: REQUEST_TEMPLATES,
});

const receiveTemplatesSuccess = templates => ({
  type: RECEIVE_TEMPLATES_SUCCESS,
  payload: {
    templates,
  },
});

const receiveTemplatesFailure = error => ({
  type: RECEIVE_TEMPLATES_FAILURE,
  payload: {
    error: error.description || error,
  },
});

export const getTemplates = (appspace, filter) => {
  return async dispatch => {
    dispatch(requestTemplates());
    try {
      const f = {
        offset: filter.offset,
        limit: filter.limit,
        q: filter.query,
      };
      if (getProp(filter, ['athlet_type'], []).length > 0) {
        f.athletType = filter.athlet_type;
      }
      if (getProp(filter, ['sport_expert_type'], []).length > 0) {
        f.sportExpertType = filter.sport_expert_type;
      }
      if (getProp(filter, ['license_type'], []).length > 0) {
        f.licenseType = filter.license_type;
      }
      const response = await API.crmGetCourierTemplates(appspace, f);
      dispatch(receiveTemplatesSuccess(response.templates));
      return {
        total: response.total,
        results: response.templates.map(template => template._id),
        nextOffset: response.next_offset,
      };
    } catch (e) {
      dispatch(receiveTemplatesFailure(e.details || e));
    }
    return null;
  };
};

export const TEMPLATES_LIST_NAME = 'TEMPLATES';

export const loadTemplatesList = ({ appSpace }) => {
  return (dispatch, getState) => {
    const params = getListParameters(TEMPLATES_LIST_NAME)(getState());
    return dispatch(
      commit.action({
        listName: TEMPLATES_LIST_NAME,
        load: async () => {
          try {
            const { total, results, nextOffset } = await dispatch(
              getTemplates(appSpace, params),
            );

            return {
              total,
              nextOffset,
              results,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};
