import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Segment from '@sportnet/ui/Segment';
import Sidebar from '@sportnet/ui/Sidebar';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Api from '../../../../Api';
import Layout from '../../../../components/Layout';
import { getStatusIcon, __ } from '../../../../utilities';
import { aclSelector } from '../../../App/selectors';
import {
  orgEntitySelector,
  orgIsErrorSelector,
  orgIsFetchingSelector,
} from '../selectors';
import Tabber, { TABS } from '../Tabber';
import MembershipForm, { STATUSES as membershipStatuses } from './form';

export const Faded = styled.span`
  color: ${({ theme }) => theme.color.fadedText};
`;

const List = ({
  org,
  match: {
    params: { appspace, orgProfileId },
  },
  isFetching,
}) => {
  const [editingItem, setEditingItem] = useState();
  const [isFetchingMembership, setIsFetchingMembership] = useState(false);
  const [memberships, setMemberships] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [lastDataFetch, setLastDataFetch] = useState();

  const submit = async data => {
    let promis = null;
    if (data._id) {
      promis = Api.crmPPOMembershipUpdate(orgProfileId, data._id, appspace, {
        data: {
          status: data.status,
          valid_from: data.valid_from,
          valid_to: data.valid_to,
        },
      });
    } else {
      promis = Api.crmPPOMembershipCreate(appspace, orgProfileId, {
        data: {
          status: data.status,
          valid_from: data.valid_from,
          valid_to: data.valid_to,
        },
      });
    }
    return promis
      .then(() => {
        setEditingItem(null);
        setLastDataFetch(new Date());
      })
      .catch(e => {
        alert(
          e.details
            ? e.details.userinfo || __('Pri ukladaní členstva nastala chyba')
            : e,
        );
      });
  };
  const onDelete = async ({ _id: itemId }) => {
    if (!window.confirm(__('Prajete si odstrániť záznam členstva?'))) {
      return false;
    }
    setIsDeleting(true);
    return Api.crmPPOMembershipDelete(orgProfileId, itemId, appspace)
      .then(() => {
        setEditingItem(null);
        setMemberships(memberships.filter(({ _id }) => _id !== itemId));
      })
      .catch(e => {
        alert(
          e.details
            ? e.details.userinfo ||
                __('Pri odstraňovaní členstva nastala chyba')
            : e,
        );
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  useEffect(() => {
    setIsFetchingMembership(true);
    Api.crmPPOMembership(appspace, orgProfileId)
      .then(({ membership }) => {
        setMemberships(membership);
      })
      .catch(e => {
        alert(
          e.details
            ? e.details.userinfo || __('Pri získavaní členstiev nastala chyba')
            : e,
        );
      })
      .finally(() => {
        setIsFetchingMembership(false);
      });
  }, [org, appspace, orgProfileId, lastDataFetch]);

  return (
    <Layout
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              primary
              onClick={() => {
                setEditingItem({});
              }}
            >
              {__('Pridať členstvo')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext
        title={org && org.full_name}
        backUrl={`/admin/${appspace}/organizations`}
        breadcrumbs={[
          {
            name: __('Späť na zoznam'),
            url: `/admin/${appspace}/organizations`,
          },
        ]}
      />
      <Tabber activeTab={TABS.membership} />
      <Segment>
        <Segment raised loading={isFetching || isFetchingMembership}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Organizácia')}</Th>
                <Th>{__('Od-do')}</Th>
                <Th>{__('Stav')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {memberships.map(item => {
                const trProps = {
                  key: item._id,
                  onClick: () => {
                    setEditingItem(item);
                  },
                };
                return (
                  <Tr {...trProps}>
                    <Td>
                      {item.org_name}
                      <br />
                      <Faded>{item.org_profile_name}</Faded>
                    </Td>
                    <Td>
                      {item.valid_from
                        ? moment(item.valid_from).format('DD.MM.YYYY')
                        : '?'}{' '}
                      -{' '}
                      {item.valid_to
                        ? moment(item.valid_to).format('DD.MM.YYYY')
                        : '?'}
                    </Td>
                    <Td>
                      {getStatusIcon(item.status, status => {
                        const statusItem = membershipStatuses.find(
                          ({ value }) => value === status,
                        );
                        return statusItem ? statusItem.label : status;
                      })}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Segment>
      </Segment>
      <Sidebar
        header={__('Členstvo')}
        visible={editingItem}
        onClose={() => {
          setEditingItem(null);
        }}
      >
        {editingItem && (
          <MembershipForm
            data={editingItem}
            onSubmit={submit}
            onDelete={onDelete}
            deleting={isDeleting}
          />
        )}
      </Sidebar>
    </Layout>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { organizationId },
    },
  },
) => ({
  org: orgEntitySelector(state, organizationId),
  didFailed: orgIsErrorSelector(state, organizationId),
  isFetching: orgIsFetchingSelector(state, organizationId),
  acl: aclSelector(state),
});

export default connect(mapStateToProps)(List);
