import Label from '@sportnet/ui/Label/Label';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { formValueSelector, isSubmitting, submit } from 'redux-form';
import styled from 'styled-components';
import Api from '../../../../Api';
import { getRequestStatusName, __ } from '../../../../utilities';
import {
  appsIsFetchingSelector,
  appspaceSelector,
  selectedAppDetailsSelector,
} from '../../../App/selectors';
import { PERSON_POSITION_TYPES } from '../../../Forms/DSGEditRequest/form';
import { universalRequestDefinition } from '../../definitions';
import { receiveRequestDetailSuccess } from '../actions';
import StatusManager from '../Universal/StatusManager';
import AppForm from './appForm';

const ALLOWED_APPS = {
  CRM: true,
  dajmespolugol: true,
  kruzkymcd: true,
  disney: true,
};

const formName = 'DSG_EDIT_REQUEST_FORM';

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.textColor};
`;

export const getPositionLabel = value => {
  const position = PERSON_POSITION_TYPES.find(i => i.value === value);
  return position ? position.label : value;
};

class DSGEditManager extends React.PureComponent {
  state = {
    loading: false,
  };

  changeStatus = async (status, values) => {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      request,
    } = this.props;
    const confirmMessage = `${__('Naozaj chcete zmeniť stav žiadosti')}
${__('z')} ${getRequestStatusName(request.status)} ${__(
      'na',
    )} ${getRequestStatusName(status)}?`;

    try {
      this.setState({
        loading: true,
      });
      let newRequest = {};
      if (status === 'REJECTED') {
        const noteText = __('Napíšte dôvod zamietnutia:');
        // eslint-disable-next-line
        const note = window.prompt(`${confirmMessage}
        
${noteText}`);
        if (!note) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status, note },
        });
      } else if (status === 'CONFIRMED') {
        // eslint-disable-next-line
        if (!window.confirm(confirmMessage)) return;

        const registerApps = [];
        Object.keys(values.registerApps || {}).forEach(key => {
          const app = values.registerApps[key];
          const userApps = Object.keys(app).reduce((acc, userId) => {
            const user = this.props.request.data.users.find(
              u => u.user._id === userId,
            );
            if (app[userId].register) {
              acc.push({
                app_id: key,
                userId,
                userName: user.user.name,
                role: app[userId].role,
                grant: app[userId].grant || false,
              });
            }
            return acc;
          }, []);
          registerApps.push(...userApps);
        });

        newRequest = await Api.crmConfirmDSGEditRequest(appspace, request._id, {
          data: { ppo: { register_apps: registerApps } },
        });
      } else {
        // eslint-disable-next-line
        if (!window.confirm(confirmMessage)) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status },
        });
      }
      dispatch(receiveRequestDetailSuccess(newRequest._id, newRequest));
    } catch (e) {
      // failed
      // eslint-disable-next-line
      window.alert(__('Došlo k neznámej chybe'));
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleReject = () => {
    this.changeStatus('REJECTED');
  };

  handleConfirm = values => {
    this.changeStatus('CONFIRMED', values);
  };

  handleClose = () => {
    this.changeStatus('CLOSED');
  };

  render() {
    const {
      request,
      isFormSubmitting,
      areAppsFetching,
      request: {
        data: { targetGroups = [], users = [] },
      },
    } = this.props;

    const removedUsers = users.filter(u => u.type === 'remove');
    const addedUsers = users.filter(u => u.type === 'add');

    return (
      <React.Fragment>
        <StatusManager
          request={request}
          loading={this.state.loading || isFormSubmitting || areAppsFetching}
          onReject={this.handleReject}
          onConfirm={() => {
            this.props.dispatch(submit(formName));
          }}
          onClosed={this.handleClose}
        />
        <Segment>
          {targetGroups.map((targetGroup, idx) => {
            if (!targetGroup.iban) {
              return null;
            }
            return (
              <Segment raised>
                <Label>
                  {__(
                    'Nový bankový účet na vyplatenie finančných prostriedkov za cieľovú skupinu',
                  )}{' '}
                  {idx + 1}:
                </Label>
                <Subtitle>
                  <span style={{ fontSize: rem(13) }}>{targetGroup.iban}</span>
                </Subtitle>
              </Segment>
            );
          })}
          {removedUsers.map(user => (
            <Segment
              raised
              header={
                <SegmentHeader
                  size="xs"
                  subtitle={
                    <Subtitle>
                      <b>{__('Odstránená oprávnená osoba')}</b>
                      <br />
                      {getPositionLabel(user.user.type)}
                      <br />
                      {user.user.email}
                    </Subtitle>
                  }
                >
                  {user.user.name}
                </SegmentHeader>
              }
            />
          ))}
          <AppForm
            form={formName}
            request={this.props.request}
            onSubmit={this.handleConfirm}
            users={addedUsers}
            apps={this.props.apps}
            registerApps={this.props.registerApps}
          />
        </Segment>
      </React.Fragment>
    );
  }
}

DSGEditManager.propTypes = {
  request: universalRequestDefinition.isRequired,
  isFormSubmitting: PropTypes.bool,
  areAppsFetching: PropTypes.bool.isRequired,
  apps: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string,
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          role: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  registerApps: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
};

DSGEditManager.defaultProps = {
  isFormSubmitting: false,
};

const mapStateToProps = state => {
  const appsFormSelector = formValueSelector(formName);
  return {
    appspace: appspaceSelector(state),
    isFormSubmitting: isSubmitting(formName)(state),
    areAppsFetching: appsIsFetchingSelector(state),
    apps: selectedAppDetailsSelector(ALLOWED_APPS)(state),
    registerApps: appsFormSelector(state, 'registerApps'),
  };
};

export default compose(withRouter, connect(mapStateToProps))(DSGEditManager);
