import { Td, Tr } from '@sportnet/ui/Table';
import Icon from '@sportnet/ui/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { expertCompetenceCodelistSelector } from '../../../App/selectors';
import SectorLabel from '../../../SectorLabel';
import { codelistDefinition, expertDefinition } from '../../definitions';
import { Faded } from '../../detail';
import { getActiveUserExpertsSelector } from '../../selectors';
import { formatDate } from '@sportnet/content/view/utilities';

const ExpertsList = ({ competences, updateDisabled, onClickRow, experts }) => {
  const getExpertCompetence = code => {
    const item = competences.find(competence => {
      return competence.value === code;
    });
    return item?.label ?? code;
  };

  return experts.map(item => {
    return (
      <Tr
        key={item._id}
        onClick={() => {
          if (!updateDisabled) {
            onClickRow(item, 'sport_expert_orgs');
          }
        }}
        style={
          !item.isActive
            ? { color: '#aaa', textDecoration: 'line-through' }
            : {}
        }
      >
        <Td>
          {item.isActive && (
            <>
              <Icon size="s" color="green" name="check-circle" />{' '}
            </>
          )}
          <strong>{item.org_profile_name}</strong>
          {item.org_name !== item.org_profile_name && (
            <Faded> ({item.org_name})</Faded>
          )}
        </Td>
        <Td>
          <SectorLabel sector={item.sector} />
        </Td>
        <Td>{getExpertCompetence(item.competence_type)}</Td>
        <Td>
          {formatDate(item.date_from)} -{' '}
          {item.date_to ? formatDate(item.date_to) : '?'}
        </Td>
        <Td>{item.valid_to && formatDate(item.valid_to)}</Td>
      </Tr>
    );
  });
};

ExpertsList.propTypes = {
  updateDisabled: PropTypes.bool.isRequired,
  onClickRow: PropTypes.func.isRequired,
  competences: PropTypes.arrayOf(codelistDefinition).isRequired,
  experts: PropTypes.arrayOf(expertDefinition).isRequired,
};

const mapStateToProps = state => {
  return {
    experts: getActiveUserExpertsSelector(state),
    competences: expertCompetenceCodelistSelector(state),
  };
};

export default connect(mapStateToProps)(ExpertsList);
