import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';

const entitiesDomainSelector = state => state.entities;

const invoicesDomainSelector = createSelector(
  entitiesDomainSelector,
  substate => substate.Invoices,
);

export const invoicesCurrentListSelector = listName =>
  createSelector(
    invoicesDomainSelector,
    getListResults(listName),
    (entities, ids) => {
      const list = (ids || []).reduce((acc, id) => {
        if (entities[id]) {
          return [...acc, entities[id]];
        }
        return acc;
      }, []);
      return list;
    },
  );
