import Button from '@sportnet/ui/Button';
import React, { useState, useEffect } from 'react';
import { Field, Form, reduxForm, change, formValueSelector } from 'redux-form';
import FormField from '@sportnet/ui/FormField/redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Segment from '@sportnet/ui/Segment';
import Api from '../../../../Api';
import { __ } from '../../../../utilities';
import { isValidEmail, isValidPhone, required } from '../../../../validation';

const FORM_NAME = 'PPO_CONTACT_PERSON_FORM';

const ContactPersonForm = ({
  appspace,
  onSuccess,
  onDelete,
  dispatch,
  handleSubmit,
  submitting,
  initialValues,
  selectedSportnetId,
}) => {
  const [isFetchingScreen, setIsFetchingScreen] = useState(0);
  const [isDeleting, setIsDeleting] = useState(0);
  const [personPositions, setPersonPositions] = useState([]);
  const [currentRolesPersons, setCurrentRolesPersons] = useState([]);

  useEffect(() => {
    // fetchneme ciselnik pozicii
    setIsFetchingScreen(i => i + 1);
    Api.getPPOCodelist(appspace, 'person-position')
      .then(({ codelist }) => {
        setPersonPositions(
          codelist.map(item => {
            return { ...item, label: item.label || item.value };
          }),
        );
      })
      .catch(e => {
        alert(
          e.details
            ? e.details.userinfo ||
                __('Pri získavaní číselníka pozícií nastala chyba')
            : e,
        );
      })
      .finally(() => {
        setIsFetchingScreen(i => i - 1);
      });
    // fetchneme zoznam osob z roles apky
    setIsFetchingScreen(i => i + 1);
    Api.appgrantGetRoles(process.env.REACT_APP_APP_ID, appspace)
      .then(({ users }) => {
        setCurrentRolesPersons([
          ...users.map(item => {
            const [name, ...surname] = item.display_name.split(' ');
            return {
              label: item.display_name,
              value: item.user_id,
              sportnetId: item.user_id,
              name: name,
              surname: surname.join(' '),
              email: '',
              phone: '',
            };
          }),
          {
            value: '--invitation--',
            label: <strong>{__('Nová osoba')}</strong>,
          },
        ]);
      })
      .catch(e => {
        alert(
          e.details
            ? e.details.userinfo || __('Pri získavaní osôb nastala chyba')
            : e,
        );
      })
      .finally(() => {
        setIsFetchingScreen(i => i - 1);
      });
  }, [appspace]);

  const onSubmit = async data => {
    try {
      let contactPerson;
      if (initialValues._id) {
        contactPerson = await Api.crmMyOrganizationContactPersonUpdate(
          appspace,
          initialValues._id,
          {
            data: {
              name: data.name,
              surname: data.surname,
              position: data.position,
              email: [data.email],
              phone: data.phone ? [data.phone] : [],
            },
          },
        );
      } else {
        contactPerson = await Api.crmMyOrganizationContactPersonCreate(
          appspace,
          {
            data: {
              ...data,
              email: [data.email],
              phone: data.phone ? [data.phone] : [],
              sportnetId:
                data.sportnetId === '--invitation--' ? null : data.sportnetId,
            },
          },
        );
      }
      onSuccess(contactPerson);
    } catch (e) {
      alert(
        e.details
          ? e.details.userinfo ||
              __(
                'Pri pridávaní osoby nastala chyba. Skontrolujte prosím správnosť údajov.',
              )
          : e,
      );
    }
    return true;
  };

  return (
    <React.Fragment>
      <Segment loading={isFetchingScreen > 0 || submitting}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {!initialValues._id && (
            <Field
              label={__('Zvoľte zo zoznamu')}
              info={
                <div style={{ maxWidth: '200px' }}>
                  {__(
                    'Zoznam obsahuje osoby, ktoré majú prístup do CRM. Ak osoba nemá prístup, zvoľte položku "nová osoba" a tá bude pozvaná pomocou pozvánky zaslanej na jej email.',
                  )}
                </div>
              }
              name="sportnetId"
              disabled={submitting}
              component={FormField}
              type="theselect"
              validate={[required]}
              options={currentRolesPersons}
              required
              parse={value => {
                return value ? value.value || null : null;
              }}
              format={v => {
                return currentRolesPersons.find(({ value }) => value === v);
              }}
              onChange={person => {
                const {
                  name = '',
                  surname = '',
                  email = '',
                  phone = '',
                } = person || {};
                dispatch(change(FORM_NAME, 'name', name));
                dispatch(change(FORM_NAME, 'surname', surname));
                dispatch(change(FORM_NAME, 'email', email));
                dispatch(change(FORM_NAME, 'phone', phone));
              }}
            />
          )}
          {selectedSportnetId !== '--invitation--' && (
            <Field
              label={__('SportNet ID')}
              name="sportnetId"
              component={FormField}
              type="text"
              disabled={true}
            />
          )}
          <Field
            label={__('Meno')}
            name="name"
            component={FormField}
            type="text"
            validate={[required]}
            required
            disabled={submitting}
          />
          <Field
            label={__('Priezvisko')}
            name="surname"
            component={FormField}
            type="text"
            validate={[required]}
            required
            disabled={submitting}
          />
          <Field
            label={__('Pozícia')}
            name="position"
            component={FormField}
            type="theselectsimple"
            validate={[required]}
            required
            options={personPositions}
            disabled={submitting}
          />
          <Field
            label={__('Email')}
            name="email"
            component={FormField}
            type="text"
            validate={[required, isValidEmail]}
            required
            disabled={submitting}
          />
          <Field
            label={__('Telefón')}
            name="phone"
            info={
              <div style={{ maxWidth: '200px' }}>
                {__('Zadávajte v medzinárodnom formáte 00421... alebo +421...')}
              </div>
            }
            component={FormField}
            type="text"
            validate={[isValidPhone]}
            disabled={submitting}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {initialValues._id ? (
              <Button
                type="button"
                danger
                disabled={submitting || isDeleting}
                loading={isDeleting}
                onClick={async () => {
                  if (window.confirm(__('Odstrániť kontaktnú osobu?'))) {
                    setIsDeleting(true);
                    try {
                      await Api.crmMyOrganizationContactPersonDelete(
                        appspace,
                        initialValues._id,
                      );
                      onDelete(initialValues._id);
                    } catch (e) {
                      alert(
                        e.details
                          ? e.details.userinfo ||
                              __(
                                'Pri pridávaní osoby nastala chyba. Skontrolujte prosím správnosť údajov.',
                              )
                          : e,
                      );
                    } finally {
                      setIsDeleting(false);
                    }
                  }
                }}
              >
                {__('Odstrániť')}
              </Button>
            ) : (
              <div></div>
            )}
            <Button
              primary
              type="submit"
              loading={submitting}
              disabled={submitting}
            >
              {__('Uložiť')}
            </Button>
          </div>
        </Form>
      </Segment>
    </React.Fragment>
  );
};

ContactPersonForm.propTypes = {
  appspace: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = state => {
  return {
    selectedSportnetId: selector(state, 'sportnetId') || '',
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
  })(ContactPersonForm),
);
