import Button from '@sportnet/ui/Button';
import Checkbox from '@sportnet/ui/Checkbox';
import FormField from '@sportnet/ui/FormField';
import FormGroup from '@sportnet/ui/FormGroup';
import Header from '@sportnet/ui/Header';
import Input from '@sportnet/ui/Input';
import Label from '@sportnet/ui/Label/Label';
import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, Form, getFormMeta, reduxForm } from 'redux-form';
import { formatUserName, getProp } from '@sportnet/utilities';
import API from '../../Api';
import { __ } from '../../utilities';
import { groupDefinition } from '../Groups/definitions';

const FORM_NAME = 'USER_GROUP_FORM';

class GroupForm extends React.Component {
  state = {
    userSearchValue: '',
    user: null,
    userIsFetching: false,
  };

  handleChangeUserSearchValue = e => {
    const { dispatch } = this.props;
    if (e.target.value.indexOf('@') >= 0) {
      this.setState({
        userSearchValue: e.target.value,
        user: { email: e.target.value },
      });
      dispatch(change(FORM_NAME, 'email', e.target.value));
      dispatch(change(FORM_NAME, 'sportnetId', ''));
    } else {
      this.setState({ userSearchValue: e.target.value, user: null });
    }
  };

  handleSubmitSearch = async event => {
    const { dispatch } = this.props;
    event.preventDefault();
    const { userSearchValue } = this.state;
    if (userSearchValue.indexOf('@') > 0) {
      this.setState({
        user: { email: userSearchValue },
        userIsFetching: false,
      });
      dispatch(change(FORM_NAME, 'email', userSearchValue));
      dispatch(change(FORM_NAME, 'sportnetId', ''));
    } else {
      try {
        this.setState({ user: null, userIsFetching: true });
        const response = await API.getPublicUserProfile(userSearchValue);
        dispatch(change(FORM_NAME, 'sportnetId', response._id));
        dispatch(change(FORM_NAME, 'email', ''));
        this.setState({ user: response, userIsFetching: false });
      } catch (e) {
        this.setState({ user: false, userIsFetching: false });
      }
    }
  };

  renderGroupsField = fieldProps => {
    const {
      parameters: { availableGroups },
    } = this.props;
    const {
      input: { value, onChange },
    } = fieldProps;
    const selectedGroups = typeof value === 'string' ? [] : value;
    return availableGroups.map(grp => (
      <Label htmlFor={grp._id} key={grp._id}>
        <Checkbox
          id={grp._id}
          checked={value.includes(grp._id)}
          onChange={e => {
            const idx = selectedGroups.indexOf(grp._id);
            let newGroups = selectedGroups.slice();
            if (idx !== -1) {
              newGroups = [
                ...selectedGroups.slice(0, idx),
                ...selectedGroups.slice(idx + 1),
              ];
            }
            if (e.target.checked) {
              newGroups.push(grp._id);
            }
            onChange(newGroups);
          }}
        />
        {grp.name}
      </Label>
    ));
  };

  renderUser = () => {
    const {
      parameters: { selectedUser },
    } = this.props;
    const { user } = this.state;

    if (selectedUser !== null) {
      return this.renderUserForm(selectedUser);
    }

    if (user === false) {
      return <Header>{__('Osoba nebola nájdená')}</Header>;
    } else if (user === null) {
      return null;
    }

    return this.renderUserForm(user);
  };

  renderUserForm = user => {
    const { handleSubmit, submitting } = this.props;
    const { _id } = user;

    return (
      <Segment>
        <Form onSubmit={handleSubmit}>
          {_id && (
            <FormField
              label={__('Meno')}
              name="_id"
              type="text"
              readOnly
              value={formatUserName(user)}
            />
          )}
          <FormGroup>
            <Label>{__('Zaradenie do skupín')}</Label>
            <Field name="groups" component={this.renderGroupsField} />
          </FormGroup>
          <Button
            type="submit"
            loading={submitting}
            primary
            data-testid="CRM_Skupina_Button_AddSave"
          >
            {_id ? __('Uložiť') : __('Odoslať pozvánku')}
          </Button>
        </Form>
      </Segment>
    );
  };

  render() {
    const {
      parameters: { selectedUser },
    } = this.props;
    const { userSearchValue, userIsFetching, user } = this.state;
    return (
      <React.Fragment>
        <Segment>
          {selectedUser === null && (
            <FormField
              label={__('Používateľ')}
              type="text"
              placeholder={__('Zadajte SportneID alebo email')}
              onChange={this.handleChangeUserSearchValue}
              required
              value={userSearchValue}
              data-testid="CRM_Skupina_Input_SearchID"
            >
              <input />
              {userIsFetching && <Input.Loading />}
              {(!user || !user.email) && (
                <Input.Button
                  onClick={this.handleSubmitSearch}
                  primary
                  icon={
                    userSearchValue.indexOf('@') >= 0 ? 'arrow-right' : 'search'
                  }
                  data-testid="CRM_Skupina_Button_SearchID"
                />
              )}
            </FormField>
          )}
          {this.renderUser()}
        </Segment>
      </React.Fragment>
    );
  }
}

GroupForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  parameters: PropTypes.shape({
    selectedGroup: PropTypes.shape({}),
    availableGroups: PropTypes.arrayOf(groupDefinition),
  }).isRequired,
  selectedUser: PropTypes.shape({}),
};

GroupForm.defaultProps = { selectedUser: null };

const connected = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(GroupForm);

const mapStateToProps = (state, props) => {
  return {
    formMeta: getFormMeta(FORM_NAME)(state),
    initialValues: getProp(props, ['parameters', 'selectedUser'], {}),
  };
};

export default connect(mapStateToProps)(connected);
