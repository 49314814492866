import { ACCESS_TOKEN_COOKIE_NAME } from '@sportnet/ui/Authorization';
import cookies from 'js-cookie';
import moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Api from '../../Api';
import OverlayLoader from '../../components/OverlayLoader';
import { getProp, __ } from '../../utilities';

class PaymentCallback extends React.PureComponent {
  async componentDidMount() {
    try {
      const {
        location: { search },
        match: {
          params: { appspace },
        },
      } = this.props;
      const rawParams = Api.paramsToObject(search);
      const params = Object.keys(rawParams).reduce((acc, next) => {
        return { ...acc, [next]: rawParams[next] };
      }, {});
      const token = cookies.get(ACCESS_TOKEN_COOKIE_NAME) || '';
      const headers = {};
      headers['Accept'] = ['application/json'];
      headers['Content-Type'] = ['application/json'];
      headers['Authorization'] = `Bearer ${token}`;
      const res = await Api.request(
        'GET',
        `${process.env.REACT_APP_API_BASE_URL}/crm/${appspace}/app/${params.ORDERNUMBER}/payment/result`,
        {},
        headers,
        params,
      );
      alert(
        __(
          `Ďakujeme, Vaša platba bola úspešne spracovaná. Aplikácia je platná do ${moment(
            res.validTo,
          ).format('DD.MM.YYYY')}`,
        ),
      );
    } catch (e) {
      if (getProp(e, ['details', 'userinfo'])) {
        alert(e.details.userinfo);
      } else if (getProp(e, ['details', 'description'])) {
        if (e.details.description === 'ORDER_ALREADY_PAYED') {
          alert(__('Objednávka už bola uhradená'));
        } else {
          alert(e.details.description);
        }
      }
    } finally {
      const {
        match: {
          params: { appspace },
        },
        history: { push },
      } = this.props;
      push(`/admin/${appspace}/apps`);
    }
  }
  render() {
    return <OverlayLoader text={__('Overujem platbu...')} />;
  }
}

export default withRouter(PaymentCallback);
