import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField/redux-form';
import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';
import { __ } from '../../../../utilities';
import { required } from '../../../../validation';
import { bankAccountTypeCodelistSelector } from '../../../App/selectors';
import { codelistDefinition } from '../../../FO/definitions';

const FORM_NAME = 'BANK_ACCOUNT_FORM';

const BankAccountForm = ({
  initialValues,
  bankAccountsTypes,
  handleSubmit,
}) => {
  return (
    <Segment>
      <Form onSubmit={handleSubmit}>
        <Field
          component={FormField}
          name="IBAN"
          validate={[required]}
          required
          label={__('IBAN')}
          data-testid="CRM_FO_Input_Bank_IBAN"
        />
        <Field
          label={__('Typ')}
          component={FormField}
          type="theselectsimple"
          name="type"
          validate={[required]}
          required
          options={bankAccountsTypes.map(type => ({
            label: type.label,
            value: type.value,
          }))}
          data-testid="CRM_FO_Input_Bank_Type"
        />
        <Field
          label={__('Stav záznamu')}
          component={FormField}
          type="theselectsimple"
          name="status"
          validate={[required]}
          required
          options={[
            { label: __('Aktívny'), value: 'active' },
            { label: __('Neaktívny'), value: 'inactive' },
          ]}
          data-testid="CRM_FO_Input_Bank_State"
        />
        {!initialValues?._id && (
          <Button type="submit" primary data-testid="CRM_FO_Button_Bank_Save">
            {__('Pridať položku')}
          </Button>
        )}
        {initialValues?._id && (
          <Button type="submit" primary>
            {__('Upraviť položku')}
          </Button>
        )}
      </Form>
    </Segment>
  );
};

const mapStateToProps = state => {
  return {
    bankAccountsTypes: bankAccountTypeCodelistSelector(state),
  };
};

BankAccountForm.propTypes = {
  bankAccountsTypes: PropTypes.arrayOf(codelistDefinition).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(BankAccountForm);

export default connect(mapStateToProps)(connected);
