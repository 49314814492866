import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import { __ } from '../../../../utilities';
import Skeleton from '@sportnet/ui/Skeleton';

const MedicalExaminationsLoader = () => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>{__('Organizácia')}</Th>
          <Th>{__('Lekár')}</Th>
          <Th>{__('Platné od')}</Th>
          <Th>{__('Platné do')}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {Array.from(new Array(5)).map((item, idx) => {
          return (
            <Tr key={`loader-row-${idx}`}>
              <Td>
                <Skeleton width={250} height={17} />
              </Td>
              <Td>
                <Skeleton width={250} height={17} />
              </Td>
              <Td>
                <Skeleton width={250} height={17} />
              </Td>
              <Td>
                <Skeleton width={250} height={17} />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default MedicalExaminationsLoader;
