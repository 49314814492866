import { commit, CommitError, getListParameters } from '@sportnet/redux-list';
import Api from '../../../Api';
import { appspaceSelector } from '../../App/selectors';
import { PPOS_LIST, RECEIVE_PPOS_SUCCESS } from './constants';
import { removeAccents } from '@sportnet/utilities';

export const receivePPOSSuccess = ppos => ({
  type: RECEIVE_PPOS_SUCCESS,
  payload: {
    ppos,
  },
});

export const loadRelatedPPOS = (appspace, filter) => {
  return async dispatch => {
    const f = {};
    if (filter.ppoType.length) {
      f.ppoType = filter.ppoType;
    }
    if (filter.relationStatus.length) {
      f.relationStatus = filter.relationStatus;
    }
    const response = await Api.crmListRelatedPPOs(appspace, {
      ...f,
      with: 'shortNames',
    });
    dispatch(
      receivePPOSSuccess(
        response.items.map(ppo => ({
          ...ppo,
          fts: [
            removeAccents(ppo._id).toLowerCase(),
            removeAccents(ppo.name).toLowerCase(),
            removeAccents(ppo.organization_name || '').toLowerCase(),
            ...(ppo.shortnames || []).map(sn =>
              removeAccents(sn.shortname || '').toLowerCase(),
            ),
          ],
        })),
      ),
    );
    return {
      results: response.items.map(i => i._id),
      total: response.items.length,
    };
  };
};

export const loadRelatedPPOSList = () => {
  return (dispatch, getState) => {
    const params = getListParameters(PPOS_LIST)(getState());
    const appSpaceId = appspaceSelector(getState()).app_space;
    return dispatch(
      commit.action({
        listName: PPOS_LIST,
        load: async () => {
          try {
            const { total, results } = await dispatch(
              loadRelatedPPOS(appSpaceId, params),
            );
            return {
              total,
              results,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadPPO = appSpaceId => {
  return async dispatch => {
    try {
      const response = await Api.organizationPPOProfile(appSpaceId);
      dispatch(receivePPOSSuccess([response]));
    } catch (e) {
      console.error(e);
    }
  };
};
