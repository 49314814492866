import FormField from '@sportnet/ui/FormField/redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { __ } from '../../../utilities';
import {
  onlyNumbers,
  required,
  onlyNumbersGreatherThanZero,
} from '../../../validation';
import { convertToNumber } from './priceFormPart';

class PercentFormPart extends React.PureComponent {
  render() {
    const { handlePercentChange } = this.props;
    return (
      <>
        <Field
          label={__('Percentuálna sadzba')}
          name="pcn"
          required
          normalize={value => value && convertToNumber(value)}
          validate={[required, onlyNumbers, onlyNumbersGreatherThanZero]}
          component={FormField}
          onChange={handlePercentChange}
        />
      </>
    );
  }
}

PercentFormPart.propTypes = {
  handlePercentChange: PropTypes.func.isRequired,
};

export default connect()(PercentFormPart);
