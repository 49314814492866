import FormField from '@sportnet/ui/FormField/redux-form';
import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { __ } from '../../../utilities';
import { required } from '../../../validation';
import { convertToNumber } from './priceFormPart';

class ValidityFormPart extends React.PureComponent {
  render() {
    const { dateRestrictionInDays, dateRestrictionByDate, onCheckboxCheck } =
      this.props;
    return (
      <Segment>
        <Field
          name="dateRestrictionInDays"
          label={__('Platnosť určená počtom dní')}
          type="checkbox"
          component={FormField}
          onChange={e => {
            if (e.target.checked) {
              onCheckboxCheck('dateRestrictionByDate');
            }
          }}
        />

        {dateRestrictionInDays && (
          <Field
            label={__('Platné počet dní')}
            name="validity.days"
            normalize={value => value && convertToNumber(value)}
            component={FormField}
            validate={[required]}
          />
        )}

        <Field
          name="dateRestrictionByDate"
          label={__('Platnosť určená dátumom')}
          type="checkbox"
          component={FormField}
          onChange={e => {
            if (e.target.checked) {
              onCheckboxCheck('dateRestrictionInDays');
            }
          }}
        />

        {dateRestrictionByDate && (
          <Field
            label={__('Platné do dátumu')}
            name="validity.dateTo"
            component={FormField}
            type="date"
            validate={[required]}
          />
        )}
      </Segment>
    );
  }
}

ValidityFormPart.propTypes = {
  dateRestrictionByDate: PropTypes.bool.isRequired,
  dateRestrictionInDays: PropTypes.bool.isRequired,
  onCheckboxCheck: PropTypes.func.isRequired,
};

export default connect()(ValidityFormPart);
