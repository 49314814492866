import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import Label from '@sportnet/ui/Label/Label';
import { rem } from 'polished';
import React from 'react';
import getProp from '@sportnet/utilities/getProp';
import styled from 'styled-components';
import { __ } from '../../../../../utilities';
import { universalRequestDefinition } from '../../../definitions';

const Text = styled.div`
  font-size: ${rem(14)};
  color: #555555;
  padding: ${rem(5)} ${rem(10)};
`;

class PaymentItem extends React.PureComponent {
  render() {
    const { request } = this.props;
    return (request.paymentItem || []).map(paymentItem => (
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label>{__('Predmet platby')}:</Label>
            <Text>{paymentItem.name}</Text>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label>{__('Cena s DPH')}:</Label>
            <Text>
              {`${getProp(paymentItem, ['price', 'amount'])} ${getProp(
                paymentItem,
                ['price', 'currency'],
              )}`}
            </Text>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label>{__('Cena bez DPH')}:</Label>
            <Text>
              {`${getProp(paymentItem, ['price', 'amount_vat_excl'])} ${getProp(
                paymentItem,
                ['price', 'currency'],
              )}`}
            </Text>
          </FormGroup>
        </Col>
      </Row>
    ));
  }
}

PaymentItem.propTypes = {
  request: universalRequestDefinition.isRequired,
};

export default PaymentItem;
