import DefaultFilter from '@sportnet/ui/Filter';
import { useTheSelectOptions } from '@sportnet/ui/TheSelect/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { __ } from '../../../utilities';
import { organizationTypeSelector } from '../../App/selectors';
import { codelistDefinition } from '../../FO/definitions';

const Filter = ({ setQuery, query, types }) => {
  // types
  const [typesCodelist, ppoType] = useTheSelectOptions(
    types,
    item => item,
    query.ppoType,
    [types],
    { multiple: true },
  );

  const filterValue = {
    q: query.q,
    ppoType,
  };

  return (
    <DefaultFilter
      value={filterValue}
      onChange={nextFilterValue => {
        const nextQuery = {
          q: nextFilterValue.q ? nextFilterValue.q : '',
          ppoType: nextFilterValue.ppoType
            ? nextFilterValue.ppoType.map(i => i.value)
            : [],
          relationStatus: nextFilterValue.relationStatus
            ? nextFilterValue.relationStatus.map(i => i.value)
            : [],
        };
        setQuery(nextQuery);
      }}
      filters={[
        {
          type: 'query',
          name: 'q',
          placeholder: __(
            'Zadajte názov organizácie, názov profilu organizácie alebo ID profilu organizácie',
          ),
        },
        {
          type: 'select',
          name: 'ppoType',
          label: __('Typ organizácie'),
          options: typesCodelist,
          clearable: true,
          multiple: true,
        },
      ]}
    />
  );
};

const mapStateToProps = state => {
  return {
    types: organizationTypeSelector(state),
  };
};

Filter.propTypes = {
  query: PropTypes.shape({
    q: PropTypes.string.isRequired,
    ppoType: PropTypes.arrayOf(PropTypes.string).isRequired,
    relationStatus: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  setQuery: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(codelistDefinition).isRequired,
};

export default connect(mapStateToProps)(Filter);
