import PropTypes from 'prop-types';
import React from 'react';
import { FileObjectDefinition } from './definitions';
import FileInput from './index';

const FileInputRedux = ({ input, meta: { touched, error }, ...restProps }) => {
  const value = input.value || [];
  return (
    <FileInput
      {...input}
      {...restProps}
      value={value}
      error={error && touched}
    />
  );
};

FileInputRedux.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(FileObjectDefinition),
    ]),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  inputRef: PropTypes.func,
  readOnly: PropTypes.bool,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  onDeleteCallback: PropTypes.func,
  onFileClick: PropTypes.func,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
};

FileInputRedux.defaultProps = {
  className: '',
  id: null,
  readOnly: false,
  inputRef: () => {},
  accept: '',
  multiple: false,
  onDeleteCallback: () => {},
  onFileClick: null,
};

export default FileInputRedux;
