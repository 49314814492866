import BasicTable from '@sportnet/ui/BasicTable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getStatusIcon, useCodelist, __ } from '../../../../utilities';
import {
  addressTypeCodelistSelector,
  countryCodelistSelector,
} from '../../../App/selectors';
import { addressDefinition, codelistDefinition } from '../../../FO/definitions';

const Table = ({ items, addressTypes, countries }) => {
  const [getAddressTypeLabel] = useCodelist(addressTypes);
  const [getCountryLabel] = useCodelist(countries);

  return (
    <BasicTable
      columns={[
        {
          id: 'type',
          header: __('Typ'),
          width: 200,
        },
        {
          id: 'address',
          header: __('Adresa'),
        },
        {
          id: 'status',
          header: __('Stav'),
          width: 70,
        },
      ]}
      rows={items}
      renderRow={item => [
        getAddressTypeLabel(item.type),
        <div key="address">
          {`${item.street} ${item.number},`}
          &nbsp;
          {item.zip}
          &nbsp;
          {item.city}
          ,&nbsp;
          {getCountryLabel(item.country)}
        </div>,
        getStatusIcon(item.status),
      ]}
      rowKey="_id"
    />
  );
};

const mapStateToProps = state => {
  return {
    addressTypes: addressTypeCodelistSelector(state),
    countries: countryCodelistSelector(state),
  };
};

Table.propTypes = {
  items: PropTypes.arrayOf(addressDefinition).isRequired,
  addressTypes: PropTypes.arrayOf(codelistDefinition).isRequired,
  countries: PropTypes.arrayOf(codelistDefinition).isRequired,
};

export default connect(mapStateToProps)(Table);
