import BasicTable from '@sportnet/ui/BasicTable';
import { __ } from '../../utilities';
import { UserWithPhoto } from './commonStyles';
import Image from '@sportnet/ui/Image';
import Skeleton from '@sportnet/ui/Skeleton';

const UsersListLoader = () => {
  return (
    <BasicTable
      columns={[
        {
          id: 'person',
          header: __('Meno a priezvisko'),
        },
        {
          id: 'regnr',
          header: __('Registračné číslo'),
          sortable: false,
        },
        {
          id: '_id',
          header: __('Sportnet ID'),
        },
        {
          id: 'birthdate',
          header: __('Dátum narodenia'),
        },
        {
          id: 'ppoRelation',
          header: __('Vzťah'),
        },
      ]}
      rows={Array.from(new Array(16))}
      renderRow={() => [
        <UserWithPhoto>
          <Image src={undefined} width={35} height={35} />
          <Skeleton width={250} height={17} />
        </UserWithPhoto>,
        <Skeleton width={250} height={17} />,
        <Skeleton width={250} height={17} />,
        <Skeleton width={250} height={17} />,
      ]}
    />
  );
};

export default UsersListLoader;
