import PropTypes from 'prop-types';
import { addressDefinition, bankAccountDefinition } from '../FO/definitions';

export const PPODefinition = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sport: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  organization_id: PropTypes.string.isRequired,
  organization_name: PropTypes.string.isRequired,
  relation: PropTypes.shape({
    _id: PropTypes.string,
  }),
});

export const matchDefinition = PropTypes.shape({
  params: PropTypes.shape({
    organizationId: PropTypes.string,
    orgProfileId: PropTypes.string,
    appspace: PropTypes.string,
  }),
});

export const customDefinition = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
});

export const PODefinition = PropTypes.shape({
  _id: PropTypes.string,
  full_name: PropTypes.string,
  name: PropTypes.string,
  legal_form: PropTypes.string,
  business_id: PropTypes.string,
  vat_nr: PropTypes.string,
  email: PropTypes.string,
  www: PropTypes.string,
  phone: PropTypes.string,
  fax: PropTypes.string,
  contact_person: PropTypes.string,
  statutory: PropTypes.string,
  logo_public_url: PropTypes.string,
  favicon_public_url: PropTypes.string,
  addresses: PropTypes.arrayOf(addressDefinition),
  bank_accounts: PropTypes.arrayOf(bankAccountDefinition),
  custom: PropTypes.arrayOf(customDefinition),
});

export const appSpaceUserDefinition = PropTypes.shape({
  app_id: PropTypes.string.isRequired,
  user_id: PropTypes.string.isRequired,
  app_space: PropTypes.string.isRequired,
  display_name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
});
