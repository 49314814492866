import Loader from '@sportnet/ui/Loader';
import TheLayout from '@sportnet/ui/TheLayout';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { aclSelector } from '../../containers/Authorization/selectors';
import { LoaderContainer } from '../List';
import getCategories from '../../utilities/getCategories';
import { useAuth } from '@sportnet/auth-react/AuthProvider';
import AppContext from '../../context/AppContext';

const TopFixed = styled.div`
  background-color: #fff;
`;

const mapStateToProps = state => ({
  acl: aclSelector(state),
});

const Layout = ({ acl, topFixed, bottomFixed, isFetching, children }) => {
  const { activeAppSpace, appSpaces, appInfo, apps, notificationCount } =
    React.useContext(AppContext);

  const [categories, setCategories] = React.useState([]);

  const { ppo: appSpace, authUser, logout, accessToken } = useAuth();

  // nacitanie poloziek menu podla ACL
  React.useEffect(() => {
    setCategories(getCategories(appSpace, acl));
  }, [acl, appSpace]);

  const renderNavigationLink = ({ item, linkProps }) => {
    const url = item.url || '';
    const [, urlSearch] = url.split(/\?/);
    return (
      <NavLink
        {...linkProps}
        exact
        to={item.url || ''}
        isActive={(match, location) => {
          if (!match) {
            return false;
          }
          // handle menu items distinguished only by query parameter
          if (urlSearch) {
            return location.search.includes(urlSearch);
          }
          return true;
        }}
      >
        {linkProps.children}
      </NavLink>
    );
  };

  const userPictureSrc = authUser.photoIsPublic ? authUser.photoUrl : undefined;
  const userName = authUser.appSpace?.displayName;
  const grant = authUser?.appSpace?.grant ?? false;

  return (
    <TheLayout
      navigation={categories}
      userName={userName}
      notificationCount={notificationCount}
      userPictureSrc={userPictureSrc}
      top={<TopFixed>{topFixed}</TopFixed>}
      bottom={<div>{bottomFixed}</div>}
      onLogout={() => {
        logout();
      }}
      app={appInfo}
      appSpace={activeAppSpace}
      appSpaces={appSpaces}
      accessToken={accessToken}
      apps={apps}
      renderNavigationLink={renderNavigationLink}
      userSubName={activeAppSpace?.role ?? ''}
      useContext
      grant={grant}
    >
      {isFetching ? (
        <LoaderContainer>
          <Loader size="xl" />
        </LoaderContainer>
      ) : (
        children
      )}
    </TheLayout>
  );
};

Layout.propTypes = {
  topFixed: PropTypes.node,
  bottomFixed: PropTypes.node,
  children: PropTypes.node.isRequired,
  acl: PropTypes.shape({
    tagList: PropTypes.bool,
  }).isRequired,
  isFetching: PropTypes.bool,
};

Layout.defaultProps = {
  topFixed: null,
  bottomFixed: null,
  isFetching: false,
};

export default connect(mapStateToProps)(Layout);
