import Api from '../../../Api';
import {
  RECEIVE_ORGANIZATIONS_SUCCESS,
  RECEIVE_ORGANIZATION_FAILURE,
  RECEIVE_ORGANIZATION_SUCCESS,
  REQUEST_ORGANIZATION,
} from './constants';

const receiveOrganizationsSuccess = organizations => ({
  type: RECEIVE_ORGANIZATIONS_SUCCESS,
  payload: {
    organizations,
  },
});

const requestOrganization = id => ({
  type: REQUEST_ORGANIZATION,
  payload: {
    id,
  },
});

const receiveOrganizationSuccess = (id, data) => ({
  type: RECEIVE_ORGANIZATION_SUCCESS,
  payload: {
    id,
    data,
  },
});

const receiveOrganizationFailure = (id, e) => ({
  type: RECEIVE_ORGANIZATION_FAILURE,
  payload: {
    id,
    e,
  },
});

export const loadOrganization = (appspace, id) => {
  return async dispatch => {
    dispatch(requestOrganization(id));
    try {
      const response = await Api.crmOrganizationFullProfile(appspace, id);
      dispatch(receiveOrganizationsSuccess([response]));
      dispatch(receiveOrganizationSuccess(id, response));
    } catch (e) {
      dispatch(receiveOrganizationFailure(id, e.details || e));
    }
  };
};
