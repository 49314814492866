import Button from '@sportnet/ui/Button';
import { darken, rem } from 'polished';
import styled, { css } from 'styled-components';

const customDarken = (amount, color) => {
  if (color === 'transparent') {
    return `rgba(0, 0, 0, ${amount})`;
  }
  return darken(amount, color);
};

export const StyledButton = styled(Button)`
  background-color: ${({ color }) => color};
  color: #fff;
  border: ${rem(1)} solid ${({ color }) => color};
  ${({ disabled }) =>
    disabled
      ? ''
      : css`
          &:hover {
            background: ${({ color }) => customDarken(0.05, color)};
          }
          &:focus {
            background: ${({ color }) => customDarken(0.1, color)};
          }
          &:active {
            background: ${({ color }) => customDarken(0.2, color)};
          }
        `};
`;

export const Status = styled.div`
  padding: 0.5em 1.5em;
  border-radius: ${({ theme }) => theme.largeBorderRadius};
  display: inline-table;
  background-color: ${({ color }) => color};
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media print {
    color: black;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
`;

export const Spacer = styled.div`
  width: ${({ size = 20 }) => rem(size)};
`;
