import DefaultFilter from '@sportnet/ui/Filter';
import { useTheSelectOptions } from '@sportnet/ui/TheSelect/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { __ } from '../../utilities';
import {
  competenceCodelistSelector,
  expertCompetenceCodelistSelector,
  licenceCodelistSelector,
  myAndParentPPOsCodelistSelector,
} from '../App/selectors';
import { codelistDefinition } from './definitions';
import Button from '@sportnet/ui/Button';
import CoreApi from '../../Api';
import { useAuth } from '@sportnet/auth-react/AuthProvider';
import { useLocation } from 'react-router-dom';

const Filter = ({
  setQuery,
  query,
  competences,
  licenseTypes,
  expertCompetences,
  membershipToPPOCodelist,
}) => {
  const { ppo: appSpace } = useAuth();

  // competences
  const [competencesOptions, athletTypeValue] = useTheSelectOptions(
    competences,
    item => item,
    query.athlet_type,
    [competences],
    { multiple: true },
  );
  // expertCompetences
  const [expertCompetencesOptions, sportExpertType] = useTheSelectOptions(
    expertCompetences,
    item => item,
    query.sport_expert_type,
    [expertCompetences],
    { multiple: true },
  );
  // licenseTypes
  const [licenseTypesOptions, licenseType] = useTheSelectOptions(
    licenseTypes,
    item => item,
    query.license_type,
    [licenseTypes],
    { multiple: true },
  );
  // membershipToPPOCodelist
  const [, membershipToPPO] = useTheSelectOptions(
    membershipToPPOCodelist,
    item => item,
    query.membershipToPPO,
    [membershipToPPOCodelist],
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [searchQuery, setSearchQuery] = React.useState(
    queryParams.get('query') ?? '',
  );

  const [filterOptions, setFilterOptions] = React.useState([]);
  const [showOptions, setShowOptions] = React.useState(false);

  const clearTextSearch = () => {
    setSearchQuery('');
    handleSearch('');
  };

  const handleSearch = value => {
    setQuery({
      ...query,
      query: value,
    });

    setShowOptions(false);
  };

  const filterValue = {
    query: searchQuery,
    athlet_type: athletTypeValue,
    sport_expert_type: sportExpertType,
    license_type: licenseType,
    membershipToPPO,
    membershipValidTo: query.membershipValidTo,
    membershipValidToIsInvalid: { value: query.membershipValidToIsInvalid },
  };

  const additionalFilters = [];

  if (filterValue.membershipToPPO) {
    additionalFilters.push({
      type: 'select',
      name: 'membershipValidToIsInvalid',
      label: 'Členstvo je',
      options: [
        { label: __('aktívne'), value: 'false' },
        { label: __('neaktívne'), value: 'true' },
      ],
      clearable: true,
    });
    additionalFilters.push({
      type: 'date',
      name: 'membershipValidTo',
      label: __('K dátumu'),
      clearable: true,
    });
  }

  const getFilterOptions = React.useCallback(async () => {
    if (!!searchQuery && !!appSpace) {
      const { users } = await CoreApi.crmGetUsers(appSpace, {
        autocomplete: searchQuery,
        limit: 10,
      });

      const options = users.reduce((map, user) => {
        const highlightValue = user.highlights
          .map(h => h.texts.map(t => t.value))
          .flat()
          .join(' ');

        if (!map.has(highlightValue.toLowerCase())) {
          map.set(highlightValue.toLowerCase(), {
            value: highlightValue,
            label: <p>{highlightValue}</p>,
          });
        }

        return map;
      }, new Map());

      setFilterOptions(Array.from(options.values()));
    }
  }, [appSpace, searchQuery]);

  React.useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);

  return (
    <DefaultFilter
      value={filterValue}
      onChange={nextFilterValue => {
        const nextQuery = {
          offset: 0,
          athlet_type: nextFilterValue.athlet_type
            ? nextFilterValue.athlet_type.map(i => i.value)
            : [],
          sport_expert_type: nextFilterValue.sport_expert_type
            ? nextFilterValue.sport_expert_type.map(i => i.value)
            : [],
          license_type: nextFilterValue.license_type
            ? nextFilterValue.license_type.map(i => i.value)
            : [],
          membershipToPPO: nextFilterValue.membershipToPPO
            ? nextFilterValue.membershipToPPO.value
            : '',
          membershipValidTo: nextFilterValue.membershipValidTo
            ? moment(nextFilterValue.membershipValidTo).startOf('day').toJSON()
            : '',
          membershipValidToIsInvalid: nextFilterValue.membershipValidToIsInvalid
            ? nextFilterValue.membershipValidToIsInvalid.value
            : '',
        };
        if (
          nextQuery.membershipToPPO &&
          query.membershipToPPO !== nextQuery.membershipToPPO &&
          !nextQuery.membershipValidTo
        ) {
          nextQuery.membershipValidTo = moment().startOf('day').toJSON();
        }
        if (
          nextQuery.membershipToPPO &&
          query.membershipToPPO !== nextQuery.membershipToPPO &&
          !nextQuery.membershipValidToIsInvalid
        ) {
          nextQuery.membershipValidToIsInvalid = 'false';
        }

        setQuery(nextQuery);
      }}
      filters={[
        {
          type: 'query',
          name: 'query',
          placeholder: __(
            'Zadajte sportnet ID, meno, priezvisko, používateľské meno, email alebo rodné číslo',
          ),
        },
        {
          type: 'select',
          name: 'athlet_type',
          label: __('Typ príslušnosti športovca'),
          options: competencesOptions,
          clearable: true,
          multiple: true,
        },
        {
          type: 'select',
          name: 'sport_expert_type',
          label: __('Funkcia športového odborníka'),
          options: expertCompetencesOptions,
          clearable: true,
          multiple: true,
        },
        {
          type: 'select',
          name: 'license_type',
          label: __('Typ kvalifikácie / licencie'),
          options: licenseTypesOptions,
          clearable: true,
          multiple: true,
        },
        {
          type: 'select',
          name: 'membershipToPPO',
          label: __('Členstvo v organizácii'),
          options: membershipToPPOCodelist,
          clearable: true,
        },
        ...additionalFilters,
      ]}
      searchInputButton={
        <>
          {searchQuery && <Button icon="close" onClick={clearTextSearch} />}
          <Button primary onClick={() => handleSearch(searchQuery)}>
            {__('Hľadať')}
          </Button>
        </>
      }
      onInputEnter={() => handleSearch(searchQuery)}
      onInputChange={value => {
        setSearchQuery(value);
        setShowOptions(true);
      }}
      autocompleteOptions={showOptions ? filterOptions : []}
      onOptionSelect={value => {
        setShowOptions(false);
        handleSearch(value);
        setSearchQuery(value);
      }}
      onOptionsClose={() => {
        setShowOptions(false);
        setFilterOptions([]);
      }}
    />
  );
};

const mapStateToProps = state => {
  return {
    competences: competenceCodelistSelector(state),
    licenseTypes: licenceCodelistSelector(state),
    expertCompetences: expertCompetenceCodelistSelector(state),
    membershipToPPOCodelist: myAndParentPPOsCodelistSelector(state),
  };
};

Filter.propTypes = {
  query: PropTypes.shape({
    query: PropTypes.string.isRequired,
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    athlet_type: PropTypes.arrayOf(PropTypes.string).isRequired,
    sport_expert_type: PropTypes.arrayOf(PropTypes.string).isRequired,
    license_type: PropTypes.arrayOf(PropTypes.string).isRequired,
    membershipValidTo: PropTypes.string.isRequired,
    membershipValidToIsInvalid: PropTypes.string.isRequired,
    membershipToPPO: PropTypes.string.isRequired,
  }).isRequired,
  setQuery: PropTypes.func.isRequired,
  competences: PropTypes.arrayOf(codelistDefinition).isRequired,
  licenseTypes: PropTypes.arrayOf(codelistDefinition).isRequired,
  expertCompetences: PropTypes.arrayOf(codelistDefinition).isRequired,
  membershipToPPOCodelist: PropTypes.arrayOf(codelistDefinition).isRequired,
};

export default connect(mapStateToProps)(Filter);
