import API from '../../../Api';
import {
  REQUEST_REQUEST_DETAIL,
  RECEIVE_REQUEST_DETAIL_SUCCESS,
  RECEIVE_REQUEST_DETAIL_FAILURE,
} from './constants';

const requestRequestDetail = id => ({
  type: REQUEST_REQUEST_DETAIL,
  payload: {
    id,
  },
});

export const receiveRequestDetailSuccess = (id, request) => ({
  type: RECEIVE_REQUEST_DETAIL_SUCCESS,
  payload: { ...request },
});

const receiveRequestDetailFailure = (id, error) => ({
  type: RECEIVE_REQUEST_DETAIL_FAILURE,
  payload: { ...error },
});

export const loadRequestDetail = (appSpace, requestId) => {
  return async dispatch => {
    dispatch(requestRequestDetail(requestId));
    try {
      const request = await API.crmRequestDetail(appSpace, requestId);
      dispatch(receiveRequestDetailSuccess(requestId, request));
    } catch (e) {
      console.error(e);
      dispatch(receiveRequestDetailFailure(requestId, e));
    }
  };
};
