const namespace = 'PO';

export const REQUEST_ORGANIZATION_PROFILE = `${namespace}/REQUEST_ORGANIZATION_PROFILE`;
export const RECEIVE_ORGANIZATION_PROFILE_SUCCESS = `${namespace}/RECEIVE_ORGANIZATION_PROFILE_SUCCESS`;
export const RECEIVE_ORGANIZATION_PROFILE_FAILURE = `${namespace}/RECEIVE_ORGANIZATION_PROFILE_FAILURE`;

export const REQUEST_ORGANIZATION_ADDRESSES = `${namespace}/REQUEST_ORGANIZATION_ADDRESSES`;
export const RECEIVE_ORGANIZATION_ADDRESSES_SUCCESS = `${namespace}/RECEIVE_ORGANIZATION_ADDRESSES_SUCCESS`;
export const RECEIVE_ORGANIZATION_ADDRESSES_FAILURE = `${namespace}/RECEIVE_ORGANIZATION_ADDRESSES_FAILURE`;

export const REQUEST_ORGANIZATION_BANK_ACCOUNTS = `${namespace}/REQUEST_ORGANIZATION_BANK_ACCOUNTS`;
export const RECEIVE_ORGANIZATION_BANK_ACCOUNTS_SUCCESS = `${namespace}/RECEIVE_ORGANIZATION_BANK_ACCOUNTS_SUCCESS`;
export const RECEIVE_ORGANIZATION_BANK_ACCOUNTS_FAILURE = `${namespace}/RECEIVE_ORGANIZATION_BANK_ACCOUNTS_FAILURE`;

export const REQUEST_ORGANIZATION_CUSTOM_FIELDS = `${namespace}/REQUEST_ORGANIZATION_CUSTOM_FIELDS`;
export const RECEIVE_ORGANIZATION_CUSTOM_FIELDS_SUCCESS = `${namespace}/RECEIVE_ORGANIZATION_CUSTOM_FIELDS_SUCCESS`;
export const RECEIVE_ORGANIZATION_CUSTOM_FIELDS_FAILURE = `${namespace}/RECEIVE_ORGANIZATION_CUSTOM_FIELDS_FAILURE`;
