import { __ } from './index';

const customRequired = errorMessage => value => {
  return !value ? errorMessage : undefined;
};

const required = customRequired(__('Pole je povinné'));

const email = value =>
  value &&
  !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  )
    ? __('Neplatná emailová adresa')
    : undefined;

export default { required, customRequired, email };
