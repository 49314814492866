import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';
import { getProp } from '@sportnet/utilities';
import at from '@sportnet/utilities/at';

const entitiesDomainSelector = state => state.entities;

const usersDomainSelector = createSelector(
  entitiesDomainSelector,
  substate => substate.FO,
);

export const usersCurrentListSelector = listName =>
  createSelector(
    usersDomainSelector,
    getListResults(listName),
    (entities, ids) => {
      return at(entities.users, ids || []);
    },
  );

export const getActiveIdSelector = createSelector(
  usersDomainSelector,
  substate => substate.activeUserId,
);

export const getActiveUserSelector = createSelector(
  usersDomainSelector,
  getActiveIdSelector,
  (substate, activeId) => {
    return substate.users[activeId];
  },
);

export const getActiveUserIsFetchingSelector = createSelector(
  getActiveUserSelector,
  activeUser => getProp(activeUser, ['isFetching'], true),
);

export const getActiveUserAddressesSelector = createSelector(
  getActiveUserSelector,
  activeUser => getProp(activeUser, ['addresses'], []),
);

export const getActiveUserBankAccountsSelector = createSelector(
  getActiveUserSelector,
  activeUser => getProp(activeUser, ['bankAccounts'], []),
);

export const getActiveUserAthletesSelector = createSelector(
  getActiveUserSelector,
  activeUser => getProp(activeUser, ['athletes'], []),
);

export const getActiveUserPhotosSelector = createSelector(
  getActiveUserSelector,
  activeUser => getProp(activeUser, ['formal_photos'], []),
);

export const getActiveUserExpertsSelector = createSelector(
  getActiveUserSelector,
  activeUser => getProp(activeUser, ['experts'], []),
);

export const getActiveUserAwardsSelector = createSelector(
  getActiveUserSelector,
  activeUser => getProp(activeUser, ['awards'], []),
);

export const getActiveUserLicencesSelector = createSelector(
  getActiveUserSelector,
  activeUser => getProp(activeUser, ['licences'], []),
);

export const getActiveUserFormalPhotosSelector = createSelector(
  getActiveUserSelector,
  activeUser => getProp(activeUser, ['formal_photos'], []),
);

export const getActiveUserCustomFieldsSelector = createSelector(
  getActiveUserSelector,
  activeUser => getProp(activeUser, ['customFields'], []),
);

export const getActiveUserMembershipsSelector = createSelector(
  getActiveUserSelector,
  activeUser => getProp(activeUser, ['memberships'], []),
);

export const getActiveUserRegnrsSelector = createSelector(
  getActiveUserSelector,
  activeUser => getProp(activeUser, ['regnrs'], []),
);

const activeUserMedicalExaminationDomain = createSelector(
  getActiveUserSelector,
  activeUser =>
    activeUser?.medicalExaminations ?? { items: [], isFetching: false },
);

export const getActiveUserMedicalExaminationsIsFetchingSeletor = createSelector(
  activeUserMedicalExaminationDomain,
  medicalExaminations => medicalExaminations.isFetching,
);

export const getActiveUserMedicalExaminationsSelector = createSelector(
  activeUserMedicalExaminationDomain,
  medicalExaminations => medicalExaminations.items,
);

export const getActiveUserMedicalExaminationsFailure = createSelector(
  activeUserMedicalExaminationDomain,
  medicalExaminations => medicalExaminations.error,
);

export const getActiveUserBiosSelector = createSelector(
  getActiveUserSelector,
  activeUser => getProp(activeUser, ['bios'], []),
);
