import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { __ } from '../../../../utilities';
import validation from '../../../../utilities/validation';
import { applicationRolesSelector } from '../../../App/selectors';

export const formName = 'SportExpertOrgManager';

const Form = styled.form`
  width: 100%;
`;

class ReduxForm extends Component {
  render() {
    const { handleSubmit, roles, showFields } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        {showFields && (
          <Row>
            <Col m={6}>
              <Field
                label={__('Overil FO - meno')}
                name="verified_by_person"
                type="text"
                component={FormFieldRedux}
                validate={[validation.required]}
                required
              />
            </Col>
            <Col m={6}>
              <Field
                label={__('Overil FO - funkcia')}
                name="verified_by_position"
                type="theselectsimple"
                component={FormFieldRedux}
                validate={[validation.required]}
                required
                options={roles.map(role => ({
                  label: role.name || role.role,
                  value: role.role,
                }))}
              />
            </Col>
          </Row>
        )}
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    roles: applicationRolesSelector(state),
  };
};

ReduxForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  showFields: PropTypes.bool.isRequired,
};

ReduxForm.defaultProps = {};

const reduxFormConnected = reduxForm({
  form: formName,
  enableReinitialize: true,
})(ReduxForm);

export default connect(mapStateToProps)(reduxFormConnected);
