import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Sidebar from '@sportnet/ui/Sidebar';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import { aclSelector } from '../../../App/selectors';
import {
  getCustomFields,
  createCustomField,
  updateCustomField,
  deleteCustomField,
} from '../../actions';
import {
  getActiveUserCustomFieldsSelector,
  getActiveUserIsFetchingSelector,
} from '../../selectors';
import Wrapper from '../index';
import CustomDataForm from './form';
import Segment from '@sportnet/ui/Segment';
import { Faded } from '../../detail';
import { useParams } from 'react-router-dom';
import { useAuth } from '@sportnet/auth-react';
import AppContext from '../../../../context/AppContext';
import getApiError from '../../../../utilities/getApiError';
import CustomDataLoader from './CustomDataLoader';

const CustomList = ({
  getCustomFields,
  customFields,
  isFetching,
  acl,
  createCustomField,
  updateCustomField,
  deleteCustomField,
}) => {
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [selectedField, setSelectedField] = React.useState(null);
  const [updateDisabled, setUpdateDisabled] = React.useState(false);
  const [createDisabled, setCreateDisabled] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const { activeAppSpace } = React.useContext(AppContext);

  const { ppo: appSpace } = useAuth();
  const { sportnetId } = useParams();

  React.useEffect(() => {
    if (!(sportnetId && appSpace)) {
      return;
    }
    getCustomFields(appSpace, sportnetId);
  }, [sportnetId, appSpace, getCustomFields]);

  React.useEffect(() => {
    if (acl) {
      setUpdateDisabled(!acl.crmUserCustomFieldsUpdate);
      setCreateDisabled(!acl.crmUserCustomFieldsCreate);
    }
  }, [acl]);

  const handleCloseSidebar = () => {
    setSidebarVisible(false);
  };

  const handleAddRecordClick = () => {
    if (activeAppSpace) {
      const {
        name: org_name,
        organization_name,
        _id: org_profile_id,
      } = activeAppSpace;
      const custom_org_name = `${organization_name} / ${org_name}`;
      setSidebarVisible(true);
      setSelectedField({
        custom_org_name,
        org_profile_id,
      });
    }
  };

  const handleFormSubmit = async data => {
    if (!data._id) {
      try {
        setIsLoading(true);
        const { label, value, org_profile_id } = data;
        await createCustomField(appSpace, sportnetId, {
          org_profile_id,
          label,
          value,
        });
      } catch (e) {
        const err = getApiError(e);
        alert(
          `Chyba vytvorenia parametra: ${err.details.statusCode} ${err.details.name} ${err.message}`,
        );
      } finally {
        setSidebarVisible(false);
        setSelectedField(null);
        setIsLoading(false);
      }
    } else {
      try {
        setIsLoading(true);
        const { label, value, org_profile_id, _id } = data;
        await updateCustomField(appSpace, sportnetId, _id, {
          org_profile_id,
          label,
          value,
        });
      } catch (e) {
        const err = getApiError(e);
        alert(
          `Chyba úpravy parametra: ${err.details.statusCode} ${err.details.name} ${err.message}`,
        );
      } finally {
        setSidebarVisible(false);
        setSelectedField(null);
        setIsLoading(false);
      }
    }
  };

  const handleDelete = customDataId => {
    if (!window.confirm(__('Položka bude vymazaná natrvalo'))) {
      return;
    }
    try {
      setIsLoading(true);
      deleteCustomField(appSpace, sportnetId, customDataId);
    } catch (e) {
      const err = getApiError(e);
      alert(
        `Chyba vymazania parametra: ${err.details.statusCode} ${err.details.name} ${err.message}`,
      );
    } finally {
      setSidebarVisible(false);
      setSelectedField(null);
      setIsLoading(false);
    }
  };

  const handleClickTableRow = item => {
    if (!updateDisabled) {
      setSelectedField({
        ...item,
        custom_org_name: `${item.org_name} / ${item.org_profile_name}`,
      });
      setSidebarVisible(true);
    }
  };

  return (
    <Layout
      bottomFixed={
        !createDisabled && (
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                onClick={handleAddRecordClick}
                primary
                data-testid="CRM_FO_Button_Custom_New"
              >
                {__('Pridať záznam')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        )
      }
    >
      <Wrapper isFetching={isFetching} activeTab={__('Ostatné')}>
        {isFetching ? (
          <CustomDataLoader />
        ) : (
          <Segment raised loading={isLoading}>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Organizácia')}</Th>
                  <Th>{__('Názov')}</Th>
                  <Th>{__('Hodnota')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {customFields.map(item => {
                  return (
                    <Tr
                      key={item._id}
                      onClick={() => handleClickTableRow(item)}
                    >
                      <Td>
                        {item.org_name}
                        <br />
                        <Faded>{item.org_profile_name}</Faded>
                      </Td>
                      <Td>{item.label}</Td>
                      <Td>{item.value}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Segment>
        )}
      </Wrapper>
      <Sidebar
        onClose={handleCloseSidebar}
        visible={sidebarVisible}
        header={__('Ostatné')}
      >
        {sidebarVisible && selectedField && (
          <CustomDataForm
            initialValues={selectedField}
            onSubmit={handleFormSubmit}
            onDelete={handleDelete}
          />
        )}
      </Sidebar>
    </Layout>
  );
};

CustomList.propTypes = {
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      _id: PropTypes.string,
    }),
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  customFields: getActiveUserCustomFieldsSelector(state),
  isFetching: getActiveUserIsFetchingSelector(state),
  acl: aclSelector(state),
});

const mapDispatchToProps = {
  getCustomFields,
  createCustomField,
  updateCustomField,
  deleteCustomField,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomList);
