import NotFound from '@sportnet/ui/NotFound';
import Segment from '@sportnet/ui/Segment';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getProp } from '@sportnet/utilities';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import { matchDefinition, PODefinition } from '../../definitions';
import {
  orgEntitySelector,
  orgIsErrorSelector,
  orgIsFetchingSelector,
} from '../selectors';
import Tabber, { TABS } from '../Tabber';
import Table from './Table';

const Custom = ({
  history,
  didFailed,
  org,
  match: {
    params: { appspace },
  },
  isFetching,
}) => {
  const shouldShowLoader = isFetching && !org;

  return (
    <Layout>
      <AppContext
        title={org && org.full_name}
        backUrl={`/admin/${appspace}/organizations`}
        breadcrumbs={[
          {
            name: __('Späť na zoznam'),
            url: `/admin/${appspace}/organizations`,
          },
        ]}
      />
      <Tabber activeTab={TABS.custom} />

      <Segment noBottomGutter>
        <Segment noBottomGutter raised loading={shouldShowLoader}>
          {didFailed ? (
            <NotFound
              title={__('Detail organizácie nie je možné načítať.')}
              icon="error"
              actionLabel={__('Späť na zoznam')}
              onClickAction={() => {
                history.push(`/admin/${appspace}/organizations`);
              }}
            />
          ) : (
            <Table items={getProp(org, ['custom'], [])} />
          )}
        </Segment>
      </Segment>
    </Layout>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { organizationId },
    },
  },
) => ({
  org: orgEntitySelector(state, organizationId),
  didFailed: orgIsErrorSelector(state, organizationId),
  isFetching: orgIsFetchingSelector(state, organizationId),
});

const mapDispatchToProps = {};

Custom.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: matchDefinition.isRequired,
  didFailed: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  org: PODefinition,
};

Custom.defaultProps = {
  org: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Custom);
