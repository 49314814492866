import Button from '@sportnet/ui/Button';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import FormField from '@sportnet/ui/FormField';
import FormGroup from '@sportnet/ui/FormGroup';
import Label from '@sportnet/ui/Label/Label';
import Segment from '@sportnet/ui/Segment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, formValueSelector, reduxForm } from 'redux-form';
import getProp from '@sportnet/utilities/getProp';
import FileInputRedux from '../../../../components/FileInput/redux-form';
import { __ } from '../../../../utilities';
import { required } from '../../../../validation';
import {
  appspaceSelector,
  personDocumentCodelistSelector,
} from '../../../App/selectors';

export const FORM_NAME = 'DOCUMENT_FORM';

const DocumentForm = ({
  handleSubmit,
  data,
  appspace: {
    org_profile: { organization_name: organizationName, name },
  },
  submitting,
  documentTypes,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Segment>
        <FormField
          label={__('Organizácia')}
          disabled
          value={`${getProp(data, ['org_name'], organizationName)} / ${getProp(
            data,
            ['org_profile_name'],
            name,
          )}`}
        />

        <Field
          component={FormFieldRedux}
          type="theselect"
          name="type"
          required
          label={__('Typ dokumentu')}
          validate={[required]}
          options={documentTypes.map(type => ({
            value: type.value,
            label: type.label || type.value,
          }))}
        />
        <FormGroup>
          <Label>{__('Príloha')}</Label>
          <Field
            component={FileInputRedux}
            name="file"
            id="userDocument"
            required
            validate={[required]}
            accept="application/pdf, image/jpeg, image/png, image/gif, text/plain"
          />
        </FormGroup>

        <div style={{ display: 'flex', 'justify-content': 'flex-end' }}>
          <Button
            type="submit"
            primary
            disabled={submitting}
            loading={submitting}
          >
            {__('Uložiť')}
          </Button>
        </div>
      </Segment>
    </Form>
  );
};

const mapStateToProps = (state, props) => {
  const connectedProps = {
    appspace: appspaceSelector(state),
    useSequence: formValueSelector(FORM_NAME)(state, 'useSequence'),
    documentTypes: personDocumentCodelistSelector(state),
  };
  if (props.data) {
    connectedProps.initialValues = {
      ...props.data,
    };
  }
  return connectedProps;
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(DocumentForm);

export default connect(mapStateToProps)(connected);
