import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Editor from '@sportnet/content/editor';
import defaultWidgets from '@sportnet/content/editor/defaultWidgets';
import Widgets from '@sportnet/content/view';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { __ } from '../../../../utilities';
import { accessTokenSelector } from '../../../Authorization/selectors';
import Col, { Row } from '@sportnet/ui/Grid';
import Icon from '@sportnet/ui/Icon';

const availableWidgets = defaultWidgets.filter(i =>
  ['text', 'table', 'document', 'hr'].includes(i.type),
);

const BioForm = ({
  initialValues,
  token,
  appSpace,
  orgProfileId,
  dispatch,
  form,
}) => {
  const editable = !orgProfileId || orgProfileId === appSpace;
  return (
    <React.Fragment>
      <Field
        name="photo"
        component={FormFieldRedux}
        type="image"
        disabled={!editable}
        label={__('Fotka profilu')}
        auth={{
          appId: 'CRM',
          appSpace,
          token,
        }}
      />
      <Field
        name="perex"
        component={FormFieldRedux}
        type="textarea"
        disabled={!editable}
        label={__('Perex')}
        info={__('Stručné zhrnutie osoby')}
      />
      <Row>
        <Col s={6}>
          <Field
            name="facebook"
            component={FormFieldRedux}
            type="text"
            placeholder="https://..."
            disabled={!editable}
            label={
              <React.Fragment>
                <Icon name="facebook" style={{ marginRight: '5px' }} />
                {__('Facebook')}
              </React.Fragment>
            }
            info={__('Link na Facebook profil')}
          />
        </Col>
        <Col s={6}>
          <Field
            name="twitter"
            component={FormFieldRedux}
            type="text"
            placeholder="https://..."
            disabled={!editable}
            label={
              <React.Fragment>
                <Icon name="twitter" style={{ marginRight: '5px' }} />
                {__('Twitter')}
              </React.Fragment>
            }
            info={__('Link na Twitter profil')}
          />
        </Col>
        <Col s={6}>
          <Field
            name="instagram"
            component={FormFieldRedux}
            type="text"
            placeholder="https://..."
            disabled={!editable}
            label={
              <React.Fragment>
                <Icon name="instagram" style={{ marginRight: '5px' }} />
                {__('Instagram')}
              </React.Fragment>
            }
            info={__('Link na Instagram profil')}
          />
        </Col>
        <Col s={6}>
          <Field
            name="youtube"
            component={FormFieldRedux}
            type="text"
            placeholder="https://..."
            disabled={!editable}
            label={
              <React.Fragment>
                <Icon name="youtube" style={{ marginRight: '5px' }} />
                {__('Youtube')}
              </React.Fragment>
            }
            info={__('Link na Youtube profil')}
          />
        </Col>
      </Row>
      {editable ? (
        <Editor
          onChange={e => dispatch(change(form, 'widgets', e))}
          availableWidgets={availableWidgets}
          initialValue={initialValues ? initialValues.widgets || [] : []}
          contentDivider="default"
          appId="CRM"
          appSpace={appSpace}
          token={token}
        />
      ) : (
        <Widgets
          items={initialValues ? initialValues.widgets || [] : []}
          contentDivider="default"
          appId="CRM"
          appSpace={appSpace}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(props.form);
  return {
    widgets: selector(state, 'widgets') || [],
    orgProfileId: selector(state, 'org_profile_id'),
    token: accessTokenSelector(state),
  };
};

BioForm.propTypes = {
  appSpace: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  widgets: PropTypes.arrayOf(PropTypes.shape({})),
};

BioForm.defaultProps = {
  widgets: [],
};

export default compose(
  connect(mapStateToProps),
  reduxForm({ enableReinitialize: true }),
)(BioForm);
