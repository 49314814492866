import Api from '../../Api';
import { receiveAppError } from '../App/actions';
import {
  AUTH_USER_AUTHORIZED,
  AUTH_USER_UNAUTHORIZED,
  RECEIVE_CODELIST_SUCCESS,
  RECEIVE_SPORTS_CODELIST_SUCCESS,
  RECEIVE_SPORT_SECTORS_CODELIST_SUCCESS,
  SET_ACL,
  SET_ACTIVE_APPSPACE,
  SET_ACTIVE_APPSPACE_ID,
  SET_APPS,
  SET_APPSPACES,
  SET_APP_INFO,
} from './constants';
import getApiError from '../../utilities/getApiError';

export const setApplicationInfo = appInfo => {
  return {
    type: SET_APP_INFO,
    payload: {
      appInfo,
    },
  };
};

export function setAcl(acl) {
  return {
    type: SET_ACL,
    payload: {
      acl,
    },
  };
}

export const loadAcl = appSpaceId => {
  return async dispatch => {
    try {
      dispatch(setAcl(null));
      const acl = await Api.crmGetUserAcl(appSpaceId);
      dispatch(setAcl(acl));
    } catch (e) {
      const err = getApiError(e);
      dispatch(
        receiveAppError(
          `Chyba načítania ACL\n${
            err.details
              ? `${err.details.statusCode}: ${err.details.name} (${
                  err.details.description ?? ''
                })`
              : err.message
          }`,
        ),
      );
    }
  };
};

export const setApps = apps => {
  return {
    type: SET_APPS,
    payload: {
      apps,
    },
  };
};

export const setAppSpaces = appSpaces => {
  return {
    type: SET_APPSPACES,
    payload: {
      appSpaces,
    },
  };
};

export const setActiveAppSpaceId = appSpaceId => {
  return {
    type: SET_ACTIVE_APPSPACE_ID,
    payload: {
      appSpaceId,
    },
  };
};

export const setActiveAppSpace = activeAppSpace => {
  return {
    type: SET_ACTIVE_APPSPACE,
    payload: {
      activeAppSpace,
    },
  };
};

export function setAuthUser(authUser, accessToken = null) {
  return authUser && accessToken
    ? {
        type: AUTH_USER_AUTHORIZED,
        authUser,
        accessToken,
      }
    : {
        type: AUTH_USER_UNAUTHORIZED,
      };
}

// app specific
const receiveCodeListSuccess = (codeListID, data) => ({
  type: RECEIVE_CODELIST_SUCCESS,
  payload: {
    codeListID,
    data,
  },
});

const receiveSportsCodeListSuccess = data => ({
  type: RECEIVE_SPORTS_CODELIST_SUCCESS,
  payload: {
    data,
  },
});

export const fetchCodeList = (codeListID, ppo = null) => {
  return async dispatch => {
    try {
      if (ppo) {
        const response = await Api.getPPOCodelist(ppo, codeListID);
        dispatch(receiveCodeListSuccess(codeListID, response.codelist));
      } else {
        const response = await Api.getCodelist(codeListID);
        dispatch(receiveCodeListSuccess(codeListID, response.codelist));
      }
    } catch (e) {
      const err = getApiError(e);
      dispatch(
        receiveAppError(
          `Chyba načítania číselníka "${codeListID}" \n${
            err.details
              ? `${err.details.statusCode}: ${err.details.name} (${
                  err.details.description ?? ''
                })`
              : err.message
          }`,
        ),
      );
    }
  };
};

const receiveSportSectorsCodeListSuccess = (sportId, data) => ({
  type: RECEIVE_SPORT_SECTORS_CODELIST_SUCCESS,
  payload: {
    sportId,
    data,
  },
});

export const fetchSportSectorsCodeList = sportId => {
  return async dispatch => {
    try {
      const response = await Api.getCodelistSector('sport', sportId);
      const { codelist } = response;
      dispatch(receiveSportSectorsCodeListSuccess(sportId, codelist));
    } catch (e) {
      dispatch(receiveAppError(e.description || e));
    }
  };
};

export const fetchSportsCodeList = () => {
  return async dispatch => {
    try {
      const response = await Api.getCodelist('sport');
      const { codelist } = response;
      dispatch(receiveSportsCodeListSuccess(codelist));
      codelist.forEach(sport => {
        dispatch(fetchSportSectorsCodeList(sport.value));
      });
    } catch (e) {
      dispatch(receiveAppError(e.description || e));
    }
  };
};
