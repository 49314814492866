import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm, formValueSelector } from 'redux-form';
import { getProp, __ } from '../../../../utilities';
import { appspaceDefinition } from '../../../App/definitions';
import { appspaceSelector } from '../../../App/selectors';
import { required } from '../../../../validation';

const FORM_NAME = 'REGNR_FORM';

class RegnrForm extends PureComponent {
  render() {
    const {
      handleSubmit,
      onDelete,
      data,
      appspace: {
        app_space: appSpace,
        org_profile: { organization_name: organizationName, name },
      },
      submitting,
      deleting,
      useSequence,
    } = this.props;
    const isOwner =
      !getProp(data, ['org_profile_id']) ||
      getProp(data, ['org_profile_id']) === appSpace;
    return (
      <Form onSubmit={handleSubmit}>
        <Segment>
          <FormField
            label={__('Organizácia')}
            disabled
            value={`${getProp(
              data,
              ['org_name'],
              organizationName,
            )} / ${getProp(data, ['org_profile_name'], name)}`}
          />

          <Field
            label={__('Registračné číslo')}
            disabled={(data && !isOwner) || useSequence}
            validate={useSequence ? null : [required]}
            name="regnr"
            component={FormFieldRedux}
          />
          {!data && (
            <Field
              label={__('Použiť sekvenciu')}
              name="useSequence"
              type="checkbox"
              component={FormFieldRedux}
            />
          )}

          {isOwner && (
            <div
              style={{ display: 'flex', 'justify-content': 'space-between' }}
            >
              {getProp(data, ['_id']) && (
                <Button
                  onClick={e => {
                    e.preventDefault();
                    onDelete(data._id);
                  }}
                  danger
                  type="button"
                  disabled={submitting || deleting}
                  loading={deleting}
                >
                  {__('Zmazať')}
                </Button>
              )}
              <Button
                type="submit"
                primary
                disabled={submitting || deleting}
                loading={submitting}
              >
                {__('Uložiť')}
              </Button>
            </div>
          )}
        </Segment>
      </Form>
    );
  }
}

RegnrForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  deleting: PropTypes.bool,
  appspace: appspaceDefinition.isRequired,
  data: PropTypes.shape({
    regnr: PropTypes.string,
  }),
};

RegnrForm.defaultProps = {
  data: null,
  deleting: false,
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(RegnrForm);

const mapStateToProps = (state, props) => {
  const connectedProps = {
    appspace: appspaceSelector(state),
    useSequence: formValueSelector(FORM_NAME)(state, 'useSequence'),
  };
  if (props.data) {
    connectedProps.initialValues = {
      ...props.data,
    };
  }
  return connectedProps;
};

export default connect(mapStateToProps)(connected);
