import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';
import at from '@sportnet/utilities/at';
import { PPOS_LIST } from './constants';

export const ppoEntities = state => state.entities.PPO;

export const ppoEntitySelector = createSelector(
  ppoEntities,
  (_, id) => id,
  (byId, id) => (id in byId ? byId[id] : null),
);

export const ppoListSelector = createSelector(
  ppoEntities,
  getListResults(PPOS_LIST),
  (byId, ids) => at(byId, ids || []),
);
