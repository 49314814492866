import getProp from '@sportnet/utilities/getProp';
import {
  AUTH_USER_AUTHORIZED,
  AUTH_USER_UNAUTHORIZED,
  RECEIVE_CODELIST_SUCCESS,
  RECEIVE_SPORTS_CODELIST_SUCCESS,
  RECEIVE_SPORT_SECTORS_CODELIST_SUCCESS,
  SET_ACL,
  SET_ACTIVE_APPSPACE_ID,
  SET_APPS,
  SET_APPSPACES,
  SET_APP_INFO,
  SET_ACTIVE_APPSPACE,
} from './constants';

export const applicationReducer = (
  state = {
    appInfo: null,
    apps: [],
    activeAppSpaceId: null,
    appSpaces: [],
    acl: null,
    authUser: null,
    accessToken: null,
    codeLists: {},
  },
  action,
) => {
  switch (action.type) {
    case AUTH_USER_AUTHORIZED:
      return {
        ...state,
        authUser: action.authUser,
        accessToken: action.accessToken,
      };
    case AUTH_USER_UNAUTHORIZED:
      return {
        ...state,
        authUser: null,
        accessToken: null,
      };
    case SET_ACTIVE_APPSPACE_ID:
      return {
        ...state,
        activeAppSpaceId: action.payload.appSpaceId,
      };
    case SET_ACTIVE_APPSPACE:
      return {
        ...state,
        activeAppSpace: action.payload.activeAppSpace,
      };
    case SET_APP_INFO:
      return {
        ...state,
        appInfo: action.payload.appInfo,
      };
    case SET_APPS:
      return {
        ...state,
        apps: action.payload.apps,
      };
    case SET_APPSPACES:
      return {
        ...state,
        appSpaces: action.payload.appSpaces,
      };
    case SET_ACL:
      return {
        ...state,
        acl: action.payload.acl,
      };
    // app specific
    case RECEIVE_CODELIST_SUCCESS:
      return {
        ...state,
        codeLists: {
          ...state.codeLists,
          [action.payload.codeListID]: action.payload.data,
        },
      };
    case RECEIVE_SPORTS_CODELIST_SUCCESS:
      return {
        ...state,
        codeLists: {
          ...state.codeLists,
          sport: action.payload.data.reduce(
            (acc, sport) => {
              return {
                ...acc,
                [sport.value]: {
                  ...acc[sport.value],
                  ...sport,
                },
              };
            },
            { ...state.codeLists.sport },
          ),
        },
      };
    case RECEIVE_SPORT_SECTORS_CODELIST_SUCCESS:
      return {
        ...state,
        codeLists: {
          ...state.codeLists,
          sport: {
            ...state.codeLists.sport,
            [action.payload.sportId]: {
              ...getProp(state.codeLists.sport, [action.payload.sportId], {}),
              sectors: action.payload.data.reduce(
                (acc, sector) => {
                  return {
                    ...acc,
                    [sector.value]: { ...acc[sector.value], ...sector },
                  };
                },
                {
                  ...getProp(
                    state.codeLists.sport,
                    [action.payload.sportId, 'sectors'],
                    {},
                  ),
                },
              ),
            },
          },
        },
      };
    default:
      return state;
  }
};
