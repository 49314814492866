import Button from '@sportnet/ui/Button';
import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import React from 'react';
import { __ } from '../../../../utilities';
import styled from 'styled-components';
import { required } from '../../../../validation';
import { Form, Field } from 'react-final-form';
import FinalFormField from '../../../../components/FinalFormField';

const Buttons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const CustomDataFrom = ({ initialValues, onSubmit, onDelete }) => {
  return (
    <Segment>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="custom_org_name"
                validate={required}
                label={__('Organizácia')}
                required
                component={FinalFormField}
                disabled
              />
              <Field
                name="label"
                validate={required}
                label={__('Názov')}
                required
                component={FinalFormField}
              />
              <Field
                name="value"
                validate={required}
                required
                label={__('Hodnota')}
                component={FinalFormField}
              />
              <Buttons>
                {!initialValues._id && (
                  <Button primary type="submit">
                    {__('Pridať položku')}
                  </Button>
                )}
                {initialValues._id && (
                  <React.Fragment>
                    <Button primary type="submit">
                      {__('Upraviť položku')}
                    </Button>
                    <Button
                      danger
                      type="button"
                      icon="trash"
                      onClick={() => {
                        onDelete(initialValues._id);
                      }}
                    />
                  </React.Fragment>
                )}
              </Buttons>
            </form>
          );
        }}
      ></Form>
    </Segment>
  );
};

CustomDataFrom.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    _id: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    custom_org_name: PropTypes.string.isRequired,
    org_profile_id: PropTypes.string.isRequired,
  }).isRequired,
};

export default CustomDataFrom;
