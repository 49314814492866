export const recipientsToSmarttagFormat = (recipients, displayMax = null) => {
  const smarttags = [];

  if (recipients.users && recipients.users.length > 0) {
    smarttags.push({
      key: 'SportNet ID',
      values: recipients.users.map(user => ({
        key: user,
      })),
    });
  }

  if (recipients.userGroups && recipients.userGroups.length > 0) {
    smarttags.push({
      key: 'group',
      title: 'Skupina',
      values: recipients.userGroups.map(grp => ({
        key: grp,
      })),
    });
  }

  if (recipients.apps && recipients.apps.length > 0) {
    smarttags.push(
      ...recipients.apps.map(({ appId, roles }) => ({
        key: `app-${appId}`,
        title: `Používateľ aplikácie ${appId}`,
        values:
          (roles || []).length === 0
            ? [{ key: `Všetky role` }]
            : roles.map(role => ({
                key: `${role}`,
              })),
      })),
    );
  }

  if (recipients.sportAthlets && recipients.sportAthlets.length > 0) {
    smarttags.push(
      ...recipients.sportAthlets.map(({ activeOnly }) => ({
        key: 'sportAthlets',
        title: 'Športovci',
        values: [{ key: activeOnly ? 'activeOnly' : 'all' }],
      })),
    );
  }

  if (recipients.sportExperts && recipients.sportExperts.length > 0) {
    smarttags.push(
      ...recipients.sportExperts.map(({ activeOnly, types }) => ({
        key: 'sportExperts',
        title: 'Športoví odborníci',
        values:
          (types || []).length === 0
            ? [{ key: activeOnly ? 'activeOnly' : 'all' }]
            : types.map(type => ({
                key: `${activeOnly ? `activeOnly-${type}` : `all-${type}`}`,
              })),
      })),
    );
  }

  if (recipients.relatedPPOs && recipients.relatedPPOs.length > 0) {
    smarttags.push(
      ...recipients.relatedPPOs.map(({ status, crmroles }) => ({
        key: 'relatedPPOs',
        title: 'Organizácie',
        values:
          (crmroles || []).length === 0
            ? [
                {
                  key: status === 'active' ? 'active' : 'inactive',
                },
              ]
            : crmroles.map(crmrole => ({
                key: `${
                  status === 'active'
                    ? `active-${crmrole}`
                    : `inactive-${crmrole}`
                }`,
              })),
      })),
    );
  }

  if (displayMax) {
    // prebehneme hodnoty a slicujeme az kyn nenaplnime maximum
    let currentValues = 0;
    let totalValues = 0;
    const reducedSmarttags = smarttags
      .map(({ key, values }) => {
        totalValues += values.length;
        const newValues = values.slice(0, displayMax - currentValues);
        currentValues += newValues.length;
        if (currentValues > displayMax) {
          return null;
        }
        return { key, values: values.slice(0, displayMax) };
      })
      .filter(i => i);
    if (currentValues === displayMax) {
      reducedSmarttags.push({
        key: 'Viac...',
        values: [{ key: `+${totalValues - displayMax}` }],
      });
    }
    return reducedSmarttags;
  }
  return smarttags;
};

export const smarttagFormatToRecipients = smarttags => {
  let stags = smarttags;
  if (!smarttags) {
    stags = [];
  }
  const data = stags.reduce(
    (acc, smarttag) => {
      const { key, values } = smarttag;
      if (key === 'SportNet ID') {
        values.forEach(value => {
          acc.users.push(value.key.trim());
        });
      } else if (['Skupina', 'group'].includes(key)) {
        values.forEach(value => {
          acc.userGroups.push(value.key.trim());
        });
      } else if (key.startsWith('app-')) {
        const valuesWithoutAll = values.filter(
          value => value.key !== 'Všetky role',
        );
        acc.apps.push({
          appId: String(key).substring(4),
          roles:
            valuesWithoutAll.length !== values.length
              ? []
              : valuesWithoutAll.map(val => val.key.trim()),
        });
      } else if (key === 'relatedPPOs') {
        acc.relatedPPOs.push(
          ...values.map(({ key }) => {
            const [status, ...role] = key.split('-');
            const crmrole = role.join('-');
            return {
              status,
              crmroles: crmrole ? [crmrole] : [],
            };
          }),
        );
      } else if (key === 'sportExperts') {
        acc.sportExperts.push(
          ...values.map(({ key }) => {
            const [activeOnlyStatus, ...type] = key.split('-');
            const experttype = type.join('-');
            return {
              activeOnly: activeOnlyStatus === 'activeOnly',
              types: experttype ? [experttype] : [],
            };
          }),
        );
      } else if (key === 'sportAthlets') {
        acc.sportAthlets.push(
          ...values.map(({ key }) => {
            return {
              activeOnly: key === 'activeOnly',
            };
          }),
        );
      } else {
        // probably app, test it
        const matches = /Aplikácia (.+)/.exec(key);
        if (matches) {
          // definitely a app
          if (values.length > 0) {
            const [, appId] = matches;

            const valuesWithoutAll = values.filter(
              value => value.key !== 'Všetky role',
            );

            acc.apps.push({
              appId,
              roles:
                valuesWithoutAll.length !== values.length
                  ? []
                  : valuesWithoutAll.map(val => val.key.trim()),
            });
          }
        }
      }
      return acc;
    },
    {
      users: [],
      userGroups: [],
      apps: [],
      sportAthlets: [],
      sportExperts: [],
      relatedPPOs: [],
    },
  );
  return Object.keys(data).reduce((acc, name) => {
    if (data[name].length) {
      return { ...acc, [name]: data[name] };
    }
    return acc;
  }, {});
};
