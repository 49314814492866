import { __ } from './';

const getCategories = (appSpace, acl) => {
  if (!(acl && appSpace)) {
    return [];
  }
  const baseUrl = `/admin/${appSpace}`;
  const categories = [
    {
      url: `${baseUrl}/me/profile`,
      label: __('Moja organizácia'),
      acl: 'crmMyOrganization',
      icon: 'suitcase',
      subitems: [
        {
          label: __('Právnická osoba'),
          url: `${baseUrl}/me/profile`,
        },
        {
          url: `${baseUrl}/settings`,
          label: __('Kontaktné údaje a nastavenia'),
          acl: [
            'crmUpdateProfileLogo',
            'crmDeleteProfileLogo',
            'crmUpdateProfileFavicon',
            'crmDeleteProfileFavicon',
            'crmGetGDPRBody',
            'crmUpdateGDPRBody',
            'crmUpdateContactInfo',
            'crmUpdateTheme',
          ],
        },
        {
          url: `${baseUrl}/apps`,
          label: __('Aplikácie'),
          acl: 'crmApps',
          icon: 'apps',
        },
      ],
    },
    {
      url: '',
      label: __('Matrika'),
      acl: ['crmGetUsers', 'crmListRelatedPPOs'],
      icon: 'business-card',
      subitems: [
        {
          url: `${baseUrl}/users`,
          label: __('Fyzické osoby'),
          acl: 'crmGetUsers',
        },
        {
          url: `${baseUrl}/organizations`,
          label: __('Právnické osoby'),
          acl: 'crmListRelatedPPOs',
        },
      ],
    },
    {
      url: '',
      label: __('Správa skupín'),
      acl: ['crmGetAppSpaceGroups'],
      icon: 'group',
      subitems: [
        {
          url: `${baseUrl}/groups`,
          label: __('Zoznam skupín'),
          acl: 'crmGetAppSpaceGroups',
        },
        {
          url: `${baseUrl}/group-users`,
          label: __('Priradenie osôb'),
        },
      ],
    },
    {
      url: `${baseUrl}/invoices?type=VF`,
      label: __('Fakturácia'),
      acl: 'getInvoicesByAppspace',
      icon: 'file',
      subitems: [
        {
          url: `${baseUrl}/invoices?type=VF`,
          label: __('Vyšlé faktúry'),
          acl: 'getInvoicesByAppspace',
        },
        {
          url: `${baseUrl}/invoices?type=DF`,
          label: __('Došlé faktúry'),
          acl: 'getInvoicesByAppspace',
        },
      ],
    },
    {
      url: `${baseUrl}/invoice-items?type=VF`,
      label: __('Zberná fakturácia'),
      acl: 'getInvoicesByAppspace',
      icon: 'files',
      subitems: [
        {
          url: `${baseUrl}/invoice-items?type=VF`,
          label: __('Vyšlé položky'),
          acl: 'getOpenedInvoiceItemsByAppspace',
        },
        {
          url: `${baseUrl}/invoice-items?type=DF`,
          label: __('Došlé položky'),
          acl: 'getOpenedInvoiceItemsByAppspace',
        },
      ],
    },
    {
      url: `${baseUrl}/courier-templates`,
      label: __('Kuriér'),
      acl: 'crmGetCourierTemplates',
      icon: 'message',
    },
    {
      url: `${baseUrl}/requests`,
      label: __('Žiadosti'),
      acl: 'crmGetRequests',
      icon: 'write',
      subitems: [
        {
          url: `${baseUrl}/create-request/person`,
          label: __('Nová pozvánka osoby'),
          acl: 'crmSendNewUserRequestInvitation',
        },
        {
          url: `${baseUrl}/create-request/transfer`,
          label: __('Nová žiadosť o prestup'),
          acl: 'createPersonTransferRequest',
        },
      ],
    },
    {
      url: '',
      label: __('Nastavenia'),
      icon: 'settings',
      acl: [
        'crmPPOPricelistItemCreate',
        'crmGetSequence',
        'crmUpdateProfileLogo',
        'crmDeleteProfileLogo',
        'crmUpdateProfileFavicon',
        'crmDeleteProfileFavicon',
        'crmGetGDPRBody',
        'crmUpdateGDPRBody',
        'crmUpdateContactInfo',
        'crmUpdateTheme',
        'setPPOCodelist',
      ],
      subitems: [
        {
          url: `${baseUrl}/pricelist-items`,
          label: __('Cenníkové položky'),
          acl: 'crmPPOPricelistItemCreate',
        },
        {
          url: `${baseUrl}/settings/sequences`,
          label: __('Číslovanie'),
          acl: 'crmGetSequence',
        },
        {
          url: `${baseUrl}/settings/codelist/award-type`,
          label: __('Číselník typov ocenení'),
          acl: 'setPPOCodelist',
        },
        {
          url: `${baseUrl}/settings/codelist/person-position`,
          label: __('Číselník pozícií osôb'),
          acl: 'setPPOCodelist',
        },
        {
          url: `${baseUrl}/settings/codelist/person-document`,
          label: __('Číselník dokumentov osôb'),
          acl: 'setPPOCodelist',
        },
      ],
    },
  ];
  return categories.reduce((acc, i) => {
    if (i.acl && Array.isArray(i.acl)) {
      const isAllowed = i.acl.some(item => acl[item]);
      if (isAllowed) {
        return [...acc, i];
      }
    }
    if (!i.acl || (i.acl && acl[i.acl])) {
      return [...acc, i];
    }
    return acc;
  }, []);
};

export default getCategories;
