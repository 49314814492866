import { createSelector } from 'reselect';

const entityDomainSelector = state => state.entities;

const applicationsDomainSelector = createSelector(
  entityDomainSelector,
  substate => substate.Applications,
);

export const applicationsDataSelector = createSelector(
  applicationsDomainSelector,
  substate => substate.data,
);

export const applicationsIsFetchingSelector = createSelector(
  applicationsDomainSelector,
  substate => substate.isFetching,
);
