import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isSubmitting } from 'redux-form';
import Api from '../../../../Api';
import { getRequestStatusName, __ } from '../../../../utilities';
import {
  appsIsFetchingSelector,
  appspaceSelector,
} from '../../../App/selectors';
import { receiveRequestDetailSuccess } from '../actions';
import { universalRequestDefinition } from '../../definitions';
import StatusManager from '../Universal/StatusManager';

const formName = 'PO_DATA_CHANGE_REQUEST_FORM';

class PoDataChangeManager extends React.PureComponent {
  state = {
    loading: false,
  };

  changeStatus = async status => {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      request,
    } = this.props;
    const confirmMessage = `${__('Naozaj chcete zmeniť stav žiadosti')}
${__('z')} ${getRequestStatusName(request.status)} ${__(
      'na',
    )} ${getRequestStatusName(status)}?`;

    try {
      this.setState({
        loading: true,
      });
      let newRequest = {};
      if (status === 'REJECTED') {
        const noteText = __('Napíšte dôvod zamietnutia:');
        // eslint-disable-next-line
        const note = window.prompt(`${confirmMessage}
        
${noteText}`);
        if (!note) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status, note },
        });
      } else if (status === 'CONFIRMED') {
        // eslint-disable-next-line
        if (!window.confirm(confirmMessage)) return;
        newRequest = await Api.crmConfirmPoDataChangeRequest(
          appspace,
          request._id,
          {
            data: {},
          },
        );
      } else {
        // eslint-disable-next-line
        if (!window.confirm(confirmMessage)) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status },
        });
      }
      dispatch(receiveRequestDetailSuccess(newRequest._id, newRequest));
    } catch (e) {
      // failed
      window.alert(__('Došlo k neznámej chybe'));
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleReject = () => {
    this.changeStatus('REJECTED');
  };

  handleConfirm = () => {
    this.changeStatus('CONFIRMED');
  };

  handleClose = () => {
    this.changeStatus('CLOSED');
  };

  render() {
    const { request, isFormSubmitting, areAppsFetching } = this.props;

    return (
      <React.Fragment>
        <StatusManager
          request={request}
          loading={this.state.loading || isFormSubmitting || areAppsFetching}
          onReject={this.handleReject}
          onConfirm={this.handleConfirm}
          onClosed={this.handleClose}
        />
      </React.Fragment>
    );
  }
}

PoDataChangeManager.propTypes = {
  request: universalRequestDefinition.isRequired,
  // dispatch: PropTypes.func.isRequired,
  // match: PropTypes.shape({
  //   params: PropTypes.shape({
  //     appspace: PropTypes.string,
  //   }).isRequired,
  // }).isRequired,
  // appspace: appspaceDefinition.isRequired,
  isFormSubmitting: PropTypes.bool,
  areAppsFetching: PropTypes.bool.isRequired,
};

PoDataChangeManager.defaultProps = {
  isFormSubmitting: false,
};

const mapStateToProps = state => {
  return {
    appspace: appspaceSelector(state),
    isFormSubmitting: isSubmitting(formName)(state),
    areAppsFetching: appsIsFetchingSelector(state),
  };
};

const routerConnected = withRouter(PoDataChangeManager);
export default connect(mapStateToProps)(routerConnected);
