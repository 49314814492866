import styled from 'styled-components';

export const UserWithPhoto = styled.div`
  display: flex;
  align-items: center;
  *:first-child {
    margin-right: 8px;
  }
  img {
    object-fit: contain;
  }
`;
