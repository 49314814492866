import { RECEIVE_PPOS_SUCCESS } from './constants';

export const PPOReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_PPOS_SUCCESS:
      return {
        ...state,
        ...action.payload.ppos.reduce((acc, next) => {
          acc[next._id] = {
            ...(state[next._id] || {}),
            ...next,
          };
          return acc;
        }, {}),
      };
    default:
      return state;
  }
};
