import {
  CLEAR_ERROR,
  RECEIVE_APPS_FAILURE,
  RECEIVE_APPS_SUCCESS,
  RECEIVE_RELATED_PPOS_FAILURE,
  RECEIVE_RELATED_PPOS_SUCCESS,
  REQUEST_APPS,
  REQUEST_RELATED_PPOS,
} from './constants';

export const errorReducer = (
  state = {
    message: null,
  },
  action,
) => {
  if (action.payload && action.payload.error) {
    return {
      ...state,
      message: action.payload.error.description || action.payload.error,
    };
  }
  if (action.type === CLEAR_ERROR) {
    return {
      ...state,
      message: null,
    };
  }
  return state;
};

export const appsReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_APPS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_APPS_SUCCESS:
      return {
        ...state,
        apps: action.payload.apps,
        isFetching: false,
      };
    case RECEIVE_APPS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const relatedPposReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_RELATED_PPOS:
      return {
        ...state,
        [action.payload.appspace]: {
          ...state[action.payload.appspace],
          isFetching: true,
        },
      };
    case RECEIVE_RELATED_PPOS_SUCCESS:
      return {
        ...state,
        [action.payload.appspace]: {
          ...state[action.payload.appspace],
          isFetching: false,
          ids: action.payload.ids,
          ppos: action.payload.ppos,
        },
      };
    case RECEIVE_RELATED_PPOS_FAILURE:
      return {
        ...state,
        [action.payload.appspace]: {
          ...state[action.payload.appspace],
          isFetching: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};
