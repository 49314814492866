import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';
import Api from '../../../Api';
import { __, parseNumber } from '../../../utilities';
import { required } from '../../../validation';
import { FORM_NAME } from '../form';
import PercentFormPart from './percentFormPart';

class ApplicationPercentForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      apps: [],
    };
  }
  componentDidMount() {
    this.fetchApps();
  }
  handlePercentChange = e => {
    const {
      target: { name, value },
    } = e;
    const { dispatch } = this.props;
    const parsedValue = parseNumber(value);
    if (name === 'pcn') {
      dispatch(
        change(FORM_NAME, 'pcn', Number(parseFloat(parsedValue).toFixed(2))),
      );
    }
  };
  async fetchApps() {
    try {
      const { apps } = await Api.getPublicApps();
      this.setState({
        apps,
      });
    } catch (e) {
      console.error(e);
      alert(__('Nepodarilo sa získať zoznam aplikácií'));
    }
  }
  render() {
    const { apps } = this.state;
    return (
      <Row>
        <Col>
          <Field
            label={__('Aplikácia')}
            component={FormField}
            type="theselectsimple"
            required
            name="applicationId"
            validate={[required]}
            options={apps.map(app => ({
              label: app.name,
              value: app._id,
            }))}
          />
        </Col>
        <Col>
          <PercentFormPart handlePercentChange={this.handlePercentChange} />
        </Col>
      </Row>
    );
  }
}

ApplicationPercentForm.propTypes = {
  percent: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const selector = formValueSelector('INVOICE_ITEM_FORM');

const mapStateToProps = state => {
  return {
    percent: selector(state, 'percent'),
  };
};

export default connect(mapStateToProps)(ApplicationPercentForm);
