import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Api from '../../../../Api';
import Layout from '../../../../components/Layout';
import { getStatusIcon, __ } from '../../../../utilities';
import { aclSelector } from '../../../App/selectors';
import { STATUSES as membershipStatuses } from '../../../PPO/Detail/Membership/form';
import Wrapper from '../index';
import ProlongModal from './prolongmodal';

export const Faded = styled.span`
  color: ${({ theme }) => theme.color.fadedText};
`;

const List = ({
  match: {
    params: { appspace },
  },
  isFetching,
}) => {
  const [isFetchingScreen, setIsFetchingScreen] = useState(0);
  const [memberships, setMemberships] = useState([]);
  const [parentRelations, setParentRelations] = useState([]);
  const [lastDataFetch, setLastDataFetch] = useState(new Date());
  const [isProlongingMembership, setIsProlongingMembership] = useState(false);

  useEffect(() => {
    // fetchneme clenstva
    setIsFetchingScreen(i => i + 1);
    Api.crmMyOrganizationMembership(appspace)
      .then(({ membership }) => {
        setMemberships(membership);
      })
      .catch(e => {
        alert(
          e.details
            ? e.details.userinfo || __('Pri získavaní členstiev nastala chyba')
            : e,
        );
      })
      .finally(() => {
        setIsFetchingScreen(i => i - 1);
      });
    // fetchneme parent relations
    setIsFetchingScreen(i => i + 1);
    Api.organizationPPOProfile(appspace)
      .then(({ parent_relations: pr = [] }) => {
        setParentRelations(pr);
      })
      .catch(e => {
        alert(
          e.details
            ? e.details.userinfo ||
                __('Pri získavaní nadradených organizácií nastala chyba')
            : e,
        );
      })
      .finally(() => {
        setIsFetchingScreen(i => i - 1);
      });
  }, [appspace, lastDataFetch]);

  return (
    <Layout
      bottomFixed={
        parentRelations.length && (
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                primary
                onClick={() => {
                  setIsProlongingMembership(true);
                }}
              >
                {__('Predĺžiť členstvo')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        )
      }
    >
      <AppContext title={__('Moja organizácia')} />
      <Wrapper activeTab={__('Členstvo')}>
        <Segment raised loading={isFetching || isFetchingScreen > 0}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Organizácia')}</Th>
                <Th>{__('Od-do')}</Th>
                <Th>{__('Stav')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {memberships.map(item => {
                return (
                  <Tr key={item._id}>
                    <Td>
                      {item.org_name}
                      <br />
                      <Faded>{item.org_profile_name}</Faded>
                    </Td>
                    <Td>
                      {item.valid_from
                        ? moment(item.valid_from).format('DD.MM.YYYY')
                        : '?'}
                      {' - '}
                      {item.valid_to
                        ? moment(item.valid_to).format('DD.MM.YYYY')
                        : '?'}
                    </Td>
                    <Td>
                      {getStatusIcon(item.status, status => {
                        const statusItem = membershipStatuses.find(
                          ({ value }) => value === status,
                        );
                        return statusItem ? statusItem.label : status;
                      })}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Segment>
      </Wrapper>
      {isProlongingMembership && (
        <ProlongModal
          onClose={() => {
            setIsProlongingMembership(false);
          }}
          onSuccess={() => {
            setLastDataFetch(new Date());
          }}
          appspace={appspace}
          parentRelations={parentRelations}
        />
      )}
    </Layout>
  );
};

List.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ appspace: PropTypes.string.isRequired })
      .isRequired,
  }).isRequired,
  isFetching: PropTypes.bool,
};

List.defaultProps = {
  isFetching: false,
};

const mapStateToProps = state => {
  return {
    acl: aclSelector(state),
  };
};

export default connect(mapStateToProps)(List);
