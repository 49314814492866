import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, Form, formValueSelector, reduxForm, change } from 'redux-form';
import { required } from '../../../../validation';
import { __ } from '../../../../utilities';
import Api from '../../../../Api';
import { sportCodelistSelector } from '../../../App/selectors';
import { withRouter } from 'react-router-dom';
import Segment from '@sportnet/ui/Segment';

const FORM_NAME = 'INVITATION_FORM';

const InvitationForm = ({
  submitting,
  handleSubmit,
  match: {
    params: { appspace },
  },
  sportCodelist,
  selectedSector,
  dispatch,
}) => {
  const [currentPPO, setCurrentPPO] = useState();
  const [currentPPOsectorsOptions, setCurrentPPOsectorsOptions] = useState([]);
  // ziskanie aktualne prihlasenej PPO a jej sektorov
  useEffect(() => {
    Api.organizationPPOProfile(appspace)
      .then(ppo => {
        setCurrentPPO(ppo);
      })
      .catch(e => {
        alert(
          `Chyba: ${
            e.details
              ? e.details.userinfo ||
                `${e.details.name}: ${e.details.description}`
              : e.message
          }`,
        );
      });
  }, [appspace]);
  useEffect(() => {
    setCurrentPPOsectorsOptions(
      (currentPPO?.sectors || []).map(
        ({ _id, sectorId, itemId, category, matricityPPO }) => {
          let label = _id;
          switch (category) {
            case 'sport':
              const labelItem = sportCodelist[itemId]?.label || itemId;
              const labelSector = sportCodelist[itemId]
                ? sportCodelist[itemId].sectors[sectorId]?.label || sectorId
                : sectorId;
              label = `Šport / ${labelItem} / ${labelSector}`;
              break;
            default:
              label = _id;
          }
          return {
            value: _id,
            label,
            matricityPPO,
          };
        },
      ),
    );
  }, [currentPPO, sportCodelist]);
  const [selectedSectorMatricityPPO, setSelectedSectorMatricityPPO] =
    useState();
  useEffect(() => {
    const sector = (currentPPOsectorsOptions || []).find(
      ({ value }) => value === selectedSector,
    );
    if (sector && sector.matricityPPO) {
      setSelectedSectorMatricityPPO(sector.matricityPPO);
      dispatch(change(FORM_NAME, 'register_matricity', true));
    } else {
      setSelectedSectorMatricityPPO(null);
      dispatch(change(FORM_NAME, 'register_matricity', false));
    }
  }, [selectedSector, currentPPOsectorsOptions, dispatch]);
  if (!currentPPO) {
    return <Segment loading />;
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12}>
          <Field
            component={FormField}
            name="emails"
            type="textarea"
            label={__('Emailové adresy')}
            validate={[required]}
            placeholder={__(
              'Zadajte emailové adresy oddelené čiarkou, alebo novým riadkom',
            )}
            required
          />
        </Col>
        <Col xs={12}>
          <Field
            label={__('Sektor')}
            component={FormField}
            name="sector"
            type="theselectsimple"
            options={currentPPOsectorsOptions}
          />
        </Col>
        <Col xs={12}>
          <Field
            component={FormField}
            name="register_matricity"
            type="checkbox"
            disabled={!!!selectedSectorMatricityPPO}
            label={__('Vytvoriť príslušnosť aj voči matričnej organizácii')}
          />
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Button
              type="submit"
              primary
              loading={submitting}
              disabled={submitting}
              block
            >
              {__('Odoslať')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

InvitationForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(InvitationForm);

export default connect((state, props) => {
  return {
    initialValues: props.data || {},
    sportCodelist: sportCodelistSelector(state),
    selectedSector: formValueSelector(FORM_NAME)(state, 'sector'),
  };
})(withRouter(connected));
