import { diffWords } from 'diff';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import { setProp } from '@sportnet/utilities';
import { deepCompare } from '@sportnet/utilities/deepCompare';
import styled, { css } from 'styled-components';
import { codelistDefinition } from '../../containers/FO/definitions';

const Rows = styled.div`
  padding: ${rem(10)} ${rem(5)};
`;

const Row = styled.div`
  padding-left: ${({ indent }) => rem((indent + 1) * 20)};
  padding-top: ${rem(2)};
  padding-bottom: ${rem(2)};
  display: flex;
  align-items: center;
  font-size: ${rem(12)};
  justify-content: space-between;
  position: relative;
  ${({ type }) => {
    let background = 'transparent';
    let content = '';
    if (type === 'M') {
      background = '#fbf6e9';
    } else if (type === 'A') {
      background = '#ecfdf0';
      content = '+';
    } else if (type === 'R') {
      background = '#fbe9eb';
      content = '-';
    }
    return css`
      background: ${background};
      &:before {
        position: absolute;
        left: 5px;
        font-family: monospace;
        color: #6e6e6e;
        content: '${content}';
      }
    `;
  }};
`;
const Label = styled.div`
  color: #555555;
  font-weight: bold;
  padding-right: ${rem(10)};
`;

const COMPARE_TYPES = ['R', 'M', 'A', 'U'];

class DeepCompareVisualization extends React.PureComponent {
  // snaží sa získať label zo schémy, ak nenájde, použije property/index
  getItemLabel = (item, defaultValue) => {
    const { schema } = this.props;
    let modifiedPath = null;
    if (item.serializedPath) {
      const path = item.serializedPath.split('.');
      modifiedPath = path
        .reduce((acc, p) => {
          if (!Number.isNaN(Number(p))) {
            return [...acc, '$'];
          }
          return [...acc, p];
        }, [])
        .join('.');
    }
    const schemaKey = Object.keys(schema).find(i => {
      return (
        (modifiedPath && i === modifiedPath) ||
        i === (item.serializedPath || item)
      );
    });
    if (schemaKey) {
      return schema[schemaKey];
    }
    return defaultValue;
  };

  // value pre string compare
  getValue = value => {
    if (typeof value === 'boolean') {
      return value === true ? 'true' : 'false';
    }
    return (value || '').toString();
  };

  getArrayInnerItemDisplayState = item => {
    let display = false;
    if (Array.isArray(item)) {
      if (!display) {
        return this.getArrayDisplayState(item);
      }
    } else if (item.type && COMPARE_TYPES.indexOf(item.type) > -1) {
      return item.type !== 'U';
    } else {
      Object.keys(item).forEach(key => {
        if (!display) {
          display = this.getArrayInnerItemDisplayState(item[key]);
        }
      });
    }
    return display;
  };

  getArrayDisplayState = array => {
    let display = false;
    array.forEach(item => {
      if (!display) {
        display = this.getArrayInnerItemDisplayState(item);
      }
    });
    return display;
  };

  // transformuje výstup z deep compare funkcie na celistvý objekt pre iteráciu
  createComparisonObject = (comparison, obj) => {
    let comparisonObject = Array.isArray(obj) ? [...obj] : { ...obj };
    comparison.forEach(c => {
      comparisonObject = setProp(comparisonObject, c.path, c);
    });
    return comparisonObject;
  };

  // ktokolvek by sa cudoval co to za kontrola, null je v js objekt
  contentIsObject = obj =>
    (obj.content !== null && typeof obj.content === 'object') ||
    (obj.originalContent !== null && typeof obj.originalContent === 'object');

  // iterácia objektu
  iterateChild = (obj, property, indent) => {
    // vetva pre pole
    if (Array.isArray(obj[property])) {
      const displayArray = this.getArrayDisplayState(obj[property]);
      if (!displayArray) {
        return null;
      }

      // budeme iterovat indexy pola
      return (
        <React.Fragment>
          <Row indent={indent}>
            <Label>
              <div>{this.getItemLabel(property, property)}: [</div>
            </Label>
          </Row>
          {obj[property].map((i, idx) => {
            const displayObject = this.getArrayInnerItemDisplayState(i);
            if (!displayObject) {
              return null;
            }
            // ak ide uz o objekt, ktory je vysledkom deepCompare funkcie, vykreslime ho ako compare
            if (i.type && COMPARE_TYPES.indexOf(i.type) > -1) {
              // ak je obsahom objekt alebo pole, musime ho znova rozbit, aby sme hodnotu vedeli vypisat bez JSON.parse alebo podobne
              if (this.contentIsObject(i)) {
                return this.renderRow(
                  `${this.getItemLabel(idx, idx)}: {`,
                  this.renderComparision(
                    i.originalContent || (Array.isArray(i.content) ? [] : {}),
                    i.content || (Array.isArray(i.originalContent) ? [] : {}),
                    indent + 2,
                    i.serializedPath,
                  ),
                  indent + 1,
                  i.content ? 'A' : 'R',
                );
              }
              // ak je content primitivna hodnota, zobrazime
              return this.renderItem(i, indent + 1);
            }
            // ak nie je na danom indexe objekt ktory je vysledkom deepCompare funkcie, musime ist hlbsie
            return this.renderRow(
              `${idx}: {`,
              this.renderComparisionObject(i, indent + 2),
              indent + 1,
            );
          })}
          <Row indent={indent}>]</Row>
        </React.Fragment>
      );
    }
    // kedze nas predchadzajuca podmienka nezachytila, tak nejdeme iterovat pole ale objekt
    // skontrolujeme ci ide o objekt ktory je vysledkom deepCompare funkcie, ak nie, porovnavame dalej
    else if (
      !obj[property].type ||
      COMPARE_TYPES.indexOf(obj[property].type) === -1
    ) {
      const displayObject = this.getArrayInnerItemDisplayState(obj[property]);
      if (!displayObject) {
        return null;
      }
      return this.renderRow(
        `${this.getItemLabel(property, property)}: {`,
        this.renderComparisionObject(obj[property], indent + 1),
        indent,
      );
    }
    // v opacnom pripade ide o vysledkom deepCompare funkcie a mozeme zobrazit rozdiel
    else if (obj[property].type && this.contentIsObject(obj[property])) {
      const displayObject = this.getArrayInnerItemDisplayState(obj[property]);
      if (!displayObject) {
        return null;
      }
      return this.renderRow(
        `${this.getItemLabel(property, property)}: {`,
        this.renderComparision(
          obj[property].originalContent ||
            (Array.isArray(obj[property].content) ? [] : {}),
          obj[property].content ||
            (Array.isArray(obj[property].originalContent) ? [] : {}),
          indent + 1,
        ),
        indent,
        obj[property].content ? 'A' : 'R',
      );
    }
    return this.renderItem(obj[property], indent);
  };

  renderComparision = (
    object = this.props.object,
    anotherObject = this.props.anotherObject,
    startingIndent = 0,
    baseSerialisedPath = '',
  ) => {
    let comparison = deepCompare(object, anotherObject);
    if (baseSerialisedPath) {
      comparison = Object.keys(comparison).map(k => ({
        ...comparison[k],
        serializedPath: `${baseSerialisedPath}.${comparison[k].serializedPath}`,
      }));
    }
    const comparisonObject = this.createComparisonObject(
      comparison,
      Array.isArray(object) ? [] : {},
    );
    return this.renderComparisionObject(comparisonObject, startingIndent);
  };

  renderComparisionObject = (obj, indent = 0) => {
    return Object.keys(obj).reduce((acc, property) => {
      return [...acc, this.iterateChild(obj, property, indent)];
    }, []);
  };

  renderContent = diffItem => {
    let serializedPath = null;

    if (diffItem.serializedPath) {
      const path = diffItem.serializedPath.split('.');
      serializedPath = path
        .reduce((acc, p) => {
          if (!Number.isNaN(Number(p))) {
            return [...acc, '$'];
          }
          return [...acc, p];
        }, [])
        .join('.');
    }

    const values = (this.props.values || {})[serializedPath];

    const original = (values || []).find(
      i => i.value === diffItem.originalContent,
    );
    const modified = (values || []).find(i => i.value === diffItem.content);

    if (diffItem.originalContent === diffItem.content) {
      return (
        <span>{original ? original.label : diffItem.originalContent}</span>
      );
    }

    const diff = diffWords(
      this.getValue(original ? original.label : diffItem.originalContent),
      this.getValue(modified ? modified.label : diffItem.content),
    );

    const added = diff.filter(i => !i.removed);
    const removed = diff.filter(i => !i.added);

    const items = [];
    removed.forEach(i =>
      items.push(
        <span
          style={{
            textDecoration: 'line-through',
            color: '#b30000',
            background: '#e8c4c4',
          }}
        >
          {i.value}
        </span>,
      ),
    );

    if (removed.length && added.length) {
      items.push(<span>&nbsp;</span>);
    }

    added.forEach(i =>
      items.push(
        <span
          style={{
            textDecoration: 'none',
            color: '#406619',
            background: '#c4e8cd',
          }}
        >
          {i.value}
        </span>,
      ),
    );

    return items;

    /**
     * Diff po pismenach
     */

    // return diff.map(part => {
    //   let style = {};
    //   if (part.added) {
    //     style = {
    //       textDecoration: 'none',
    //       color: '#406619',
    //       background: '#c4e8cd',
    //     };
    //   } else if (part.removed) {
    //     style = {
    //       textDecoration: 'none',
    //       color: '#b30000',
    //       background: '#e8c4c4',
    //     };
    //   }
    //   return <span style={style}>{part.value}</span>;
    // });
  };

  renderItem = (diffItem, indent) => {
    return (
      <Row type={diffItem.type} indent={indent}>
        <Label>
          {this.getItemLabel(diffItem, { ...diffItem }.path.pop())}:
        </Label>
        <div style={{ wordBreak: 'break-word', textAlign: 'right' }}>
          {this.renderContent(diffItem)}
        </div>
      </Row>
    );
  };

  renderRow = (label, value, indent, type = undefined) => {
    return (
      <React.Fragment>
        <Row indent={indent} {...(type && { ...type })}>
          <Label>
            <div>{label}</div>
          </Label>
        </Row>
        {value}
        <Row indent={indent} {...(type && { ...type })}>
          {'}'}
        </Row>
      </React.Fragment>
    );
  };

  render() {
    return <Rows>{this.renderComparision()}</Rows>;
  }
}

DeepCompareVisualization.propTypes = {
  object: PropTypes.shape({}).isRequired,
  anotherObject: PropTypes.shape({}).isRequired,
  schema: PropTypes.shape({}),
  values: PropTypes.arrayOf(codelistDefinition),
};

DeepCompareVisualization.defaultProps = {
  schema: {},
  values: {},
};

export default DeepCompareVisualization;
