import NotFound from '@sportnet/ui/NotFound';
import Segment from '@sportnet/ui/Segment';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ContentLoader from '../../../../components/ContentLoader';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import { matchDefinition, PODefinition } from '../../definitions';
import { orgEntitySelector, orgIsErrorSelector } from '../selectors';
import Tabber, { TABS } from '../Tabber';
import ProfileForm from './form';

const Profile = ({
  history,
  didFailed,
  org,
  match: {
    params: { appspace },
  },
}) => {
  return (
    <Layout>
      <AppContext
        title={org && org.full_name}
        backUrl={`/admin/${appspace}/organizations`}
        breadcrumbs={[
          {
            name: __('Späť na zoznam'),
            url: `/admin/${appspace}/organizations`,
          },
        ]}
      />
      <Tabber activeTab={TABS.profile} />
      <Segment noBottomGutter>
        {didFailed ? (
          <Segment raised>
            <NotFound
              title={__('Detail organizácie nie je možné načítať.')}
              icon="error"
              actionLabel={__('Späť na zoznam')}
              onClickAction={() => {
                history.push(`/admin/${appspace}/organizations`);
              }}
            />
          </Segment>
        ) : !org ? (
          <ContentLoader />
        ) : (
          <ProfileForm onSubmit={() => {}} initialValues={org} />
        )}
      </Segment>
    </Layout>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { organizationId },
    },
  },
) => ({
  org: orgEntitySelector(state, organizationId),
  didFailed: orgIsErrorSelector(state, organizationId),
});

const mapDispatchToProps = {};

Profile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: matchDefinition.isRequired,
  didFailed: PropTypes.bool.isRequired,
  org: PODefinition,
};

Profile.defaultProps = {
  org: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
