import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import Segment from '@sportnet/ui/Segment';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import KeyValueTable from '../../../../../components/KeyValueTable';
import { getProp, __ } from '../../../../../utilities';
import { appspaceDefinition } from '../../../../App/definitions';
import {
  appspaceSelector,
  codelistEntrySelector,
  sportsCodelistSelector,
} from '../../../../App/selectors';
import { codelistDefinition } from '../../../../FO/definitions';
import SectorLabel from '../../../../SectorLabel';
import { universalRequestDefinition } from '../../../definitions';

class SportExpertOrg extends PureComponent {
  render() {
    const {
      request: { data: { sport_expert_org: sportExpertOrg } = {} },
      competenceType,
      legalForm,
      sports,
      appspace: { org_profile: orgProfile },
    } = this.props;
    if (!sportExpertOrg) return null;
    const sport = getProp(sports, [sportExpertOrg.sport, 'label'], '');
    const sector = getProp(
      sports,
      [sportExpertOrg.sport, 'sectors', sportExpertOrg.sport_sector, 'label'],
      '',
    );
    const fields = [
      {
        label: __('Šport'),
        value: sport,
      },
      {
        label: __('Športové odvetvie'),
        value: sector,
      },
    ];
    if (competenceType.label) {
      fields.push({
        label: __('Typ / funkcia'),
        value: competenceType.label || '',
      });
    }
    if (legalForm.label) {
      fields.push({
        label: __('Právny titul'),
        value: legalForm.label || '',
      });
    }
    if (sportExpertOrg.date_from) {
      fields.push({
        label: sportExpertOrg.date_to
          ? __('Planosť od - do')
          : __('Planosť od'),
        value: `${moment(sportExpertOrg.date_from).format('DD.MM.YYYY')}${
          sportExpertOrg.date_to
            ? ` - ${moment(sportExpertOrg.date_to).format('DD.MM.YYYY')}`
            : ''
        }`,
      });
    }
    const kvFields = [
      {
        key: __('Organizácia / profil organizácie'),
        values: [
          `${orgProfile.organization_name || ''} / ${orgProfile.name || ''}`,
        ],
      },
      {
        key: __('Sektor'),
        values: [<SectorLabel sector={sportExpertOrg.sector} />],
      },
    ];
    if (competenceType.label) {
      kvFields.push({
        key: __('Typ / funkcia'),
        values: [competenceType.label || ''],
      });
    }
    if (legalForm.label) {
      kvFields.push({
        key: __('Právny titul'),
        values: [legalForm.label || ''],
      });
    }
    if (sportExpertOrg.date_from) {
      kvFields.push({
        key: sportExpertOrg.date_to ? __('Planosť od - do') : __('Planosť od'),
        values: [
          `${moment(sportExpertOrg.date_from).format('DD.MM.YYYY')}${
            sportExpertOrg.date_to
              ? ` - ${moment(sportExpertOrg.date_to).format('DD.MM.YYYY')}`
              : ''
          }`,
        ],
      });
    }
    return (
      <Segment raised>
        <Row>
          <Col m={6}>
            <Header size="s">{__('Športová odborná príslušnosť')}</Header>
            <KeyValueTable rows={kvFields} />
          </Col>
        </Row>
      </Segment>
    );
  }
}

SportExpertOrg.propTypes = {
  request: universalRequestDefinition.isRequired,
  competenceType: codelistDefinition.isRequired,
  legalForm: codelistDefinition.isRequired,
  sports: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  appspace: appspaceDefinition.isRequired,
};

const mapStateToProps = (state, props) => {
  const {
    request: { data: { sport_expert_org: sportExpertOrg = {} } = {} },
  } = props;
  return {
    competenceType: codelistEntrySelector(
      'sport-expert-competence-type',
      sportExpertOrg.competence_type,
    )(state),
    legalForm: codelistEntrySelector(
      'sport-expert-legal-form',
      sportExpertOrg.legal_form,
    )(state),
    sports: sportsCodelistSelector(state),
    appspace: appspaceSelector(state),
  };
};

export default connect(mapStateToProps)(SportExpertOrg);
