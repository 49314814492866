import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, useHistory, Redirect } from 'react-router-dom';
import Authorization from '../Authorization';
import { clearError } from './actions';
import { errorMessageSelector } from './selectors';
import AuthProvider from '@sportnet/auth-react/AuthProvider';
import PpoSelector from '@sportnet/auth-react/PpoSelector';
import { compose } from 'redux';

const matchDispatchToProps = {
  clearError,
};

const mapStateToProps = state => ({
  error: errorMessageSelector(state),
});

const App = ({ error, clearError }) => {
  const history = useHistory();

  React.useEffect(() => {
    if (error) {
      alert(error);
      clearError();
    }
  }, [error, clearError]);

  return (
    <>
      <AuthProvider app={process.env.REACT_APP_APP_ID}>
        <Route exact path="/">
          <PpoSelector
            onSelectAppspace={appSpace => {
              history.push(`/admin/${appSpace.ppo}/users`);
            }}
          />
        </Route>
        <Route path={'/_auth'} />
      </AuthProvider>
      <Route path="/admin/:appspace/:path?" component={Authorization} />
      <Route exact path="/admin">
        <Redirect to="/" />
      </Route>
    </>
  );
};

App.propTypes = {
  error: PropTypes.string,
};

App.defaultProps = {
  error: null,
};

export default compose(connect(mapStateToProps, matchDispatchToProps))(App);
