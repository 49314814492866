import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import React from 'react';
import { Field } from 'redux-form';
import { __ } from '../../../utilities';
import { required } from '../../../validation';

class GDPRForm extends React.PureComponent {
  render() {
    return (
      <Row>
        <Col>
          <Field
            component={FormField}
            name="gdpr"
            type="textarea"
            placeholder={__('Znenie súhlasu s GDPR')}
            validate={[required]}
            required
            label={__('Znenie súhlasu s GDPR')}
          />
        </Col>
      </Row>
    );
  }
}

export default GDPRForm;
