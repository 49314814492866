import Api from '../../Api';
import { RECEIVE_PRICELIST_SUCCESS } from './constants';

export const receivePriceListSuccess = data => ({
  type: RECEIVE_PRICELIST_SUCCESS,
  payload: {
    data,
  },
});

export const createPriceList = (appspace, data) => {
  return async () => {
    try {
      await Api.crmPPOPricelistItemCreate(appspace, { data });
    } catch (e) {
      console.error(e);
    }
  };
};

export const updatePriceList = (appspace, id, data) => {
  return async () => {
    try {
      await Api.crmPPOPricelistItemUpdate(appspace, id, { data });
    } catch (e) {
      console.error(e);
    }
  };
};

export const deletePriceList = (appspace, id) => {
  return async () => {
    try {
      await Api.crmPPOPricelistItemDelete(appspace, id);
    } catch (e) {
      console.error(e);
    }
  };
};
