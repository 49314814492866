const getApiError = error => {
  if (error.details !== undefined) {
    if (error.details.code !== undefined) {
      const { code: statusCode, ...restDetails } = error.details;
      return {
        ...error,
        details: {
          ...restDetails,
          statusCode,
        },
      };
    } else {
      return error;
    }
  } else if (error.statusCode !== undefined) {
    return {
      message: error.description,
      details: {
        statusCode: 500,
        name: error.name,
        description: error.description,
      },
    };
  }
  return {
    message: error.message,
    details: {
      statusCode: 500,
      name: 'OTHER_EXCEPTION',
      description: error.message,
    },
  };
};

export default getApiError;
