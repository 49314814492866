import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { isSubmitting, submit as submitFormAction } from 'redux-form';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import { aclSelector, appspaceSelector } from '../../../App/selectors';
import { createBio, deleteBio, getBios, updateBio } from '../../actions';
import { Faded } from '../../detail';
import {
  getActiveUserBiosSelector,
  getActiveUserIsFetchingSelector,
} from '../../selectors';
import Wrapper from '../index';
import BioForm from './form';
import { useParams } from 'react-router-dom';
import { useAuth } from '@sportnet/auth-react';

const BIO_FORM_NAME = 'BIO_FORM';

const BiosList = ({
  createBio,
  getBios,
  updateBio,
  deleteBio,
  isFetching,
  items,
  acl,
  submitFormAction,
  submitting,
}) => {
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [createDisabled, setCreateDisabled] = React.useState(true);
  const [deleting, setDeleting] = React.useState(false);

  const { ppo: appSpace } = useAuth();
  const { sportnetId } = useParams();

  React.useEffect(() => {
    if (!(sportnetId && appSpace)) {
      return;
    }
    getBios(appSpace, sportnetId);
  }, [appSpace, sportnetId, getBios]);

  React.useEffect(() => {
    if (acl) {
      setCreateDisabled(!acl.crmUserBioCreate);
    }
  }, [acl]);

  const submit = data => {
    try {
      if (!data._id) {
        createBio(appSpace, sportnetId, {
          org_profile_id: appSpace,
          ...data,
        });
      } else {
        updateBio(appSpace, sportnetId, data._id, {
          ...data,
        });
      }
      setData(null);
      setSidebarVisible(false);
    } catch (e) {
      alert('Error');
    }
  };

  const onDelete = async itemId => {
    const confirm = window.confirm(__('Skutočne si želáte zmazať položku?'));
    if (confirm) {
      setDeleting(true);
      await deleteBio(appSpace, sportnetId, itemId);
      setData(null);
      setSidebarVisible(false);
      setDeleting(false);
    }
  };

  const itemAlreadyExists = items.find(i => i.org_profile_id === appSpace);

  return (
    <Layout
      bottomFixed={
        !createDisabled &&
        !isFetching && (
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                primary
                onClick={() => {
                  setSidebarVisible(true);
                  setData(itemAlreadyExists || null);
                }}
              >
                {__('Upraviť bio')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        )
      }
    >
      <Wrapper activeTab={__('Bio')} isFetching={isFetching}>
        <Segment raised loading={isFetching}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Organizácia')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.map(item => {
                return (
                  <Tr
                    key={item._id}
                    onClick={() => {
                      setSidebarVisible(true);
                      setData(item);
                    }}
                  >
                    <Td>
                      {item.org_name}
                      <br />
                      <Faded>{item.org_profile_name}</Faded>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Segment>
        <Modal
          isOpen={sidebarVisible}
          handleClose={() => {
            setSidebarVisible(false);
          }}
        >
          <ModalContent>
            {sidebarVisible && (
              <BioForm
                initialValues={data}
                appSpace={appSpace}
                form={BIO_FORM_NAME}
                onSubmit={submit}
              />
            )}
          </ModalContent>
          {(!data || data.org_profile_id === appSpace) && (
            <ModalActions>
              <div>
                {data && data._id && (
                  <Button
                    loading={deleting}
                    onClick={() => {
                      onDelete(data._id);
                    }}
                    danger
                  >
                    {__('Zmazať')}
                  </Button>
                )}
              </div>

              <div>
                <Button
                  onClick={() => {
                    setSidebarVisible(false);
                  }}
                >
                  {__('Zavrieť')}
                </Button>
                &nbsp;
                <Button
                  onClick={() => {
                    submitFormAction(BIO_FORM_NAME);
                  }}
                  primary
                  loading={submitting}
                >
                  {__('Uložiť')}
                </Button>
              </div>
            </ModalActions>
          )}
        </Modal>
      </Wrapper>
    </Layout>
  );
};

BiosList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      org_name: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  items: getActiveUserBiosSelector(state),
  isFetching: getActiveUserIsFetchingSelector(state),
  acl: aclSelector(state),
  submitting: isSubmitting(BIO_FORM_NAME)(state),
  appSpace: appspaceSelector(state),
});

const mapDispatchToProps = {
  getBios,
  createBio,
  updateBio,
  deleteBio,
  submitFormAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BiosList);
