import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import Segment from '@sportnet/ui/Segment';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import KeyValueTable from '../../../../../components/KeyValueTable';
import { getStateName, __ } from '../../../../../utilities';
import { appspaceDefinition } from '../../../../App/definitions';
import {
  appspaceSelector,
  codelistEntrySelector,
} from '../../../../App/selectors';
import { codelistDefinition } from '../../../../FO/definitions';
import SectorLabel from '../../../../SectorLabel';
import { universalRequestDefinition } from '../../../definitions';

class SportOrg extends PureComponent {
  render() {
    const {
      request: { data: { sport_org: sportOrg } = {} },
      transferType,
      appspace: { org_profile: orgProfile },
    } = this.props;
    if (!sportOrg) return null;
    const kvFields = [
      {
        key: __('Organizácia / profil organizácie'),
        values: [
          `${orgProfile.organization_name || ''} / ${orgProfile.name || ''}`,
        ],
      },
      {
        key: __('Sektor'),
        values: [<SectorLabel sector={sportOrg.sector} />],
      },
    ];

    if (transferType.label) {
      kvFields.push({
        key: __('Typ transferu'),
        values: [transferType.label || ''],
      });
    }
    if (sportOrg.date_from) {
      kvFields.push({
        key: sportOrg.date_to ? __('Planosť od - do') : __('Planosť od'),
        values: [
          `${moment(sportOrg.date_from).format('DD.MM.YYYY')}${
            sportOrg.date_to
              ? ` - ${moment(sportOrg.date_to).format('DD.MM.YYYY')}`
              : ''
          }`,
        ],
      });
    }
    if (sportOrg.status) {
      kvFields.push({
        key: __('Stav záznamu'),
        values: [getStateName(sportOrg.status)],
      });
    }

    return (
      <Segment raised>
        <Row>
          <Col m={6}>
            <Header size="s">{__('Športová príslušnosť')}</Header>
            <KeyValueTable rows={kvFields} />
          </Col>
        </Row>
      </Segment>
    );
  }
}

SportOrg.propTypes = {
  request: universalRequestDefinition.isRequired,
  transferType: codelistDefinition.isRequired,
  sports: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  appspace: appspaceDefinition.isRequired,
};

const mapStateToProps = (state, props) => {
  const {
    request: { data: { sport_org: sportOrg = {} } = {} },
  } = props;
  return {
    transferType: codelistEntrySelector(
      'sport-org-tranfer-type',
      sportOrg.transfer_type,
    )(state),
    appspace: appspaceSelector(state),
  };
};

export default connect(mapStateToProps)(SportOrg);
