import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Sidebar from '@sportnet/ui/Sidebar';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import { aclSelector, appspaceSelector } from '../../../App/selectors';
import {
  createUserMedicalExamination,
  editUserMedicalExamination,
  fetchUserMedicalExaminations,
} from '../../actions';
import { medicalExaminationDefinition } from '../../definitions';
import {
  getActiveIdSelector,
  getActiveUserMedicalExaminationsIsFetchingSeletor,
  getActiveUserMedicalExaminationsSelector,
  getActiveUserMedicalExaminationsFailure,
} from '../../selectors';
import Wrapper from '../index';
import MedicalExaminationForm from './form';
import Segment from '@sportnet/ui/Segment';
import { Faded } from '../../detail';
import formatDate from '../../../../utilities/formatDate';
import config from '../../../../config';
import { useParams } from 'react-router-dom';
import { useAuth } from '@sportnet/auth-react';
import AppContext from '../../../../context/AppContext';
import { parseJSON } from 'date-fns';
import getApiError from '../../../../utilities/getApiError';
import MedicalExaminationsLoader from './MedicalExaminationsLoader';

const MedicalExaminationsList = ({
  fetchUserMedicalExaminations,
  editUserMedicalExamination,
  createUserMedicalExamination,
  acl,
  isFetching,
  medicalExaminations,
  error,
}) => {
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [selectedMedicalExamination, setSelectedMedicalExamination] =
    React.useState(null);
  const [updateDisabled, setUpdateDisabled] = React.useState(false);
  const [createDisabled, setCreateDisabled] = React.useState(false);

  const { ppo: appSpace } = useAuth();
  const { sportnetId } = useParams();
  const { activeAppSpace } = React.useContext(AppContext);

  React.useEffect(() => {
    if (!(sportnetId && appSpace)) {
      return;
    }
    fetchUserMedicalExaminations(sportnetId, appSpace);
  }, [sportnetId, appSpace, fetchUserMedicalExaminations]);

  React.useEffect(() => {
    if (acl) {
      setUpdateDisabled(!acl.crmUserMedicalExaminationUpdate);
      setCreateDisabled(!acl.crmUserMedicalExaminationCreate);
    }
  }, [acl]);

  const submit = async data => {
    const {
      org_profile_id,
      doctor,
      valid_from,
      valid_to: validTo = null,
    } = data;
    setSidebarVisible(false);
    setSelectedMedicalExamination(null);
    if (data._id) {
      try {
        await editUserMedicalExamination(sportnetId, data._id, appSpace, {
          org_profile_id,
          doctor,
          valid_from,
          valid_to: validTo,
        });
      } catch (e) {
        const err = getApiError(e);
        alert(
          `Chyba úpravy lekárskej prehliadky: ${err.details.statusCode} ${err.details.name} ${err.message}`,
        );
      }
    } else {
      try {
        await createUserMedicalExamination(sportnetId, appSpace, {
          org_profile_id,
          doctor,
          valid_from,
          valid_to: validTo,
        });
      } catch (e) {
        const err = getApiError(e);
        alert(
          `Chyba vytvorenia lekárskej prehliadky: ${err.details.statusCode} ${err.details.name} ${err.message}`,
        );
      }
    }
  };

  const handleAddRecordClick = () => {
    if (activeAppSpace) {
      const {
        name: org_name,
        organization_name,
        _id: org_profile_id,
      } = activeAppSpace;
      const custom_org_name = `${organization_name} / ${org_name}`;
      setSidebarVisible(true);
      setSelectedMedicalExamination({
        custom_org_name,
        org_profile_id,
      });
    }
  };

  const handleClickTableRow = item => {
    if (!updateDisabled) {
      setSidebarVisible(true);
      setSelectedMedicalExamination({
        ...item,
        valid_from: parseJSON(item.valid_from),
        valid_to: item.valid_to ? parseJSON(item.valid_to) : null,
        custom_org_name: `${item.org_name} / ${item.org_profile_name}`,
      });
    }
  };

  const handleSidebarClose = () => {
    setSidebarVisible(false);
    setSelectedMedicalExamination(null);
  };

  return (
    <Layout
      bottomFixed={
        !createDisabled && (
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                onClick={handleAddRecordClick}
                primary
                data-testid="CRM_FO_Button_Medical_New"
              >
                {__('Pridať záznam')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        )
      }
    >
      <Wrapper activeTab={__('Lekárske prehliadky')}>
        <Segment raised loading={isFetching}>
          {isFetching && medicalExaminations.length === 0 ? (
            <MedicalExaminationsLoader />
          ) : (
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Organizácia')}</Th>
                  <Th>{__('Lekár')}</Th>
                  <Th>{__('Platné od')}</Th>
                  <Th>{__('Platné do')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {(medicalExaminations ?? []).map(item => {
                  return (
                    <Tr
                      key={item._id}
                      onClick={() => {
                        handleClickTableRow(item);
                      }}
                    >
                      <Td>
                        {item.org_name}
                        <br />
                        <Faded>{item.org_profile_name}</Faded>
                      </Td>
                      <Td>{item.doctor}</Td>
                      <Td>{formatDate(item.valid_from, config.DATE_FORMAT)}</Td>
                      <Td>
                        {item.valid_to &&
                          formatDate(item.valid_to, config.DATE_FORMAT)}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
        </Segment>
      </Wrapper>
      <Sidebar
        onClose={handleSidebarClose}
        visible={sidebarVisible}
        header={__('Lekárska prehliadka')}
      >
        {sidebarVisible && (
          <MedicalExaminationForm
            initialValues={selectedMedicalExamination}
            onSubmit={submit}
          />
        )}
      </Sidebar>
    </Layout>
  );
};

MedicalExaminationsList.propTypes = {
  medicalExaminations: PropTypes.arrayOf(medicalExaminationDefinition)
    .isRequired,
};

const mapStateToProps = state => {
  return {
    medicalExaminations: getActiveUserMedicalExaminationsSelector(state),
    isFetching: getActiveUserMedicalExaminationsIsFetchingSeletor(state),
    acl: aclSelector(state),
    appspace: appspaceSelector(state),
    activeUserId: getActiveIdSelector(state),
    error: getActiveUserMedicalExaminationsFailure(state),
  };
};

const mapDispatchToProps = {
  createUserMedicalExamination,
  editUserMedicalExamination,
  fetchUserMedicalExaminations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MedicalExaminationsList);
