import DefaultTabber from '@sportnet/ui/Tabber';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { __ } from '../../../utilities';
import { matchDefinition } from '../definitions';

export const TABS = {
  profile: __('Základné údaje'),
  addresses: __('Adresy'),
  bankAccounts: __('Bankové účty'),
  contactPersons: __('Kontaktné osoby'),
  membership: __('Členstvo'),
  shortNames: __('Kódy organizácie'),
  sectors: __('Sektory'),
  users: __('Používatelia'),
  custom: __('Ostatné'),
};

const TABS_VALUES = Object.values(TABS);

const Tabber = ({
  match: {
    params: { organizationId, appspace, orgProfileId },
  },
  history,
  activeTab,
  isFetching,
}) => {
  const handleTabClick = React.useCallback(
    tabName => {
      const changeToTab = tab => {
        history.push(
          `/admin/${appspace}/organizations/${organizationId}/profiles/${orgProfileId}/${tab}`,
        );
      };
      switch (tabName) {
        case TABS.profile:
          changeToTab('profile');
          break;
        case TABS.addresses:
          changeToTab('addresses');
          break;
        case TABS.bankAccounts:
          changeToTab('bank-accounts');
          break;
        case TABS.custom:
          changeToTab('custom');
          break;
        case TABS.membership:
          changeToTab('membership');
          break;
        case TABS.users:
          changeToTab('users');
          break;
        case TABS.contactPersons:
          changeToTab('contact-persons');
          break;
        case TABS.shortNames:
          changeToTab('shortnames');
          break;
        case TABS.sectors:
          changeToTab('sectors');
          break;
        default:
          break;
      }
    },
    [history, appspace, organizationId, orgProfileId],
  );

  return (
    <DefaultTabber
      active={activeTab}
      isFetching={isFetching}
      onClickItem={handleTabClick}
      items={TABS_VALUES}
    />
  );
};

Tabber.propTypes = {
  activeTab: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  match: matchDefinition.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

Tabber.defaultProps = {
  isFetching: false,
};

export default withRouter(Tabber);
