import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import List from '@sportnet/ui/List';
import ListItem from '@sportnet/ui/List/ListItem';
import moment from 'moment';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { getRequestStatusName, __ } from '../../../../../utilities';
import { universalRequestDefinition } from '../../../definitions';
import Header from '@sportnet/ui/Header';
import Segment from '@sportnet/ui/Segment';

export const ListSubItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: ${rem(12)};
  justify-content: space-between;
  & > div {
    padding: ${rem(1)} ${rem(3)};
  }
`;

export const StyledListItem = styled(ListItem)`
  & > div {
    padding: 0 ${rem(7)};
  }
`;

const StatusLog = ({ request: { status_log: statusLog } }) => {
  if (statusLog && statusLog.length > 0)
    return (
      <Segment raised>
        <Row>
          <Col m={6}>
            <Header size="s">{__('História stavov')}</Header>
            <FormGroup>
              <List>
                {statusLog.map(item => {
                  const { admin_user: adminUser = null } = item;
                  const { userInfo = null } = item;
                  return (
                    <StyledListItem key={item.change_date}>
                      <ListSubItemsWrapper>
                        <div>
                          {moment(item.change_date).format('DD.MM.YYYY HH:mm')}
                        </div>
                        <div>
                          {`${getRequestStatusName(
                            item.old_status,
                          )} -> ${getRequestStatusName(item.new_status)}`}
                        </div>
                        <div>
                          {userInfo
                            ? userInfo.display_name
                            : adminUser
                            ? adminUser.display_name
                            : __('n/a')}
                        </div>
                      </ListSubItemsWrapper>
                    </StyledListItem>
                  );
                })}
              </List>
            </FormGroup>
          </Col>
        </Row>
      </Segment>
    );
  return null;
};

StatusLog.propTypes = {
  request: universalRequestDefinition.isRequired,
};

export default StatusLog;
