import AuthProvider from '@sportnet/auth-react/AuthProvider';
import PropTypes from 'prop-types';
import React from 'react';
import Administration from '../Administration';
import { useParams } from 'react-router-dom';
import CoreApi from '../../Api';

const Authorization = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  const params = useParams();

  const { appspace: appSpaceFromUrl } = params;

  const handleOnAuthorize = React.useCallback(({ accessToken }) => {
    CoreApi.setToken(accessToken);
    setIsAuthenticated(true);
  }, []);

  React.useEffect(() => {
    if (appSpaceFromUrl) {
      setIsAuthenticated(false);
    }
  }, [appSpaceFromUrl]);

  return (
    <AuthProvider
      app={process.env.REACT_APP_APP_ID}
      ppo={appSpaceFromUrl}
      onAuthorize={handleOnAuthorize}
    >
      {!isAuthenticated ? <>Authorizing ...</> : <Administration />}
    </AuthProvider>
  );
};

Authorization.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      appspace: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    hash: PropTypes.string,
  }).isRequired,
};

Authorization.defaultProps = {};

export default Authorization;
