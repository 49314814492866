// import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Route, withRouter } from 'react-router-dom';
import CourierTemplatesList from './list';
import Edit from './detail/edit';
import Messages from './detail/messages';
import { CompatRoute } from 'react-router-dom-v5-compat';

class Courier extends PureComponent {
  render() {
    return (
      <React.Fragment>
        <CompatRoute
          exact
          path="/admin/:appspace/courier-templates"
          component={CourierTemplatesList}
        />
        <CompatRoute
          exact
          path="/admin/:appspace/courier-templates/:templateId"
          component={Edit}
        />
        <Route
          exact
          path="/admin/:appspace/courier-templates/:templateId/messages"
          component={Messages}
        />
      </React.Fragment>
    );
  }
}

Courier.propTypes = {};

Courier.defaultProps = {};

export default withRouter(Courier);
