import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';

const entityDomainSelector = state => state.entities;
const pricelistItemsDomainSelector = createSelector(
  entityDomainSelector,
  substate => substate.PriceList,
);

export const pricelistItemsSelector = listName =>
  createSelector(
    pricelistItemsDomainSelector,
    getListResults(listName),
    (items, ids) => {
      return (ids || []).map(id => items[id]);
    },
  );
