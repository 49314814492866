import { formatToString, __ } from '../../../../../utilities';

const getAddressAsString = addrObj => {
  return (
    formatToString(
      `${addrObj.street} ${addrObj.number}`,
      `${addrObj.zip} ${addrObj.city}`,
      addrObj.country,
    ) + ` (${getStatusString(addrObj.status)})`
  );
};

const getAddrType = (type, addrTypes) => {
  const result = addrTypes.find(a => type === a.value);
  if (result) {
    return result.label;
  }
  return type;
};

export const compareAddresses = (initialData, modifiedData, addrTypes) => {
  if (!(initialData.addresses && modifiedData.addresses)) {
    return [];
  }
  const addrOld = [...initialData.addresses];
  const addrNew = [...modifiedData.addresses];

  const pairs = addrOld.map(addr => {
    const idx = addrNew.findIndex(element => element.type === addr.type);
    if (idx > -1) {
      const match = addrNew.splice(idx, 1);
      return {
        key: getAddrType(addr.type, addrTypes),
        values: [getAddressAsString(addr), getAddressAsString(match[0])],
      };
    }
    return {
      key: getAddrType(addr.type, addrTypes),
      values: [getAddressAsString(addr), null],
    };
  });

  if (addrNew.length) {
    pairs.push(
      ...addrNew.map(addr => {
        return {
          key: getAddrType(addr.type, addrTypes),
          values: [null, getAddressAsString(addr)],
        };
      }),
    );
  }
  return pairs;
};

const getBankType = (type, bankAccountTypes) => {
  const result = bankAccountTypes.find(a => type === a.value);
  if (result) {
    return result.label;
  }
  return type;
};

const getStatusString = status => {
  switch (status) {
    case 'active':
      return __('Aktívny');
    case 'inactive':
      return __('Neaktívny');
    default:
      return status;
  }
};

const getBankAccountString = bank => {
  return `${bank.IBAN} (${getStatusString(bank.status)})`;
};

export const compareBankAccounts = (
  initialData,
  modifiedData,
  bankAccountTypes,
) => {
  if (!(initialData.bank_accounts && modifiedData.bank_accounts)) {
    return [];
  }
  const init = [...initialData.bank_accounts];
  const modif = [...modifiedData.bank_accounts];

  const pairs = init.map(bank => {
    const idx = modif.findIndex(element => element.type === bank.type);
    if (idx > -1) {
      const match = modif.splice(idx, 1);
      return {
        key: getBankType(bank.type, bankAccountTypes),
        values: [getBankAccountString(bank), getBankAccountString(match[0])],
      };
    }
    return {
      key: getBankType(bank.type, bankAccountTypes),
      values: [getBankAccountString(bank), null],
    };
  });

  if (modif.length) {
    pairs.push(
      ...modif.map(bank => {
        return {
          key: getBankType(bank.type, bankAccountTypes),
          values: [null, getBankAccountString(bank)],
        };
      }),
    );
  }
  return pairs;
};

export const compareOthers = (initialData, modifiedData) => {
  if (!(initialData.custom && modifiedData.custom)) {
    return [];
  }
  const init = [...initialData.custom];
  const modif = [...modifiedData.custom];

  const pairs = init.map(custom => {
    const idx = modif.findIndex(element => element.label === custom.label);
    if (idx > -1) {
      const match = modif.splice(idx, 1);
      return { key: custom.label, values: [custom.value, match[0].value] };
    }
    return { key: custom.label, values: [custom.value, null] };
  });

  if (init.length) {
    pairs.concat(
      init.map(custom => {
        return { key: custom.label, values: [null, custom.value] };
      }),
    );
  }
  return pairs;
};
