import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';
import Api from '../../../Api';
import { __, parseNumber } from '../../../utilities';
import { required } from '../../../validation';
import { FORM_NAME } from '../form';
import PriceFormPart from './priceFormPart';
import ValidityFormPart from './validityFormPart';

class ApplicationForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      apps: [],
    };
  }
  componentDidMount() {
    this.fetchApps();
  }
  handlePriceChange = e => {
    const {
      target: { name, value },
    } = e;
    const {
      price: { amount_vat_excl, vat_rate },
      dispatch,
    } = this.props;
    const parsedValue = parseNumber(value);
    if (name === 'price.amount_vat_excl') {
      dispatch(
        change(
          FORM_NAME,
          'price.amount',
          Number(
            parseFloat(parsedValue * ((100 + Number(vat_rate)) / 100)).toFixed(
              2,
            ),
          ),
        ),
      );
    } else if (name === 'price.amount') {
      dispatch(
        change(
          FORM_NAME,
          'price.amount_vat_excl',
          Number(
            parseFloat(parsedValue / ((100 + Number(vat_rate)) / 100)).toFixed(
              2,
            ),
          ),
        ),
      );
    } else if (name === 'price.vat_rate') {
      dispatch(
        change(
          FORM_NAME,
          'price.amount',
          Number(
            parseFloat(
              Number(amount_vat_excl) * ((100 + parsedValue) / 100),
            ).toFixed(2),
          ),
        ),
      );
    }
  };
  async fetchApps() {
    try {
      const { apps } = await Api.getPublicApps();
      this.setState({
        apps,
      });
    } catch (e) {
      console.error(e);
      alert(__('Nepodarilo sa získať zoznam aplikácií'));
    }
  }
  render() {
    const { apps } = this.state;
    const { dateRestrictionByDate, dateRestrictionInDays } = this.props;
    return (
      <Row>
        <Col>
          <Field
            label={__('Aplikácia')}
            component={FormField}
            type="theselectsimple"
            required
            name="applicationId"
            validate={[required]}
            options={apps.map(app => ({
              label: app.name,
              value: app._id,
            }))}
          />
        </Col>
        <PriceFormPart handlePriceChange={this.handlePriceChange} />
        <ValidityFormPart
          dateRestrictionByDate={dateRestrictionByDate}
          dateRestrictionInDays={dateRestrictionInDays}
          onCheckboxCheck={name => {
            this.props.dispatch(change(FORM_NAME, name, false));
          }}
        />
      </Row>
    );
  }
}

ApplicationForm.propTypes = {
  price: PropTypes.shape({
    amount_vat_excl: PropTypes.number,
    amount: PropTypes.number,
    vat_rate: PropTypes.number,
    currency: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  dateRestrictionByDate: PropTypes.bool.isRequired,
  dateRestrictionInDays: PropTypes.bool.isRequired,
};

const selector = formValueSelector('INVOICE_ITEM_FORM');

const mapStateToProps = state => {
  return {
    price: selector(state, 'price'),
    dateRestrictionByDate: selector(state, 'dateRestrictionByDate'),
    dateRestrictionInDays: selector(state, 'dateRestrictionInDays'),
  };
};

export default connect(mapStateToProps)(ApplicationForm);
