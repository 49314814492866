import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';

const entitiesDomainSelector = state => {
  return state.entities;
};

export const courierTemplatesEntitiesSelector = createSelector(
  entitiesDomainSelector,
  substate => substate.TemplatesReducer,
);

export const templatesCurrenListSelector = listName =>
  createSelector(
    courierTemplatesEntitiesSelector,
    getListResults(listName),
    (entities, ids) => {
      const list = (ids || []).reduce((acc, id) => {
        if (entities.templates[id]) {
          return [...acc, entities.templates[id]];
        }
        return acc;
      }, []);
      return list;
    },
  );
