import Loader from '@sportnet/ui/Loader';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Loading = props => {
  return (
    <LoaderContainer>
      <Loader size={props.size} />
    </LoaderContainer>
  );
};

Loading.propTypes = {
  size: PropTypes.string,
};

Loading.defaultProps = {
  size: 'xxl',
};
