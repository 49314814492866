import moment from 'moment';
import React from 'react';
import { __ } from '../../../../../utilities';
import { universalRequestDefinition } from '../../../definitions';
import KeyValueTable from '../../../../../components/KeyValueTable';
import Col, { Row } from '@sportnet/ui/Grid';
import styled from 'styled-components';
import Header from '@sportnet/ui/Header';
import Segment from '@sportnet/ui/Segment';

const CleanATag = styled.a`
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: all 200ms;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    text-decoration: none;
  }
`;

const CommonRequestInfo = ({ request, appSpace }) => {
  const mySportnetOnlineRequestUrl = 'https://my.sportnet.online/request';

  const { ownerUser = {} } = request;
  const kvFields = [
    {
      key: __('Identifikátor:'),
      values: [
        <CleanATag
          target="_blank"
          href={`${mySportnetOnlineRequestUrl}/${request._id}`}
        >
          {request._id}
        </CleanATag>,
      ],
    },
    {
      key: __('Dátum vytvorenia: '),
      values: [moment(request.created_date).format('LLLL')],
    },
    {
      key: __('Osoba (vlastník žiadosti): '),
      values: [
        <React.Fragment>
          {`${ownerUser.display_name || ''}`} (
          <a
            href={`/admin/${appSpace}/users?query=${ownerUser.sportnet_id}`}
          >{`${ownerUser.sportnet_id}`}</a>
          )
        </React.Fragment>,
      ],
    },
  ];
  if (request.data && request.data.finalSportnetId) {
    kvFields.push({
      key: __('Žiadosťou vytvorená osoba: '),
      values: [
        <React.Fragment>
          <a
            href={`/admin/${appSpace}/users?query=${request.data.finalSportnetId}`}
          >{`${request.data.finalSportnetId}`}</a>
        </React.Fragment>,
      ],
    });
  }
  return (
    <Segment raised>
      <Row>
        <Col s={12} m={6}>
          <Header size="s">{__('Základné informácie')}</Header>
          <KeyValueTable rows={kvFields} />
        </Col>
      </Row>
    </Segment>
  );
};

CommonRequestInfo.propTypes = {
  request: universalRequestDefinition.isRequired,
};

export default CommonRequestInfo;
