import Button from '@sportnet/ui/Button';
import Segment from '@sportnet/ui/Segment';
import React from 'react';
import { __ } from '../../../../utilities';
import { required } from '../../../../validation';
import { Form, Field } from 'react-final-form';
import FinalFormField from '../../../../components/FinalFormField';

const MedicalExaminationForm = ({ initialValues, onSubmit }) => {
  return (
    <Segment>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="custom_org_name"
                validate={required}
                label={__('Organizácia')}
                required
                component={FinalFormField}
                disabled
              />
              <Field
                label={__('Lekár')}
                name="doctor"
                type="text"
                component={FinalFormField}
                validate={required}
                required
                data-testid="CRM_FO_Input_Medical_Doctor"
              />

              <Field
                label={__('Platnosť od')}
                name="valid_from"
                type="date"
                component={FinalFormField}
                validate={required}
                required
                data-testid="CRM_FO_Input_Medical_ValidFrom"
              />

              <Field
                label={__('Platnosť do')}
                name="valid_to"
                type="date"
                component={FinalFormField}
                data-testid="CRM_FO_Input_Medical_ValidTo"
              />

              {!values._id ? (
                <Button
                  type="submit"
                  primary
                  data-testid="CRM_FO_Button_Medical_Save"
                >
                  {__('Pridať položku')}
                </Button>
              ) : (
                <Button type="submit" primary>
                  {__('Upraviť položku')}
                </Button>
              )}
            </form>
          );
        }}
      ></Form>
    </Segment>
  );
};

export default MedicalExaminationForm;
