import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Sidebar from '@sportnet/ui/Sidebar';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Layout from '../../../../components/Layout';
import { getStatusIcon, __ } from '../../../../utilities';
import {
  aclSelector,
  bankAccountTypeCodelistSelector,
} from '../../../App/selectors';
import {
  createBankAccount,
  editBankAccount,
  fetchUserBankAccounts,
} from '../../actions';
import { bankAccountDefinition, codelistDefinition } from '../../definitions';
import {
  getActiveUserBankAccountsSelector,
  getActiveUserIsFetchingSelector,
} from '../../selectors';
import Wrapper from '../index';
import BankAccountForm from './form';
import { useParams } from 'react-router-dom';
import { useAuth } from '@sportnet/auth-react';

const BankAccountsList = ({
  bankAccounts,
  isFetching,
  acl,
  editBankAccount,
  createBankAccount,
  bankAccountTypes,
  fetchUserBankAccounts,
}) => {
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = React.useState(null);
  const [updateDisabled, setUpdateDisabled] = React.useState(false);
  const [createDisabled, setCreateDisabled] = React.useState(false);

  const { ppo: appSpace } = useAuth();
  const { sportnetId } = useParams();

  React.useEffect(() => {
    if (!(sportnetId && appSpace)) {
      return;
    }
    fetchUserBankAccounts(sportnetId, appSpace);
  }, [sportnetId, appSpace, fetchUserBankAccounts]);

  React.useEffect(() => {
    if (acl) {
      setUpdateDisabled(!acl.crmUserBankAccountsUpdate);
      setCreateDisabled(!acl.crmUserBankAccountsCreate);
    }
  }, [acl]);

  const onSubmit = async data => {
    try {
      const { _id, ...formData } = data;
      const requestData = {
        IBAN: formData.IBAN,
        type: formData.type,
        status: formData.status,
      };
      if (_id) {
        editBankAccount(appSpace, sportnetId, data._id, requestData);
      } else {
        createBankAccount(appSpace, sportnetId, requestData);
      }
    } catch (e) {
      alert(__('Bankový účet sa nepodarilo uložiť'));
    } finally {
      setSidebarVisible(false);
      setSelectedBankAccount(null);
    }
  };

  const getBankAccountType = accountTypeCode => {
    const item = bankAccountTypes.find(type => {
      return type.value === accountTypeCode;
    });
    return item.label;
  };

  return (
    <Layout
      bottomFixed={
        !createDisabled && (
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                primary
                data-testid="CRM_FO_Button_Bank_New"
                onClick={() => {
                  setSidebarVisible(true);
                }}
              >
                {__('Pridať bankový účet')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        )
      }
    >
      <Wrapper isFetching={isFetching} activeTab={__('Bankové účty')}>
        <Segment raised loading={isFetching}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Typ')}</Th>
                <Th>{__('IBAN')}</Th>
                <Th>{__('Stav')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {bankAccounts.map(account => {
                return (
                  <Tr
                    key={account._id}
                    onClick={() => {
                      if (!updateDisabled) {
                        setSidebarVisible(true);
                        setSelectedBankAccount({ ...account });
                      }
                    }}
                  >
                    <Td>{getBankAccountType(account.type)}</Td>
                    <Td>{account.IBAN}</Td>
                    <Td>{getStatusIcon(account.status)}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Segment>
      </Wrapper>
      {!updateDisabled && (
        <Sidebar
          onClose={() => {
            setSidebarVisible(false);
            setSelectedBankAccount(null);
          }}
          visible={sidebarVisible}
          header={__('Bankový účet')}
        >
          {sidebarVisible && (
            <BankAccountForm
              initialValues={selectedBankAccount}
              onSubmit={onSubmit}
            />
          )}
        </Sidebar>
      )}
    </Layout>
  );
};

BankAccountsList.propTypes = {
  bankAccounts: PropTypes.arrayOf(bankAccountDefinition).isRequired,
  bankAccountTypes: PropTypes.arrayOf(codelistDefinition).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    bankAccounts: getActiveUserBankAccountsSelector(state),
    bankAccountTypes: bankAccountTypeCodelistSelector(state),
    isFetching: getActiveUserIsFetchingSelector(state),
    acl: aclSelector(state),
  };
};

const mapDispatchToProps = {
  editBankAccount,
  createBankAccount,
  fetchUserBankAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountsList);
