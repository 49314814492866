import Col, { Row } from '@sportnet/ui/Grid';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getProp } from '@sportnet/utilities';
import { __ } from '../../../../../utilities';
import { codelistEntrySelector } from '../../../../App/selectors';
import { codelistDefinition } from '../../../../FO/definitions';
import { universalRequestDefinition } from '../../../definitions';
import KeyValueTable from '../../../../../components/KeyValueTable';
import Header from '@sportnet/ui/Header';
import Segment from '@sportnet/ui/Segment';

class UserLicense extends PureComponent {
  render() {
    const {
      request: { data: { user_license: userLicense } = {} },
      licenseType,
    } = this.props;
    if (!userLicense) return null;
    const kvFields = [
      {
        key: __('Typ licencie / kvalifikácie'),
        values: [licenseType.label || ''],
      },
      {
        key: __('Názov licencie / kvalifikácie'),
        values: [userLicense.name || ''],
      },
      {
        key: __('Číslo dokladu'),
        values: [userLicense.documentnr || ''],
      },
      {
        key: __('Názov licencie / kvalifikácie'),
        values: [userLicense.name || ''],
      },
      {
        key: __('Planosť od'),
        values: [
          userLicense.valid_from
            ? moment(userLicense.valid_from).format('DD.MM.YYYY')
            : '',
        ],
      },
      {
        key: __('Planosť do'),
        values: [
          userLicense.valid_to
            ? moment(userLicense.valid_to).format('DD.MM.YYYY')
            : '',
        ],
      },
      {
        key: __('Vydal PO'),
        values: [userLicense.issued_by_po || ''],
      },
    ];
    if (userLicense.verified_by_position) {
      kvFields.push({
        key: __('Overil FO - funkcia'),
        values: [userLicense.verified_by_position || ''],
      });
    }
    if (userLicense.verified_by_person) {
      kvFields.push({
        key: __('Overil FO - meno'),
        values: [userLicense.verified_by_person || ''],
      });
    }
    if (userLicense.registration_date) {
      kvFields.push({
        key: __('Dátum registrácie'),
        values: [
          userLicense.registration_date
            ? moment(userLicense.registration_date).format('DD.MM.YYYY')
            : '',
        ],
      });
    }
    if (userLicense.note) {
      kvFields.push({
        key: __('Poznámka'),
        values: [userLicense.note],
      });
    }
    return (
      <Segment raised>
        <Row>
          <Col m={6}>
            <Header size="s">{__('Licencie')}</Header>
            <KeyValueTable rows={kvFields} />
          </Col>
        </Row>
      </Segment>
    );
  }
}

UserLicense.propTypes = {
  request: universalRequestDefinition.isRequired,
  licenseType: codelistDefinition.isRequired,
};

const mapStateToProps = (state, props) => {
  const userLicenseType = getProp(props, [
    'request',
    'data',
    'user_license',
    'type',
  ]);
  return {
    licenseType: codelistEntrySelector('license-type', userLicenseType)(state),
  };
};

export default connect(mapStateToProps)(UserLicense);
