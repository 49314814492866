import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Sidebar from '@sportnet/ui/Sidebar';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Layout from '../../../../components/Layout';
import { getProp, __ } from '../../../../utilities';
import {
  aclSelector,
  appspaceSelector,
  licenceCodelistSelector,
} from '../../../App/selectors';
import {
  createUserLicence,
  deleteUserLicenseFiles,
  editUserLicence,
  fetchUserLicences,
  uploadUserLicenseFiles,
} from '../../actions';
import {
  codelistDefinition,
  licenceDefinition,
  matchDefinition,
} from '../../definitions';
import {
  getActiveIdSelector,
  getActiveUserIsFetchingSelector,
  getActiveUserLicencesSelector,
} from '../../selectors';
import Wrapper from '../index';
import LicencesForm from './form';
import Segment from '@sportnet/ui/Segment';

class LicencesList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidebarVisible: false,
      licence: null,
      createDisabled: false,
      updateDisabled: false,
    };
    this.getLicenceType = this.getLicenceType.bind(this);
  }

  componentDidMount() {
    const { sportnetId, appspace } = this.props.match.params;
    this.props.dispatch(fetchUserLicences(sportnetId, appspace));
    this.setAclAccess(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setAclAccess(nextProps);
  }

  getLicenceType(code) {
    const item = this.props.licenceTypes.find(type => {
      return type.value === code;
    });
    return getProp(item, ['label']);
  }

  setAclAccess = props => {
    if (typeof this.state.disabled === 'undefined' && props.acl) {
      this.setState({
        updateDisabled: !props.acl.crmUserLicenseUpdate,
        createDisabled: !props.acl.crmUserLicenseCreate,
      });
    }
  };

  submit = async data => {
    const {
      dispatch,
      activeUserId,
      appspace: { app_space },
    } = this.props;
    let licenseId = '';
    const {
      _id,
      org_id,
      org_name,
      org_profile_name,
      updated,
      files,
      deletedFiles,
      ...formData
    } = data;
    if (data._id) {
      licenseId = data._id;
      await dispatch(
        editUserLicence(activeUserId, licenseId, app_space, formData),
      );
    } else {
      const createdLicense = await dispatch(
        createUserLicence(activeUserId, app_space, formData),
      );
      licenseId = createdLicense._id;
    }
    const filesToUpload = (data.files || []).reduce((acc, file) => {
      if (file._new) {
        acc.push(file.file);
      }
      return acc;
    }, []);
    if (filesToUpload.length > 0) {
      await dispatch(
        uploadUserLicenseFiles(
          activeUserId,
          licenseId,
          app_space,
          filesToUpload,
        ),
      );
    }
    const fileIdsToDelete = Object.keys(data.deletedFiles || {});
    if (fileIdsToDelete.length > 0) {
      await dispatch(
        deleteUserLicenseFiles(
          activeUserId,
          licenseId,
          app_space,
          fileIdsToDelete,
        ),
      );
    }
    this.setState({ sidebarVisible: false, licence: null });
  };

  render() {
    const { licences, isFetching } = this.props;
    const { licence, sidebarVisible, createDisabled, updateDisabled } =
      this.state;
    return (
      <Layout
        bottomFixed={
          !createDisabled && (
            <ContextBar>
              <ContextBarSpacer />
              <ContextBarItem>
                <Button
                  onClick={() => {
                    this.setState({
                      sidebarVisible: true,
                    });
                  }}
                  primary
                  data-testid="CRM_FO_Button_Licences_New"
                >
                  {__('Pridať záznam')}
                </Button>
              </ContextBarItem>
            </ContextBar>
          )
        }
      >
        <Wrapper
          isFetching={isFetching}
          activeTab={__('Kvalifikácie / Licencie')}
        >
          <Segment raised loading={isFetching}>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Názov')}</Th>
                  <Th>{__('Typ')}</Th>
                  <Th>{__('Číslo dokumentu')}</Th>
                  <Th>{__('Platné od')}</Th>
                  <Th>{__('Platné do')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {licences.map(item => {
                  const trProps = {
                    key: item._id,
                  };
                  if (!updateDisabled) {
                    trProps.onClick = () => {
                      this.setState({
                        sidebarVisible: true,
                        licence: item,
                      });
                    };
                  }
                  return (
                    <Tr {...trProps}>
                      <Td>{item.name}</Td>
                      <Td>{this.getLicenceType(item.type)}</Td>
                      <Td>{item.documentnr}</Td>
                      <Td>{moment(item.valid_from).format('DD.MM.YYYY')}</Td>
                      <Td>
                        {item.valid_to &&
                          moment(item.valid_to).format('DD.MM.YYYY')}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Segment>
        </Wrapper>
        <Sidebar
          onClose={() => {
            this.setState({ sidebarVisible: false, licence: null });
          }}
          visible={sidebarVisible}
          header={__('Licencia')}
        >
          {sidebarVisible && (
            <LicencesForm data={licence} onSubmit={this.submit} />
          )}
        </Sidebar>
      </Layout>
    );
  }
}

LicencesList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: matchDefinition.isRequired,
  licenceTypes: PropTypes.arrayOf(codelistDefinition).isRequired,
  licences: PropTypes.arrayOf(licenceDefinition).isRequired,
  theme: PropTypes.shape({}).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    licenceTypes: licenceCodelistSelector(state),
    licences: getActiveUserLicencesSelector(state),
    isFetching: getActiveUserIsFetchingSelector(state),
    acl: aclSelector(state),
    appspace: appspaceSelector(state),
    activeUserId: getActiveIdSelector(state),
  };
};

export default connect(mapStateToProps)(LicencesList);
