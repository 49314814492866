import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { __ } from '../../../../../utilities';
import { required } from '../../../../../validation';
import { applicationRolesCodelistSelector } from '../../../../App/selectors';
import { codelistDefinition } from '../../../../FO/definitions';

export const FORM_NAME = 'crm-user-invite';

const InviteCRMUserForm = ({ roles }) => {
  return (
    <>
      <Field
        label={__('E-mailová adresa')}
        name="email"
        component={FormFieldRedux}
        type="email"
        validate={[required]}
        required
      />
      <Field
        label={__('Rola v aplikácii CRM')}
        name="role"
        component={FormFieldRedux}
        type="theselectsimple"
        options={roles}
        validate={[required]}
        required
      />
      <Field
        label={__('Právo spravovať iných používateľov')}
        name="grant"
        component={FormFieldRedux}
        type="checkbox"
      />
    </>
  );
};

InviteCRMUserForm.propTypes = {
  roles: PropTypes.arrayOf(codelistDefinition).isRequired,
};

InviteCRMUserForm.defaultProps = {};

const mapStateToProps = state => {
  return {
    roles: applicationRolesCodelistSelector(state),
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: FORM_NAME,
  }),
  React.memo,
)(InviteCRMUserForm);
