import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Sidebar from '@sportnet/ui/Sidebar';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import { aclSelector } from '../../../App/selectors';
import {
  createRegnr,
  deleteRegnr,
  updateRegnr,
  getRegnrs,
} from '../../actions';
import { matchDefinition } from '../../definitions';
import {
  getActiveUserIsFetchingSelector,
  getActiveUserRegnrsSelector,
} from '../../selectors';
import Wrapper from '../index';
import RegnrForm from './form';
import { Faded } from '../../detail';

class RegnrsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidebarVisible: false,
      data: null,
      createDisabled: true,
      deleting: false,
    };
  }
  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace, sportnetId },
      },
    } = this.props;
    dispatch(getRegnrs(appspace, sportnetId));
  }
  componentWillReceiveProps(nextProps) {
    this.setAclAccess(nextProps);
  }
  submit = async data => {
    const {
      dispatch,
      match: {
        params: { appspace, sportnetId },
      },
    } = this.props;
    const { _id, regnr, useSequence } = data;
    try {
      if (!_id) {
        await dispatch(
          createRegnr(appspace, sportnetId, {
            org_profile_id: appspace,
            regnr,
            useSequence: !!useSequence,
          }),
        );
      } else {
        await dispatch(
          updateRegnr(appspace, sportnetId, _id, {
            regnr,
          }),
        );
      }
      this.setState({
        data: null,
        sidebarVisible: false,
      });
    } catch (e) {
      alert('Error');
    }
  };
  onDelete = async itemId => {
    const confirm = window.confirm(
      __('Skutočne si želáte zmazať registračné číslo?'),
    );
    if (confirm) {
      this.setState({ deleting: true });
      const {
        dispatch,
        match: {
          params: { appspace, sportnetId },
        },
      } = this.props;
      await dispatch(deleteRegnr(appspace, sportnetId, itemId));
      this.setState({
        data: null,
        sidebarVisible: false,
        deleting: false,
      });
    }
  };
  setAclAccess = props => {
    if (typeof this.state.disabled === 'undefined' && props.acl) {
      this.setState({
        createDisabled: !props.acl.crmUserRegnrCreate,
      });
    }
  };
  render() {
    const { regnrs, isFetching } = this.props;
    const { data, sidebarVisible, createDisabled } = this.state;
    return (
      <Layout
        bottomFixed={
          !createDisabled && (
            <ContextBar>
              <ContextBarSpacer />
              <ContextBarItem>
                <Button
                  primary
                  onClick={() => {
                    this.setState({
                      sidebarVisible: true,
                      data: null,
                    });
                  }}
                >
                  {__('Pridať registračné číslo')}
                </Button>
              </ContextBarItem>
            </ContextBar>
          )
        }
      >
        <Wrapper activeTab={__('Registračné čísla')} isFetching={isFetching}>
          <Segment raised loading={isFetching}>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Organizácia')}</Th>
                  <Th>{__('Registračné číslo')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {regnrs.map(item => {
                  const trProps = {
                    key: item._id,
                    onClick: () => {
                      this.setState({
                        sidebarVisible: true,
                        data: item,
                      });
                    },
                  };
                  return (
                    <Tr {...trProps}>
                      <Td>
                        {item.org_name}
                        <br />
                        <Faded>{item.org_profile_name}</Faded>
                      </Td>
                      <Td>{item.regnr}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Segment>
          <Sidebar
            header={__('Registračné číslo')}
            visible={sidebarVisible}
            onClose={() => {
              this.setState({
                sidebarVisible: false,
              });
            }}
          >
            <RegnrForm
              data={data}
              onSubmit={this.submit}
              onDelete={this.onDelete}
              deleting={this.state.deleting}
            />
          </Sidebar>
        </Wrapper>
      </Layout>
    );
  }
}

RegnrsList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: matchDefinition.isRequired,
  regnrs: PropTypes.arrayOf(
    PropTypes.shape({
      org_name: PropTypes.string,
      regnr: PropTypes.string,
    }),
  ).isRequired,
  theme: PropTypes.shape({}).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  regnrs: getActiveUserRegnrsSelector(state),
  isFetching: getActiveUserIsFetchingSelector(state),
  acl: aclSelector(state),
});

export default connect(mapStateToProps)(RegnrsList);
