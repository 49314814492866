import API from '../../../../../Api';

export const getAttachment = async (appSpace, requestId, idx) => {
  try {
    await API.crmGetRequestAttachmentFile(appSpace, requestId, idx)
      .then(r => r.blob())
      .then(pdf => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(pdf);
        a.target = '_blank';
        a.click();
        return;
      });
  } catch (e) {
    alert('Nepodarilo sa získať prílohu');
    console.error(e);
  }
};
