import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Detail from './Detail';
import List from './List';

const PPOS = () => {
  return (
    <Switch>
      <Route exact path="/admin/:appspace/organizations" component={List} />
      <Route
        exact
        path="/admin/:appspace/organizations/:organizationId/profiles/:orgProfileId/:view"
        component={Detail}
      />
      <Redirect from="*" to="/admin/:appspace/organizations" />
    </Switch>
  );
};

export default PPOS;
