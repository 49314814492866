import Api from '../../Api';
import {
  RECEIVE_APPS_FAILURE,
  RECEIVE_APPS_SUCCESS,
  REQUEST_APPS,
} from './constants';

const requestApps = () => ({
  type: REQUEST_APPS,
});

const receiveAppsSuccess = apps => ({
  type: RECEIVE_APPS_SUCCESS,
  payload: {
    apps,
  },
});

const receiveAppsFailure = error => ({
  type: RECEIVE_APPS_FAILURE,
  payload: {
    error,
  },
});

export const fetchApps = appspace => {
  return async dispatch => {
    try {
      dispatch(requestApps());
      const res = await Api.crmApps(appspace);
      dispatch(receiveAppsSuccess(res.apps));
    } catch (e) {
      dispatch(receiveAppsFailure(e.description || e));
    }
  };
};
