import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';

const entitiesDomainSelector = state => state.entities;

const invoiceItemsDomainSelector = createSelector(
  entitiesDomainSelector,
  substate => substate.InvoiceItems,
);

export const invoiceItemsCurrentListSelector = listName =>
  createSelector(
    invoiceItemsDomainSelector,
    getListResults(listName),
    (entities, ids) => {
      const list = (ids || []).reduce((acc, id) => {
        if (entities[id]) {
          return [...acc, entities[id]];
        }
        return acc;
      }, []);
      return list;
    },
  );
