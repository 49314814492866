import Col, { Row } from '@sportnet/ui/Grid';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { __ } from '../../../../../utilities';
import { universalRequestDefinition } from '../../../definitions';
import Header from '@sportnet/ui/Header';
import Segment from '@sportnet/ui/Segment';
import { getAttachment } from './actions';
import Icon from '@sportnet/ui/Icon';
import Loader from '@sportnet/ui/Loader';

const StyledList = styled.dl`
  font-size: ${rem(14)};
  > dt {
    text-decoration: underline;
    width: fit-content;
    cursor: pointer;
    color: #0000ee;
    display: flex;
    gap: ${rem(4)};
    align-items: center;

    :not(:last-child) {
      margin-bottom: ${rem(4)};
    }

    :hover {
      color: #551a8b;
    }
  }
`;

const LicenseAttachments = ({
  request: {
    appSpace,
    _id,
    data: { attachments },
  },
}) => {
  const [fetchingAttachment, setFetchingAttachment] = React.useState(null);
  const handleGetAttachment = async idx => {
    try {
      setFetchingAttachment(idx);
      await getAttachment(appSpace, _id, idx);
    } finally {
      setFetchingAttachment(null);
    }
  };

  if (attachments && attachments.length > 0)
    return (
      <Segment raised>
        <Row>
          <Col m={6}>
            <Header size="s">{__('Prílohy')}</Header>
            <StyledList>
              {attachments.map((item, idx) => (
                <dt key={idx} onClick={() => handleGetAttachment(idx)}>
                  {fetchingAttachment === idx ? (
                    <Loader size={'m'} />
                  ) : (
                    <Icon name="file" />
                  )}
                  <span>{item.name}</span>
                </dt>
              ))}
            </StyledList>
          </Col>
        </Row>
      </Segment>
    );
  return null;
};

LicenseAttachments.propTypes = {
  request: universalRequestDefinition.isRequired,
};

export default LicenseAttachments;
