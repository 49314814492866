import Loader from '@sportnet/ui/Loader';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { __ } from '../../utilities';
import { getRelatedPpos, loadApps } from '../App/actions';
import AppsList from '../Apps/list';
import PaymentCallback from '../Apps/payment-callback';
import {
  fetchCodeList,
  fetchSportsCodeList,
  loadAcl,
  setApps,
  setAppSpaces,
  setActiveAppSpaceId,
  setApplicationInfo,
  setActiveAppSpace,
} from '../Authorization/actions';
import { aclSelector } from '../Authorization/selectors';
import Courier from '../Courier';
import CustomCodelist from '../CustomCodelist';
import FODetail from '../FO/detail';
import FOList from '../FO/list';
import DSGEditRequest from '../Forms/DSGEditRequest';
import GroupsList from '../Groups/list';
import GroupUsersList from '../GroupUsers/list';
import InvoiceItemsList from '../InvoiceItems/list';
import InvoicesList from '../Invoices/list';
import PO from '../PO';
import PPO from '../PPO';
import PricelistItemsList from '../PricelistItems/list';
import Requests from '../Requests';
import RequestDetail from '../Requests/Detail';
import Settings from '../Settings';
import SequencesSettingsList from '../Settings/Sequences/list';
import { compose } from 'redux';
import { useAuth } from '@sportnet/auth-react/AuthProvider';
import CoreApi from '../../Api';
import AppContext from '../../context/AppContext';

const INITIAL_APP_STATE = {
  isLoading: false,
  // objekt appspace s ktorym pracujem
  activeAppSpace: undefined,
  // vsetky appspaces ku ktorym ma uzivatel pristup
  appSpaces: undefined,
  // info o aplikacii
  appInfo: undefined,
  // appky, ku ktorym ma uzivatel pristup
  apps: undefined,
  // id appspace s ktorym pracujem
  activeAppSpaceId: undefined,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'START_AUTH': {
      return {
        ...INITIAL_APP_STATE,
        isLoading: true,
      };
    }
    case 'DONE': {
      if (action.payload) {
        const {
          apps,
          appSpaces,
          activeAppSpaceId,
          appInfo,
          activeAppSpace,
          accessToken,
        } = action.payload;
        return {
          ...state,
          apps,
          appSpaces,
          activeAppSpaceId,
          appInfo,
          isLoading: false,
          activeAppSpace,
          accessToken,
        };
      }
      return state;
    }
    case 'ERROR': {
      return {
        ...INITIAL_APP_STATE,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

const mapStateToProps = state => ({
  acl: aclSelector(state),
});

const mapDispatchToProps = {
  fetchCodeList,
  loadAcl,
  loadApps,
  getRelatedPpos,
  fetchSportsCodeList,
  setApps,
  setAppSpaces,
  setActiveAppSpaceId,
  setApplicationInfo,
  setActiveAppSpace,
};

const Administration = ({
  acl,
  fetchCodeList,
  loadAcl,
  loadApps,
  getRelatedPpos,
  fetchSportsCodeList,
  setApps,
  setAppSpaces,
  setActiveAppSpaceId,
  setApplicationInfo,
  setActiveAppSpace,
}) => {
  const { ppo: appSpace, accessToken } = useAuth();
  const [state, dispatch] = React.useReducer(reducer, INITIAL_APP_STATE);
  const [notificationCount, setNotificationCount] = React.useState(-1);

  const timerRef = React.useRef(null);

  React.useEffect(() => {
    if (!appSpace) {
      return;
    }
    fetchCodeList('address-type');
    fetchCodeList('bank-account-type');
    fetchCodeList('country');
    fetchCodeList('nationality');
    fetchCodeList('sport-org-competence-type');
    fetchCodeList('sport-expert-competence-type');
    fetchCodeList('sport-expert-legal-form');
    fetchCodeList('sport-org-tranfer-type');
    fetchCodeList('license-type');
    fetchCodeList('award-type', appSpace);
    fetchCodeList('person-document', appSpace);
    fetchCodeList('legal-form');
    fetchCodeList('organization-profile-type');
    fetchCodeList('ppo-relation-type');
    fetchSportsCodeList();
    loadAcl(appSpace);
    loadApps();
    getRelatedPpos(appSpace);

    (async () => {
      const response = await CoreApi.meAppSpaces({ expandApp: true });
      const apps = (response?.apps || []).map(a => {
        const appSpaceIds = (a.appspaces || []).map(as => as.app_space);
        return { ...a.app, appSpaceIds };
      });
      setApps(apps);

      const appObj = (response.apps || []).find(
        a => a.app_id === process.env.REACT_APP_APP_ID,
      );

      const appSpaces =
        appObj && Array.isArray(appObj.appspaces)
          ? appObj.appspaces.map(appAppSpace => appAppSpace.org_profile)
          : [];
      setAppSpaces(appSpaces);

      setActiveAppSpaceId(appSpace);

      const appInfo = await CoreApi.getPublicApp(process.env.REACT_APP_APP_ID);
      setApplicationInfo(appInfo);

      const activeAppSpace = await CoreApi.organizationPPOProfile(appSpace);

      setActiveAppSpace({
        org_profile: activeAppSpace,
        app_space: activeAppSpace._id,
      });

      // Zamer je zbavit sa reduxu a vsetko riesit v aplikacii cez AppContext provider. Tento dispatch
      // nepatri Reduxu, ale je to lokalny state, ktory sa posuva do kontextu, z ktoreho by sa mali vycitat
      // informacie o aktualnom appSpace a aplikaciach v ramci celej aplikacie.
      dispatch({
        type: 'DONE',
        payload: {
          apps,
          appSpaces,
          activeAppSpaceId: activeAppSpace.app_space,
          appInfo,
          activeAppSpace,
        },
      });
    })();
  }, [
    appSpace,
    fetchCodeList,
    loadAcl,
    loadApps,
    getRelatedPpos,
    fetchSportsCodeList,
    setApps,
    setAppSpaces,
    setActiveAppSpaceId,
    setApplicationInfo,
    dispatch,
    setActiveAppSpace,
  ]);

  React.useEffect(() => {
    if (appSpace) {
      dispatch({ type: 'START_AUTH' });
    }
  }, [appSpace]);

  React.useEffect(() => {
    if (!accessToken) {
      return;
    }

    const fetchNotificationCount = async () => {
      try {
        const { count } = await CoreApi.meUnreadMessagesCount();
        setNotificationCount(count ?? 0);
        if (timerRef.current) {
          window.clearInterval(timerRef.current);
        }
        timerRef.current = window.setTimeout(fetchNotificationCount, 300000);
      } catch (e) {
        setNotificationCount(-1);
      }
    };

    fetchNotificationCount();

    return () => {
      if (timerRef.current) {
        window.clearInterval(timerRef.current);
      }
    };
  }, [accessToken]);

  const appContextValue = React.useMemo(() => {
    return {
      activeAppSpace: state.activeAppSpace,
      appSpaces: state.appSpaces,
      appInfo: state.appInfo,
      apps: state.apps,
      activeAppSpaceId: state.activeAppSpaceId,
      notificationCount,
    };
  }, [state, notificationCount]);

  if (!appSpace || !acl || state.isLoading || !state.activeAppSpace) {
    return (
      <LoaderWrapper>
        <Loader size="xl" />
      </LoaderWrapper>
    );
  }
  return (
    <AppContext.Provider value={appContextValue}>
      <Switch>
        <Route exact path="/admin/:appspace">
          <Redirect to={`/admin/${appSpace}/users`} />
        </Route>
        <Route exact path="/admin/:appspace/me/:view" component={PO} />
        <Route exact path="/admin/:appspace/apps" component={AppsList} />
        <Route exact path="/admin/:appspace/users" component={FOList} />
        <Route exact path="/admin/:appspace/groups" component={GroupsList} />
        <Route
          exact
          path="/admin/:appspace/group-users"
          component={GroupUsersList}
        />
        <Route exact path="/admin/:appspace/requests" component={Requests} />
        <Route
          exact
          path="/admin/:appspace/create-request/:createRequestType/:targetPersonId?"
          component={Requests}
        />
        <Redirect
          from="/admin/:appspace/requests/:id"
          to="/admin/:appspace/request/:id"
        />
        <Route
          exact
          path="/admin/:appspace/request/:id"
          component={RequestDetail}
        />
        <Route
          path="/admin/:appspace/users/:sportnetId/:view"
          component={FODetail}
        />
        <Route path="/admin/:appspace/organizations" component={PPO} />
        <Route
          exact
          path="/admin/:appspace/pricelist-items"
          component={PricelistItemsList}
        />
        <Route path="/admin/:appspace/courier-templates" component={Courier} />
        <Route
          exact
          path="/admin/:appspace/invoices"
          component={InvoicesList}
        />
        <Route
          exact
          path="/admin/:appspace/invoice-items"
          component={InvoiceItemsList}
        />
        <Route
          exact
          path="/admin/:appspace/settings/sequences"
          component={SequencesSettingsList}
        />
        <Route exact path="/admin/:appspace/settings" component={Settings} />
        <Route
          exact
          path="/admin/:appspace/settings/codelist/award-type"
          render={props => {
            return (
              <CustomCodelist
                {...props}
                codelistId="award-type"
                codelistName={__('Typ ocenenia')}
              />
            );
          }}
        />
        <Route
          exact
          path="/admin/:appspace/settings/codelist/person-position"
          render={props => {
            return (
              <CustomCodelist
                {...props}
                codelistId="person-position"
                codelistName={__('Pozícia osoby')}
              />
            );
          }}
        />
        <Route
          exact
          path="/admin/:appspace/settings/codelist/person-document"
          render={props => {
            return (
              <CustomCodelist
                {...props}
                codelistId="person-document"
                codelistName={__('Dokument osoby')}
              />
            );
          }}
        />
        <Route
          exact
          path="/admin/:appspace/payment-callback"
          component={PaymentCallback}
        />
        <Route
          exact
          path="/admin/:appspace/forms/dsg-edit-request"
          component={DSGEditRequest}
        />
        <Redirect from="/admin/:appspace/:path?" to="/admin/:appspace/users" />
      </Switch>
    </AppContext.Provider>
  );
};

Administration.propTypes = {
  acl: PropTypes.shape({}),
  fetchCodeList: PropTypes.func.isRequired,
  fetchSportsCodeList: PropTypes.func.isRequired,
  loadAcl: PropTypes.func.isRequired,
  loadApps: PropTypes.func.isRequired,
  getRelatedPpos: PropTypes.func.isRequired,
};

Administration.defaultProps = {
  acl: null,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Administration,
);
