import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import AddressApi from './AddressApi';
import Api from './Api';
import rootReducer from './reducer';

if (process.env.REACT_APP_API_BASE_URL) {
  Api.setBaseUrl(String(process.env.REACT_APP_API_BASE_URL));
} else {
  throw Error('REACT_APP_API_BASE_URL not set');
}

if (process.env.REACT_APP_ADDRESS_API_BASE_URL) {
  AddressApi.setBaseUrl(String(process.env.REACT_APP_ADDRESS_API_BASE_URL));
} else {
  throw Error('REACT_APP_ADDRESS_API_BASE_URL not set');
}

let composeEnhancers = compose;
if (typeof window !== 'undefined') {
  const devToolsCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  composeEnhancers = devToolsCompose
    ? devToolsCompose({
        trace: true,
        traceLimit: 25,
      })
    : compose;
}

const thunk = thunkMiddleware.withExtraArgument({
  Api,
  AddressApi,
});

const enhancer = composeEnhancers(applyMiddleware(thunk));

export default function configureStore() {
  return createStore(rootReducer, enhancer);
}
