import { ArrayParam, StringParam } from '@sportnet/query-hoc/useQuery';

const namespace = 'PPOS';

export const RECEIVE_PPOS_SUCCESS = `${namespace}/RECEIVE_PPOS_SUCCESS`;

export const PPOS_LIST = 'ppos_list';
export const PPOS_LIST_QUERY_HOC_CONFIG = {
  parameters: {
    q: StringParam(''),
    ppoType: ArrayParam(StringParam(''), [], ','),
    relationStatus: ArrayParam(StringParam(''), [], ','),
  },
};
