import { RECEIVE_PRICELIST_SUCCESS } from './constants';

const priceListReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_PRICELIST_SUCCESS:
      return {
        ...state,
        ...action.payload.data.reduce((acc, next) => {
          return { ...acc, [next._id]: next };
        }, {}),
      };
    default:
      return state;
  }
};

export default priceListReducer;
