import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isSubmitting, SubmissionError, submit } from 'redux-form';
import Api from '../../../../Api';
import { getProp, getRequestStatusName, __ } from '../../../../utilities';
import { appspaceDefinition } from '../../../App/definitions';
import {
  appsIsFetchingSelector,
  appspaceSelector,
} from '../../../App/selectors';
import { fetchCodeList } from '../../../Authorization/actions';
import { receiveRequestDetailSuccess } from '../actions';
import { sportExpertOrgRequestDefinition } from '../../definitions';
import StatusManager from '../Universal/StatusManager';
import Form, { formName } from './form';

class PoRegistryManager extends PureComponent {
  state = {
    loading: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchCodeList('organization-profile-type'));
  }

  changeStatus = async status => {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      request,
    } = this.props;
    const confirmMessage = `${__('Naozaj chcete zmeniť stav žiadosti')}
${__('z')} ${getRequestStatusName(request.status)} ${__(
      'na',
    )} ${getRequestStatusName(status)}?`;

    try {
      this.setState({
        loading: true,
      });
      let newRequest = {};
      if (status === 'REJECTED') {
        const noteText = __('Napíšte dôvod zamietnutia:');
        // eslint-disable-next-line
        const note = window.prompt(`${confirmMessage}
        
${noteText}`);
        if (!note) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status, note },
        });
      } else if (status === 'CONFIRMED') {
        // eslint-disable-next-line
        if (!window.confirm(confirmMessage)) return;
        newRequest = await Api.crmConfirmPoRegistryRequest(
          appspace,
          request._id,
        );
      } else {
        // eslint-disable-next-line
        if (!window.confirm(confirmMessage)) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status },
        });
      }
      dispatch(receiveRequestDetailSuccess(newRequest._id, newRequest));
    } catch (e) {
      // failed
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleCancel = () => {
    this.changeStatus('CANCELLED');
  };

  handleNew = () => {
    this.changeStatus('NEW');
  };

  handleReject = () => {
    this.changeStatus('REJECTED');
  };

  handleClose = () => {
    this.props.dispatch(submit(formName));
  };

  handleConfirm = () => {
    this.changeStatus('CONFIRMED');
  };

  handleSubmit = async values => {
    const status = 'CLOSED';
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      request,
      appspace: { org_profile: { sport } = {} },
    } = this.props;
    const confirmMessage = `${__('Naozaj chcete zmeniť stav žiadosti')}
${__('z')} ${getRequestStatusName(request.status)} ${__(
      'na',
    )} ${getRequestStatusName(status)}?`;
    // eslint-disable-next-line
    if (!window.confirm(confirmMessage)) return;
    // Format data to send
    const data = {};
    if (values.createPpo) {
      data.ppo = {
        sport,
        profile: values.profile,
      };
      const { registerApps = {} } = values;
      const allUsersForRegistration = [
        request.ownerUser,
        ...getProp(request, ['data', 'po', 'users'], []),
      ];
      const registerAppsArr = [];
      Object.keys(registerApps).forEach(key => {
        const app = registerApps[key];
        const userApps = Object.keys(app).reduce((acc, userId) => {
          const user = allUsersForRegistration.find(
            u => u.sportnet_id === userId || u._id === userId,
          );
          if (app[userId].register) {
            acc.push({
              app_id: key,
              userId,
              userName: user.display_name || user.name,
              role: app[userId].role,
              grant: app[userId].grant || false,
            });
          }
          return acc;
        }, []);
        registerAppsArr.push(...userApps);
      });
      if (registerAppsArr.length > 0) {
        data.ppo.register_apps = registerAppsArr;
      }
    }
    try {
      const newRequest = await Api.crmClosePoRegistryRequest(
        appspace,
        request._id,
        {
          data,
        },
      );
      dispatch(receiveRequestDetailSuccess(newRequest._id, newRequest));
    } catch (e) {
      const submissionError = {
        _error: __('Zmena stavu žiadosti sa nepodarila'),
      };
      const errors = getProp(e, ['details', 'payload', 'errors'], []);
      if (errors.length > 0) {
        errors.forEach(error => {
          submissionError[error.path.join('.')] = __('Chybne vyplnené pole');
        });
      }
      const error = getProp(e, ['details'], {});
      if (error.name === 'DUPLICATED_BUSINESS_ID') {
        // eslint-disable-next-line
        window.alert(__('Daná organizácia je už v systéme zaregistrovaná'));
      }
      throw new SubmissionError(submissionError);
    }
  };

  render() {
    const { request, isFormSubmitting, areAppsFetching } = this.props;
    const requestPo = getProp(request, ['data', 'po'], null);
    return (
      <Fragment>
        <StatusManager
          request={request}
          loading={this.state.loading || isFormSubmitting || areAppsFetching}
          onCancel={this.handleCancel}
          onNew={this.handleNew}
          onReject={this.handleReject}
          onConfirm={this.handleConfirm}
          onClosed={this.handleClose}
        />
        {request.status === 'CONFIRMED' && (
          <Form
            request={request}
            onSubmit={this.handleSubmit}
            showFields={!!requestPo}
          />
        )}
      </Fragment>
    );
  }
}

PoRegistryManager.propTypes = {
  request: sportExpertOrgRequestDefinition.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      appspace: PropTypes.string,
    }).isRequired,
  }).isRequired,
  appspace: appspaceDefinition.isRequired,
  isFormSubmitting: PropTypes.bool,
  areAppsFetching: PropTypes.bool.isRequired,
};

PoRegistryManager.defaultProps = {
  isFormSubmitting: false,
};

const mapStateToProps = state => {
  return {
    appspace: appspaceSelector(state),
    isFormSubmitting: isSubmitting(formName)(state),
    areAppsFetching: appsIsFetchingSelector(state),
  };
};

const routerConnected = withRouter(PoRegistryManager);
export default connect(mapStateToProps)(routerConnected);
