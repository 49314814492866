import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import PersonInvitation from './Person';
import NewTransfer from './Transfer';

const Wrapper = styled.div``;

/**
 * ****************************************************************************
 * Invitation request pre sportovca / odbornika
 * ****************************************************************************
 */

class PersonRequestComponent extends Component {
  render() {
    const { onSubmitSuccess } = this.props;
    return (
      <Segment>
        <Wrapper>
          <PersonInvitation onSubmitSuccess={onSubmitSuccess} />
        </Wrapper>
      </Segment>
    );
  }
}
PersonRequestComponent.propTypes = {
  onSubmitSuccess: PropTypes.func,
};
PersonRequestComponent.defaultProps = {
  onSubmitSuccess: () => {},
};

export const PersonRequest = connect(() => {
  return {};
})(withRouter(PersonRequestComponent));

/**
 * ****************************************************************************
 * Request pre transfer sportovca
 * ****************************************************************************
 */

class TransferRequestComponent extends Component {
  render() {
    const { onSubmitSuccess } = this.props;
    return (
      <Segment>
        <Wrapper>
          <NewTransfer onSubmitSuccess={onSubmitSuccess} />
        </Wrapper>
      </Segment>
    );
  }
}

TransferRequestComponent.propTypes = {
  onSubmitSuccess: PropTypes.func,
};

TransferRequestComponent.defaultProps = {
  onSubmitSuccess: () => {},
};

export const TransferRequest = connect(() => {
  return {};
})(withRouter(TransferRequestComponent));
