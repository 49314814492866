import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import PhotoUpload from '.';

class PhotoUploadRedux extends React.PureComponent {
  handleUpload = file => {
    const { dispatch, uploadFieldName, deleteFieldName, formName } = this.props;
    dispatch(change(formName, uploadFieldName, file));
    dispatch(change(formName, deleteFieldName, false));
  };

  handleDelete = () => {
    const { dispatch, uploadFieldName, deleteFieldName, formName } = this.props;
    dispatch(change(formName, uploadFieldName, null));
    dispatch(change(formName, deleteFieldName, true));
  };

  render() {
    const { src, label, availableAspectRatios } = this.props;
    return (
      <PhotoUpload
        availableAspectRatios={availableAspectRatios}
        src={src}
        onUpload={this.handleUpload}
        onDelete={this.handleDelete}
        label={label}
      />
    );
  }
}

PhotoUploadRedux.propTypes = {
  formName: PropTypes.string.isRequired,
  uploadFieldName: PropTypes.string.isRequired,
  deleteFieldName: PropTypes.string.isRequired,
  src: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  label: PropTypes.string,
  availableAspectRatios: PropTypes.arrayOf(PropTypes.string),
};

PhotoUploadRedux.defaultProps = {
  src: null,
  label: null,
  availableAspectRatios: [null],
};

export default connect()(PhotoUploadRedux);
