import Button from '@sportnet/ui/Button';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Input from '@sportnet/ui/Input';
import Label from '@sportnet/ui/Label/Label';
import Segment from '@sportnet/ui/Segment';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { change, Field, Form, formValueSelector, reduxForm } from 'redux-form';
import getProp from '@sportnet/utilities/getProp';
import styled from 'styled-components';
import FileInputRedux from '../../../../components/FileInput/redux-form';
import { __ } from '../../../../utilities';
import { required } from '../../../../validation';
import { appspaceDefinition } from '../../../App/definitions';
import {
  appspaceSelector,
  licenceCodelistSelector,
  pposSelector,
} from '../../../App/selectors';
import { licenseFileClick } from '../../actions';
import {
  codelistDefinition,
  licenceDefinition,
  PPODefinintion,
} from '../../definitions';
import { getActiveIdSelector } from '../../selectors';

const StyledButton = styled(Button)`
  margin-bottom: ${rem(10)};
`;

export const FORM_NAME = 'LICENSE_FORM';

class LicencesForm extends PureComponent {
  componentDidMount() {
    this.onChangePPO();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.org_profile_id !== nextProps.org_profile_id) {
      this.onChangePPO(nextProps);
    }
  }

  onChangePPO = (props = this.props) => {
    const { ppos, dispatch, org_profile_id: orgProfileId } = props;
    const ppo = ppos.find(i => i._id === orgProfileId);
    if (ppo) {
      dispatch(change(FORM_NAME, 'org_id', ppo.organization_id));
    }
  };

  onFileDelete = file => {
    if (file._new) return;
    const { dispatch } = this.props;
    const id = file._id;
    dispatch(change(FORM_NAME, `deletedFiles.${id}`, true));
  };

  onFileClick = async file => {
    if (file._new) return;
    const { dispatch, initialValues, activeUserId, appspace } = this.props;
    dispatch(
      licenseFileClick(
        activeUserId,
        initialValues._id,
        getProp(appspace, ['org_profile', '_id']),
        file._id,
      ),
    );
  };

  render() {
    const {
      data,
      handleSubmit,
      licenceTypes,
      submitting,
      ppos,
      appspace: { org_profile },
    } = this.props;

    let organizations = [];
    let profiles = [];
    if (data) {
      profiles = [
        {
          label: getProp(data, ['org_profile_name']),
          value: getProp(data, ['org_profile_id']),
        },
      ];
    } else {
      organizations = ppos.map(ppo => ({
        label: ppo.organization_name,
        value: ppo.organization_id,
      }));
      profiles = ppos.map(ppo => ({
        label: ppo.name || ppo._id,
        value: ppo._id,
      }));
    }

    let isOwner = data && data.org_profile_id === org_profile._id;

    if (!isOwner && data) {
      const ppo = ppos.find(i => i._id === data.org_profile_id);
      if (ppo) {
        isOwner = true;
      }
    }

    return (
      <Segment>
        <Form onSubmit={handleSubmit}>
          {data ? (
            <FormGroup>
              <Label required>{__('Organizácia')}</Label>
              <Input disabled value={data.org_name} />
            </FormGroup>
          ) : (
            <Field
              component={FormFieldRedux}
              type="theselectsimple"
              label={__('Organizácia')}
              name="org_id"
              validate={[required]}
              disabled
              required
              options={organizations}
            />
          )}
          <Field
            component={FormFieldRedux}
            label={__('Profil organizácie')}
            type="theselectsimple"
            name="org_profile_id"
            validate={[required]}
            disabled={data}
            required
            options={profiles}
            data-testid="CRM_FO_Input_Licences_Org"
          />
          <Field
            component={FormFieldRedux}
            type="theselectsimple"
            name="type"
            required
            label={__('Typ licencie / kvalifikácie')}
            disabled={data && !isOwner}
            validate={[required]}
            options={licenceTypes.map(type => ({
              value: type.value,
              label: type.label,
            }))}
            data-testid="CRM_FO_Input_Licences_Type"
          />
          <Field
            component={FormFieldRedux}
            name="name"
            required
            disabled={data && !isOwner}
            label={__('Názov licencie / kvalifikácie')}
            validate={[required]}
            data-testid="CRM_FO_Input_Licences_NameLic"
          />
          <Field
            component={FormFieldRedux}
            name="documentnr"
            required
            disabled={data && !isOwner}
            validate={[required]}
            label={__('Číslo dokladu')}
            data-testid="CRM_FO_Input_Licences_NumDoc"
          />
          <Field
            component={FormFieldRedux}
            name="valid_from"
            validate={[required]}
            required
            type="date"
            disabled={data && !isOwner}
            label={__('Platnosť od')}
            data-testid="CRM_FO_Input_Licences_ValFro"
          />
          <Field
            component={FormFieldRedux}
            name="valid_to"
            type="date"
            disabled={data && !isOwner}
            label={__('Platnosť do')}
            data-testid="CRM_FO_Input_Licences_ValTo"
          />
          <Field
            component={FormFieldRedux}
            name="issued_by_po"
            required
            disabled={data && !isOwner}
            validate={[required]}
            label={__('Vydal PO')}
            data-testid="CRM_FO_Input_Licences_Author"
          />
          <Field
            component={FormFieldRedux}
            name="verified_by_position"
            required
            disabled={data && !isOwner}
            validate={[required]}
            label={__('Overil FO - funkcia')}
            data-testid="CRM_FO_Input_Licences_ContrFunc"
          />
          <Field
            component={FormFieldRedux}
            name="verified_by_person"
            required
            disabled={data && !isOwner}
            validate={[required]}
            label={__('Overil FO - meno')}
            data-testid="CRM_FO_Input_Licences_ContrName"
          />
          <Field
            component={FormFieldRedux}
            name="registration_date"
            required
            disabled={data && !isOwner}
            validate={[required]}
            type="date"
            label={__('Dátum registrácie')}
            data-testid="CRM_FO_Input_Licences_DateR"
          />
          <Field
            component={FormFieldRedux}
            name="note"
            type="textarea"
            disabled={data && !isOwner}
            label={__('Poznámka')}
            data-testid="CRM_FO_Input_Licences_Note"
          />
          <FormGroup>
            <Label>{__('Prílohy')}</Label>
            <Field
              component={FileInputRedux}
              name="files"
              onDeleteCallback={this.onFileDelete}
              onFileClick={this.onFileClick}
              multiple
              disabled={data && !isOwner}
              accept="application/pdf, image/jpeg, image/png, image/gif, text/plain"
            />
          </FormGroup>
          {(!data || isOwner) && (
            <div>
              {!data ? (
                <StyledButton
                  type="submit"
                  primary
                  disabled={submitting}
                  loading={submitting}
                  data-testid="CRM_FO_Button_Licences_Save"
                >
                  {__('Pridať položku')}
                </StyledButton>
              ) : (
                <StyledButton
                  type="submit"
                  primary
                  disabled={submitting}
                  loading={submitting}
                >
                  {__('Upraviť položku')}
                </StyledButton>
              )}
            </div>
          )}
        </Form>
      </Segment>
    );
  }
}

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, ownProps) => {
  const appspace = appspaceSelector(state);
  const initialValues = ownProps.data || {
    org_id: appspace.org_profile.organization_id,
    org_profile_id: appspace.org_profile._id,
  };
  return {
    initialValues,
    appspace,
    org_profile_id: selector(state, 'org_profile_id'),
    ppos: pposSelector(state),
    licenceTypes: licenceCodelistSelector(state),
    activeUserId: getActiveIdSelector(state),
  };
};

LicencesForm.propTypes = {
  appspace: appspaceDefinition.isRequired,
  data: licenceDefinition,
  licenceTypes: PropTypes.arrayOf(codelistDefinition).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  initialValues: PropTypes.shape({
    _id: PropTypes.string,
    org_name: PropTypes.string,
    org_profile_name: PropTypes.string,
  }).isRequired,
  ppos: PropTypes.arrayOf(PPODefinintion).isRequired,
  org_profile_id: PropTypes.string.isRequired,
};

LicencesForm.defaultProps = {
  data: null,
  submitting: false,
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(LicencesForm);

export default connect(mapStateToProps)(connected);
