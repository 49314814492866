import { getProp } from '@sportnet/utilities';
import FileInput from './FileInput';
import FileInputSimple from './FileInputSimple';
import FormField from '@sportnet/ui/FormField';
import FormGroup from '@sportnet/ui/FormGroup';
import Label from '@sportnet/ui/Label/Label';
import RadioInput from './RadioInput';
import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

export const RelativeWrapper = styled.div`
  position: relative;
  padding-bottom: ${rem(10)};
`;

export const ErrorText = styled.div`
  font-size: ${rem(11)};
  color: ${({ theme }) => theme.color.danger};
  position: absolute;
  margin-top: ${rem(3)};
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

// TODO: Natypovat
const FinalFormField = props => {
  const {
    required,
    label,
    meta,
    input,
    options,
    onUpload,
    onDelete,
    onBlur,
    onChange,
    disabled,
  } = props;
  if (input.type === 'radio') {
    return (
      <RadioInput
        required={required}
        name={input.name}
        value={input.value}
        options={options}
        onChange={input.onChange}
        label={label}
        type={input.type}
        error={meta.error && meta.touched ? meta.error : ''}
      />
    );
  } else if (input.type === 'file') {
    return (
      <FormGroup>
        <Label required>{label}</Label>
        <RelativeWrapper>
          <FileInput
            required={required}
            name={input.name}
            value={input.value}
            options={options}
            onChange={input.onChange}
            label={label}
            type={input.type}
            error={meta.error && meta.touched ? meta.error : ''}
            onUpload={onUpload}
            onDelete={onDelete}
          />
          {!!meta.error && meta.touched && (
            <ErrorText title={meta.error}>{meta.error}</ErrorText>
          )}
        </RelativeWrapper>
      </FormGroup>
    );
  } else if (input.type === 'fileSimple') {
    return (
      <FormGroup>
        <Label required>{label}</Label>
        <RelativeWrapper>
          <FileInputSimple
            required={required}
            name={input.name}
            value={input.value}
            options={options}
            onChange={input.onChange}
            label={label}
            type={input.type}
            error={meta.error && meta.touched ? meta.error : ''}
          />
          {!!meta.error && meta.touched && (
            <ErrorText title={meta.error}>{meta.error}</ErrorText>
          )}
        </RelativeWrapper>
      </FormGroup>
    );
  }
  return (
    <FormField
      required={required}
      name={input.name}
      {...(input.type === 'checkbox'
        ? { checked: input.checked }
        : { value: input.value })}
      options={options}
      onChange={e => {
        if (input.type === 'checkbox') {
          input.onChange(e.target.checked || null);
          if (onChange) {
            onChange(e.target.checked || null);
          }
        } else {
          const val = getProp(e, ['target', 'value'], e) || null;
          input.onChange(val);
          if (onChange) {
            onChange(val);
          }
        }
      }}
      label={label}
      type={input.type}
      error={meta.error && meta.touched ? meta.error : ''}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
};

export default FinalFormField;
