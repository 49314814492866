import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import { ContentLoader } from '@sportnet/ui/Loader';
import Message from '@sportnet/ui/Message';
import Segment from '@sportnet/ui/Segment';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, submit } from 'redux-form';
import { withTheme } from 'styled-components';
import Api from '../../../../Api';
import Layout from '../../../../components/Layout';
import { getProp, __ } from '../../../../utilities';
import {
  aclSelector,
  legalFormsCodelistSelector,
} from '../../../App/selectors';
import { activeAppSpaceRelationsSelector } from '../../../Authorization/selectors';
import { codelistDefinition, matchDefinition } from '../../../FO/definitions';
import {
  editOrganizationProfile,
  fetchOrganizationProfile,
} from '../../actions';
import { organizationProfileDetailSelector } from '../../selectors';
import Wrapper from '../index';

const FORM_NAME = 'PO';

const submitForm = async (fields, dispatch, props) => {
  const {
    business_id,
    contact_person,
    email,
    fax,
    full_name,
    legal_form,
    name,
    phone,
    vat_nr,
    www,
    statutory,
  } = fields;
  if (fields.logoUpload) {
    try {
      await Api.crmMyOrganizationLogoUpload(props.match.params.appspace, {
        upload: fields.logoUpload,
      });
    } catch (e) {
      window.alert(__('Nahratie loga sa nepodarilo'));
    }
  } else if (fields.logoDelete) {
    try {
      await Api.crmMyOrganizationLogoDelete(props.match.params.appspace);
    } catch (e) {
      window.alert(__('Vymazanie loga sa nepodarilo'));
    }
  }
  if (fields.faviconUpload) {
    try {
      await Api.crmMyOrganizationFaviconUpload(props.match.params.appspace, {
        upload: fields.faviconUpload,
      });
    } catch (e) {
      window.alert(__('Nahratie obrázka favicon sa nepodarilo'));
    }
  } else if (fields.faviconDelete) {
    try {
      await Api.crmMyOrganizationFaviconDelete(props.match.params.appspace);
    } catch (e) {
      window.alert(__('Vymazanie obrázka favicon sa nepodarilo'));
    }
  }
  if (props.relations.length) {
    await Api.crmMyOrganizationRequestChange(props.match.params.appspace, {
      data: {
        business_id,
        contact_person,
        email,
        fax,
        full_name,
        legal_form,
        name,
        phone,
        vat_nr,
        www,
        statutory,
      },
    });
  } else {
    await props.dispatch(
      editOrganizationProfile(props.match.params.appspace, {
        business_id,
        contact_person,
        email,
        fax,
        full_name,
        legal_form,
        name,
        phone,
        vat_nr,
        www,
        statutory,
      }),
    );
  }
};

class POProfileDetail extends PureComponent {
  state = {
    isFetching: true,
    orgLogoSrc: null,
    orgFaviconSrc: null,
    updateDisabled: true,
    parentProfile: null,
  };

  componentDidMount() {
    this.loadOrganizationProfile();
    this.setAclAccess(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      getProp(this.props, ['acl', 'crmMyOrganizationUpdate']) !==
      getProp(nextProps, ['acl', 'crmMyOrganizationUpdate'])
    ) {
      this.setAclAccess(nextProps);
    }
  }

  setAclAccess = props => {
    if (props.acl) {
      this.setState({
        updateDisabled: !props.acl.crmMyOrganizationUpdate,
      });
    }
  };

  loadOrganizationProfile = async () => {
    const {
      match: {
        params: { appspace },
      },
      relations,
    } = this.props;
    const organization = await this.props.dispatch(
      fetchOrganizationProfile(appspace),
    );
    const state = {
      isFetching: false,
    };
    if (organization) {
      state.orgLogoSrc = organization.logo_public_url || null;
      state.orgFaviconSrc = organization.favicon_public_url || null;
    }
    if (relations.length) {
      try {
        const parentProfile = await Api.organizationPPOProfile(
          relations[0].related_ppo,
        );
        if (parentProfile) {
          state.parentProfile = parentProfile;
        }
      } catch (e) {
        //
      }
    }
    state.dataNotApproved = organization._dataNotApproved;
    this.setState(state);
  };

  render() {
    const { legalForms, handleSubmit, submitting } = this.props;
    const { updateDisabled, isFetching, parentProfile, dataNotApproved } =
      this.state;

    return (
      <Layout
        bottomFixed={
          !updateDisabled && (
            <ContextBar>
              <ContextBarSpacer />
              <ContextBarItem>
                <Button
                  onClick={() => {
                    this.props.dispatch(submit(FORM_NAME));
                  }}
                  primary
                  loading={submitting}
                  disabled={updateDisabled}
                >
                  {parentProfile
                    ? __('Požiadať o zmenu údajov')
                    : __('Uložiť zmeny')}
                </Button>
              </ContextBarItem>
            </ContextBar>
          )
        }
      >
        <AppContext title={__('Moja organizácia')} />
        <Wrapper activeTab={__('Základné údaje')}>
          {isFetching ? (
            <ContentLoader size="xl" />
          ) : (
            <>
              {!updateDisabled && !!parentProfile && (
                <Message
                  warning
                  title={__('Zmeny podliehajú schvaľovaciemu procesu')}
                >
                  {`${__(
                    'Uložením zmien sa zmeny zašlú na schválenie organizácii',
                  )} ${parentProfile.name}`}
                </Message>
              )}
              {dataNotApproved === true && !!parentProfile && (
                <Message danger title={__('Údaje čakajú na schválenie')}>
                  {`${__('Zmeny boli odoslané na schválenie organizácii')} ${
                    parentProfile?.name
                  }`}
                </Message>
              )}
              <form onSubmit={handleSubmit}>
                <Segment raised>
                  <Row>
                    <Col xs={12} m={4}>
                      <Field
                        label={__('Názov spoločnosti')}
                        name="full_name"
                        component={FormFieldRedux}
                        disabled={updateDisabled}
                      />
                    </Col>
                    <Col xs={12} m={4}>
                      <Field
                        name="name"
                        component={FormFieldRedux}
                        disabled={updateDisabled}
                        label={__('Skrátený názov')}
                      />
                    </Col>
                    <Col xs={12} m={4}>
                      <Field
                        name="statutory"
                        component={FormFieldRedux}
                        disabled={updateDisabled}
                        label={__('Štatutár')}
                      />
                    </Col>
                    <Col xs={12} s={6} m={4}>
                      <FormGroup>
                        <Field
                          name="legal_form"
                          label={__('Právna forma')}
                          component={FormFieldRedux}
                          type="theselectsimple"
                          disabled={updateDisabled}
                          options={legalForms.map(item => ({
                            label: item.label,
                            value: item.value,
                          }))}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} s={6} m={4}>
                      <Field
                        name="business_id"
                        component={FormFieldRedux}
                        disabled={updateDisabled}
                        label={__('IČO')}
                      />
                    </Col>
                    <Col xs={12} s={6} m={4}>
                      <Field
                        name="vat_nr"
                        component={FormFieldRedux}
                        disabled={updateDisabled}
                        label={__('DIČ')}
                      />
                    </Col>
                    {/* <Col xs={12} s={6} m={6} l={3}>
                      <Field
                        name="email"
                        component={FormFieldRedux}
                        label={__('E-mailová adresa')}
                        disabled={updateDisabled}
                      />
                    </Col>
                    <Col xs={12} s={6} m={6} l={3}>
                      <Field
                        name="www"
                        component={FormFieldRedux}
                        disabled={updateDisabled}
                        label={__('Web')}
                      />
                    </Col>
                    <Col xs={12} s={6} m={6} l={3}>
                      <Field
                        name="phone"
                        component={FormFieldRedux}
                        disabled={updateDisabled}
                        label={__('Telefón')}
                      />
                    </Col>
                    <Col xs={12} s={6} m={6} l={3}>
                      <Field
                        name="fax"
                        component={FormFieldRedux}
                        disabled={updateDisabled}
                        label={__('Fax')}
                      />
                    </Col> */}
                  </Row>
                </Segment>
              </form>
            </>
          )}
        </Wrapper>
      </Layout>
    );
  }
}

POProfileDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  match: matchDefinition.isRequired,
  legalForms: PropTypes.arrayOf(codelistDefinition).isRequired,
  theme: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line
  acl: PropTypes.shape({}),
  submitting: PropTypes.bool.isRequired,
  relations: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      related_ppo: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

POProfileDetail.defaultProps = {
  acl: null,
};

const ReduxPOForm = reduxForm({
  form: FORM_NAME,
  onSubmit: submitForm,
  enableReinitialize: true,
})(POProfileDetail);

const mapStateToProps = state => {
  return {
    legalForms: legalFormsCodelistSelector(state),
    initialValues: organizationProfileDetailSelector(state),
    acl: aclSelector(state),
    relations: activeAppSpaceRelationsSelector(state),
  };
};

export default connect(mapStateToProps)(withTheme(ReduxPOForm));
