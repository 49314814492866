import { Td, Tr } from '@sportnet/ui/Table';
import Icon from '@sportnet/ui/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  competenceCodelistSelector,
  transferTypeCodelistSelector,
} from '../../../App/selectors';
import SectorLabel from '../../../SectorLabel';
import { athleteDefinition, codelistDefinition } from '../../definitions';
import { Faded } from '../../detail';
import { getActiveUserAthletesSelector } from '../../selectors';
import { formatDate } from '@sportnet/content/view/utilities';

const AthletesList = ({
  athletes,
  competences,
  transferTypes,
  onClickRow,
  updateDisabled,
}) => {
  const getCompetence = code => {
    const item = competences.find(competence => {
      return competence.value === code;
    });
    return item?.label ?? code;
  };

  const getTransferType = code => {
    const item = transferTypes.find(transferType => {
      return transferType.value === code;
    });
    return item?.label ?? code;
  };

  return athletes.map(item => {
    return (
      <Tr
        key={item._id}
        onClick={() => {
          if (!updateDisabled) {
            onClickRow(item);
          }
        }}
        style={
          !item.isActive
            ? { color: '#aaa', textDecoration: 'line-through' }
            : {}
        }
      >
        <Td>
          {item.isActive && (
            <>
              <Icon size="s" color="green" name="check-circle" />{' '}
            </>
          )}
          <strong>{item.org_profile_name}</strong>
          {item.org_name !== item.org_profile_name && (
            <Faded> ({item.org_name})</Faded>
          )}
        </Td>
        <Td>
          <SectorLabel sector={item.sector} />
        </Td>
        <Td>
          {getCompetence(item.competence_type)} /{' '}
          {getTransferType(item.transfer_type)}
        </Td>
        <Td>
          {formatDate(item.date_from)} -{' '}
          {item.date_to ? formatDate(item.date_to) : '?'}
        </Td>
        <Td>{item.valid_to && formatDate(item.valid_to)}</Td>
      </Tr>
    );
  });
};

AthletesList.propTypes = {
  onClickRow: PropTypes.func.isRequired,
  athletes: PropTypes.arrayOf(athleteDefinition).isRequired,
  competences: PropTypes.arrayOf(codelistDefinition).isRequired,
  updateDisabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  athletes: getActiveUserAthletesSelector(state),
  competences: competenceCodelistSelector(state),
  transferTypes: transferTypeCodelistSelector(state),
});

export default connect(mapStateToProps)(AthletesList);
