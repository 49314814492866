import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';
import { getProp } from '../../utilities';

const entitiesDomainSelector = state => state.entities;

const requestsDomainSelector = createSelector(
  entitiesDomainSelector,
  substate => substate.Requests,
);

export const requestsCurrenListSelector = listName =>
  createSelector(
    requestsDomainSelector,
    getListResults(listName),
    (entities, ids) => {
      const list = (ids || []).reduce((acc, id) => {
        if (entities[id]) {
          return [...acc, entities[id]];
        }
        return acc;
      }, []);
      return list;
    },
  );

// Request detail
const requestDetailDomainSelector = state => state.requestDetailSidebar;

const requestDetailObjectSelector = id =>
  createSelector(requestDetailDomainSelector, domain =>
    getProp(domain, id, {}),
  );

export const requestDetailIsFetchingSelector = id =>
  createSelector(
    requestDetailObjectSelector(id),
    detail => detail.isFetching || false,
  );

export const requestDetailSelector = id =>
  createSelector(requestsDomainSelector, byId => getProp(byId, [id], {}));
