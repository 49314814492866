import FormGroup from '@sportnet/ui/FormGroup';
import Icon from '@sportnet/ui/Icon';
import Label from '@sportnet/ui/Label/Label';
import Message from '@sportnet/ui/Message';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formatUserName } from '@sportnet/utilities';
import getProp from '@sportnet/utilities/getProp';
import Api from '../../../../Api';
import { LoaderContainer, Wrapper } from '../../../../components/List';
import { Loading } from '../../../../components/Loader';
import ReadOnlyTextArea from '../../../../components/ReadOnlyTextArea';
import { getRequestStatusName, __ } from '../../../../utilities';
import { sportOrgRequestDefinition } from '../../definitions';
import { receiveRequestDetailSuccess } from '../actions';
import StatusManager from '../Universal/StatusManager';

class SportOrgManager extends PureComponent {
  state = {
    loading: false,
    concernedOrganizations: [],
    isFetchingConcernedOrganizations: false,
  };

  componentDidMount() {
    this.getConcernedOrganizations(this.props.request);
  }

  componentDidUpdate(prev) {
    if (
      prev.request &&
      this.props.request &&
      prev.request.status !== this.props.request.status
    ) {
      this.getConcernedOrganizations(this.props.request);
    }
  }

  getConcernedOrganizations = request => {
    this.setState(
      {
        concernedOrganizations: [],
        isFetchingConcernedOrganizations: true,
      },
      async () => {
        try {
          const promises = [];
          request.confirmedBy.forEach(confirmation => {
            promises.push(Api.organizationPPOProfile(confirmation.appSpace));
          });
          const organizations = await Promise.all(promises);
          this.setState({
            concernedOrganizations: organizations,
          });
        } catch (e) {
          //
        } finally {
          this.setState({
            isFetchingConcernedOrganizations: false,
          });
        }
      },
    );
  };

  changeStatus = async status => {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      request,
    } = this.props;
    const confirmMessage = `${__('Naozaj chcete zmeniť stav žiadosti')}
${__('z')} ${getRequestStatusName(request.status)} ${__(
      'na',
    )} ${getRequestStatusName(status)}?`;

    try {
      this.setState({
        loading: true,
      });
      let newRequest = {};
      if (status === 'REJECTED') {
        const noteText = __('Napíšte dôvod zamietnutia:');
        // eslint-disable-next-line
        const note = window.prompt(`${confirmMessage}
        
${noteText}`);
        if (!note) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status, note },
        });
      } else if (status === 'CONFIRMED') {
        // eslint-disable-next-line
        if (!window.confirm(confirmMessage)) return;
        newRequest = await Api.crmConfirmSportOrgRequest(
          appspace,
          request._id,
          {
            data: {},
          },
        );
      } else {
        // eslint-disable-next-line
        if (!window.confirm(confirmMessage)) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status },
        });
      }
      dispatch(receiveRequestDetailSuccess(newRequest._id, newRequest));
    } catch (e) {
      // failed
      const error = getProp(e, ['details'], {});
      if (error.name === 'INVALID_IDNR') {
        // eslint-disable-next-line
        window.alert(__('Neplatné Identifikačné číslo používateľa'));
      } else if (error.statusCode === 409 && error.name === 'DUPLICATED_IDNR') {
        // eslint-disable-next-line
        window.alert(
          __(
            'Rodné číslo už v systéme existuje. Nie je možné schváliť žiadosť.',
          ),
        );
      } else if (
        error.statusCode === 409 &&
        error.name === 'DUPLICATED_REGNR'
      ) {
        // eslint-disable-next-line
        window.alert(
          __(
            'Registračné číslo už v systéme existuje. Skontrolujte prosím nastavenie sekvencera pre generovanie registračných čísiel.',
          ),
        );
      } else if (
        error.statusCode === 403 &&
        error.name === 'GUARDIAN_FORBIDDEN'
      ) {
        // eslint-disable-next-line
        window.alert(
          __(
            'Zadávateľ žiadosti nie je oprávnená osoba na registráciu zadaného rodného čísla.',
          ),
        );
      } else {
        // eslint-disable-next-line
        window.alert(
          `${__('Došlo k neznámej chybe')} (${error.statusCode}:${error.name}:${
            error.description
          })`,
        );
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleCancel = () => {
    this.changeStatus('CANCELLED');
  };

  handleNew = () => {
    this.changeStatus('NEW');
  };

  handleReject = () => {
    this.changeStatus('REJECTED');
  };

  handleConfirm = () => {
    this.changeStatus('CONFIRMED');
  };

  handleClose = () => {
    this.changeStatus('CLOSED');
  };

  render() {
    /*
    const { _id, ...modifiedSportnetUser } = getProp(
      this.props.request,
      ['data', 'sportnet_user'],
      {},
    );
    const { sportnet_id: sportnetId, ...sportnetUser } = getProp(
      this.props.request,
      ['user'],
      {},
    );
    */
    const isChildRegistration = getProp(
      this.props.request,
      ['data', 'isChildRegistration'],
      false,
    );
    if (this.state.isFetchingConcernedOrganizations) {
      return (
        <Wrapper>
          <LoaderContainer>
            <Loading size="xl" />
          </LoaderContainer>
        </Wrapper>
      );
    }
    return (
      <React.Fragment>
        <StatusManager
          request={this.props.request}
          loading={this.state.loading}
          onCancel={this.handleCancel}
          onNew={this.handleNew}
          onReject={this.handleReject}
          onConfirm={this.handleConfirm}
          onClosed={this.handleClose}
        />
        <FormGroup>
          <Label>{__('Registrácia dieťaťa: ')}</Label>
          <ReadOnlyTextArea>
            {isChildRegistration ? __('Áno') : __('Nie')}
          </ReadOnlyTextArea>
        </FormGroup>
        {!!isChildRegistration &&
          this.props.request &&
          this.props.request.data.sportnet_user.isRelated && (
            <Message success>
              <div>
                {__('Žiadateľ')} {this.props.request.ownerUser.display_name}{' '}
                {__('je oprávnený žiadať o zmeny pre dieťa')}{' '}
                {formatUserName(this.props.request.data.sportnet_user)}
              </div>
            </Message>
          )}
        {!!isChildRegistration &&
          this.props.request &&
          this.props.request.data.existingUser && (
            <Message
              danger
              title={__('Pozor, osoba zo žiadosti v systéme už existuje!')}
            >
              {
                <>
                  <div>
                    {__('Žiadateľ')}{' '}
                    <strong>{this.props.request.ownerUser.display_name}</strong>{' '}
                    {__(
                      'žiada registráciu dietaťa s rodným číslom, ktoré ale už v systéme existuje',
                    )}
                  </div>
                  <ul>
                    {[
                      [
                        __('SportnetId'),
                        this.props.request.data.existingUser._id,
                      ],
                      [
                        __('Meno a priezvisko'),
                        formatUserName(this.props.request.data.existingUser),
                      ],
                      [
                        __('Rodné číslo'),
                        this.props.request.data.existingUser.idnr,
                      ],
                      [__('Email'), this.props.request.data.existingUser.email],
                      [
                        __('Prihlasovacie meno'),
                        this.props.request.data.existingUser.username,
                      ],
                      [
                        __('Aktuálne príslušnosti'),
                        (
                          this.props.request.data.existingUser.ppoRelations ||
                          []
                        )
                          .map(({ ppo }) => ppo)
                          .join(', '),
                      ],
                    ].map(([k, v]) => (
                      <li>{`${k}: ${v}`}</li>
                    ))}
                  </ul>
                  <div>
                    {__(
                      'V prípade, že bude žiadosť schválená, žiadateľ sa stane oprávnenou osobou.',
                    )}
                  </div>
                  <br />
                  <div>
                    <strong>
                      {__(
                        'Ak uvedené údaje osoby pri tejto žiadosti sú iné, prosím kontaktujte pre ich zmenu uvedenú organizáciu, ktorá je pôvodným registrátorom osoby',
                      )}
                    </strong>
                    <br />
                    {__(
                      'Schválením tejto žiadosti budú údaje z tejto žiadosti ignorované.',
                    )}
                  </div>
                </>
              }
            </Message>
          )}
        {this.state.concernedOrganizations.length > 0 && (
          <FormGroup>
            <Label>{__('Potvrdzujúce organizácie: ')}</Label>
            {this.state.concernedOrganizations.map(org => (
              <ReadOnlyTextArea key={org._id}>
                {org.name}{' '}
                <Icon
                  size="s"
                  name={
                    (
                      this.props.request.confirmedBy.find(
                        ({ appSpace }) => appSpace === org._id,
                      ) || {}
                    ).confirmed
                      ? 'check'
                      : 'visibility'
                  }
                />
              </ReadOnlyTextArea>
            ))}
          </FormGroup>
        )}
      </React.Fragment>
    );
  }
}

SportOrgManager.propTypes = {
  request: sportOrgRequestDefinition.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      appspace: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = () => {
  return {};
};

const routerConnected = withRouter(SportOrgManager);
export default connect(mapStateToProps)(routerConnected);
