import BasicTable from '@sportnet/ui/BasicTable';
import PropTypes from 'prop-types';
import React from 'react';
import { __ } from '../../../../utilities';
import { customDefinition } from '../../definitions';

const Table = ({ items }) => {
  return (
    <BasicTable
      columns={[
        {
          id: 'label',
          header: __('Názov'),
        },
        {
          id: 'value',
          header: __('Hodnota'),
        },
      ]}
      rows={items}
      renderRow={item => [item.label, item.value]}
      rowKey="value"
    />
  );
};

Table.propTypes = {
  items: PropTypes.arrayOf(customDefinition).isRequired,
};

export default Table;
