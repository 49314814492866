import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import {
  FilterPanel,
  QueryFilter,
  SelectFilter,
} from '@sportnet/ui/FilterPanel';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import Sidebar from '@sportnet/ui/Sidebar';
import Tag from '@sportnet/ui/SmarttagsControl/SmarttagTags/Tag';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import connectToQueryHoc, { QueryHocTypes } from '@sportnet/query-hoc';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  commit,
  getListNextOffset,
  getListTotal,
  initialize,
  isCommiting,
  setParams,
} from '@sportnet/redux-list';
import { formatUserName, getProp } from '@sportnet/utilities';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import { __ } from '../../utilities';
import { appspaceDefinition } from '../App/definitions';
import { appspaceSelector } from '../App/selectors';
import { getGroups } from '../Groups/actions';
import { groupDefinition } from '../Groups/definitions';
import { LIST_NAME as GROUPS_LIST_NAME } from '../Groups/list';
import { groupsCurrentListSelector } from '../Groups/selectors';
import { getGroupUsers, inviteUserToGroups, saveUserGroups } from './actions';
import { groupUserDefinition } from './definitions';
import GroupForm from './form';
import { groupUsersCurrentListSelector } from './selectors';

const LIST_LIMIT = 100;
const LIST_NAME = 'GROUPS_USERS_LIST';

const Tags = styled.div`
  white-space: nowrap;
`;

class GroupsUsersList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidebarVisible: false,
      selectedUser: null,
    };
  }

  componentDidMount() {
    const {
      dispatch,
      appspace: {
        org_profile: { _id: appspace },
      },
    } = this.props;

    this.loadGroups();
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          appspace,
        },
      }),
    );
    this.getGroupUsers();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.serializedQuery !== nextProps.serializedQuery) {
      nextProps.dispatch(
        setParams({
          listName: LIST_NAME,
          parameters: nextProps.query,
        }),
      );
      this.getGroupUsers(nextProps);
    }
  }

  getGroupUsers = (props = this.props) => {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      query,
    } = props;
    dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          const res = await dispatch(
            getGroupUsers(appspace, { ...query, limit: LIST_LIMIT }),
          );
          return {
            total: res.total,
            results: res.users.map(i => i._id),
            nextOffset: res.nextOffset,
          };
        },
      }),
    );
  };

  loadGroups = () => {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;
    dispatch(
      initialize({
        listName: GROUPS_LIST_NAME,
        initialParams: {
          appspace,
        },
      }),
    );
    dispatch(
      commit.action({
        listName: GROUPS_LIST_NAME,
        load: async () => {
          const res = await dispatch(getGroups(appspace));
          return {
            total: 0,
            results: res.groups.map(i => i._id),
            nextOffset: null,
          };
        },
      }),
    );
  };

  handleSubmit = async data => {
    const { selectedUser } = this.state;
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;

    let res;

    const { email, sportnetId, groups } = data;
    if ((!groups || !groups.length) && !selectedUser) {
      alert(__('Zvoľte prosím aspoň jednu skupinu'));
      return;
    }
    if (email && !selectedUser) {
      res = await dispatch(inviteUserToGroups(appspace, email, data.groups));
      alert(__('Pozvánka bola odoslaná na zadaný email'));
    } else {
      if (selectedUser === null) {
        res = await dispatch(saveUserGroups(appspace, sportnetId, data.groups));
      } else {
        res = await dispatch(
          saveUserGroups(appspace, selectedUser._id, data.groups),
        );
      }
    }

    if (res) {
      this.getGroupUsers();
      this.setState({
        sidebarVisible: false,
        selectedUser: null,
      });
    }
  };

  render() {
    const {
      users,
      isFetching,
      total,
      groups,
      query: { offset, query, user_group_ids: userGroupIds },
      setParameter,
    } = this.props;
    const { selectedUser } = this.state;
    return (
      <Layout
        bottomFixed={
          <ContextBar>
            <ContextBarItem>
              <Paginator
                offset={offset}
                limit={LIST_LIMIT}
                total={total || 0}
                onChangeOffset={e => {
                  setParameter({
                    offset: e,
                  });
                }}
              />
            </ContextBarItem>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                primary
                data-testid="CRM_Skupina_Button_Add"
                onClick={() => {
                  this.setState({
                    sidebarVisible: true,
                  });
                }}
              >
                {__('Pridať ďalšiu osobu')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        }
        topFixed={
          <FilterPanel>
            <QueryFilter
              value={query}
              onChange={e => {
                setParameter({
                  query: e,
                });
              }}
            />
            <SelectFilter
              placeholder={__('Skupina')}
              value={userGroupIds}
              onChange={e => {
                setParameter({
                  user_group_ids: e,
                });
              }}
              items={[
                {
                  title: __('Skupiny'),
                  value: groups.map(item => {
                    return {
                      value: item._id,
                      title: item.name,
                    };
                  }),
                },
              ]}
            />
          </FilterPanel>
        }
      >
        <AppContext title={__('Priradenie osôb')} />
        <Segment noBottomGutter>
          <Segment raised loading={isFetching}>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Sportnet ID')}</Th>
                  <Th>{__('Meno')}</Th>
                  <Th>{__('Zaradenie do skupín')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.map(item => {
                  return (
                    <Tr
                      key={item._id}
                      onClick={() => {
                        this.setState({
                          sidebarVisible: true,
                          selectedUser: {
                            ...item,
                            groups: getProp(item, ['groups', 0, 'group'], []),
                          },
                        });
                      }}
                    >
                      <Td>{item._id}</Td>
                      <Td>{formatUserName(item)}</Td>
                      <Td>
                        <Tags>
                          {getProp(item, ['groups', 0, 'group'], []).map(
                            grp => (
                              <Tag key={grp} removable={false} subtitle={grp} />
                            ),
                          )}
                        </Tags>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Segment>
        </Segment>
        <Sidebar
          header={
            selectedUser === null
              ? __('Nové priradenie osoby')
              : __('Úprava priradenia osoby')
          }
          visible={this.state.sidebarVisible}
          onClose={() => {
            this.setState({
              sidebarVisible: false,
              selectedUser: null,
            });
          }}
        >
          <GroupForm
            key={selectedUser ? selectedUser._id : 'new'}
            parameters={{ selectedUser, availableGroups: groups }}
            onSubmit={this.handleSubmit}
          />
        </Sidebar>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    groups: groupsCurrentListSelector(GROUPS_LIST_NAME)(state),
    users: groupUsersCurrentListSelector(LIST_NAME)(state),
    nextOffset: getListNextOffset(LIST_NAME)(state),
    total: getListTotal(LIST_NAME)(state),
    isFetching: isCommiting(LIST_NAME)(state),
    appspace: appspaceSelector(state),
  };
};

GroupsUsersList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(groupDefinition).isRequired,
  users: PropTypes.arrayOf(groupUserDefinition).isRequired,
  query: PropTypes.string.isRequired,
  total: PropTypes.number,
  match: PropTypes.shape({
    params: PropTypes.shape({
      appsapce: PropTypes.string,
    }),
  }).isRequired,
  isFetching: PropTypes.bool,
  appspace: appspaceDefinition.isRequired,
  serializedQuery: PropTypes.string.isRequired,
  setParameter: PropTypes.func.isRequired,
};

GroupsUsersList.defaultProps = {
  total: null,
  isFetching: false,
};

const connected = connectToQueryHoc({
  parameters: {
    query: {
      type: QueryHocTypes.String,
      defaultValue: '',
    },
    offset: {
      type: QueryHocTypes.Number,
      defaultValue: 0,
    },
    user_group_ids: {
      type: QueryHocTypes.Array,
      defaultValue: [],
      delimiter: ',',
    },
  },
})(GroupsUsersList);

export default connect(mapStateToProps)(connected);
