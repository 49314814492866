import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import React from 'react';
import { Field } from 'redux-form';
import { __ } from '../../../utilities';

const COLORS = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#5283e7',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];

class ThemeForm extends React.PureComponent {
  render() {
    return (
      <Row>
        <Col>
          <Field
            label={__('Primárna farba')}
            name="theme.color.primary"
            type="color"
            component={FormField}
            colors={COLORS}
          />
        </Col>
      </Row>
    );
  }
}

export default ThemeForm;
