import PropTypes from 'prop-types';
import { appSpaceDefinition as authAppSpaceDefinition } from '../Authorization/definitions';

export const roleDefinition = PropTypes.shape({
  role: PropTypes.string.isRequired,
  name: PropTypes.string,
});

export const appInfoDefinition = PropTypes.shape({
  app_id: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  roles: PropTypes.arrayOf(roleDefinition),
});

export const appspaceDefinition = authAppSpaceDefinition;
