import Tabber from '@sportnet/ui/Tabber';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { __ } from '../../../utilities';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '@sportnet/auth-react';

export const Faded = styled.span`
  color: ${({ theme }) => theme.color.fadedText};
`;

const DetailWrapper = ({ activeTab, isFetching, children }) => {
  const history = useHistory();
  const { templateId } = useParams();
  const { ppo: appSpace } = useAuth();

  const tabItems = [__('Správa')];
  if (templateId !== 'new') {
    tabItems.push(__('Doručenky'));
  }

  return (
    <>
      <AppContext
        title={
          templateId === 'new'
            ? __('Nová správa')
            : __('Detail odoslanej správy')
        }
        backUrl={`/admin/${appSpace}/courier-templates`}
        breadcrumbs={[
          {
            url: `/admin/${appSpace}/courier-templates`,
            name: __('Kuriér'),
          },
          templateId === 'new'
            ? { name: __('Nová správa') }
            : { name: __('Detail odoslanej správy') },
        ]}
      />
      <Tabber
        active={activeTab}
        isFetching={isFetching}
        onClickItem={itemName => {
          switch (itemName) {
            case __('Správa'):
              history.push(
                `/admin/${appSpace}/courier-templates/${templateId}`,
              );
              break;
            case __('Doručenky'):
              history.push(
                `/admin/${appSpace}/courier-templates/${templateId}/messages`,
              );
              break;
            default:
              break;
          }
        }}
        items={tabItems}
      >
        {children}
      </Tabber>
    </>
  );
};

DetailWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  activeTab: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
};

DetailWrapper.defaultProps = {
  isFetching: false,
};

export default DetailWrapper;
