import { ContextBar, ContextBarItem } from '@sportnet/ui/ContextBar';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import moment from 'moment';
import PropTypes from 'prop-types';
import connectToQueryHoc, { QueryHocTypes } from '@sportnet/query-hoc';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  commit,
  getListResults,
  getListTotal,
  initialize,
  isCommiting,
  setParams,
} from '@sportnet/redux-list';
import { formatUserName, getProp } from '@sportnet/utilities';
import styled from 'styled-components';
import Api from '../../../../Api';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import { appSpaceDefinition } from '../../../Authorization/definitions';
import { activeAppSpaceSelector } from '../../../Authorization/selectors';
import { messageDefinition } from '../../definitions';
import Wrapper from '../wrapper';

const LIST_LIMIT = 100;
const LIST_NAME = 'TEMPLATES';

export const Faded = styled.span`
  color: ${({ theme }) => theme.color.fadedText};
`;

class Messages extends PureComponent {
  constructor(props) {
    super(props);

    const {
      dispatch,
      appspace: {
        org_profile: { _id: appspace },
      },
      query,
    } = props;

    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          ...query,
          appspace,
        },
      }),
    );

    this.loadList();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.serializedQuery !== nextProps.serializedQuery) {
      nextProps.dispatch(
        setParams({
          listName: LIST_NAME,
          parameters: nextProps.query,
        }),
      );
      this.loadList(nextProps);
    }
    if (
      getProp(this.props, ['acl', 'crmGetCourierTemplates']) !==
      getProp(nextProps, ['acl', 'crmGetCourierTemplates'])
    ) {
      this.setAclAccess(nextProps);
    }
  }

  loadList = (props = this.props) => {
    const {
      dispatch,
      match: {
        params: { appspace, templateId },
      },
      query,
    } = props;
    dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          const response = await Api.crmGetCourierMessagesByTemplateId(
            appspace,
            templateId,
            {
              ...query,
              limit: LIST_LIMIT,
            },
          );

          return {
            total: response.total,
            results: response.messages,
            nextOffset: response.next_offset,
          };
        },
      }),
    );
  };

  renderRow = item => (
    <Tr key={item._id}>
      <Td>{formatUserName(item.user)}</Td>
      <Td>{getProp(item, ['user', '_id'], '?')}</Td>
      <Td>
        {item.date_first_read
          ? `${__('Prečítané')} (${moment(item.date_first_read).format(
              'DD.MM.YYYY HH:mm',
            )})`
          : __('Neprečítané')}
      </Td>
    </Tr>
  );

  render() {
    const {
      isFetching,
      setParameter,
      total,
      messages,
      query: { offset },
    } = this.props;
    return (
      <Layout
        bottomFixed={
          <ContextBar>
            <ContextBarItem>
              <Paginator
                offset={offset}
                limit={LIST_LIMIT}
                total={total || 0}
                onChangeOffset={e => {
                  setParameter({
                    offset: e,
                  });
                }}
              />
            </ContextBarItem>
          </ContextBar>
        }
      >
        <Wrapper isFetching={isFetching} activeTab={__('Doručenky')}>
          <Segment>
            <Segment raised>
              <Table>
                <Thead>
                  <Tr>
                    <Th>{__('Príjemca')}</Th>
                    <Th>{__('Sportnet ID')}</Th>
                    <Th>{__('Stav')}</Th>
                  </Tr>
                </Thead>
                <Tbody>{messages.map(this.renderRow)}</Tbody>
              </Table>
            </Segment>
          </Segment>
        </Wrapper>
      </Layout>
    );
  }
}

Messages.propTypes = {
  dispatch: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(messageDefinition),
  total: PropTypes.number,
  setParameter: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      appspace: PropTypes.string,
      templateId: PropTypes.string,
    }),
  }).isRequired,
  appspace: appSpaceDefinition.isRequired,
};

Messages.defaultProps = {
  messages: [],
  total: null,
  isFetching: false,
};

const mapStateToProps = state => {
  return {
    messages: getListResults(LIST_NAME)(state) || [],
    total: getListTotal(LIST_NAME)(state),
    isFetching: isCommiting(LIST_NAME)(state),
    appspace: activeAppSpaceSelector(state),
  };
};

const connected = connectToQueryHoc({
  parameters: {
    offset: {
      type: QueryHocTypes.Number,
      defaultValue: 0,
    },
  },
})(Messages);

export default connect(mapStateToProps)(connected);
