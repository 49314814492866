import { __ } from './utilities';

export const required = value => {
  if (value || value === 0) {
    return null;
  }
  return __('Pole je povinné');
};

export const isValidUsername = value => {
  if (value.match(/^[a-z0-9_.\-@]{1,}$/g)) {
    return null;
  }
  return __('Používateľské meno obsahuje nepovolené znaky');
};

export const isValidEmail = value => {
  if (value.match(/^[^\s]+@[^\s]+\.[^\s]+$/g)) {
    return null;
  }
  return __('Email nie je v správnom formáte');
};

export const isValidPhone = value => {
  if (!value) {
    return null;
  }
  if (value.match(/^(\+|00)[0-9]+$/g)) {
    return null;
  }
  return __('Zadajte telefón v medzinárodnom formáte');
};

export const onlyNumbers = value => {
  if (!Number.isNaN(parseFloat(value)) && Number.isFinite(value)) {
    return null;
  }
  return __('Pole môže obsahovať iba čísla');
};

export const onlyIntegers = value => {
  if (Number.isInteger(Number(value))) {
    return null;
  }
  return __('Pole môže obsahovať len celé číslo.');
};

export const onlyPositiveNumbers = value => {
  if (Number(value) >= 0) {
    return null;
  }
  return __('Zadajte prosím číslo väčšie alebo rovné 0.');
};

export const onlyNumbersGreatherThanZero = value => {
  if (Number(value) > 0) {
    return null;
  }
  return __('Zadajte prosím číslo väčšie ako 0.');
};

export const isIdnr = value => {
  const isNaN = onlyNumbers(value);
  if (isNaN) {
    return isNaN;
  }
  if (value && value.length !== 10) {
    return __('Identifikačné číslo sa musí skladať z presne 10 čísel');
  }
  if (value && value % 11 !== 0) {
    return __('Identifikačné číslo musí byť deliteľné číslom 11 bezozvyšku');
  }
  return null;
};

export const validateAddress = address => {
  const data = address || {};

  const validation = {
    street: required(data.street),
    number: required(data.number),
    zip: required(data.zip),
    city: required(data.city),
    country: required(data.country),
  };

  if (Object.values(validation).some(x => x)) {
    return validation;
  }

  return null;
};
