import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Sidebar from '@sportnet/ui/Sidebar';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import {
  aclSelector,
  sportsCodelistSelector,
  pposSelector,
} from '../../../App/selectors';
import { createUserAward, editUserAward, fetchUserAwards } from '../../actions';
import {
  awardDefinition,
  matchDefinition,
  PPODefinintion,
} from '../../definitions';
import { Faded } from '../../detail';
import {
  getActiveUserAwardsSelector,
  getActiveUserIsFetchingSelector,
} from '../../selectors';
import Wrapper from '../index';
import AwardsForm from './form';
import { useParams } from 'react-router-dom';
import { useAuth } from '@sportnet/auth-react';
import config from '../../../../config';
import formatDate from '../../../../utilities/formatDate';
import AppContext from '../../../../context/AppContext';

const AwardsList = ({
  fetchUserAwards,
  acl,
  editUserAward,
  createUserAward,
  isFetching,
  awards,
  sports,
  ppos,
}) => {
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [selectedAward, setSelectedAward] = React.useState(null);
  const [updateDisabled, setUpdateDisabled] = React.useState(false);
  const [createDisabled, setCreateDisabled] = React.useState(false);

  const { ppo: appSpace } = useAuth();
  const { sportnetId } = useParams();
  const { activeAppSpace } = React.useContext(AppContext);

  const { _id: org_profile_id, organization_id } = activeAppSpace ?? {};

  const formInitialValues = React.useMemo(() => {
    if (selectedAward) {
      return selectedAward;
    } else if (org_profile_id && organization_id) {
      const ppo = (ppos ?? []).find(i => i._id === org_profile_id);
      if (ppo) {
        return {
          org_id: organization_id,
          org_profile_id,
          sport: ppo.sport,
        };
      }
    } else {
      return {
        org_id: '',
        org_profile_id: '',
        sport: '',
      };
    }
  }, [org_profile_id, organization_id, ppos, selectedAward]);

  React.useEffect(() => {
    if (!(sportnetId && appSpace)) {
      return;
    }
    fetchUserAwards(sportnetId, appSpace);
  }, [sportnetId, appSpace, fetchUserAwards]);

  React.useEffect(() => {
    if (acl) {
      setUpdateDisabled(!acl.crmUserAwardsUpdate);
      setCreateDisabled(!acl.crmUserAwardsCreate);
    }
  }, [acl]);

  const getSport = (sportIdx, sectorIdx) => {
    let sport;
    let sector;
    if (sports[sportIdx]) {
      sport = sports[sportIdx].label;
      if (sports[sportIdx].sectors && sports[sportIdx].sectors[sectorIdx]) {
        sector = sports[sportIdx].sectors[sectorIdx].label;
      } else {
        sector = '';
      }
    }
    return `${sport} / ${sector}`;
  };

  const handleSubmit = async data => {
    if (!sportnetId) {
      return;
    }
    const {
      award_type,
      issue_date,
      issued_by,
      org_profile_id,
      sport,
      sport_sector,
    } = data;
    if (data._id) {
      editUserAward(sportnetId, data._id, appSpace, {
        award_type,
        issue_date,
        issued_by,
        org_profile_id,
        sport,
        sport_sector,
      });
    } else {
      createUserAward(sportnetId, appSpace, {
        award_type,
        issue_date,
        issued_by,
        org_profile_id,
        sport,
        sport_sector,
      });
    }
    fetchUserAwards(sportnetId, appSpace);
    setSidebarVisible(false);
    setSelectedAward(null);
  };

  return (
    <Layout
      bottomFixed={
        !createDisabled && (
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                onClick={() => {
                  setSidebarVisible(true);
                  setSelectedAward(null);
                }}
                primary
                data-testid="CRM_FO_Button_Awards_New"
                disabled={selectedAward}
              >
                {__('Pridať záznam')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        )
      }
    >
      <Wrapper isFetching={isFetching} activeTab={__('Ocenenia')}>
        <Segment raised loading={isFetching}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Organizácia')}</Th>
                <Th>{__('Sektor')}</Th>
                <Th>{__('Typ')}</Th>
                <Th>{__('Udelené dňa')}</Th>
                <Th>{__('Udelil')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {awards.map(item => {
                return (
                  <Tr
                    key={item._id}
                    onClick={() => {
                      setSelectedAward(item);
                      setSidebarVisible(true);
                    }}
                  >
                    <Td>
                      {item.org_name}
                      <br />
                      <Faded>{item.org_profile_name}</Faded>
                    </Td>
                    <Td>{getSport(item.sport, item.sport_sector)}</Td>
                    <Td>{item.award_type}</Td>
                    <Td>{formatDate(item.issue_date, config.DATE_FORMAT)}</Td>
                    <Td>{item.issued_by}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Segment>
      </Wrapper>
      <Sidebar
        onClose={() => {
          setSidebarVisible(false);
          setSelectedAward(null);
        }}
        visible={sidebarVisible}
        header={__('Ocenenie')}
      >
        {sidebarVisible && (
          <AwardsForm
            onSubmit={handleSubmit}
            updateDisabled={updateDisabled}
            initialValues={formInitialValues}
            selectedAward={selectedAward}
          />
        )}
      </Sidebar>
    </Layout>
  );
};

AwardsList.propTypes = {
  match: matchDefinition.isRequired,
  sports: PropTypes.shape({}).isRequired,
  awards: PropTypes.arrayOf(awardDefinition).isRequired,
  isFetching: PropTypes.bool.isRequired,
  ppos: PropTypes.arrayOf(PPODefinintion).isRequired,
};

const mapStateToProps = state => {
  return {
    sports: sportsCodelistSelector(state),
    awards: getActiveUserAwardsSelector(state),
    isFetching: getActiveUserIsFetchingSelector(state),
    acl: aclSelector(state),
    ppos: pposSelector(state),
  };
};

const mapDispatchToProps = {
  fetchUserAwards,
  editUserAward,
  createUserAward,
};

export default connect(mapStateToProps, mapDispatchToProps)(AwardsList);
