import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Checkbox from '@sportnet/ui/Checkbox';
import Label from '@sportnet/ui/Label/Label';
import Icon from '@sportnet/ui/Icon';
import Image from '@sportnet/ui/Image';
import Popup from '@sportnet/ui/Popup';
import Segment from '@sportnet/ui/Segment';
import styled, { css } from 'styled-components';
import { mb } from '@sportnet/ui/Themes/utilities';
import PhotoEditor from '@sportnet/ui/PhotoEditor';
import { rem } from 'polished';
import PropTypes from 'prop-types';
// import React, { PureComponent } from 'react';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import Api from '../../../../Api';
import Layout from '../../../../components/Layout';
import { aclSelector, appsSelector } from '../../../App/selectors';
import {
  getActiveUserIsFetchingSelector,
  getActiveUserSelector,
} from '../../selectors';
import { updateUserProfile } from '../../actions';
import Wrapper from '../index';
import Form, { FORM_NAME } from './form';
import { withTheme } from 'styled-components';
import { compose } from 'redux';
import { theme } from '@sportnet/content/theme/theme';
import { submit } from 'redux-form';
import { formatUserName, getProp } from '@sportnet/utilities';
import { __ } from '../../../../utilities';
import moment from 'moment';

const CreatorInfo = styled(Segment)`
  font-size: ${rem(13)};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ gap = rem(8) }) => gap};
  font-weight: 500;
  align-items: center;
`;

const PhotoButton = styled.div`
  color: ${({ color = theme.color.primary }) => color};
  border: ${({ color = theme.color.primary }) => color} solid 1px;
  padding: ${rem(2)} ${rem(3)};
  border-radius: ${rem(2)};
  cursor: pointer;
  transition: all ease 0.1s;
  margin-top: ${rem(6)};
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    opacity: 0.8;
  }
`;

const Divider = styled.hr`
  border: 0;
  height: 0;
  border-top: ${rem(1)} solid rgba(0, 0, 0, 0.1);
  border-bottom: ${rem(1)} solid rgba(255, 255, 255, 0.3);
  width: 100%;
`;

const Photo = styled.div`
  gap: ${rem(8)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    opacity: 1;
    transition: opacity ease 0.5s;
    position: relative;
  }

  ${({ isPublic }) =>
    !isPublic &&
    css`
      img {
        opacity: 0.2;
      }
    `}
`;

const FormWithPhoto = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  margin-bottom: 0;
  ${mb('l')} {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: ${rem(16)};
  }
`;

const PhotoWrapper = styled.div`
  gap: ${rem(8)};
  flex-grow: 1;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: ${rem(12)};
  margin-bottom: ${rem(16)};
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0;
  border: none;
  & hr {
    display: block;
  }
  ${mb('s')} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    padding-bottom: ${rem(16)};
    border-bottom: ${rem(1)} solid rgba(0, 0, 0, 0.1);

    & hr {
      display: none;
    }
  }

  ${mb('l')} {
    flex-direction: column;
    align-items: center;
    padding: 0;
    border: none;
    margin-right: ${rem(16)};
    margin-bottom: ${rem(0)};
    max-width: 200px;

    & hr {
      display: block;
    }
  }
`;

const RowWrapper = styled.div`
  display: flex;
  gap: ${({ gap = rem(8) }) => gap};
`;

const FileInput = styled.input`
  display: none;
`;

const NoPhoto = styled(Icon)`
  text-align: center;
`;

const FormWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  ${mb('l')} {
    width: 70%;
  }
`;

const BasicText = styled('div')`
  color: ${({ color = theme.textColor }) => color};
  font-size: ${({ size = rem(12) }) => size};
`;

const mapStateToProps = state => {
  return {
    user: getActiveUserSelector(state),
    isFetching: getActiveUserIsFetchingSelector(state),
    acl: aclSelector(state),
    apps: appsSelector(state),
  };
};

const ProfileDetail = ({ apps, user, isFetching, acl, theme, dispatch }) => {
  const fileInput = React.useRef(null);

  const [creator, setCreator] = React.useState('');

  const [photoEditorOpened, setPhotoEditorOpened] = React.useState();
  const [workingFile, setWorkingFile] = React.useState();
  const [formalPhotoSrc, setFormalPhotoSrc] = React.useState();
  const [deleteFormalPhoto, setDeleteFormalPhoto] = React.useState(false);
  const [isFormalPhotoPublic, setFormalPhotoPublic] = React.useState();
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [onUpload, setOnUpload] = React.useState();

  const { appspace, sportnetId } = useParams();

  React.useEffect(() => {
    setFormalPhotoPublic(user?.formalPhoto?.public);
  }, [user]);

  const fetchCreator = React.useCallback(async creatorId => {
    try {
      const creatorSportnetUser = await Api.getPublicUserProfile(creatorId);
      setCreator(creatorSportnetUser);
    } catch (e) {
      console.error(e);
    }
  }, []);

  React.useEffect(() => {
    if (user?._createdBySportnetId) {
      fetchCreator(user?._createdBySportnetId);
    }
  }, [user?._createdBySportnetId, fetchCreator]);

  const getAppLabel = appId => {
    const app = apps.find(i => i._id === appId);
    return app ? app.name : __('<neznáma aplikácia>');
  };

  const handleSubmit = async data => {
    const {
      birthcountry,
      birthdate,
      citizenship,
      deathdate,
      email = '',
      family_name,
      idnr,
      name,
      nationality,
      phone,
      sex,
      surname,
      titles,
      formalPhotoSrc,
      isFormalPhotoPublic,
    } = data;

    setSubmitting(true);

    if (deleteFormalPhoto) {
      await Api.crmUserFormalPhotoDelete(sportnetId, appspace).catch(r => {
        console.error(r);
      });
      user.formalPhoto = undefined;
    }

    if (formalPhotoSrc) {
      await Api.crmUserFormalPhotoUpload(sportnetId, appspace, {
        orgProfileId: appspace,
        upload: onUpload,
        public: isFormalPhotoPublic,
      }).catch(err => {
        console.error(err);
        setSubmitting(false);
      });
    }

    const updateFormalPhotoPublicState =
      isFormalPhotoPublic !== user.formalPhoto?.public;

    await dispatch(
      updateUserProfile(sportnetId, appspace, {
        birthcountry,
        birthdate,
        citizenship,
        deathdate,
        family_name,
        idnr,
        name,
        nationality,
        phone,
        sex,
        surname,
        titles: {
          before: getProp(titles, ['before']),
          after: getProp(titles, ['after']),
        },
        ...(updateFormalPhotoPublicState
          ? { formalPhoto: { public: isFormalPhotoPublic } }
          : {}),
        ...(email ? { email } : {}),
      }),
    ).catch(err => {
      console.error(err);
    });
    setSubmitting(false);
  };

  const handleClickEndAllRelations = async () => {
    if (
      window.confirm(
        __('Táto operácia je nenávratná. Naozaj chcete pokračovať?'),
      )
    ) {
      await Api.crmEndUserRelation(sportnetId, appspace);
    }
  };

  const handleFileInputClick = () => {
    if (fileInput.current) {
      fileInput.current.value = '';
      fileInput.current.click();
    }
  };

  const handleDeletePhoto = async () => {
    if (formalPhotoSrc) {
      setFormalPhotoSrc(undefined);
    }

    setDeleteFormalPhoto(true);
  };

  const handleRevertPhoto = async () => {
    setFormalPhotoSrc(undefined);
    setDeleteFormalPhoto(false);
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    setWorkingFile(URL.createObjectURL(file));
    setPhotoEditorOpened(true);
  };

  const handlePhotoEditorClose = () => {
    setPhotoEditorOpened(false);
  };

  const handlePhotoEditorSubmit = async (data, blobPromise) => {
    const blob = await blobPromise;
    setDeleteFormalPhoto(false);
    setOnUpload(blob);
    setFormalPhotoSrc(URL.createObjectURL(blob));
    setPhotoEditorOpened(false);
  };

  // return <>{console.log(console.log(initialUser))}</>;
  return (
    <Layout
      bottomFixed={
        acl.crmUpdateUserProfile && (
          <ContextBar>
            <ContextBarSpacer />
            {acl.crmEndUserRelation && (
              <ContextBarItem>
                <Button
                  danger
                  onClick={handleClickEndAllRelations}
                  disabled={!acl.crmUpdateUserProfile}
                >
                  {__('Ukončiť všetky príslušnosti')}
                </Button>
              </ContextBarItem>
            )}
            <ContextBarItem>
              <Button
                primary
                onClick={() => {
                  dispatch(submit(FORM_NAME));
                }}
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {__('Uložiť')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        )
      }
    >
      <Wrapper activeTab={__('Profil')} isFetching={isFetching}>
        {photoEditorOpened && (
          <PhotoEditor
            src={workingFile}
            onClose={handlePhotoEditorClose}
            onSubmit={handlePhotoEditorSubmit}
            availableAspectRatios={['1:1']}
          />
        )}
        <Segment raised loading={isFetching}>
          <FormWithPhoto>
            <PhotoWrapper>
              <React.Fragment>
                {user && (
                  <ColumnWrapper>
                    <ColumnWrapper gap={0}>
                      <BasicText>{__('Formálna fotka')}</BasicText>
                      {!formalPhotoSrc && !user.formalPhoto && (
                        <BasicText
                          size={'smaller'}
                          color={theme.color.fadedText}
                        >
                          {__('(Bez fotky)')}
                        </BasicText>
                      )}
                    </ColumnWrapper>
                    <Photo isPublic={isFormalPhotoPublic}>
                      {formalPhotoSrc ||
                      (!!user.formalPhoto?.public_url && !deleteFormalPhoto) ? (
                        <>
                          <Image
                            src={formalPhotoSrc ?? user.formalPhoto.public_url}
                            width={200}
                          />
                          <Label htmlFor="public-formal-photo">
                            <Checkbox
                              id="public-formal-photo"
                              checked={isFormalPhotoPublic}
                              onChange={() => {
                                setFormalPhotoPublic(!isFormalPhotoPublic);
                              }}
                            />
                            {__('Fotka je verejná')}
                          </Label>
                        </>
                      ) : (
                        <NoPhoto
                          name="person-outline"
                          size="xxl"
                          color="gray"
                        />
                      )}

                      <RowWrapper>
                        <PhotoButton onClick={handleFileInputClick}>
                          {__('Nahrať')}
                        </PhotoButton>
                        {(formalPhotoSrc || user.formalPhoto?.public_url) && (
                          <>
                            {!deleteFormalPhoto && (
                              <PhotoButton
                                onClick={handleDeletePhoto}
                                color={theme.color.danger}
                              >
                                {__('Zmazať')}
                              </PhotoButton>
                            )}
                            {user.formalPhoto?.public_url &&
                              deleteFormalPhoto && (
                                <PhotoButton
                                  onClick={handleRevertPhoto}
                                  color={'black'}
                                >
                                  <Icon name="return"></Icon>
                                  {__('Vrátiť pôvodnú')}
                                </PhotoButton>
                              )}
                          </>
                        )}
                      </RowWrapper>
                    </Photo>
                  </ColumnWrapper>
                )}
                <Divider />
                {user && (
                  <ColumnWrapper>
                    <ColumnWrapper gap={0}>
                      <BasicText>{__('Osobná fotka')}</BasicText>
                      {!user.photo && (
                        <BasicText
                          size={'smaller'}
                          color={theme.color.fadedText}
                        >
                          {__('(Bez fotky)')}
                        </BasicText>
                      )}
                    </ColumnWrapper>
                    <Photo isPublic={user.photo && user.photo.public}>
                      {user.photo ? (
                        <Image src={user.photo.public_url} width={110} />
                      ) : (
                        <NoPhoto
                          name="person-outline"
                          size="xxl"
                          color="lightGray"
                        />
                      )}
                    </Photo>
                  </ColumnWrapper>
                )}
                <FileInput
                  ref={fileInput}
                  type="file"
                  accept="image/jpeg, image/png, image/gif"
                  onChange={handleFileChange}
                />
              </React.Fragment>
            </PhotoWrapper>
            <FormWrapper>
              <Form
                onSubmit={handleSubmit}
                data={{ ...user, formalPhotoSrc, isFormalPhotoPublic }}
              />
            </FormWrapper>
          </FormWithPhoto>
        </Segment>
        {!!user && !!user._createdByAppId && (
          <CreatorInfo raised>
            {__('Používateľ bol manuálne vytvorený')}{' '}
            <i>{moment(user.created_datetime).format('DD.MM.YYYY o HH:mm')}</i>{' '}
            {__('prostredníctvom aplikácie')}{' '}
            <b>{getAppLabel(user._createdByAppId)}</b>{' '}
            {!!creator && (
              <>
                {__('používateľom')} <b>{formatUserName(creator)}</b> (
                <i>{creator._id}</i>)
              </>
            )}
            .
          </CreatorInfo>
        )}
      </Wrapper>
    </Layout>
  );
};

ProfileDetail.propTypes = {
  theme: PropTypes.shape({
    color: PropTypes.shape({
      success: PropTypes.string.isRequired,
      danger: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const component = Popup.withPopups()(ProfileDetail);

export default compose(
  connect(mapStateToProps),
  withTheme,
  withRouter,
)(component);
