import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import Sidebar from '@sportnet/ui/Sidebar';
import { Table, Tbody, Th, Thead, Tr } from '@sportnet/ui/Table';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import Api from '../../../../Api';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import { appspaceDefinition } from '../../../App/definitions';
import {
  aclSelector,
  appspaceSelector,
  pposSelector,
} from '../../../App/selectors';
import {
  createUserAthlete,
  createUserExpert,
  editUserAthlete,
  editUserExpert,
  fetchUserAthletes,
  fetchUserExperts,
  fetchUserLicences,
} from '../../actions';
import { PPODefinintion } from '../../definitions';
import {
  getActiveIdSelector,
  getActiveUserIsFetchingSelector,
} from '../../selectors';
import AthletesList from '../athletes/list';
import ExpertsList from '../experts/list';
import Wrapper from '../index';
import AffiliationForm from './form';
import { useAuth } from '@sportnet/auth-react';
import { useParams } from 'react-router-dom';

const AffiliationsList = ({
  acl,
  activeUserId,
  isFetching,
  editUserAthlete,
  editUserExpert,
  createUserExpert,
  createUserAthlete,
  fetchUserAthletes,
  fetchUserExperts,
  fetchUserLicences,
  ppos,
}) => {
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [isOwner, setIsOwner] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [updateDisabled, setUpdateDisabled] = React.useState(true);
  const [createDisabled, setCreateDisabled] = React.useState(true);
  const [deleteDisabled, setDeletDisabled] = React.useState(true);

  const { ppo: appSpace } = useAuth();
  const { sportnetId } = useParams();

  React.useEffect(() => {
    if (!(appSpace && sportnetId)) {
      return;
    }
    fetchUserAthletes(sportnetId, appSpace);
    fetchUserExperts(sportnetId, appSpace);
    fetchUserLicences(sportnetId, appSpace);
  }, [
    sportnetId,
    appSpace,
    fetchUserAthletes,
    fetchUserExperts,
    fetchUserLicences,
  ]);

  React.useEffect(() => {
    if (acl) {
      setUpdateDisabled(
        !acl.crmUserSportOrgUpdate || !acl.crmUserSportExpertOrgUpdate,
      );
      setCreateDisabled(
        !acl.crmUserRegisterSportOrg || !acl.crmUserRegisterSportExpertOrg,
      );
      setDeletDisabled(
        !acl.crmUserSportOrgDelete || !acl.crmUserSportExpertOrgDelete,
      );
    }
  }, [acl]);

  const onClickRow = (rowData, rowCategory) => {
    setSidebarVisible(true);
    setIsOwner(getIsOwner(rowData));
    setData({
      ...rowData,
      category: rowCategory,
    });
  };

  const getIsOwner = data => {
    let isOwner = data && data.org_profile_id === appSpace;

    if (!isOwner && data) {
      const profile = ppos.find(i => i._id === data.org_profile_id);
      if (profile) {
        isOwner = true;
      }
    }
    return isOwner;
  };

  const sectorId2Sector = _id => {
    const [category, itemId, sectorId] = _id.split(':');
    return {
      _id,
      category,
      itemId,
      sectorId,
    };
  };

  const submit = async data => {
    const { category, ...formData } = data;
    if (category === 'sport_orgs' && data._id) {
      const { date_from, date_to, transfer_type, competence_type, status } =
        formData;
      editUserAthlete(activeUserId, formData._id, appSpace, {
        date_to: date_to ? new Date(date_to).toJSON() : date_to,
        status,
        date_from: date_from ? new Date(date_from).toJSON() : date_from,
        transfer_type,
        competence_type,
      });
    } else if (category === 'sport_orgs' && !formData._id) {
      const { sector: sectorId, ...data } = formData;
      createUserAthlete(activeUserId, appSpace, {
        ...data,
        sector: sectorId2Sector(sectorId),
      });
    }
    if (category === 'sport_expert_orgs' && formData._id) {
      const { date_from, date_to, legal_form, license_id, competence_type } =
        formData;
      editUserExpert(activeUserId, formData._id, appSpace, {
        date_to: date_to ? new Date(date_to).toJSON() : date_to,
        date_from: date_from ? new Date(date_from).toJSON() : date_from,
        legal_form,
        license_id,
        competence_type,
      });
    } else if (category === 'sport_expert_orgs' && !formData._id) {
      const { sector: sectorId, ...data } = formData;
      createUserExpert(activeUserId, appSpace, {
        ...data,
        sector: sectorId2Sector(sectorId),
      });
    }
    setSidebarVisible(false);
    setData(null);
  };

  const deleteAffiliationForm = async data => {
    if (
      window.confirm(
        __('Prajete si odstrániť príslušnosť? Jedná sa o nezvratnú operáciu'),
      )
    ) {
      try {
        setIsDeleting(true);
        if (data.category === 'sport_orgs') {
          await Api.crmUserSportOrgDelete(sportnetId, data._id, appSpace);
          fetchUserAthletes(sportnetId, appSpace);
        } else if (data.category === 'sport_expert_orgs') {
          await Api.crmUserSportExpertOrgDelete(sportnetId, data._id, appSpace);
          fetchUserExperts(sportnetId, appSpace);
          fetchUserLicences(sportnetId, appSpace);
        }
        setSidebarVisible(false);
        setData(null);
      } catch (e) {
        alert(
          e.details
            ? e.details.userinfo ||
                __('Pri odstraňovaní príslušnosti nastala chyba')
            : e,
        );
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <Layout
      bottomFixed={
        !createDisabled && (
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                onClick={() => {
                  setSidebarVisible(true);
                }}
                primary
                data-testid="CRM_FO_Button_Affiliations_New"
              >
                {__('Pridať záznam')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        )
      }
    >
      <Wrapper isFetching={isFetching} activeTab={__('Príslušnosti')}>
        <Segment
          raised
          loading={isFetching}
          header={
            <SegmentHeader size="s" collapsible>
              {__('Športovec')}
            </SegmentHeader>
          }
        >
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Organizácia')}</Th>
                <Th>{__('Sektor')}</Th>
                <Th>{__('Typ')}</Th>
                <Th>{__('Dátum od - do')}</Th>
                <Th>{__('Členstvo do')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              <AthletesList
                onClickRow={rowData => {
                  onClickRow(rowData, 'sport_orgs');
                }}
                updateDisabled={updateDisabled}
              />
            </Tbody>
          </Table>
        </Segment>
        <Segment
          raised
          loading={isFetching}
          header={
            <SegmentHeader size="s" collapsible>
              {__('Športový odborník')}
            </SegmentHeader>
          }
        >
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Organizácia')}</Th>
                <Th>{__('Sektor')}</Th>
                <Th>{__('Typ')}</Th>
                <Th>{__('Dátum od - do')}</Th>
                <Th>{__('Členstvo do')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              <ExpertsList
                onClickRow={rowData => {
                  onClickRow(rowData, 'sport_expert_orgs');
                }}
                updateDisabled={updateDisabled}
              />
            </Tbody>
          </Table>
        </Segment>
      </Wrapper>
      <Sidebar
        onClose={() => {
          setSidebarVisible(false);
          setData(null);
        }}
        visible={sidebarVisible}
        header={__('Príslušnosti')}
      >
        <AffiliationForm
          isOwner={isOwner}
          onSubmit={submit}
          onDelete={deleteAffiliationForm}
          isDeleting={isDeleting}
          deleteDisabled={deleteDisabled}
          data={data}
        />
      </Sidebar>
    </Layout>
  );
};

AffiliationsList.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  ppos: PropTypes.arrayOf(PPODefinintion).isRequired,
  appspace: appspaceDefinition.isRequired,
};

const mapStateToProps = state => ({
  isFetching: getActiveUserIsFetchingSelector(state),
  acl: aclSelector(state),
  appspace: appspaceSelector(state),
  activeUserId: getActiveIdSelector(state),
  ppos: pposSelector(state),
});

const mapDispatchToProps = {
  fetchUserAthletes,
  fetchUserExperts,
  fetchUserLicences,
  editUserAthlete,
  createUserAthlete,
  editUserExpert,
  createUserExpert,
};

export default connect(mapStateToProps, mapDispatchToProps)(AffiliationsList);
