import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import Text from '@sportnet/ui/Text';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getStatusIcon, useCodelist, __ } from '../../../../utilities';
import { applicationRolesCodelistSelector } from '../../../App/selectors';
import {
  bankAccountDefinition,
  codelistDefinition,
} from '../../../FO/definitions';

const SecondaryText = styled(Text)`
  font-size: ${rem(13)};
`;

const Table = ({ items, roles, onDelete, isDeleteDisabled }) => {
  const [getRoleLabel] = useCodelist(roles);

  const columns = [
    {
      id: 'displayName',
      header: __('Meno a priezvisko'),
    },
    {
      id: 'role',
      header: __('Rola'),
    },
    {
      id: 'grant',
      header: __('grant'),
      width: 70,
    },
  ];

  if (!isDeleteDisabled) {
    columns.push({
      id: 'delete',
      header: '',
      width: 65,
    });
  }

  const renderRoles = item => {
    const { role, child_apps_role: subRoles } = item;
    return (
      <React.Fragment key="role">
        {getRoleLabel(role)}
        {subRoles && !!subRoles.length && (
          <Text faded>
            {` (${subRoles.map(r => `${r.app_id}: ${r.role}`).join(', ')})`}
          </Text>
        )}
      </React.Fragment>
    );
  };

  const renderRow = item => {
    const rows = [
      <React.Fragment key="displayName">
        {item.display_name}
        <br />
        <SecondaryText faded>{item.user_id}</SecondaryText>
      </React.Fragment>,
      renderRoles(item),
      getStatusIcon(item.grant ? 'active' : 'inactive'),
    ];
    if (!isDeleteDisabled) {
      rows.push(
        <Button
          danger
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onDelete(item);
          }}
        >
          {__('Zmazať')}
        </Button>,
      );
    }
    return rows;
  };

  return (
    <BasicTable
      columns={columns}
      rows={items}
      renderRow={renderRow}
      rowKey="user_id"
    />
  );
};

const mapStateToProps = state => {
  return {
    roles: applicationRolesCodelistSelector(state),
  };
};

Table.propTypes = {
  onDelete: PropTypes.func.isRequired,
  isDeleteDisabled: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(bankAccountDefinition).isRequired,
  roles: PropTypes.arrayOf(codelistDefinition).isRequired,
};

export default connect(mapStateToProps)(Table);
