import { RECEIVE_GROUP_USERS_SUCCESS } from './constants';

export const GroupUsersReducer = (
  state = {
    users: {},
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_GROUP_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users.reduce((acc, next) => {
          return { ...acc, [next._id]: next };
        }, {}),
      };
    default:
      return state;
  }
};
