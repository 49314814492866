import Api from '../../Api';
import {
  RECEIVE_INVOICEITEMS_FAILURE,
  RECEIVE_INVOICEITEMS_SUCCESS,
  REQUEST_INVOICEITEMS,
} from './contants';

const requestInvoiceItems = () => ({
  type: REQUEST_INVOICEITEMS,
});

const receiveInvoiceItemsSuccess = invoices => {
  return {
    type: RECEIVE_INVOICEITEMS_SUCCESS,
    payload: {
      invoices,
    },
  };
};

const receiveInvoiceItemsFailure = error => ({
  type: RECEIVE_INVOICEITEMS_FAILURE,
  payload: {
    error,
  },
});

export const getInvoiceItems = (appSpace, q, buyer) => {
  return async dispatch => {
    try {
      dispatch(requestInvoiceItems());
      const f = { q };
      if (buyer) {
        f.buyer = buyer;
      }
      const res = await Api.getOpenedInvoiceItemsByAppspace(appSpace, f);
      dispatch(receiveInvoiceItemsSuccess(res.invoices));
      return res;
    } catch (e) {
      dispatch(receiveInvoiceItemsFailure(e.description || e));
    }
  };
};
