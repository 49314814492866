import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import NotFound from '@sportnet/ui/NotFound';
import Segment from '@sportnet/ui/Segment';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Api from '../../../../Api';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import { appspaceDefinition } from '../../../App/definitions';
import { appspaceSelector } from '../../../App/selectors';
import { matchDefinition, PODefinition } from '../../definitions';
import {
  orgEntitySelector,
  orgIsErrorSelector,
  orgIsFetchingSelector,
} from '../selectors';
import Tabber, { TABS } from '../Tabber';
import InviteManagerModal from './InviteCRMUserModal';
import Table from './Table';

const Users = ({
  history,
  didFailed,
  org,
  match: {
    params: { orgProfileId, appspace },
  },
  isFetching,
  activeAppSpace,
}) => {
  const [users, setUsers] = React.useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = React.useState(false);
  const [loadingError, setLoadingError] = React.useState(null);
  const [invitationOpened, setInvitationOpened] = React.useState(false);

  const loadUsers = async (appId, appSpace) => {
    setIsLoadingUsers(true);
    setLoadingError(null);
    try {
      const res = await Api.appgrantGetRoles(appId, appSpace);
      setUsers(res.users);
    } catch (e) {
      setLoadingError(e);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  React.useEffect(() => {
    loadUsers(process.env.REACT_APP_APP_ID, orgProfileId);
  }, [orgProfileId]);

  const handleDeleteAppSpaceUser = async appSpaceUser => {
    // eslint-disable-next-line no-alert
    if (!window.confirm(__('Naozaj chcete odstrániť tohto používateľa?'))) {
      return;
    }
    try {
      await Api.appgrantDeleteRoleForUser(
        appSpaceUser.app_id,
        appSpaceUser.app_space,
        appSpaceUser.user_id,
      );
      await loadUsers(process.env.REACT_APP_APP_ID, orgProfileId);
    } catch (e) {
      // eslint-disable-next-line no-alert
      window.alert(__('Vymazanie používateľa sa nepodarilo'));
    }
  };

  const shouldShowLoader =
    (isFetching && !org) || (isLoadingUsers && !users.length);

  return (
    <Layout
      bottomFixed={
        (activeAppSpace.grant || true) && (
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                primary
                onClick={() => {
                  setInvitationOpened(true);
                }}
              >
                {__('Pozvať používateľa')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        )
      }
    >
      <AppContext
        title={org && org.full_name}
        backUrl={`/admin/${appspace}/organizations`}
        breadcrumbs={[
          {
            name: __('Späť na zoznam'),
            url: `/admin/${appspace}/organizations`,
          },
        ]}
      />
      <Tabber activeTab={TABS.users} />
      <Segment noBottomGutter>
        <Segment noBottomGutter raised loading={shouldShowLoader}>
          {loadingError || didFailed ? (
            <NotFound
              title={__('Detail organizácie nie je možné načítať.')}
              icon="error"
              actionLabel={__('Späť na zoznam')}
              onClickAction={() => {
                history.push(`/admin/${appspace}/organizations`);
              }}
            />
          ) : (
            <Table
              items={users}
              onDelete={handleDeleteAppSpaceUser}
              isDeleteDisabled={!activeAppSpace.grant}
            />
          )}
        </Segment>
      </Segment>
      <InviteManagerModal
        orgProfileId={orgProfileId}
        isOpen={invitationOpened}
        onClose={() => {
          setInvitationOpened(false);
        }}
      />
    </Layout>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { organizationId },
    },
  },
) => ({
  org: orgEntitySelector(state, organizationId),
  didFailed: orgIsErrorSelector(state, organizationId),
  isFetching: orgIsFetchingSelector(state, organizationId),
  activeAppSpace: appspaceSelector(state),
});

Users.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: matchDefinition.isRequired,
  didFailed: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  org: PODefinition,
  activeAppSpace: appspaceDefinition.isRequired,
};

Users.defaultProps = {
  org: null,
};

export default connect(mapStateToProps)(Users);
