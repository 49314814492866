import { commit, CommitError, getListParameters } from '@sportnet/redux-list';
import API from '../../Api';
import { getProp, __ } from '../../utilities';
import { appspaceSelector } from '../App/selectors';
import {
  RECEIVE_CUSTOM_FIELDS_SUCCESS,
  RECEIVE_USERS_FAILURE,
  RECEIVE_USERS_SUCCESS,
  RECEIVE_USER_ADDRESSES_SUCCESS,
  RECEIVE_USER_ATHLETES_SUCCESS,
  RECEIVE_USER_AWARDS_SUCCESS,
  RECEIVE_USER_BANK_ACCOUNTS_SUCCESS,
  RECEIVE_USER_BIOS_SUCCESS,
  RECEIVE_USER_EXPERTS_SUCCESS,
  RECEIVE_USER_FAILURE,
  RECEIVE_USER_FORMAL_PHOTOS_SUCCESS,
  RECEIVE_USER_LICENCES_SUCCESS,
  RECEIVE_USER_MEDICAL_EXAMINATIONS_SUCCESS,
  RECEIVE_USER_MEMBERSHIPS_SUCCESS,
  RECEIVE_USER_REGNRS_SUCCESS,
  RECEIVE_USER_SUCCESS,
  REQUEST_USER,
  REQUEST_USERS,
  SET_ACTIVE_USER,
  SET_SEARCH_FILTER,
  SET_SEARCH_QUERY,
  USERS_LIST,
  REQUEST_USER_MEDICAL_EXAMINATIONS,
  RECEIVE_USER_MEDICAL_EXAMINATIONS_FAILURE,
} from './constants';

const requestUsers = () => ({
  type: REQUEST_USERS,
});

const receiveUsersSuccess = users => ({
  type: RECEIVE_USERS_SUCCESS,
  payload: {
    users,
  },
});

const receiveUsersFailure = error => ({
  type: RECEIVE_USERS_FAILURE,
  payload: {
    error: error.description || error,
  },
});

export const getUsersListParams2Filter = params => {
  const f = {
    offset: params.offset,
    limit: params.limit,
  };
  if (params.query) {
    f.q = params.query;
  }
  if (getProp(params, ['athlet_type'], []).length > 0) {
    f.athletType = params.athlet_type;
  }
  if (getProp(params, ['sport_expert_type'], []).length > 0) {
    f.sportExpertType = params.sport_expert_type;
  }
  if (getProp(params, ['license_type'], []).length > 0) {
    f.licenseType = params.license_type;
  }
  if (params.membershipValidTo) {
    f.membershipValidTo = params.membershipValidTo;
  }
  if (params.membershipToPPO) {
    // NOTE: check PPO / Ppo
    f.membershipToPpo = params.membershipToPPO;
  }
  if (params.membershipValidToIsInvalid) {
    f.membershipValidToIsInvalid = params.membershipValidToIsInvalid;
  }
  if (params.sorter) {
    f.sorter = params.sorter;
  }
  // doplnime memberships
  f.with = params.with || 'membership,regnrs';
  return f;
};

export const getUsers = (appspace, filter) => {
  return async dispatch => {
    dispatch(requestUsers());
    try {
      const f = getUsersListParams2Filter(filter);
      let response = await API.crmGetUsers(appspace, f);
      if (response.total < response.offset) {
        f.offset = 0;
        response = await API.crmGetUsers(appspace, f);
      }
      dispatch(receiveUsersSuccess(response.users));
      return {
        results: response.users.map(i => i._id),
        total: response.total,
      };
    } catch (e) {
      dispatch(receiveUsersFailure(e.details || e));
      throw e;
    }
  };
};

export const loadUsersList = () => {
  return (dispatch, getState) => {
    const params = getListParameters(USERS_LIST)(getState());
    const appSpaceId = appspaceSelector(getState()).app_space;
    return dispatch(
      commit.action({
        listName: USERS_LIST,
        load: async () => {
          try {
            const { total, results } = await dispatch(
              getUsers(appSpaceId, params),
            );
            return {
              total,
              results,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const setSearchQuery = query => ({
  type: SET_SEARCH_QUERY,
  payload: {
    query,
  },
});

export const setSearchFilter = (type, values) => ({
  type: SET_SEARCH_FILTER,
  payload: {
    type,
    values,
  },
});

export const setActiveFO = id => ({
  type: SET_ACTIVE_USER,
  payload: {
    id,
  },
});

const requestUser = id => ({
  type: REQUEST_USER,
  payload: {
    id,
  },
});

const receiveUserSuccess = (id, data) => ({
  type: RECEIVE_USER_SUCCESS,
  payload: {
    id,
    data,
  },
});

const receiveUserFailure = (id, error) => ({
  type: RECEIVE_USER_FAILURE,
  payload: {
    id,
    error: error.description || error,
  },
});

export const fetchActiveUser = (appspace, id) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      const response = await API.crmGetUser(id, appspace);
      dispatch(receiveUserSuccess(id, response));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

const receiveUserAddressesSuccess = (id, data) => ({
  type: RECEIVE_USER_ADDRESSES_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const fetchUserAddresses = (id, appspace) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      const response = await API.crmUserAddresses(id, appspace);
      dispatch(receiveUserAddressesSuccess(id, response.items));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

const receiveUserBankAccountsSuccess = (id, data) => ({
  type: RECEIVE_USER_BANK_ACCOUNTS_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const fetchUserBankAccounts = (id, appspace) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      const response = await API.crmUserBankAccounts(id, appspace);
      dispatch(receiveUserBankAccountsSuccess(id, response.items));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

const receiveUserAthletesSuccess = (id, data) => ({
  type: RECEIVE_USER_ATHLETES_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const fetchUserAthletes = (id, appspace) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      const response = await API.crmUserSportOrgs(id, appspace);
      dispatch(receiveUserAthletesSuccess(id, response.items));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

const receiveUserExpertsSuccess = (id, data) => ({
  type: RECEIVE_USER_EXPERTS_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const fetchUserExperts = (id, appspace) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      const response = await API.crmUserSportExpertOrgs(id, appspace);
      dispatch(receiveUserExpertsSuccess(id, response.items));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

const receiveUserAwardsSuccess = (id, data) => ({
  type: RECEIVE_USER_AWARDS_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const fetchUserAwards = (id, appspace) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      const response = await API.crmUserAwards(id, appspace);
      dispatch(receiveUserAwardsSuccess(id, response.items));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

const receiveUserLicencesSuccess = (id, data) => ({
  type: RECEIVE_USER_LICENCES_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const fetchUserLicences = (id, appspace) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      const response = await API.crmUserLicenses(id, appspace);
      dispatch(receiveUserLicencesSuccess(id, response.items));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

export const createUserAthlete = (id, appspace, data) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      await API.crmUserRegisterSportOrg(id, appspace, {
        data,
      });
      await dispatch(fetchUserAthletes(id, appspace));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

export const editUserAthlete = (id, itemId, appspace, data) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      await API.crmUserSportOrgUpdate(id, itemId, appspace, {
        data,
      });
      await dispatch(fetchUserAthletes(id, appspace));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

export const createUserExpert = (id, appspace, data) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      await API.crmUserRegisterSportExpertOrg(id, appspace, {
        data,
      });
      await dispatch(fetchUserExperts(id, appspace));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

export const editUserExpert = (id, itemId, appspace, data) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      await API.crmUserSportExpertOrgUpdate(id, itemId, appspace, {
        data,
      });
      await dispatch(fetchUserExperts(id, appspace));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

export const createUserAward = (id, appspace, data) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      await API.crmUserAwardsCreate(id, appspace, {
        data,
      });
      await dispatch(fetchUserAwards(id, appspace));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

export const editUserAward = (id, itemId, appspace, data) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      await API.crmUserAwardsUpdate(id, itemId, appspace, {
        data,
      });
      await dispatch(fetchUserAwards(id, appspace));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

export const createUserLicence = (id, appspace, data) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      const response = await API.crmUserLicenseCreate(id, appspace, {
        data,
      });
      await dispatch(fetchUserLicences(id, appspace));
      return response;
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
    return null;
  };
};

export const editUserLicence = (id, itemId, appspace, data) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      await API.crmUserLicenseUpdate(id, itemId, appspace, {
        data,
      });
      await dispatch(fetchUserLicences(id, appspace));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

export const uploadUserLicenseFiles = (id, itemId, appspace, files) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      await Promise.all(
        files.map(file => {
          return API.crmUserLicenseFileUpload(id, itemId, appspace, {
            upload: file,
          });
        }),
      );
      await dispatch(fetchUserLicences(id, appspace));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

export const deleteUserLicenseFiles = (id, itemId, appspace, fileIds) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      await Promise.all(
        fileIds.map(fileId => {
          return API.crmUserLicenseFileDelete(id, itemId, appspace, fileId);
        }),
      );
      await dispatch(fetchUserLicences(id, appspace));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};

export const licenseFileClick = (userId, itemId, appspace, fileId) => {
  return async dispatch => {
    try {
      const response = await API.crmUserLicenseFileGet(
        userId,
        itemId,
        appspace,
        fileId,
      );
      window.location.href = response.url;
    } catch (e) {
      console.error(e.details || e);
      dispatch(receiveUserFailure(userId, e.details || e));
    }
  };
};

export const updateUserProfile = (id, appspace, data) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      const res = await API.crmUpdateUserProfile(id, appspace, { data });
      dispatch(receiveUserSuccess(id, res));
    } catch (e) {
      let error = e.details || e;
      if (getProp(e, ['details', 'name']) === 'DUPLICATED_IDNR') {
        error = __('Osoba s daným rodným číslom už existuje.');
      } else if (getProp(e, ['details', 'name']) === 'INVALID_IDNR') {
        error = __(
          'Zadané identifikačné číslo nevyhovuje požiadavkam pre identifikačné číslo užívateľa slovenskej štátnej príslušnosti.',
        );
      }
      dispatch(receiveUserFailure(id, error));
    }
  };
};

export const createAddress = (appspace, userId, data) => {
  return async dispatch => {
    try {
      await API.crmUserAddressesCreate(userId, appspace, { data });
      dispatch(fetchUserAddresses(userId, appspace));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
      throw e;
    }
  };
};
export const editAddress = (appspace, userId, addressId, data) => {
  return async dispatch => {
    try {
      await API.crmUserAddressesUpdate(userId, addressId, appspace, { data });
      dispatch(fetchUserAddresses(userId, appspace));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
      throw e;
    }
  };
};

export const createBankAccount = (appspace, userId, data) => {
  return async dispatch => {
    try {
      await API.crmUserBankAccountsCreate(userId, appspace, { data });
      dispatch(fetchUserBankAccounts(userId, appspace));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
    }
  };
};
export const editBankAccount = (appspace, userId, addressId, data) => {
  return async dispatch => {
    try {
      await API.crmUserBankAccountsUpdate(userId, addressId, appspace, {
        data,
      });
      dispatch(fetchUserBankAccounts(userId, appspace));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
    }
  };
};

const receiveCustomFieldsSuccess = (id, data) => ({
  type: RECEIVE_CUSTOM_FIELDS_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const getCustomFields = (appspace, userId) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      const res = await API.crmUserCustomFields(userId, appspace);
      dispatch(receiveCustomFieldsSuccess(userId, res.items));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
    }
  };
};

export const createCustomField = (appspace, userId, data) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      await API.crmUserCustomFieldsCreate(userId, appspace, { data });
      dispatch(getCustomFields(appspace, userId));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
    }
  };
};

export const updateCustomField = (appspace, userId, itemId, data) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      await API.crmUserCustomFieldsUpdate(userId, itemId, appspace, { data });
      dispatch(getCustomFields(appspace, userId));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
    }
  };
};

export const deleteCustomField = (appspace, userId, itemId) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      await API.crmUserCustomFieldsDelete(userId, itemId, appspace);
      dispatch(getCustomFields(appspace, userId));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
    }
  };
};

export const registerNewUser = (appSpace, data) => {
  return async dispatch => {
    try {
      dispatch(requestUsers());
      await API.crmCreateUser(appSpace, { data });
      return true;
    } catch (e) {
      let error = e.details || e;
      if (getProp(e, ['details', 'name']) === 'DUPLICATED_USER') {
        error = __('Osoba so zadaným používateľským menom už existuje.');
      }
      if (getProp(e, ['details', 'name']) === 'DUPLICATED_IDNR') {
        error = __('Osoba so zadaným rodným číslom už existuje.');
      }
      if (getProp(e, ['details', 'name']) === 'INVALID_IDNR') {
        error = __(
          'Zadané identifikačné číslo nevyhovuje požiadavkam pre identifikačné číslo užívateľa slovenskej štátnej príslušnosti.',
        );
      }
      dispatch(receiveUsersFailure(error));
    }
    return false;
  };
};

const receiveMembershipsSuccess = (id, data) => ({
  type: RECEIVE_USER_MEMBERSHIPS_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const getMemberships = (appSpace, userId) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      const data = await API.crmUserMembership(userId, appSpace);
      dispatch(receiveMembershipsSuccess(userId, data.items));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
    }
  };
};

export const createMembership = (appSpace, userId, data) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      await API.crmUserMembershipCreate(userId, appSpace, { data });
      await dispatch(getMemberships(appSpace, userId));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
    }
  };
};

export const updateMembership = (appSpace, userId, itemId, data) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      await API.crmUserMembershipUpdate(userId, itemId, appSpace, { data });
      await dispatch(getMemberships(appSpace, userId));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
    }
  };
};

export const deleteMembership = (appSpace, userId, itemId) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      await API.crmUserMembershipDelete(userId, itemId, appSpace);
      await dispatch(getMemberships(appSpace, userId));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
    }
  };
};

// Regnrs
const receiveRegnrsSuccess = (id, data) => ({
  type: RECEIVE_USER_REGNRS_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const getRegnrs = (appSpace, userId) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      const data = await API.crmUserRegnrs(userId, appSpace);
      dispatch(receiveRegnrsSuccess(userId, data.items));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
    }
  };
};

export const createRegnr = (appSpace, userId, data) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      await API.crmUserRegnrCreate(userId, appSpace, { data });
      await dispatch(getRegnrs(appSpace, userId));
    } catch (e) {
      let error = e.details || e;
      if (getProp(e, ['details', 'name']) === 'PPO_REGNR_ALREADY_EXISTS') {
        error = __(
          'Registračné číslo pre túto organizáciu je už zaregistrované',
        );
      } else if (getProp(e, ['details', 'name']) === 'DUPLICATED_CONFLICT') {
        error = __(
          'Zadané registračné číslo je už zaregistrované pre iného používateľa',
        );
      }
      dispatch(receiveUserFailure(userId, error));
    }
  };
};

export const updateRegnr = (appSpace, userId, itemId, data) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      await API.crmUserRegnrUpdate(userId, itemId, appSpace, { data });
      await dispatch(getRegnrs(appSpace, userId));
    } catch (e) {
      let error = e.details || e;
      if (getProp(e, ['details', 'name']) === 'DUPLICATED_CONFLICT') {
        error = __(
          'Zadané registračné číslo je už zaregistrované pre iného používateľa',
        );
      }
      dispatch(receiveUserFailure(userId, error));
    }
  };
};

export const deleteRegnr = (appSpace, userId, itemId) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      await API.crmUserRegnrDelete(userId, itemId, appSpace);
      await dispatch(getRegnrs(appSpace, userId));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
    }
  };
};

// Bios
const receiveBiosSuccess = (id, data) => ({
  type: RECEIVE_USER_BIOS_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const getBios = (appSpace, userId) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      const data = await API.crmUserBio(userId, appSpace);
      dispatch(receiveBiosSuccess(userId, data.items));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
    }
  };
};

export const createBio = (appSpace, userId, data) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      await API.crmUserBioCreate(userId, appSpace, { data });
      await dispatch(getBios(appSpace, userId));
    } catch (e) {
      let error = e.details || e;
      dispatch(receiveUserFailure(userId, error));
    }
  };
};

export const updateBio = (appSpace, userId, itemId, data) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      await API.crmUserBioUpdate(userId, itemId, appSpace, { data });
      await dispatch(getBios(appSpace, userId));
    } catch (e) {
      let error = e.details || e;
      dispatch(receiveUserFailure(userId, error));
    }
  };
};

export const deleteBio = (appSpace, userId, itemId) => {
  return async dispatch => {
    try {
      dispatch(requestUser(userId));
      await API.crmUserBioDelete(userId, itemId, appSpace);
      await dispatch(getBios(appSpace, userId));
    } catch (e) {
      dispatch(receiveUserFailure(userId, e.details || e));
    }
  };
};

// Medical examinations

const requestUserMedicalExaminations = id => ({
  type: REQUEST_USER_MEDICAL_EXAMINATIONS,
  payload: {
    id,
  },
});

const receiveUserMedicalExaminationsSuccess = (id, data) => ({
  type: RECEIVE_USER_MEDICAL_EXAMINATIONS_SUCCESS,
  payload: {
    id,
    data,
  },
});

const receiveUserMedicalExaminationsFailure = (id, error) => ({
  type: RECEIVE_USER_MEDICAL_EXAMINATIONS_FAILURE,
  payload: {
    id,
    error,
  },
});

export const fetchUserMedicalExaminations = (id, appspace) => {
  return async dispatch => {
    dispatch(requestUserMedicalExaminations(id));
    try {
      const response = await API.crmUserMedicalExaminations(id, appspace);
      dispatch(receiveUserMedicalExaminationsSuccess(id, response.items));
    } catch (e) {
      dispatch(receiveUserMedicalExaminationsFailure(id, e.details || e));
    }
  };
};
export const createUserMedicalExamination = (id, appspace, data) => {
  return async dispatch => {
    dispatch(requestUserMedicalExaminations(id));
    try {
      await API.crmUserMedicalExaminationCreate(id, appspace, {
        data,
      });
      await dispatch(fetchUserMedicalExaminations(id, appspace));
    } catch (e) {
      dispatch(receiveUserMedicalExaminationsFailure(id, e.details || e));
    }
  };
};

export const editUserMedicalExamination = (id, itemId, appspace, data) => {
  return async dispatch => {
    dispatch(requestUserMedicalExaminations(id));
    try {
      await API.crmUserMedicalExaminationUpdate(id, itemId, appspace, {
        data,
      });
      await dispatch(fetchUserMedicalExaminations(id, appspace));
    } catch (e) {
      dispatch(receiveUserMedicalExaminationsFailure(id, e.details || e));
    }
  };
};

const receiveUserFormalPhotosSuccess = (id, data) => ({
  type: RECEIVE_USER_FORMAL_PHOTOS_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const getUserFormalPhotos = (id, appspace) => {
  return async dispatch => {
    dispatch(requestUser(id));
    try {
      const response = await API.crmUserFormalPhotos(id, appspace);
      dispatch(receiveUserFormalPhotosSuccess(id, response.items));
    } catch (e) {
      dispatch(receiveUserFailure(id, e.details || e));
    }
  };
};
