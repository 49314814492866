import Loader from '@sportnet/ui/Loader';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => rem(theme.grid.gutterWidth * 2)} 0;
`;

export default () => (
  <Wrapper>
    <Loader size="xl" />
  </Wrapper>
);
