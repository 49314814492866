import PropTypes from 'prop-types';
import React from 'react';
import LoaderImg from './loader.svg';

const OverlayLoader = props => {
  const { text } = props;
  return (
    <div id="loader">
      <img src={LoaderImg} alt="loader" />
      {text && <span>{text}</span>}
    </div>
  );
};

OverlayLoader.propTypes = {
  text: PropTypes.string,
};

OverlayLoader.defaultProps = {
  text: null,
};

export default OverlayLoader;
