import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import Segment from '@sportnet/ui/Segment';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import KeyValueTable from '../../../../../components/KeyValueTable';
import { __, getRequestTypeName } from '../../../../../utilities';
import {
  appspaceSelector,
  sportsCodelistSelector,
} from '../../../../App/selectors';
import { universalRequestDefinition } from '../../../definitions';
import Api from '../../../../../Api';

const UserTransfer = ({ request, sports }) => {
  const isTransferRequest = [
    'person-transfer-permanent',
    'person-transfer-limited',
  ].includes(request.request_type);

  const [sectorName, setSectorName] = useState([]);
  useEffect(() => {
    if (request && request.data?.sector && isTransferRequest) {
      if (request.data.sector.category === 'sport') {
        setSectorName(s => {
          s[0] = __('Šport');
          s[1] =
            (sports[request.data.sector.itemId] || {}).label ||
            request.data.sector.itemId;
          return [...s];
        });
        Api.getCodelistSector('sport', request.data.sector.itemId).then(
          ({ codelist: items }) => {
            setSectorName(s => {
              s[2] =
                (
                  items.find(
                    ({ value }) => value === request.data.sector.itemId,
                  ) || {}
                ).label || request.data.sector.itemId;
              return [...s];
            });
          },
        );
      } else {
        setSectorName([
          request.data.sector.category,
          request.data.sector.itemId,
          request.data.sector.sectorId,
        ]);
      }
    }
  }, [request, isTransferRequest, sports]);

  if (!isTransferRequest) {
    return null;
  }

  const kvFields = [
    {
      key: __('Typ žiadosti'),
      values: [getRequestTypeName(request.request_type)],
    },
    {
      key: __('Prestup z organizácie'),
      values: [
        request.data.transferSportOrgs
          .map(({ org_profile_name }) => org_profile_name)
          .join(' / '),
      ],
    },
    {
      key: __('Prestup do organizácie'),
      values: [request.data.targetPPO.name],
    },
    {
      key: __('Prestup od'),
      values: [moment(request.data.dateFrom).format('DD.MM.YYYY')],
    },
    ...(request.data.dateTo
      ? [
          {
            key: __('Prestup do'),
            values: [moment(request.data.dateTo).format('DD.MM.YYYY')],
          },
        ]
      : []),
    {
      key: __('Sektor'),
      values: [sectorName.join(' / ')],
    },
  ];

  return (
    <Segment raised>
      <Row>
        <Col m={6}>
          <Header size="s">{__('Údaje o prestupe')}</Header>
          <KeyValueTable rows={kvFields} />
        </Col>
      </Row>
    </Segment>
  );
};

UserTransfer.propTypes = {
  request: universalRequestDefinition.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    sports: sportsCodelistSelector(state),
    appspace: appspaceSelector(state),
  };
};

export default connect(mapStateToProps)(UserTransfer);
