import AddressFormRedux from '@sportnet/ui/AddressForm/redux-form';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField/redux-form';
import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';
import { getProp, __ } from '../../../../utilities';
import { required, validateAddress } from '../../../../validation';
import { addressTypeCodelistSelector } from '../../../App/selectors';
import { addressDefinition, codelistDefinition } from '../../definitions';

const FORM_NAME = 'FO_ADDRESS_FORM';

const statusCodelist = [
  { label: 'aktívny', value: 'active' },
  { label: 'neaktívny', value: 'inactive' },
];

const AddressesForm = ({ addressTypes, handleSubmit, parameters }) => {
  return (
    <Segment>
      <Form onSubmit={handleSubmit}>
        <Field
          name="address"
          component={AddressFormRedux}
          required
          withConscriptionNumber
          validate={[validateAddress]}
        />
        <Field
          label={__('Typ')}
          name="address.type"
          component={FormField}
          type="theselectsimple"
          options={addressTypes}
          validate={[required]}
          required
          clearable={false}
          data-testid="CRM_FO_Input_Adresy_City"
        />
        <Field
          label={__('Stav záznamu')}
          name="address.status"
          component={FormField}
          type="theselectsimple"
          options={statusCodelist}
          validate={[required]}
          required
          clearable={false}
          data-testid="CRM_FO_Input_Adresy_State"
        />
        {!parameters.address && (
          <Button primary type="submit" data-testid="CRM_FO_Button_Adresy_Save">
            {__('Pridať položku')}
          </Button>
        )}
        {parameters.address && (
          <Button primary type="submit">
            {__('Upraviť položku')}
          </Button>
        )}
      </Form>
    </Segment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    addressTypes: addressTypeCodelistSelector(state),
    initialValues: getProp(props, ['parameters'], {}),
  };
};

AddressesForm.propTypes = {
  parameters: PropTypes.shape({
    address: addressDefinition,
  }).isRequired,
  addressTypes: PropTypes.arrayOf(codelistDefinition).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(AddressesForm);

export default connect(mapStateToProps)(connected);
