import Button from '@sportnet/ui/Button';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, Form, getFormMeta, reduxForm } from 'redux-form';
import { getProp } from '@sportnet/utilities';
import keyify from '@sportnet/utilities/keyify';
import { __ } from '../../utilities';
import { required } from '../../validation';

const FORM_NAME = 'GROUP_FORM';

class GroupForm extends React.Component {
  state = {
    idIsDirty: false,
  };

  handleChangeName = e => {
    const {
      dispatch,
      parameters: { selectedGroup },
    } = this.props;
    const { idIsDirty } = this.state;
    if (selectedGroup === null && !idIsDirty) {
      dispatch(change(FORM_NAME, '_id', keyify(e.target.value)));
    }
  };

  handleChangeId = () => {
    this.setState({ idIsDirty: true });
  };

  render() {
    const {
      handleSubmit,
      parameters: { selectedGroup },
    } = this.props;
    return (
      <Segment>
        <Form onSubmit={handleSubmit}>
          <Field
            label={__('Názov skupiny')}
            name="name"
            type="text"
            component={FormFieldRedux}
            validate={[required]}
            onChange={this.handleChangeName}
            required
            data-testid="CRM_Skupina_Input_Nazov"
          />
          <Field
            label={__('ID skupiny')}
            name="_id"
            type="text"
            component={FormFieldRedux}
            validate={[required]}
            onChange={this.handleChangeId}
            disabled={selectedGroup !== null}
            required
            data-testid="CRM_Skupina_Input_ID"
          />
          <Button type="submit" primary data-testid="CRM_Skupina_Button_Save">
            {__('Uložiť')}
          </Button>
        </Form>
      </Segment>
    );
  }
}

GroupForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  parameters: PropTypes.shape({
    selectedGroup: PropTypes.shape({}),
  }).isRequired,
};

GroupForm.defaultProps = {};

const connected = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(GroupForm);

const mapStateToProps = (state, props) => {
  return {
    formMeta: getFormMeta(FORM_NAME)(state),
    initialValues: getProp(props, ['parameters', 'selectedGroup'], {}),
  };
};

export default connect(mapStateToProps)(connected);
