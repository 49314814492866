import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Input from '@sportnet/ui/Input';
import Label from '@sportnet/ui/Label/Label';
import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, Form, formValueSelector, reduxForm } from 'redux-form';
import { getProp, __ } from '../../../../utilities';
import { required } from '../../../../validation';
import {
  awardsCodelistSelector,
  pposSelector,
  sportsCodelistSelector,
} from '../../../App/selectors';
import {
  awardDefinition,
  codelistDefinition,
  PPODefinintion,
} from '../../definitions';
import AppContext from '../../../../context/AppContext';

const FORM_NAME = 'AWARDS_FORM';

const AwardsForm = ({
  ppos,
  change,
  selectedAward,
  awardTypes,
  sports,
  handleSubmit,
  sport,
}) => {
  const { activeAppSpace } = React.useContext(AppContext);

  let isOwner =
    selectedAward && selectedAward.org_profile_id === activeAppSpace._id;

  if (!isOwner && selectedAward) {
    const ppo = ppos.find(i => i._id === selectedAward.org_profile_id);
    if (ppo) {
      isOwner = true;
    }
  }

  const sectorsObj = getProp(sports, [sport, 'sectors'], {});
  const sectors = Object.keys(sectorsObj).map(key => sectorsObj[key]);

  let organizations = [];
  let profiles = [];
  if (selectedAward) {
    profiles = [
      {
        label: getProp(selectedAward, ['org_profile_name']),
        value: getProp(selectedAward, ['org_profile_id']),
      },
    ];
  } else {
    organizations = ppos.map(ppo => ({
      label: ppo.organization_name,
      value: ppo.organization_id,
    }));
    profiles = ppos.map(ppo => ({
      label: ppo.name || ppo._id,
      value: ppo._id,
    }));
  }

  return (
    <Segment>
      <Form onSubmit={handleSubmit}>
        {selectedAward ? (
          <FormGroup>
            <Label required>{__('Organizácia')}</Label>
            <Input disabled value={selectedAward.org_name} />
          </FormGroup>
        ) : (
          <Field
            component={FormField}
            type="theselectsimple"
            label={__('Organizácia')}
            name="org_id"
            validate={[required]}
            disabled
            required
            options={organizations}
          />
        )}
        <Field
          component={FormField}
          label={__('Profil organizácie')}
          type="theselectsimple"
          name="org_profile_id"
          disabled={selectedAward}
          validate={[required]}
          required
          options={profiles}
          data-testid="CRM_FO_Input_Awards_Org"
          clearable={false}
          onChange={value => {
            change('org_profile_id', value);
            const ppo = (ppos ?? []).find(i => i._id === value);
            if (ppo) {
              change('sport', ppo.sport);
            }
          }}
        />
        <Field
          component={FormField}
          label={__('Šport')}
          type="theselectsimple"
          name="sport"
          validate={[required]}
          required
          disabled
          options={Object.keys(sports).map(s => ({
            label: sports[s].label,
            value: sports[s].value,
          }))}
        />

        <Field
          component={FormField}
          label={__('Športové odvetvie')}
          disabled={selectedAward}
          name="sport_sector"
          validate={[required]}
          required
          type="theselectsimple"
          options={sectors.map(sector => ({
            label: sector.label,
            value: sector.value,
          }))}
          data-testid="CRM_FO_Input_Awards_Sector"
        />

        <Field
          component={FormField}
          label={__('Typ')}
          type="theselectsimple"
          name="award_type"
          required
          validate={[required]}
          disabled={selectedAward && !isOwner}
          options={awardTypes.map(type => ({
            label: type.value,
            value: type.value,
          }))}
          data-testid="CRM_FO_Input_Awards_Type"
        />

        <Field
          component={FormField}
          label={__('Udelil')}
          name="issued_by"
          disabled={selectedAward && !isOwner}
          data-testid="CRM_FO_Input_Awards_Name"
        />

        <Field
          component={FormField}
          type="date"
          label={__('Udelené dňa')}
          disabled={selectedAward && !isOwner}
          required
          validate={[required]}
          name="issue_date"
          data-testid="CRM_FO_Input_Awards_Date"
          autoComplete="off"
        />

        {!selectedAward && (
          <Button type="submit" primary data-testid="CRM_FO_Button_Awards_Save">
            {__('Pridať položku')}
          </Button>
        )}
        {isOwner && (
          <Button type="submit" primary>
            {__('Upraviť položku')}
          </Button>
        )}
      </Form>
    </Segment>
  );
};

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = state => {
  const data = {
    sport: selector(state, 'sport'),
    sports: sportsCodelistSelector(state),
    awardTypes: awardsCodelistSelector(state),
    ppos: pposSelector(state),
  };
  return data;
};

const mapDispatchToProps = {
  change,
};

AwardsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  sports: PropTypes.shape({}).isRequired,
  selectedAward: awardDefinition,
  ppos: PropTypes.arrayOf(PPODefinintion).isRequired,
  sport: PropTypes.string,
  awardTypes: PropTypes.arrayOf(codelistDefinition).isRequired,
  updateDisabled: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
};

const form = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(AwardsForm);

export default connect(mapStateToProps, mapDispatchToProps)(form);
