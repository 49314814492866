import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import Segment from '@sportnet/ui/Segment';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { getProp, __ } from '../../../../utilities';
import validation from '../../../../utilities/validation';
import { required } from '../../../../validation';
import {
  applicationRolesSelector,
  organizationTypeSelector,
  selectedAppDetailsSelector,
} from '../../../App/selectors';

export const formName = 'PoRegistryManager';

/**
 * Apps to be displayed in form,
 * to remove allowed app remove the key
 */
const ALLOWED_APPS = {
  CRM: true,
  dajmespolugol: true,
  kruzkymcd: true,
  disney: true,
};

const Form = styled.form`
  width: 100%;
`;

const Note = styled.div`
  border: ${rem(2)} solid #e94141;
  color: ${({ theme }) => theme.color.danger};
  text-align: center;
  box-shadow: none;
  font-weight: bold;
  font-size: ${rem(14)};
  padding: ${rem(10)};
  border-radius: ${rem(5)};
  margin-bottom: ${rem(10)};
`;

class ReduxForm extends Component {
  renderCreateAppBlock = sportnetId => {
    const {
      // createPpo,
      apps,
      registerApps,
    } = this.props;

    // if (createPpo) {
    return apps.map(app => {
      const register = getProp(
        registerApps,
        [app._id, sportnetId, 'register'],
        false,
      );
      const appName = app.name || app._id;
      const appRoles = app.roles || [];
      return (
        <Row key={app._id}>
          <Col xs={12}>
            <Field
              label={`${__('Priradiť aplikáciu')} ${appName}`}
              name={`registerApps.${app._id}.${sportnetId}.register`}
              type="checkbox"
              component={FormFieldRedux}
            />
          </Col>
          {register && (
            <Fragment>
              <Col xs={12}>
                <Field
                  label={`${__(
                    'Právo spravovať iných používateľov v aplikácií',
                  )} ${appName}`}
                  name={`registerApps.${app._id}.${sportnetId}.grant`}
                  type="checkbox"
                  component={FormFieldRedux}
                />
              </Col>
              <Col xs={12}>
                <Field
                  label={`${__('Rola v aplikácií')} ${appName}`}
                  name={`registerApps.${app._id}.${sportnetId}.role`}
                  type="theselectsimple"
                  component={FormFieldRedux}
                  validate={[validation.required]}
                  required
                  options={appRoles.map(role => ({
                    label: role.name || role.role,
                    value: role.role,
                  }))}
                />
              </Col>
            </Fragment>
          )}
        </Row>
      );
    });
    // }
  };
  render() {
    const {
      handleSubmit,
      showFields,
      createPpo,
      request: {
        ownerUser: created_by,
        data: {
          po: {
            appSpace: existingAppSpace,
            sport_expert_1,
            sport_expert_2,
            users = [],
            appSpace,
          },
        },
      },
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        {this.props.request.duplicate && !existingAppSpace && (
          <Row>
            <Col xs={12}>
              <Note>
                {__(
                  'Pre dané IČO už v platforme existuje právnická osoba. Pri potvrdení žiadosti bude vytvorený len profil právnickej osoby.',
                )}
              </Note>
            </Col>
          </Row>
        )}
        {showFields && (
          <Fragment>
            {!appSpace && (
              <Row>
                <Col xs={12}>
                  <Field
                    label={__('Vytvoriť PPO')}
                    name="createPpo"
                    type="checkbox"
                    component={FormFieldRedux}
                  />
                </Col>
              </Row>
            )}
            {createPpo && (
              <Row>
                <Col xs={12}>
                  <Field
                    label={__('Názov PPO')}
                    name="profile.name"
                    component={FormFieldRedux}
                    disabled={!!appSpace}
                    required
                    validate={[required]}
                  />
                </Col>
              </Row>
            )}
            <Segment>
              <Segment raised>
                <Col>
                  <Header size="xs">{created_by.display_name}</Header>
                </Col>
                {this.renderCreateAppBlock(created_by.sportnet_id)}
              </Segment>
              {getProp(sport_expert_1, ['_id']) && (
                <Segment raised>
                  <Col>
                    <Header size="xs">
                      {sport_expert_1.display_name} (
                      {sport_expert_1.license_type})
                    </Header>
                  </Col>
                  {this.renderCreateAppBlock(sport_expert_1._id)}
                </Segment>
              )}
              {getProp(sport_expert_2, ['_id']) && (
                <Segment raised>
                  <Col>
                    <Header size="xs">
                      {sport_expert_2.display_name} (
                      {sport_expert_2.license_type})
                    </Header>
                  </Col>
                  {this.renderCreateAppBlock(sport_expert_2._id)}
                </Segment>
              )}
              {users.map(u => (
                <Segment raised>
                  <Col>
                    <Header size="xs">
                      {u.name} ({__('Tréner')})
                    </Header>
                  </Col>
                  {this.renderCreateAppBlock(u._id)}
                </Segment>
              ))}
            </Segment>
          </Fragment>
        )}
      </Form>
    );
  }
}

const selector = formValueSelector(formName);
const mapStateToProps = (state, props) => {
  const initialValues = {
    createPpo: true,
    registerApps: {
      CRM: {
        [getProp(
          props.request,
          ['created_by', 'sportnet_id'],
          props.request.ownerUser.sportnet_id,
        )]: {
          register: true,
          role: 'manager',
          grant: true,
        },
      },
    },
    profile: {
      name: getProp(props, ['request', 'data', 'po', 'profile', 'name'], ''),
      type: getProp(props, ['request', 'data', 'po', 'profile', 'type'], ''),
    },
  };
  if (getProp(props, ['request', 'appSpace']) === 'dajmespolugol') {
    initialValues.registerApps.dajmespolugol = {
      [getProp(
        props.request,
        ['created_by', 'sportnet_id'],
        props.request.ownerUser.sportnet_id,
      )]: {
        register: true,
        role: 'manager',
        grant: true,
      },
    };
    delete ALLOWED_APPS.kruzkymcd;
    delete ALLOWED_APPS.disney;
  }
  if (getProp(props, ['request', 'appSpace']) === 'kruzkymcd') {
    initialValues.registerApps.kruzkymcd = {
      [getProp(
        props.request,
        ['created_by', 'sportnet_id'],
        props.request.ownerUser.sportnet_id,
      )]: {
        register: true,
        role: 'manager',
        grant: true,
      },
    };
    delete ALLOWED_APPS.dajmespolugol;
    delete ALLOWED_APPS.disney;
  } else if (getProp(props, ['request', 'appSpace']) === 'disney') {
    initialValues.registerApps.disney = {
      [getProp(
        props.request,
        ['created_by', 'sportnet_id'],
        props.request.ownerUser.sportnet_id,
      )]: {
        register: true,
        role: 'manager',
        grant: true,
      },
    };
    delete ALLOWED_APPS.dajmespolugol;
    delete ALLOWED_APPS.kruzkymcd;
  }
  return {
    roles: applicationRolesSelector(state),
    apps: selectedAppDetailsSelector(ALLOWED_APPS)(state),
    profileTypes: organizationTypeSelector(state),
    createPpo: selector(state, 'createPpo'),
    registerApps: selector(state, 'registerApps'),
    initialValues,
  };
};

ReduxForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  apps: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string,
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          role: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  showFields: PropTypes.bool.isRequired,
  createPpo: PropTypes.bool.isRequired,
  registerApps: PropTypes.shape({}).isRequired,
  request: PropTypes.shape({
    duplicate: PropTypes.bool,
  }).isRequired,
};

const reduxFormConnected = reduxForm({
  form: formName,
  enableReinitialize: true,
})(ReduxForm);

export default connect(mapStateToProps)(reduxFormConnected);
