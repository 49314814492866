import { createSelector } from 'reselect';
import { getProp } from '@sportnet/utilities';

export const orgByIdDomain = state => state.organizationsById;

const orgByIdObjectSelector = createSelector(
  orgByIdDomain,
  (_, id) => id,
  (byId, id) => (id in byId ? byId[id] : null),
);

export const orgIsFetchingSelector = createSelector(
  orgByIdObjectSelector,
  obj => getProp(obj, ['isFetching'], false),
);

export const orgIsErrorSelector = createSelector(
  orgByIdObjectSelector,
  obj => !!getProp(obj, ['error'], null),
);

export const orgEntities = state => state.entities.PO;

export const orgEntitySelector = createSelector(
  orgEntities,
  (_, id) => id,
  (byId, id) => (id in byId ? byId[id] : null),
);
