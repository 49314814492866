import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';

const entitiesDomain = state => state.entities;

const groupUsersDomain = createSelector(
  entitiesDomain,
  substate => substate.GroupUsers,
);

export const groupUsersCurrentListSelector = listName =>
  createSelector(
    groupUsersDomain,
    getListResults(listName),
    (entities, ids) => {
      const list = (ids || []).reduce((acc, id) => {
        if (entities.users[id]) {
          return [...acc, entities.users[id]];
        }
        return acc;
      }, []);
      return list;
    },
  );
