import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { appspaceSelector } from '../App/selectors';
import AddressesList from './detail/addresses/list';
import BankAccountsList from './detail/bankAccounts/list';
import CustomFieldsList from './detail/customFields/list';
import POProfileDetail from './detail/profile/detail';
import Membership from './detail/membership';
import ContactPersons from './detail/contactpersons';
import { organizationIsFetching } from './selectors';

export const Faded = styled.span`
  color: ${({ theme }) => theme.color.fadedText};
`;

class PO extends PureComponent {
  render() {
    return (
      <React.Fragment>
        <Route
          exact
          path="/admin/:appspace/me/profile"
          component={POProfileDetail}
        />
        <Route
          exact
          path="/admin/:appspace/me/addresses"
          component={AddressesList}
        />
        <Route
          exact
          path="/admin/:appspace/me/bank-accounts"
          component={BankAccountsList}
        />
        <Route
          exact
          path="/admin/:appspace/me/custom"
          component={CustomFieldsList}
        />
        <Route
          exact
          path="/admin/:appspace/me/membership"
          component={Membership}
        />
        <Route
          exact
          path="/admin/:appspace/me/contact-persons"
          component={ContactPersons}
        />
      </React.Fragment>
    );
  }
}

PO.defaultProps = {};

const mapStateToProps = state => {
  return {
    appspace: appspaceSelector(state),
    isFetching: organizationIsFetching(state),
  };
};

export default connect(mapStateToProps)(withRouter(PO));
