import {
  RECEIVE_ORGANIZATION_ADDRESSES_FAILURE,
  RECEIVE_ORGANIZATION_ADDRESSES_SUCCESS,
  RECEIVE_ORGANIZATION_BANK_ACCOUNTS_FAILURE,
  RECEIVE_ORGANIZATION_BANK_ACCOUNTS_SUCCESS,
  RECEIVE_ORGANIZATION_CUSTOM_FIELDS_FAILURE,
  RECEIVE_ORGANIZATION_CUSTOM_FIELDS_SUCCESS,
  RECEIVE_ORGANIZATION_PROFILE_FAILURE,
  RECEIVE_ORGANIZATION_PROFILE_SUCCESS,
  REQUEST_ORGANIZATION_ADDRESSES,
  REQUEST_ORGANIZATION_BANK_ACCOUNTS,
  REQUEST_ORGANIZATION_CUSTOM_FIELDS,
  REQUEST_ORGANIZATION_PROFILE,
} from './constants';

export const organizationReducer = (
  state = {
    profile: {
      data: null,
      isFetching: false,
    },
    addresses: {
      data: {},
      isFetching: false,
    },
    bankAccounts: {
      data: {},
      isFetching: false,
    },
    customFields: {
      data: {},
      isFetching: false,
    },
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_ORGANIZATION_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          isFetching: true,
        },
      };
    case RECEIVE_ORGANIZATION_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          isFetching: false,
          data: action.payload.data,
        },
      };
    case RECEIVE_ORGANIZATION_PROFILE_FAILURE:
      return {
        ...state,
        profile: {
          ...state.profile,
          isFetching: false,
        },
      };
    case REQUEST_ORGANIZATION_ADDRESSES:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          isFetching: true,
        },
      };
    case RECEIVE_ORGANIZATION_ADDRESSES_SUCCESS:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          isFetching: false,
          data: action.payload.data.reduce((acc, item) => {
            return { ...acc, [item._id]: item };
          }, {}),
        },
      };
    case RECEIVE_ORGANIZATION_ADDRESSES_FAILURE:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          isFetching: false,
        },
      };
    case REQUEST_ORGANIZATION_BANK_ACCOUNTS:
      return {
        ...state,
        bankAccounts: {
          ...state.bankAccounts,
          isFetching: true,
        },
      };
    case RECEIVE_ORGANIZATION_BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        bankAccounts: {
          ...state.bankAccounts,
          isFetching: false,
          data: action.payload.data.reduce((acc, item) => {
            return { ...acc, [item._id]: item };
          }, {}),
        },
      };
    case RECEIVE_ORGANIZATION_BANK_ACCOUNTS_FAILURE:
      return {
        ...state,
        bankAccounts: {
          ...state.bankAccounts,
          isFetching: false,
        },
      };
    case REQUEST_ORGANIZATION_CUSTOM_FIELDS:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          isFetching: true,
        },
      };
    case RECEIVE_ORGANIZATION_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          isFetching: false,
          data: action.payload.data.reduce((acc, item) => {
            return { ...acc, [item._id]: item };
          }, {}),
        },
      };
    case RECEIVE_ORGANIZATION_CUSTOM_FIELDS_FAILURE:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          isFetching: false,
        },
      };
    default:
      return state;
  }
};
