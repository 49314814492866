import Api from '../../Api';
import {
  RECEIVE_INVOICES_FAILURE,
  RECEIVE_INVOICES_SUCCESS,
  REQUEST_INVOICES,
} from './contants';

const requestInvoices = () => ({
  type: REQUEST_INVOICES,
});

const receiveInvoicesSuccess = invoices => ({
  type: RECEIVE_INVOICES_SUCCESS,
  payload: {
    invoices,
  },
});

const receiveInvoicesFailure = error => ({
  type: RECEIVE_INVOICES_FAILURE,
  payload: {
    error,
  },
});

export const getInvoices = (
  appSpace,
  offset,
  limit,
  q,
  buyer,
  deliveryDateFrom,
  deliveryDateTo,
) => {
  return async dispatch => {
    try {
      dispatch(requestInvoices());
      const f = { limit, offset, q };
      if (buyer) {
        f.buyer = buyer;
      }

      if (deliveryDateFrom) {
        f.deliveryDateFrom = deliveryDateFrom;
      }

      if (deliveryDateTo) {
        f.deliveryDateTo = deliveryDateTo;
      }

      const res = await Api.getInvoicesByAppspace(appSpace, f);
      dispatch(receiveInvoicesSuccess(res.invoices));
      return res;
    } catch (e) {
      dispatch(receiveInvoicesFailure(e.description || e));
    }
  };
};
