import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Sidebar from '@sportnet/ui/Sidebar';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import {
  aclSelector,
  personDocumentCodelistSelector,
} from '../../../App/selectors';
import { getActiveUserIsFetchingSelector } from '../../selectors';
import Wrapper from '../index';
import DocumentForm from './form';
import Segment from '@sportnet/ui/Segment';
import Api from '../../../../Api';
import { Faded } from '../../detail';

const mapStateToProps = state => {
  return {
    personDocumentTypes: personDocumentCodelistSelector(state),
    userIsFetching: getActiveUserIsFetchingSelector(state),
    acl: aclSelector(state),
  };
};

const DocumentsList = ({
  match: {
    params: { sportnetId, appspace },
  },
  acl,
  userIsFetching,
}) => {
  const [documents, setDocuments] = useState({ isFetching: false, items: [] });
  useEffect(() => {
    if (!userIsFetching) {
      Api.crmUserDocuments(sportnetId, appspace)
        .then(({ items }) => {
          setDocuments(d => ({ ...d, items }));
        })
        .catch(e => {
          alert(e.message);
        })
        .finally(() => {
          setDocuments(d => ({ ...d, isFetching: false }));
        });
    }
  }, [sportnetId, appspace, userIsFetching]);
  const [createDocument, setCreateDocument] = useState(null);
  const onSubmitDocument = async data => {
    try {
      return Api.crmUserDocumentCreate(sportnetId, appspace, {
        upload: data.file[0].file,
        type: data.type.value,
        orgProfileId: appspace,
      })
        .then(userDocument => {
          setDocuments(d => {
            return { ...d, items: [...d.items, userDocument] };
          });
          setCreateDocument(null);
        })
        .catch(e => {
          console.error(e);
          alert(`Chyba pri pridávaní dokumentu: ${e.message}`);
        });
    } catch (e) {
      console.error(e);
      alert(`Chyba pri pridávaní dokumentu: ${e.message}`);
    }
  };
  const [deletingDocumentId, setDeletingDocumentId] = useState(null);
  const deleteDocument = docid => {
    setDeletingDocumentId(docid);
    if (window.confirm(__('Dokument bude trvalo zmazaný'))) {
      Api.crmUserDocumentDelete(sportnetId, docid, appspace)
        .then(() => {
          setDocuments(d => {
            const documentIndex = d.items.findIndex(({ _id }) => _id === docid);
            const newItems = [...d.items];
            newItems.splice(documentIndex, 1);
            return { ...d, items: newItems };
          });
        })
        .catch(e => {
          console.error(e);
          alert(`Chyba pri mazaní dokumentu: ${e.message}`);
        })
        .finally(() => {
          setDeletingDocumentId(null);
        });
    } else {
      setDeletingDocumentId(null);
    }
  };
  const [downloadingDocumentId, setDownloadingDocumentId] = useState(null);
  const downloadDocument = docid => {
    setDownloadingDocumentId(docid);
    Api.crmUserDocumentDownloader(sportnetId, docid, appspace)
      .then(downloader => {
        window.location.href = downloader.url;
      })
      .catch(e => {
        console.error(e);
        alert(`Chyba pri sťahovaní dokumentu: ${e.message}`);
      })
      .finally(() => {
        setDownloadingDocumentId(null);
      });
  };
  return (
    <Layout
      bottomFixed={
        acl.crmUserDocumentCreate && (
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                onClick={() => {
                  setCreateDocument({});
                }}
                primary
              >
                {__('Pridať dokument')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        )
      }
    >
      <Wrapper isFetching={userIsFetching} activeTab={__('Dokumenty')}>
        <Segment raised loading={documents.isFetching || userIsFetching}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Organizácia')}</Th>
                <Th>{__('Typ')}</Th>
                <Th>{__('Dokument')}</Th>
                <Th>{__('Veľkosť')}</Th>
                <Th>{__('Dátum vytvorenia')}</Th>
                {acl.crmUserDocumentDelete && <Th></Th>}
              </Tr>
            </Thead>
            <Tbody>
              {documents.items.map(item => {
                const trProps = {
                  key: item._id,
                };
                return (
                  <Tr {...trProps}>
                    <Td>
                      {item.org_name}
                      <br />
                      <Faded>{item.org_profile_name}</Faded>
                    </Td>
                    <Td>{item.type}</Td>
                    <Td>
                      <Button
                        icon="file"
                        onClick={() => downloadDocument(item._id)}
                        disabled={downloadingDocumentId === item._id}
                        loading={downloadingDocumentId === item._id}
                      >
                        {item.name}
                      </Button>
                    </Td>
                    <Td>{Math.round(item.size / 1024)} kB</Td>
                    <Td>{moment(item.created_date).format('DD.MM.YYYY')}</Td>
                    {acl.crmUserDocumentDelete && (
                      <Td>
                        <Button
                          icon="trash"
                          onClick={() => deleteDocument(item._id)}
                          disabled={deletingDocumentId === item._id}
                          loading={deletingDocumentId === item._id}
                        />
                      </Td>
                    )}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Segment>
      </Wrapper>
      {
        <Sidebar
          onClose={() => {
            setCreateDocument(null);
          }}
          visible={createDocument}
          header={__('Dokument')}
        >
          {createDocument && (
            <DocumentForm data={{}} onSubmit={onSubmitDocument} />
          )}
        </Sidebar>
      }
    </Layout>
  );
};

export default connect(mapStateToProps)(DocumentsList);
