import PropTypes from 'prop-types';

const generalRequestProps = {
  _id: PropTypes.string,
  appSpace: PropTypes.string,
  request_type: PropTypes.string,
  status: PropTypes.string,
  ownerUser: PropTypes.shape({
    display_name: PropTypes.string,
    email: PropTypes.string,
    sportnet_id: PropTypes.string,
  }),
  last_update: PropTypes.string,
  created_date: PropTypes.string,
  status_log: PropTypes.arrayOf(
    PropTypes.shape({
      change_date: PropTypes.string,
      old_status: PropTypes.string,
      new_status: PropTypes.string,
      admin_user: PropTypes.shape({
        display_name: PropTypes.string,
        sportnet_id: PropTypes.string,
      }),
      ip: PropTypes.string,
      ips: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    phone: PropTypes.string,
    sportnet_id: PropTypes.string,
  }),
  note: PropTypes.string,
  user_note: PropTypes.string,
};

const sportnetUserDefinition = PropTypes.shape({
  name: PropTypes.string,
  surname: PropTypes.string,
  titles: PropTypes.shape({
    before: PropTypes.string,
    after: PropTypes.string,
  }),
  phone: PropTypes.string,
  email: PropTypes.string,
  sex: PropTypes.string,
  birthdate: PropTypes.string,
  birthplace: PropTypes.string,
  birthcountry: PropTypes.string,
  shoesize: PropTypes.string,
  dresssize: PropTypes.string,
  nationality: PropTypes.string,
  citizenship: PropTypes.string,
});

export const sportOrgRequestDefinition = PropTypes.shape({
  ...generalRequestProps,
  data: PropTypes.shape({
    sport_org: PropTypes.shape({
      sport: PropTypes.string,
      sport_sector: PropTypes.string,
      competence_type: PropTypes.string,
      org_id: PropTypes.string,
      org_profile_id: PropTypes.string,
      transfer_type: PropTypes.string,
      date_from: PropTypes.string,
      date_to: PropTypes.string,
      status: PropTypes.string,
    }),
    sportnet_user: sportnetUserDefinition,
  }),
});

export const sportExpertOrgRequestDefinition = PropTypes.shape({
  ...generalRequestProps,
  data: PropTypes.shape({
    sportnet_user: sportnetUserDefinition,
    sport_expert_org: PropTypes.shape({
      competence_type: PropTypes.string,
      legal_form: PropTypes.string,
      date_from: PropTypes.string,
      sport: PropTypes.string,
      sport_sector: PropTypes.string,
      org_profile_id: PropTypes.string,
      license_id: PropTypes.string,
    }),
    user_license: PropTypes.shape({
      org_profile_id: PropTypes.string,
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      documentnr: PropTypes.string.isRequired,
      valid_from: PropTypes.string.isRequired,
      valid_to: PropTypes.string.isRequired,
      issued_by_po: PropTypes.string.isRequired,
      verified_by_position: PropTypes.string,
      verified_by_person: PropTypes.string,
      registration_date: PropTypes.string.isRequired,
      note: PropTypes.string,
    }),
  }),
});

// Contains all possible request data objects
export const universalRequestDefinition = PropTypes.shape({
  ...generalRequestProps,
  data: PropTypes.shape({}),
});

export const requestColorThemedefinition = PropTypes.shape({
  requestColor: PropTypes.shape({
    NEW: PropTypes.string.isRequired,
    CANCELLED: PropTypes.string.isRequired,
    APPROVED: PropTypes.string.isRequired,
    PARTIALLY_CONFIRMED: PropTypes.string.isRequired,
    CONFIRMED: PropTypes.string.isRequired,
    REJECTED: PropTypes.string.isRequired,
    CLOSED: PropTypes.string.isRequired,
  }).isRequired,
});

export const searchParamsDefinition = PropTypes.shape({
  q: PropTypes.string,
  statuses: PropTypes.arrayOf(PropTypes.string),
  requestTypes: PropTypes.arrayOf(PropTypes.string),
  limit: PropTypes.number,
  offset: PropTypes.number,
});
