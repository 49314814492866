import FormGroupContext from '@sportnet/ui/FormGroup/FormGroupContext';
import Input from '@sportnet/ui/Input';
import React, { memo, useRef } from 'react';
import { __ } from '../../utilities';
import getProp from '@sportnet/utilities/getProp';
import styled from 'styled-components';

const FileInputEl = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const NameInput = styled(Input)``;

const FileInput = ({ value, loading, disabled, onChange }) => {
  const inputEl = useRef(null);

  const handleChangeInput = async e => {
    onChange(
      e.target.files && e.target.files.length > 0 ? e.target.files[0] : null,
    );
  };

  return (
    <>
      <FormGroupContext.Consumer>
        {({ id }) => (
          <FileInputEl
            onChange={handleChangeInput}
            type="file"
            id={id}
            ref={inputEl}
          />
        )}
      </FormGroupContext.Consumer>
      <NameInput
        id="@empty"
        value={getProp(value, ['name'], value) || ''}
        onChange={() => {
          /** */
        }}
        readOnly
        placeholder="Kliknite pre nahratie suboru..."
      >
        {value ? (
          <Input.Button
            danger
            loading={loading}
            disabled={disabled || loading}
            onClick={async e => {
              e.preventDefault();
              onChange(null);
            }}
          >
            {__('Vymazať súbor')}
          </Input.Button>
        ) : (
          <Input.Button
            success
            loading={loading}
            disabled={disabled || loading}
            onClick={e => {
              e.preventDefault();
              if (inputEl.current) {
                inputEl.current.click();
              }
            }}
          >
            {__('Nahrať súbor')}
          </Input.Button>
        )}
        <input />
      </NameInput>
    </>
  );
};

export default memo(FileInput);
