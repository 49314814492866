import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Segment from '@sportnet/ui/Segment';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Api from '../../../../Api';
import Layout from '../../../../components/Layout';
import { createDisplayName, __ } from '../../../../utilities';
import { aclSelector } from '../../../App/selectors';
import Wrapper from '../index';
import ContactPersonForm from './form';
import Sidebar from '@sportnet/ui/Sidebar';
import BasicTable from '@sportnet/ui/BasicTable';

export const Faded = styled.span`
  color: ${({ theme }) => theme.color.fadedText};
`;

const List = ({
  match: {
    params: { appspace },
  },
  isFetching,
}) => {
  const [isFetchingScreen, setIsFetchingScreen] = useState(0);
  const [contactPersons, setContactPersons] = useState([]);
  const [showPersonForm, setShowPersonForm] = useState(null);

  useEffect(() => {
    // fetchneme clenstva
    setIsFetchingScreen(i => i + 1);
    Api.crmMyOrganizationContactPersons(appspace)
      .then(({ contactPersons: items }) => {
        setContactPersons(items);
      })
      .catch(e => {
        alert(
          e.details
            ? e.details.userinfo ||
                __('Pri získavaní kontaktných osôb nastala chyba')
            : e,
        );
      })
      .finally(() => {
        setIsFetchingScreen(i => i - 1);
      });
  }, [appspace]);

  return (
    <Layout
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              primary
              onClick={() => {
                setShowPersonForm({});
              }}
            >
              {__('Pridať kontaktnú osobu')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext title={__('Moja organizácia')} />
      <Wrapper activeTab={__('Kontaktné osoby')}>
        <Segment raised loading={isFetching || isFetchingScreen > 0}>
          <BasicTable
            columns={[
              { id: 'sportnetId', header: __('SportNet ID') },
              { id: 'displayName', header: __('Meno a priezvisko') },
              { id: 'position', header: __('Pozícia') },
              { id: 'email', header: __('Email') },
              { id: 'phone', header: __('Telefón') },
            ]}
            rows={contactPersons}
            renderRow={item => {
              return [
                item.sportnetId || __('Pozvánka odoslaná...'),
                createDisplayName(item),
                item.position,
                item.email.join(', '),
                item.phone.join(', '),
              ].map(col => {
                if (!item.sportnetId) {
                  return <Faded>{col}</Faded>;
                }
                return col;
              });
            }}
            rowKey="_id"
            onClickRow={person => {
              setShowPersonForm({
                ...person,
                email: person.email[0],
                phone: person.phone[0],
              });
            }}
          />
        </Segment>
      </Wrapper>
      {showPersonForm && (
        <Sidebar
          visible={true}
          header={__('Kontaktná osoba')}
          onClose={() => {
            setShowPersonForm(null);
          }}
        >
          <ContactPersonForm
            appspace={appspace}
            onSuccess={cp => {
              if (cp) {
                const existing = contactPersons.findIndex(
                  ({ _id }) => _id === cp._id,
                );
                if (existing >= 0) {
                  setContactPersons(ii => {
                    ii.splice(existing, 1, cp);
                    return ii;
                  });
                } else {
                  setContactPersons(ii => [...ii, cp]);
                }
              }
              setShowPersonForm(null);
            }}
            onDelete={deletedId => {
              setContactPersons(ii =>
                ii.filter(({ _id }) => _id !== deletedId),
              );
              setShowPersonForm(null);
            }}
            initialValues={showPersonForm}
          />
        </Sidebar>
      )}
    </Layout>
  );
};

List.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ appspace: PropTypes.string.isRequired })
      .isRequired,
  }).isRequired,
  isFetching: PropTypes.bool,
};

List.defaultProps = {
  isFetching: false,
};

const mapStateToProps = state => {
  return {
    acl: aclSelector(state),
  };
};

export default connect(mapStateToProps)(List);
