import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as listReducer } from '@sportnet/redux-list';
import {
  appsReducer,
  errorReducer,
  relatedPposReducer,
} from './containers/App/reducer';
import { myApplicationsReducer } from './containers/Apps/reducer';
import { applicationReducer } from './containers/Authorization/reducer';
import { templatesReducer } from './containers/Courier/reducer';
import { FOReducer } from './containers/FO/reducer';
import { GroupsReducer } from './containers/Groups/reducer';
import { GroupUsersReducer } from './containers/GroupUsers/reducer';
import { invoiceItemsReducer } from './containers/InvoiceItems/reducer';
import { invoicesReducer } from './containers/Invoices/reducer';
import { organizationReducer } from './containers/PO/reducer';
import {
  organizationsByIdReducer,
  organizationsReducer,
} from './containers/PPO/Detail/reducer';
import { PPOReducer } from './containers/PPO/List/reducer';
import priceListReducer from './containers/PricelistItems/reducer';
import { requestDetailReducer } from './containers/Requests/Detail/reducer';
import { requestsReducer } from './containers/Requests/reducer';

export default combineReducers({
  form: formReducer,
  application: applicationReducer,
  error: errorReducer,
  entities: combineReducers({
    FO: FOReducer,
    PPO: PPOReducer,
    PO: organizationsReducer,
    PriceList: priceListReducer,
    Invoices: invoicesReducer,
    InvoiceItems: invoiceItemsReducer,
    Applications: myApplicationsReducer,
    Requests: requestsReducer,
    TemplatesReducer: templatesReducer,
    Groups: GroupsReducer,
    GroupUsers: GroupUsersReducer,
  }),
  list: listReducer,
  requestDetail: requestDetailReducer,
  organization: organizationReducer,
  apps: appsReducer,
  relatedPpos: relatedPposReducer,
  organizationsById: organizationsByIdReducer,
});
