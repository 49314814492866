import { rem } from 'polished';
import React, { memo } from 'react';
import styled from 'styled-components';

const Option = styled.label`
  display: flex;
  color: ${({ theme }) => theme.form.labelColor};
  font-size: ${rem(13)};
  :not(:last-of-type) {
    margin-bottom: ${rem(20)};
  }
  cursor: pointer;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Label = styled.div`
  font-weight: bold;
  font-size: ${rem(15)};
`;

const Description = styled.div`
  margin-top: ${rem(6)};
`;

const Input = styled.input`
  margin: ${rem(3)} ${rem(6)} 0 0;
`;

const RadioInput = ({ value, name, options, onChange, ...restProps }) => {
  return (
    <>
      {options.map(option => {
        return (
          <Option key={option.value} htmlFor={option.value}>
            <Input
              {...restProps}
              name={name}
              type="radio"
              value={option.value}
              checked={option.value === value}
              id={option.value}
              onChange={onChange}
            />
            <Text>
              <Label>{option.label}</Label>
              {!!option.description && (
                <Description>{option.description}</Description>
              )}
            </Text>
          </Option>
        );
      })}
    </>
  );
};

export default memo(RadioInput);
