// import Button from '@sportnet/ui/Button';
// import {
//   ContextBar,
//   ContextBarItem,
//   ContextBarSpacer,
// } from '@sportnet/ui/ContextBar';
import Sidebar from '@sportnet/ui/Sidebar';
import Segment from '@sportnet/ui/Segment';
import Message from '@sportnet/ui/Message';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Api from '../../../../Api';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import { aclSelector } from '../../../App/selectors';
import { activeAppSpaceRelationsSelector } from '../../../Authorization/selectors';
import { matchDefinition } from '../../../FO/definitions';
import {
  fetchOrganizationProfile,
  getOrganizationCustomFields,
} from '../../actions';
import {
  organizationCustomFieldsListSelector,
  organizationIsFetching,
} from '../../selectors';
import Wrapper from '../index';
import CustomFieldForm from './form';

class CustomFieldsList extends PureComponent {
  state = {
    sidebarVisible: false,
    field: null,
    parentProfileFetching: false,
    parentProfile: null,
    updateDisabled: true,
    createDisabled: true,
    dataNotApproved: false,
  };

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;
    this.loadOrganizationProfile();
    dispatch(getOrganizationCustomFields(appspace)).then(data => {
      this.setState({ dataNotApproved: data._dataNotApproved });
    });
    this.setAclAccess(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setAclAccess(nextProps);
  }

  setAclAccess = props => {
    if (typeof this.state.disabled === 'undefined' && props.acl) {
      this.setState({
        updateDisabled: !props.acl.crmMyOrganizationRequestChange,
        createDisabled: !props.acl.crmMyOrganizationRequestChange,
      });
    }
  };

  loadOrganizationProfile = async () => {
    const {
      match: {
        params: { appspace },
      },
      relations,
    } = this.props;
    this.setState({
      parentProfileFetching: true,
    });
    const organization = await this.props.dispatch(
      fetchOrganizationProfile(appspace),
    );
    const state = {
      parentProfileFetching: false,
    };
    if (organization) {
      state.orgLogoSrc = organization.logo_public_url || null;
      state.orgFaviconSrc = organization.favicon_public_url || null;
    }
    if (relations.length) {
      try {
        const parentProfile = await Api.organizationPPOProfile(
          relations[0].related_ppo,
        );
        if (parentProfile) {
          state.parentProfile = parentProfile;
        }
      } catch (e) {
        //
      }
    }
    this.setState(state);
  };

  render() {
    const { customFields, isFetching } = this.props;
    const { sidebarVisible, field, parentProfileFetching } = this.state;
    return (
      <Layout
      // bottomFixed={
      //   !this.state.createDisabled && (
      //     <ContextBar>
      //       <ContextBarSpacer />
      //       <ContextBarItem>
      //         <Button
      //           onClick={() => {
      //             this.setState({
      //               sidebarVisible: true,
      //             });
      //           }}
      //           primary
      //         >
      //           {__('Pridať záznam')}
      //         </Button>
      //       </ContextBarItem>
      //     </ContextBar>
      //   )
      // }
      >
        <AppContext title={__('Moja organizácia')} />
        <Wrapper
          isFetching={isFetching || parentProfileFetching}
          activeTab={__('Ostatné')}
        >
          {!this.state.updateDisabled && this.state.parentProfile && (
            <Message
              warning
              title={__('Zmeny podliehajú schvaľovaciemu procesu')}
            >
              {`${__(
                'Pridaním alebo úpravou položky sa zmeny zašlú na schválenie organizácii',
              )} ${this.state.parentProfile.name}`}
            </Message>
          )}
          {this.state.dataNotApproved === true && this.state.parentProfile && (
            <Message danger title={__('Údaje čakajú na schválenie')}>
              {`${__('Zmeny boli odoslané na schválenie organizácii')} ${
                this.state.parentProfile.name
              }`}
            </Message>
          )}
          <Segment raised>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Názov')}</Th>
                  <Th>{__('Hodnota')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {customFields.map(item => {
                  return (
                    <Tr
                      key={item._id}
                      {...(!this.state.updateDisabled && {
                        onClick: () => {
                          this.setState({
                            field: item,
                            sidebarVisible: true,
                          });
                        },
                      })}
                    >
                      <Td>{item.label}</Td>
                      <Td>{item.value}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Segment>
        </Wrapper>
        <Sidebar
          onClose={() => {
            this.setState({ sidebarVisible: false, field: null });
          }}
          visible={sidebarVisible}
          header={__('Ostatné')}
        >
          {sidebarVisible && (
            <CustomFieldForm
              closeSidebar={() => {
                this.setState({ sidebarVisible: false, field: null });
              }}
              parameters={{ field }}
            />
          )}
        </Sidebar>
      </Layout>
    );
  }
}

CustomFieldsList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: matchDefinition.isRequired,
  customFields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      _id: PropTypes.string,
    }),
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  customFields: organizationCustomFieldsListSelector(state),
  isFetching: organizationIsFetching(state),
  relations: activeAppSpaceRelationsSelector(state),
  acl: aclSelector(state),
});

export default connect(mapStateToProps)(withRouter(CustomFieldsList));
