import { rem } from 'polished';
import styled from 'styled-components';

const ReadOnlyTextArea = styled.div`
  width: 100%;
  font-size: ${rem(14)};
  word-break: break-word;
  color: ${({ theme }) => theme.textColor};
  font-family: ${({ theme }) => theme.fontFamily};
  padding: ${rem(10)} ${rem(10)};
  white-space: pre-wrap;
`;

export default ReadOnlyTextArea;
