import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Segment from '@sportnet/ui/Segment';
import Sidebar from '@sportnet/ui/Sidebar';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { commit, initialize, isCommiting } from '@sportnet/redux-list';
import Layout from '../../components/Layout';
import { __ } from '../../utilities';
import { appspaceDefinition } from '../App/definitions';
import { appspaceSelector } from '../App/selectors';
import { createNewGroup, getGroups, saveGroup } from './actions';
import { groupDefinition } from './definitions';
import GroupForm from './form';
import { groupsCurrentListSelector } from './selectors';

export const LIST_NAME = 'GROUPS_LIST';

class GroupsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidebarVisible: false,
      selectedGroup: null,
    };
  }

  componentDidMount() {
    const {
      dispatch,
      appspace: {
        org_profile: { _id: appspace },
      },
    } = this.props;
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          appspace,
        },
      }),
    );
    this.getGroupsList();
  }

  getGroupsList = (props = this.props) => {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = props;
    dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          const res = await dispatch(getGroups(appspace));
          return {
            total: 0,
            results: res.groups.map(i => i._id),
            nextOffset: null,
          };
        },
      }),
    );
  };

  handleSubmit = async data => {
    const { selectedGroup } = this.state;
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;

    let res;
    if (selectedGroup === null) {
      res = await dispatch(createNewGroup(appspace, data));
    } else {
      res = await dispatch(
        saveGroup(appspace, selectedGroup._id, { name: data.name }),
      );
    }

    if (res) {
      this.getGroupsList();
      this.setState({
        sidebarVisible: false,
        selectedGroup: null,
      });
    }
  };

  render() {
    const {
      groups,
      isFetching,
      match: {
        params: { appspace },
      },
    } = this.props;
    const { selectedGroup } = this.state;
    return (
      <Layout
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                primary
                data-testid="CRM_Skupina_Button_Pridat"
                onClick={() => {
                  this.setState({
                    sidebarVisible: true,
                  });
                }}
              >
                {__('Pridať skupinu')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        }
      >
        <AppContext title={__('Zoznam skupín')} />
        <Segment noBottomGutter>
          <Segment raised loading={isFetching}>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('ID skupiny')}</Th>
                  <Th>{__('Názov skupiny')}</Th>
                  <Th>{__('Používatelia v skupine')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {groups.map(item => {
                  return (
                    <Tr
                      key={item._id}
                      onClick={() => {
                        this.setState({
                          sidebarVisible: true,
                          selectedGroup: item,
                        });
                      }}
                    >
                      <Td>{item._id}</Td>
                      <Td>{item.name}</Td>
                      <Td>
                        <Button
                          onClick={e => {
                            e.stopPropagation();
                            window.open(
                              `/admin/${appspace}/group-users?user_group_ids=${item._id}`,
                            );
                          }}
                          primary
                          basic
                        >
                          {__('Zobraziť')}
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Segment>
        </Segment>
        <Sidebar
          header={
            selectedGroup === null ? __('Nová skupina') : __('Úprava skupiny')
          }
          visible={this.state.sidebarVisible}
          onClose={() => {
            this.setState({
              sidebarVisible: false,
              selectedGroup: null,
            });
          }}
        >
          <GroupForm
            key={selectedGroup ? selectedGroup._id : 'new'}
            parameters={{ selectedGroup }}
            onSubmit={this.handleSubmit}
          />
        </Sidebar>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    groups: groupsCurrentListSelector(LIST_NAME)(state),
    isFetching: isCommiting(LIST_NAME)(state),
    appspace: appspaceSelector(state),
  };
};

GroupsList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(groupDefinition).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      appsapce: PropTypes.string,
    }),
  }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  appspace: appspaceDefinition.isRequired,
};

const connected = withRouter(GroupsList);

export default connect(mapStateToProps)(connected);
