import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import ContentEditor from '@sportnet/content/editor';
import ContentView from '@sportnet/content/view';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { isSubmitting, submit } from 'redux-form';
import styled from 'styled-components';
import Api from '../../../../Api';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import { activeAppSpaceSelector } from '../../../Authorization/selectors';
import { smarttagFormatToRecipients } from '../../utilities';
import Wrapper from '../wrapper';
import Form, { FORM_NAME } from './form';
import { useParams } from 'react-router-dom-v5-compat';
import { useAuth } from '@sportnet/auth-react';
import { useNavigate } from 'react-router-dom-v5-compat';
import getApiError from '../../../../utilities/getApiError';

export const Faded = styled.span`
  color: ${({ theme }) => theme.color.fadedText};
`;

const Detail = ({ submitting, submit }) => {
  const [template, setTemplate] = React.useState(null);
  const [body, setBody] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(false);

  const { ppo: appSpace } = useAuth();
  const { templateId } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      if (templateId !== 'new') {
        setIsFetching(true);
        try {
          const template = await Api.crmGetCourierTemplateById(
            appSpace,
            templateId,
          );
          setBody(template?.parameters?.message_parameters?.widgets ?? []);
          setTemplate({
            subject: template?.parameters?.message_parameters?.subject ?? '',
            perex: template?.parameters?.message_parameters?.perex,
            recipients: template?.parameters?.recipient_parameters ?? {},
          });
        } catch (e) {
          console.error(e);
        } finally {
          setIsFetching(false);
        }
      }
    })();
  }, [appSpace, templateId]);

  const handleSubmit = async values => {
    if (templateId !== 'new') {
      navigate(`/admin/${appSpace}/courier-templates/new`);
      return;
    }
    try {
      await Api.crmSendMessages(appSpace, {
        data: {
          message_parameters: {
            subject: values.subject,
            perex: values.perex || '',
            widgets: body || [],
          },
          recipient_parameters: smarttagFormatToRecipients(values.recipients),
        },
      });
      navigate(`/admin/${appSpace}/courier-templates`);
    } catch (e) {
      const err = getApiError(e);
      if (err.details && e.details.statusCode === 400) {
        if (e.details.name === 'NO_RECIPIENT_FILTER_SPECIFIED') {
          alert(__('Zadané kritériá nevyhovujú žiadnym osobám.'));
        } else {
          alert(__('Vyplňte prosím všetky povinné údaje'));
        }
      } else {
        alert(__('Správu nie je možné odoslať'));
      }
      console.error(e);
    }
  };

  const handleClickSubmit = () => {
    submit(FORM_NAME);
  };

  return (
    <Layout
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              primary
              loading={submitting}
              onClick={handleClickSubmit}
              data-testid="CRM_Curier_Button_SendMessage"
            >
              {templateId === 'new' ? __('Odoslať') : __('Odoslať znova')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Wrapper activeTab={__('Správa')}>
        <Segment loading={isFetching}>
          <Form
            onSubmit={handleSubmit}
            data={template}
            appspace={appSpace}
            readOnly={templateId !== 'new'}
          />
          <Segment
            raised
            header={
              <SegmentHeader size="xs">
                {__('Rozšírený obsah (nepovinné)')}
              </SegmentHeader>
            }
          >
            {templateId === 'new' ? (
              <ContentEditor
                appId="CRM"
                appSpace={appSpace}
                token={Api.token}
                initialValue={body}
                availableWidgets={[
                  { label: 'Dokument', type: 'document' },
                  { label: 'Externý obsah', type: 'externalArticle' },
                  { label: 'Fotogaléria', type: 'photogallery' },
                  { label: 'Fotografia', type: 'photo' },
                  { label: 'Tabuľka', type: 'table' },
                  { label: 'Video', type: 'video' },
                ]}
                onChange={body => {
                  setBody(body);
                }}
              />
            ) : (
              <ContentView
                items={body || []}
                renderLink={link => (
                  <a href="/" target="_blank" {...link.linkProps}>
                    {link.linkProps.children}
                  </a>
                )}
              />
            )}
          </Segment>
        </Segment>
      </Wrapper>
    </Layout>
  );
};

Detail.propTypes = {
  submitting: PropTypes.bool.isRequired,
};

Detail.defaultProps = {};

const mapDispatchToProps = {
  submit,
};

const mapStateToProps = state => {
  return {
    appspace: activeAppSpaceSelector(state),
    submitting: isSubmitting(FORM_NAME)(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
