import API from '../../Api';
import {
  RECEIVE_ORGANIZATION_ADDRESSES_FAILURE,
  RECEIVE_ORGANIZATION_ADDRESSES_SUCCESS,
  RECEIVE_ORGANIZATION_BANK_ACCOUNTS_FAILURE,
  RECEIVE_ORGANIZATION_BANK_ACCOUNTS_SUCCESS,
  RECEIVE_ORGANIZATION_CUSTOM_FIELDS_FAILURE,
  RECEIVE_ORGANIZATION_CUSTOM_FIELDS_SUCCESS,
  RECEIVE_ORGANIZATION_PROFILE_FAILURE,
  RECEIVE_ORGANIZATION_PROFILE_SUCCESS,
  REQUEST_ORGANIZATION_ADDRESSES,
  REQUEST_ORGANIZATION_BANK_ACCOUNTS,
  REQUEST_ORGANIZATION_CUSTOM_FIELDS,
  REQUEST_ORGANIZATION_PROFILE,
} from './constants';

const requestOrganizationProfile = () => ({
  type: REQUEST_ORGANIZATION_PROFILE,
});

const receiveOrganizationProfileSuccess = data => ({
  type: RECEIVE_ORGANIZATION_PROFILE_SUCCESS,
  payload: {
    data,
  },
});

const receiveOrganizationProfileFailure = error => ({
  type: RECEIVE_ORGANIZATION_PROFILE_FAILURE,
  payload: {
    error,
  },
});

export const fetchOrganizationProfile = appspace => {
  return async dispatch => {
    dispatch(requestOrganizationProfile());
    try {
      const response = await API.crmMyOrganization(appspace);
      dispatch(receiveOrganizationProfileSuccess(response));
      return response;
    } catch (e) {
      dispatch(receiveOrganizationProfileFailure(e.details || e));
    }
  };
};

export const editOrganizationProfile = (appspace, data) => {
  return async dispatch => {
    dispatch(requestOrganizationProfile());
    try {
      await API.crmMyOrganizationUpdate(appspace, {
        data,
      });
      await dispatch(fetchOrganizationProfile(appspace));
    } catch (e) {
      dispatch(receiveOrganizationProfileFailure(e.details || e));
    }
  };
};

const requestOrganizationAddresses = () => ({
  type: REQUEST_ORGANIZATION_ADDRESSES,
});

const receiveOrganizationAddressesSuccess = data => ({
  type: RECEIVE_ORGANIZATION_ADDRESSES_SUCCESS,
  payload: {
    data,
  },
});

const receiveOrganizationAddressesFailure = error => ({
  type: RECEIVE_ORGANIZATION_ADDRESSES_FAILURE,
  payload: {
    error,
  },
});

export const fetchOrganizationAddresses = appspace => {
  return async dispatch => {
    dispatch(requestOrganizationAddresses());
    try {
      const response = await API.crmMyOrganizationAddresses(appspace);
      dispatch(receiveOrganizationAddressesSuccess(response.items));
      return response;
    } catch (e) {
      dispatch(receiveOrganizationAddressesFailure(e.details || e));
    }
  };
};

export const createOrganizationAddress = (appspace, data) => {
  return async dispatch => {
    dispatch(requestOrganizationAddresses());
    try {
      await API.crmMyOrganizationAddressesCreate(appspace, {
        data,
      });
      await dispatch(fetchOrganizationAddresses(appspace));
    } catch (e) {
      dispatch(receiveOrganizationAddressesFailure(e.details || e));
    }
  };
};

export const editOrganizationAddress = (itemId, appspace, data) => {
  return async dispatch => {
    dispatch(requestOrganizationAddresses());
    try {
      await API.crmMyOrganizationAddressesUpdate(appspace, itemId, {
        data,
      });
      await dispatch(fetchOrganizationAddresses(appspace));
    } catch (e) {
      dispatch(receiveOrganizationAddressesFailure(e.details || e));
    }
  };
};

const requestOrganizationBankAccounts = () => ({
  type: REQUEST_ORGANIZATION_BANK_ACCOUNTS,
});

const receiveOrganizationBankAccountsSuccess = data => ({
  type: RECEIVE_ORGANIZATION_BANK_ACCOUNTS_SUCCESS,
  payload: {
    data,
  },
});

const receiveOrganizationBankAccountsFailure = error => ({
  type: RECEIVE_ORGANIZATION_BANK_ACCOUNTS_FAILURE,
  payload: {
    error,
  },
});

export const fetchOrganizationBankAccounts = appspace => {
  return async dispatch => {
    dispatch(requestOrganizationBankAccounts());
    try {
      const response = await API.crmMyOrganizationBankAccounts(appspace);
      dispatch(receiveOrganizationBankAccountsSuccess(response.items));
      return response;
    } catch (e) {
      dispatch(receiveOrganizationBankAccountsFailure(e.details || e));
    }
  };
};

export const createOrganizationBankAccount = (appspace, data) => {
  return async dispatch => {
    dispatch(requestOrganizationBankAccounts());
    try {
      await API.crmMyOrganizationBankAccountsCreate(appspace, {
        data,
      });
      await dispatch(fetchOrganizationBankAccounts(appspace));
    } catch (e) {
      dispatch(receiveOrganizationBankAccountsFailure(e.details || e));
    }
  };
};

export const editOrganizationBankAccount = (itemId, appspace, data) => {
  return async dispatch => {
    dispatch(requestOrganizationBankAccounts());
    try {
      await API.crmMyOrganizationBankAccountsUpdate(appspace, itemId, {
        data,
      });
      await dispatch(fetchOrganizationBankAccounts(appspace));
    } catch (e) {
      dispatch(receiveOrganizationBankAccountsFailure(e.details || e));
    }
  };
};

const requestOrganizationCustomFields = () => ({
  type: REQUEST_ORGANIZATION_CUSTOM_FIELDS,
});

const receiveOrganizationCustomFieldsSuccess = data => ({
  type: RECEIVE_ORGANIZATION_CUSTOM_FIELDS_SUCCESS,
  payload: {
    data,
  },
});

const receiveOrganizationCustomFieldsFailure = error => ({
  type: RECEIVE_ORGANIZATION_CUSTOM_FIELDS_FAILURE,
  payload: {
    error,
  },
});

export const getOrganizationCustomFields = appspace => {
  return async dispatch => {
    try {
      dispatch(requestOrganizationCustomFields());
      const res = await API.crmMyOrganizationCustomFields(appspace);
      dispatch(receiveOrganizationCustomFieldsSuccess(res.items));
      return res;
    } catch (e) {
      dispatch(receiveOrganizationCustomFieldsFailure(e.details || e));
    }
  };
};

export const createOrganizationCustomField = (appspace, data) => {
  return async dispatch => {
    try {
      dispatch(requestOrganizationCustomFields());
      await API.crmMyOrganizationCustomFieldsCreate(appspace, { data });
      dispatch(getOrganizationCustomFields(appspace));
    } catch (e) {
      dispatch(receiveOrganizationCustomFieldsFailure(e.details || e));
    }
  };
};

export const updateOrganizationCustomField = (appspace, itemId, data) => {
  return async dispatch => {
    try {
      dispatch(requestOrganizationCustomFields());
      await API.crmMyOrganizationCustomFieldsUpdate(appspace, itemId, { data });
      dispatch(getOrganizationCustomFields(appspace));
    } catch (e) {
      dispatch(receiveOrganizationCustomFieldsFailure(e.details || e));
    }
  };
};
