import Button from '@sportnet/ui/Button';
import Header from '@sportnet/ui/Header';
import Message from '@sportnet/ui/Message';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isSubmitting, reset, SubmissionError, submit } from 'redux-form';
import Api from '../../../../../Api';
import { __ } from '../../../../../utilities';
import InviteCRMUserForm, { FORM_NAME } from './form';

const InviteCRMUserModal = ({
  isOpen,
  onClose,
  orgProfileId,
  resetForm,
  submitting,
  submitForm,
}) => {
  async function handleSubmit(values) {
    try {
      await Api.appgrantInviteUser(process.env.REACT_APP_APP_ID, orgProfileId, {
        data: {
          ...values,
          child_apps_role: [
            { app_id: 'mediamanager', role: 'admin' },
            { app_id: 'tagmanager', role: 'admin' },
          ],
          grant: !!values.grant,
        },
      });
      window.alert(__('Ďakujeme, pozvánka bola odoslaná!'));
      onClose();
      resetForm(FORM_NAME);
    } catch (e) {
      const message = __('Posielanie pozvánky zlyhalo!');
      window.alert(message);
      throw new SubmissionError({ _error: message });
    }
  }

  return (
    <Modal isOpen={isOpen} handleClose={onClose}>
      <ModalContent>
        <Header>{__('Pozvánka nového používateľa')}</Header>
        <Message primary>
          {__(
            'Vložte e-mailovú adresu človeka, ktorého chcete pridať medzi používateľov zvolenej organizácie. Nemusí existovať na platforme sportnet.',
          )}
        </Message>
        <InviteCRMUserForm onSubmit={handleSubmit} />
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose}>{__('Zrušiť')}</Button>
        <Button
          primary
          onClick={() => submitForm(FORM_NAME)}
          loading={submitting}
        >
          {__('Odoslať pozvánku')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

const mapStateToProps = state => ({
  submitting: isSubmitting(FORM_NAME)(state),
});

const mapDispatchToProps = {
  resetForm: reset,
  submitForm: submit,
};

InviteCRMUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orgProfileId: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  React.memo,
)(InviteCRMUserModal);
