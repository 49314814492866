import PropTypes from 'prop-types';

export const groupUserDefinition = PropTypes.shape({
  _id: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  titles: PropTypes.shape({
    before: PropTypes.string,
    after: PropTypes.string,
  }),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
});
