import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Sidebar from '@sportnet/ui/Sidebar';
import Segment from '@sportnet/ui/Segment';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import { aclSelector } from '../../../App/selectors';
import {
  createMembership,
  deleteMembership,
  updateMembership,
  getMemberships,
} from '../../actions';
import { matchDefinition } from '../../definitions';
import {
  getActiveUserIsFetchingSelector,
  getActiveUserMembershipsSelector,
} from '../../selectors';
import Wrapper from '../index';
import MembershipForm, { STATUSES as membershipStatuses } from './form';
import { Faded } from '../../detail';
import moment from 'moment';
import { getStatusIcon } from '../../../../utilities';

class MembershipList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidebarVisible: false,
      data: null,
      createDisabled: true,
      deleting: false,
    };
  }
  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace, sportnetId },
      },
    } = this.props;
    dispatch(getMemberships(appspace, sportnetId));
  }
  componentWillReceiveProps(nextProps) {
    this.setAclAccess(nextProps);
  }
  submit = async data => {
    const {
      dispatch,
      match: {
        params: { appspace, sportnetId },
      },
    } = this.props;
    const { _id, valid_from, valid_to, status } = data;
    try {
      if (!_id) {
        await dispatch(
          createMembership(appspace, sportnetId, {
            org_profile_id: appspace,
            status,
            valid_from,
            valid_to,
          }),
        );
      } else {
        await dispatch(
          updateMembership(appspace, sportnetId, _id, {
            status,
            valid_from,
            valid_to,
          }),
        );
      }
      this.setState({
        data: null,
        sidebarVisible: false,
      });
    } catch (e) {
      alert('Error');
    }
  };
  onDelete = async itemId => {
    const confirm = window.confirm(__('Skutočne si želáte zmazať členstvo?'));
    if (confirm) {
      this.setState({ deleting: true });
      const {
        dispatch,
        match: {
          params: { appspace, sportnetId },
        },
      } = this.props;
      await dispatch(deleteMembership(appspace, sportnetId, itemId));
      this.setState({
        data: null,
        sidebarVisible: false,
        deleting: false,
      });
    }
  };
  setAclAccess = props => {
    if (typeof this.state.disabled === 'undefined' && props.acl) {
      this.setState({
        createDisabled: !props.acl.crmUserMembershipCreate,
      });
    }
  };
  render() {
    const { memberships, isFetching } = this.props;
    const { data, sidebarVisible, createDisabled } = this.state;
    return (
      <Layout
        bottomFixed={
          !createDisabled && (
            <ContextBar>
              <ContextBarSpacer />
              <ContextBarItem>
                <Button
                  primary
                  onClick={() => {
                    this.setState({
                      sidebarVisible: true,
                      data: null,
                    });
                  }}
                  data-testid="CRM_FO_Button_MemberS_New"
                >
                  {__('Pridať členstvo')}
                </Button>
              </ContextBarItem>
            </ContextBar>
          )
        }
      >
        <Wrapper activeTab={__('Členstvá')} isFetching={isFetching}>
          <Segment raised loading={isFetching}>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Organizácia')}</Th>
                  <Th>{__('Od-do')}</Th>
                  <Th>{__('Stav')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {memberships.map(item => {
                  const trProps = {
                    key: item._id,
                    onClick: () => {
                      this.setState({
                        sidebarVisible: true,
                        data: item,
                      });
                    },
                  };
                  return (
                    <Tr {...trProps}>
                      <Td>
                        {item.org_name}
                        <br />
                        <Faded>{item.org_profile_name}</Faded>
                      </Td>
                      <Td>
                        {item.valid_from
                          ? moment(item.valid_from).format('DD.MM.YYYY')
                          : '?'}{' '}
                        -{' '}
                        {item.valid_to
                          ? moment(item.valid_to).format('DD.MM.YYYY')
                          : '?'}
                      </Td>
                      <Td>
                        {getStatusIcon(item.status, status => {
                          const statusItem = membershipStatuses.find(
                            ({ value }) => value === status,
                          );
                          return statusItem ? statusItem.label : status;
                        })}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Segment>
          <Sidebar
            header={__('Členstvo')}
            visible={sidebarVisible}
            onClose={() => {
              this.setState({
                sidebarVisible: false,
              });
            }}
          >
            <MembershipForm
              data={data}
              onSubmit={this.submit}
              onDelete={this.onDelete}
              deleting={this.state.deleting}
            />
          </Sidebar>
        </Wrapper>
      </Layout>
    );
  }
}

MembershipList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: matchDefinition.isRequired,
  memberships: PropTypes.arrayOf(
    PropTypes.shape({
      org_name: PropTypes.string,
      dateTo: PropTypes.string,
    }),
  ).isRequired,
  theme: PropTypes.shape({}).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  memberships: getActiveUserMembershipsSelector(state),
  isFetching: getActiveUserIsFetchingSelector(state),
  acl: aclSelector(state),
});

export default connect(mapStateToProps)(MembershipList);
