import { getProp } from '@sportnet/utilities';
import {
  RECEIVE_CUSTOM_FIELDS_SUCCESS,
  RECEIVE_USERS_SUCCESS,
  RECEIVE_USER_ADDRESSES_SUCCESS,
  RECEIVE_USER_ATHLETES_SUCCESS,
  RECEIVE_USER_AWARDS_SUCCESS,
  RECEIVE_USER_BANK_ACCOUNTS_SUCCESS,
  RECEIVE_USER_BIOS_SUCCESS,
  RECEIVE_USER_EXPERTS_SUCCESS,
  RECEIVE_USER_FORMAL_PHOTOS_SUCCESS,
  RECEIVE_USER_LICENCES_SUCCESS,
  RECEIVE_USER_MEDICAL_EXAMINATIONS_SUCCESS,
  RECEIVE_USER_MEMBERSHIPS_SUCCESS,
  RECEIVE_USER_REGNRS_SUCCESS,
  RECEIVE_USER_SUCCESS,
  SET_ACTIVE_USER,
  REQUEST_USER,
  REQUEST_USER_MEDICAL_EXAMINATIONS,
  RECEIVE_USER_MEDICAL_EXAMINATIONS_FAILURE,
} from './constants';

export const FOReducer = (
  state = {
    users: {},
    activeUserId: null,
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users.reduce((acc, next) => {
          return { ...acc, [next._id]: next };
        }, {}),
      };
    case REQUEST_USER:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...(state.users[action.payload.id] ?? {}),
            isFetching: true,
          },
        },
      };
    case RECEIVE_USER_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...getProp(state.users, [action.payload.id]),
            ...action.payload.data,
            isFetching: false,
          },
        },
      };
    case RECEIVE_USER_ADDRESSES_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users[action.payload.id],
            addresses: action.payload.data,
            isFetching: false,
          },
        },
      };
    case RECEIVE_USER_BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users[action.payload.id],
            bankAccounts: action.payload.data,
            isFetching: false,
          },
        },
      };
    case RECEIVE_USER_ATHLETES_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users[action.payload.id],
            athletes: action.payload.data,
            isFetching: false,
          },
        },
      };
    case RECEIVE_USER_FORMAL_PHOTOS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users[action.payload.id],
            formal_photos: action.payload.data,
            isFetching: false,
          },
        },
      };
    case RECEIVE_USER_EXPERTS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users[action.payload.id],
            experts: action.payload.data,
            isFetching: false,
          },
        },
      };
    case RECEIVE_USER_AWARDS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users[action.payload.id],
            awards: action.payload.data,
            isFetching: false,
          },
        },
      };
    case RECEIVE_USER_LICENCES_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users[action.payload.id],
            licences: action.payload.data,
            isFetching: false,
          },
        },
      };
    case RECEIVE_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users[action.payload.id],
            customFields: action.payload.data,
            isFetching: false,
          },
        },
      };
    case RECEIVE_USER_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users[action.payload.id],
            memberships: action.payload.data,
            isFetching: false,
          },
        },
      };
    case RECEIVE_USER_REGNRS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users[action.payload.id],
            regnrs: action.payload.data,
            isFetching: false,
          },
        },
      };
    case RECEIVE_USER_BIOS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users[action.payload.id],
            bios: action.payload.data,
            isFetching: false,
          },
        },
      };
    case REQUEST_USER_MEDICAL_EXAMINATIONS: {
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users[action.payload.id],
            medicalExaminations: {
              ...(state.users[action.payload.id].medicalExaminations ?? {
                items: [],
              }),
              isFetching: true,
              error: null,
            },
          },
        },
      };
    }
    case RECEIVE_USER_MEDICAL_EXAMINATIONS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users[action.payload.id],
            medicalExaminations: {
              isFetching: false,
              items: action.payload.data,
              error: null,
            },
          },
        },
      };
    case RECEIVE_USER_MEDICAL_EXAMINATIONS_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: {
            ...state.users[action.payload.id],
            medicalExaminations: {
              ...state.users[action.payload.id].medicalExaminations,
              isFetching: false,
              error: action.payload.error,
            },
          },
        },
      };
    case SET_ACTIVE_USER:
      return {
        ...state,
        activeUserId: action.payload.id,
      };
    default:
      return state;
  }
};
