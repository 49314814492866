import getProp from '@sportnet/utilities/getProp';
import { RECEIVE_REQUESTS_SUCCESS } from './constants';

export const requestsReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_REQUESTS_SUCCESS:
      return {
        ...state,
        ...action.payload.requests.reduce((acc, next) => {
          return {
            ...acc,
            [next._id]: {
              ...getProp(state, [next._id], {}),
              ...next,
            },
          };
        }, {}),
      };
    default:
      return state;
  }
};
