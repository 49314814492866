import FormGroupContext from '@sportnet/ui/FormGroup/FormGroupContext';
import Input from '@sportnet/ui/Input';
import React, { memo, useRef, useState } from 'react';
import { __ } from '../../utilities';
import getProp from '@sportnet/utilities/getProp';
import styled from 'styled-components';

const FileInputEl = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const NameInput = styled(Input)``;

const FileInput = ({ value, onUpload, onDelete, loading, disabled }) => {
  const inputEl = useRef(null);
  const [innerLoading, setInnerLoading] = useState(false);

  const handleChange = async files => {
    const newValue = [];
    for (let i = 0; i < (files || []).length; i++) {
      const file = (files || [])[i];
      newValue.push({
        name: file.name,
        size: file.size,
        file,
      });
    }
    if (onUpload && newValue.length > 0) {
      setInnerLoading(true);
      await onUpload(newValue[0]);
      setInnerLoading(false);
    }
  };

  const handleChangeInput = async e => {
    e.preventDefault();
    await handleChange(e.target.files);
  };

  return (
    <>
      <FormGroupContext.Consumer>
        {({ id }) => (
          <FileInputEl
            value=""
            onChange={handleChangeInput}
            type="file"
            id={id}
            ref={inputEl}
          />
        )}
      </FormGroupContext.Consumer>
      <NameInput
        id="@empty"
        value={getProp(value, ['name'], value) || ''}
        onChange={() => {
          /** */
        }}
        readOnly
        placeholder="Kliknite pre nahratie suboru..."
      >
        {value ? (
          <Input.Button
            danger
            loading={loading || innerLoading}
            disabled={disabled || loading || innerLoading}
            onClick={async e => {
              e.preventDefault();
              if (onDelete) {
                setInnerLoading(true);
                await onDelete(value);
                setInnerLoading(false);
              }
            }}
          >
            {__('Vymazať súbor')}
          </Input.Button>
        ) : (
          <Input.Button
            success
            loading={loading || innerLoading}
            disabled={disabled || loading || innerLoading}
            onClick={e => {
              e.preventDefault();
              if (inputEl.current) {
                inputEl.current.click();
              }
            }}
          >
            {__('Nahrať súbor')}
          </Input.Button>
        )}
        <input />
      </NameInput>
    </>
  );
};

export default memo(FileInput);
