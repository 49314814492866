import Loader from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import Sidebar from '@sportnet/ui/Sidebar';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Api from '../../../Api';
import Layout from '../../../components/Layout';
import { LoaderContainer } from '../../../components/List';
import { __ } from '../../../utilities';
import { appspaceDefinition } from '../../App/definitions';
import { appspaceSelector } from '../../App/selectors';
import { matchDefinition } from '../../FO/definitions';
import Form from './form';

class SequencesSettingsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidebarVisible: false,
      data: null,
      isFetching: false,
    };
  }

  onSubmit = async data => {
    const {
      match: {
        params: { appspace },
      },
    } = this.props;
    try {
      this.setState({
        isFetching: true,
      });
      await Api.crmUpdateSequence(appspace, data._id, {
        data: {
          pattern: data.pattern,
          value: Number(data.value),
        },
      });
      this.setState({
        data: null,
        sidebarVisible: false,
        isFetching: false,
      });
    } catch (e) {
      console.error(e);
      alert(__('Sekvenciu nebolo možné uložiť'));
    }
  };

  fetchSequence = async _id => {
    const {
      match: {
        params: { appspace },
      },
    } = this.props;
    try {
      this.setState({
        isFetching: true,
        sidebarVisible: true,
      });
      const data = await Api.crmGetSequence(appspace, _id);
      this.setState({
        data: {
          ...data,
          _id,
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({
        isFetching: false,
      });
    }
  };

  renderSettings = () => {
    const settings = [
      {
        _id: 'regnr_pattern',
        name: __('Registračné číslo členstva'),
      },
      {
        _id: 'invoicenr_pattern',
        name: __('Faktúry'),
      },
    ];
    return settings.map(setting => {
      return (
        <Tr
          onClick={async () => {
            await this.fetchSequence(setting._id);
          }}
        >
          <Td>{setting.name}</Td>
        </Tr>
      );
    });
  };

  render() {
    const { sidebarVisible, data, isFetching } = this.state;
    return (
      <Layout>
        <AppContext title={__('Číslovanie')} />

        <Segment>
          <Segment raised>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Sekvencia')}</Th>
                </Tr>
              </Thead>
              <Tbody>{this.renderSettings()}</Tbody>
            </Table>
          </Segment>
        </Segment>
        <Sidebar
          header={__('Sekvencia')}
          onClose={() => {
            this.setState({
              sidebarVisible: false,
            });
          }}
          visible={sidebarVisible}
        >
          {isFetching && (
            <LoaderContainer>
              <Loader size="xl" />
            </LoaderContainer>
          )}
          <Form onSubmit={this.onSubmit} data={data} />
        </Sidebar>
      </Layout>
    );
  }
}

SequencesSettingsList.propTypes = {
  appspace: appspaceDefinition.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: matchDefinition.isRequired,
};

const mapStateToProps = state => ({
  appspace: appspaceSelector(state),
});

export default connect(mapStateToProps)(SequencesSettingsList);
