import {
  RECEIVE_REQUEST_DETAIL_FAILURE,
  RECEIVE_REQUEST_DETAIL_SUCCESS,
  REQUEST_REQUEST_DETAIL,
} from './constants';

export const requestDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_REQUEST_DETAIL:
      return {
        ...state,
        detail: {},
        error: null,
        isFetching: true,
      };
    case RECEIVE_REQUEST_DETAIL_SUCCESS:
      return {
        ...state,
        detail: { ...action.payload },
        isFetching: false,
      };
    case RECEIVE_REQUEST_DETAIL_FAILURE:
      return {
        ...state,
        detail: {},
        isFetching: false,
        error: action.payload.details || action.payload,
      };
    default:
      return state;
  }
};
