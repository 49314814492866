import API from '../../Api';
import {
  RECEIVE_REQUESTS_FAILURE,
  RECEIVE_REQUESTS_SUCCESS,
  REQUEST_REQUESTS,
  SET_SEARCH_PARAMETER,
  SET_SEARCH_PARAMETERS,
} from './constants';

// Search
export const setSearchParam = (type, value) => ({
  type: SET_SEARCH_PARAMETER,
  payload: {
    type,
    value,
  },
});

export const setSearchParams = params => ({
  type: SET_SEARCH_PARAMETERS,
  payload: {
    params,
  },
});

// Request List
const requestRequests = searchString => ({
  type: REQUEST_REQUESTS,
  payload: {
    searchString,
  },
});

const receiveRequestsSuccess = requests => ({
  type: RECEIVE_REQUESTS_SUCCESS,
  payload: {
    requests,
  },
});

const receiveRequestsFailure = (searchString, error) => ({
  type: RECEIVE_REQUESTS_FAILURE,
  payload: {
    searchString,
    error,
  },
});

export const loadRequestsList = (appSpace, limit, queryParameters = {}) => {
  return async dispatch => {
    dispatch(requestRequests());
    try {
      const params = {};
      Object.keys(queryParameters).forEach(key => {
        if (queryParameters[key].length !== 0) {
          params[key] = queryParameters[key];
        }
      });
      const data = await API.crmGetRequests(appSpace, { ...params, limit });
      dispatch(receiveRequestsSuccess(data.requests));
      return data;
    } catch (e) {
      dispatch(receiveRequestsFailure(e.description || e));
    }
    return null;
  };
};
