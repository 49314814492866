import Header from '@sportnet/ui/Header';
import moment from 'moment';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import getProp from '@sportnet/utilities/getProp';
import styled from 'styled-components';
import Api from '../../../../Api';
import DeepCompareVisualization from '../../../../components/DeepCompareVisualization';
import { getRequestStatusName, __ } from '../../../../utilities';
import { receiveRequestDetailSuccess } from '../actions';
import StatusManager from '../Universal/StatusManager';

const Wrapper = styled.div`
  padding: ${rem(10)} ${rem(5)};
`;

const UserProfileChangeManager = ({
  dispatch,
  match: {
    params: { appspace },
  },
  request,
}) => {
  const [loading, setLoading] = React.useState(false);

  const { modifiedSportnetUser, profile } = React.useMemo(() => {
    const {
      data: { 'user-profile-change': userProfileChangeRequest },
      userProfile,
    } = request;
    const result = { modifiedSportnetUser: {}, profile: {} };

    if (userProfileChangeRequest) {
      const {
        org_id,
        org_profile_id,
        sport,
        photo,
        deathdate,
        membership_status,
        isChildRegistration,
        ...modifiedSportnetUser
      } = userProfileChangeRequest;
      result.modifiedSportnetUser = modifiedSportnetUser;
    }

    if (userProfile) {
      const { bank_accounts, ...profile } = userProfile;
      result.profile = profile;
    } else if (result.modifiedSportnetUser) {
      result.profile = result.modifiedSportnetUser;
    }

    return result;
  }, [request]);

  const changeStatus = async status => {
    const confirmMessage = `${__('Naozaj chcete zmeniť stav žiadosti')}
${__('z')} ${getRequestStatusName(request.status)} ${__(
      'na',
    )} ${getRequestStatusName(status)}?`;

    try {
      setLoading(true);
      let newRequest = {};
      if (status === 'REJECTED') {
        const noteText = __('Napíšte dôvod zamietnutia:');
        const note = window.prompt(`${confirmMessage}
        
${noteText}`);
        if (!note) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status, note },
        });
      } else if (status === 'CONFIRMED') {
        if (!window.confirm(confirmMessage)) return;
        newRequest = await Api.crmConfirmUserProfileChangeRequest(
          appspace,
          request._id,
          {
            data: {},
          },
        );
      } else {
        if (!window.confirm(confirmMessage)) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status },
        });
      }
      dispatch(receiveRequestDetailSuccess(newRequest._id, newRequest));
    } catch (e) {
      const error = getProp(e, ['details'], {});
      if (error.name === 'INVALID_IDNR') {
        window.alert(__('Neplatné Identifikačné číslo používateľa'));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    changeStatus('CANCELLED');
  };

  const handleNew = () => {
    changeStatus('NEW');
  };

  const handleReject = () => {
    changeStatus('REJECTED');
  };

  const handleConfirm = () => {
    changeStatus('CONFIRMED');
  };

  const handleClose = () => {
    changeStatus('CLOSED');
  };

  const formatData = data => {
    if (data.created_datetime) {
      data.created_datetime = moment(data.created_date).format('DD.MM.YYYY');
    }
    if (data.birthdate) {
      data.birthdate = moment(data.birthdate).format('DD.MM.YYYY');
    }
    if (data.sex) {
      data.sex =
        data.sex === 'M' ? __('Muž') : data.sex === 'F' ? __('Žena') : data.sex;
    }
    if (data.addresses) {
      data.addresses = data.addresses.map(a => ({
        ...a,
        type:
          a.type === 'HOME'
            ? 'Trvalý pobyt'
            : a.type === 'INVOICE'
            ? 'Fakturačná adresa'
            : a.type === 'POSTAL'
            ? 'Korešpondenčná adresa'
            : a.type,
      }));
    }
    return data;
  };

  return (
    <React.Fragment>
      <StatusManager
        request={request}
        onCancel={handleCancel}
        onNew={handleNew}
        onReject={handleReject}
        onConfirm={handleConfirm}
        onClosed={handleClose}
        loading={loading}
      />
      <Wrapper>
        <Header size="xs">{__('Sumár zmien')}</Header>
        <DeepCompareVisualization
          object={formatData(profile)}
          anotherObject={formatData(modifiedSportnetUser)}
          schema={{
            _id: __('Sportnet ID'),
            username: __('Používateľské meno'),
            isChildRegistration: __('Registrácia dieťaťa'),
            created_datetime: __('Dátum a čas vytvorenia'),
            addresses: __('Adresy'),
            'addresses.$.street': __('Ulica'),
            'addresses.$.number': __('Číslo'),
            'addresses.$.conscription_number': __('Súpisné číslo'),
            'addresses.$.city': __('Mesto'),
            'addresses.$.zip': __('PSČ'),
            'addresses.$.country': __('Štát'),
            'addresses.$.type': __('Typ adresy'),
            birthplace: __('Miesto narodenia'),
            titles: __('Tituly'),
            'titles.before': __('Titul pred'),
            'titles.after': __('Titul za'),
            dresssize: __('Veľkosť oblečenia'),
            birthcountry: __('Krajina narodenia'),
            birthdate: __('Dátum narodenia'),
            citizenship: __('Štátna príslušnosť'),
            email: __('Emailová adresa'),
            family_name: __('Rodné priezvisko'),
            idnr: __('Identifikačné číslo'),
            name: __('Meno'),
            nationality: __('Národnosť'),
            phone: __('Telefónne číslo'),
            sex: __('Pohlavie'),
            shoesize: __('Veľkosť topánok'),
            surname: __('Priezvisko'),
          }}
        />
      </Wrapper>
    </React.Fragment>
  );
};

UserProfileChangeManager.propTypes = {
  request: PropTypes.shape({
    data: PropTypes.shape({
      sportnet_user: PropTypes.shape({}),
      user_profile_change: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default withRouter(connect()(UserProfileChangeManager));
