import NotFound from '@sportnet/ui/NotFound';
import Button from '@sportnet/ui/Button';
import Segment from '@sportnet/ui/Segment';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import useQuery, { serializeQuery } from '@sportnet/query-hoc/useQuery';
import React from 'react';
import { connect } from 'react-redux';
import {
  getListTotal,
  hasCommitFailed,
  isCommiting,
} from '@sportnet/redux-list';
import { removeAccents } from '@sportnet/utilities';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Layout from '../../../components/Layout';
import { __ } from '../../../utilities';
import { initializeOrSetListParams } from '../../App/actions';
import { matchDefinition, PPODefinition } from '../definitions';
import { loadRelatedPPOSList } from './actions';
import { PPOS_LIST, PPOS_LIST_QUERY_HOC_CONFIG } from './constants';
import Filter from './Filter';
import { ppoListSelector } from './selectors';
import Table from './Table';
import Prolongmodal from './prolongmodal';
import Api from '../../../Api';

const List = ({
  history,
  location: { search, pathname },
  match: {
    params: { appspace },
  },
  ppos,
  filteredPPOs,
  loadPPOs,
  isLoading,
  hasLoadFailed,
  setListParams,
  total,
}) => {
  const { query, setQuery } = useQuery(
    search,
    serializedQuery => history.push(`${pathname}${serializedQuery}`),
    PPOS_LIST_QUERY_HOC_CONFIG,
  );

  // we want to filter by q on FE, so no request should be made when q changes
  const listSerializedQuery = React.useMemo(() => {
    return serializeQuery(
      {
        ppoType: query.ppoType,
        relationStatus: query.relationStatus,
      },
      PPOS_LIST_QUERY_HOC_CONFIG,
    );
  }, [query]);

  React.useEffect(() => {
    setListParams({
      listName: PPOS_LIST,
      params: {
        ppoType: query.ppoType,
        relationStatus: query.relationStatus,
      },
    }).then(() => loadPPOs());
  }, [
    loadPPOs,
    setListParams,
    listSerializedQuery,
    query.ppoType,
    query.relationStatus,
  ]);

  const handleClickPPO = React.useCallback(
    PPO => {
      history.push(
        `/admin/${appspace}/organizations/${PPO.organization_id}/profiles/${PPO._id}/profile`,
      );
    },
    [history, appspace],
  );

  const [selected, setSelected] = React.useState({});

  const shouldShowLoader = isLoading && filteredPPOs.length === 0;

  const getSelectedPPOIds = () => {
    return Object.keys(selected).filter(ppo => selected[ppo]);
  };
  const isAnyPPOSelected = () => {
    return getSelectedPPOIds().length > 0;
  };

  const [prolongPPOs, setProlongPPOs] = React.useState();

  const setMembershipProlongPPOs = ppoObjects => {
    setProlongPPOs(ppoObjects.length ? ppoObjects : null);
  };

  const [isExportingPPOs, setIsExportingPPOs] = React.useState(false);
  const startExportingPPOs = async () => {
    setIsExportingPPOs(true);

    try {
      const { url } = await Api.crmListRelatedPPOs(appspace, {
        ppoType: query.ppoType,
        with: 'shortNames',
        output: 'xlsx',
      });
      setIsExportingPPOs(false);
      window.location = url;
    } catch (e) {
      alert(`Pri exportovaní nastala chyba ${e}`);
    } finally {
      setIsExportingPPOs(false);
    }
  };

  return (
    <Layout
      topFixed={<Filter query={query} setQuery={setQuery} />}
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            {total > 0 && (
              <Button
                icon="file-download"
                loading={isExportingPPOs}
                disabled={isExportingPPOs}
                title={__('Exportovať všetky organizácie do excelu')}
                onClick={startExportingPPOs}
              >
                {__(
                  `Exportovať ${total} ${
                    total === 1
                      ? 'organizáciu'
                      : total < 5
                      ? 'organizácie'
                      : 'organizácií'
                  }`,
                )}
              </Button>
            )}
          </ContextBarItem>
          <ContextBarSpacer />
          {isAnyPPOSelected() && (
            <ContextBarItem>
              <Button
                basic
                primary
                onClick={() => {
                  setMembershipProlongPPOs(
                    ppos.filter(ppo => selected[ppo._id]),
                  );
                }}
              >
                {`${__('Predĺžiť členstvo')} (${getSelectedPPOIds().length})`}
              </Button>
            </ContextBarItem>
          )}
        </ContextBar>
      }
    >
      <AppContext title={__('Zoznam právnických osôb')} />
      <Segment noBottomGutter>
        <Segment noBottomGutter raised loading={shouldShowLoader}>
          {hasLoadFailed ? (
            <NotFound
              title={__('Ups! Nastala chyba pri načítaní')}
              icon="error"
              actionLabel={__('Skúsiť znova')}
              onClickAction={loadPPOs}
            />
          ) : (
            <Table
              items={filteredPPOs}
              onClickItem={handleClickPPO}
              onSelect={setSelected}
              selected={selected}
            />
          )}
        </Segment>
      </Segment>
      {prolongPPOs && (
        <Prolongmodal
          appspace={appspace}
          ppos={prolongPPOs}
          onClose={() => {
            setProlongPPOs(null);
          }}
          onFinish={loadPPOs}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = (state, props) => {
  const q = removeAccents(
    new URLSearchParams(props.location.search).get('q') || '',
  ).toLowerCase();
  const ppos = ppoListSelector(state);
  const filteredPPOs = !!q
    ? ppos.filter(ppo => {
        return ppo.fts.some(text => text.includes(q));
      })
    : ppos;
  return {
    ppos,
    filteredPPOs,
    total: getListTotal(PPOS_LIST)(state) || 0,
    isLoading: isCommiting(PPOS_LIST)(state) || false,
    hasLoadFailed: hasCommitFailed(PPOS_LIST)(state) || false,
  };
};

const mapDispatchToProps = {
  loadPPOs: loadRelatedPPOSList,
  setListParams: initializeOrSetListParams,
};

List.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: matchDefinition.isRequired,
  ppos: PropTypes.arrayOf(PPODefinition).isRequired,
  filteredPPOs: PropTypes.arrayOf(PPODefinition).isRequired,
  loadPPOs: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasLoadFailed: PropTypes.bool.isRequired,
  setListParams: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
