import Button from '@sportnet/ui/Button';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { FileObjectDefinition } from './definitions';

const Title = styled.div`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Name = styled(Title)`
  font-size: ${rem(14)};
`;

const ButtoWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  position: relative;
  padding: ${rem(8)} ${rem(10)};
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `};
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily};
  .sportnetui-btn {
    padding: 0 ${rem(7)};
    font-size: ${rem(8)};
    min-height: ${rem(22)};
    .icon {
      height: ${rem(8)};
      width: ${rem(8)};
    }
  }
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
  min-height: ${rem(45)};
  align-items: center;
`;

class FileLine extends Component {
  render() {
    const { file, onDelete, onClick } = this.props;
    return (
      <Wrapper onClick={onClick} clickable={!!onClick}>
        <Name>{file.name || ''}</Name>
        <ButtoWrapper>
          <Button danger icon="close" onClick={onDelete} />
        </ButtoWrapper>
      </Wrapper>
    );
  }
}

FileLine.propTypes = {
  file: FileObjectDefinition.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

FileLine.defaultProps = {
  onClick: null,
};

export default FileLine;
