const namespace = 'Requests';

// Search
export const SET_SEARCH_PARAMETER = `${namespace}/SET_SEARCH_PARAMETER`;
export const SET_SEARCH_PARAMETERS = `${namespace}/SET_SEARCH_PARAMETERS`;

// Requests list
export const REQUEST_REQUESTS = `${namespace}/REQUEST_REQUESTS`;
export const RECEIVE_REQUESTS_SUCCESS = `${namespace}/RECEIVE_REQUESTS_SUCCESS`;
export const RECEIVE_REQUESTS_FAILURE = `${namespace}/RECEIVE_REQUESTS_FAILURE`;
