import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField/redux-form';
import PropTypes from 'prop-types';
import Segment from '@sportnet/ui/Segment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, formValueSelector, reduxForm } from 'redux-form';
import { getProp, __ } from '../../utilities';
import { required } from '../../validation';
import { appspaceDefinition } from '../App/definitions';
import { appspaceSelector } from '../App/selectors';
import ApplicationForm from './Form/applicationForm';
import ApplicationPercentForm from './Form/applicationPercentForm';
import MembershipForm from './Form/membershipForm';
import PpomembershipForm from './Form/ppomembershipForm';
import Col, { Row } from '@sportnet/ui/Grid';
import { PRICELIST_TYPES_CODELIST } from './list';

export const FORM_NAME = 'INVOICE_ITEM_FORM';

class InvoiceItemForm extends React.PureComponent {
  render() {
    const { data, appspace, type, handleSubmit } = this.props;
    const types = PRICELIST_TYPES_CODELIST.filter(({ sportnetOnly }) => {
      return getProp(appspace, 'app_space') === 'sportnet'
        ? true
        : !sportnetOnly;
    });
    const isUpdatingItem = !!getProp(data, ['_id']);
    return (
      <Segment>
        <Form onSubmit={handleSubmit}>
          <Field
            label={__('Typ položky')}
            name="type"
            type="theselect"
            options={types}
            parse={item => {
              return item ? item.value : '';
            }}
            format={value =>
              value ? types.find(o => o.value === value) || null : null
            }
            required
            validate={[required]}
            component={FormField}
          />

          <Field
            label={__('Názov položky')}
            name="name"
            required
            validate={[required]}
            component={FormField}
          />
          <Field
            label={__('Popis položky')}
            name="description"
            type="textarea"
            component={FormField}
          />

          {type === 'membership' && <MembershipForm data={data} />}
          {type === 'application' && <ApplicationForm />}
          {type === 'applicationPcn' && <ApplicationPercentForm />}
          {type === 'ppomembership' && <PpomembershipForm />}

          <Row>
            {isUpdatingItem && (
              <Col l={6}>
                <Button
                  onClick={e => {
                    e.preventDefault();
                    this.props.deleteItem(data._id);
                  }}
                  block
                  danger
                  type="button"
                >
                  {__('Zmazať položku')}
                </Button>
              </Col>
            )}
            <Col l={isUpdatingItem ? 6 : 12}>
              <Button type="submit" block primary>
                {__('Uložiť položku')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Segment>
    );
  }
}

InvoiceItemForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  appspace: appspaceDefinition.isRequired,
  data: PropTypes.shape({
    _id: PropTypes.string,
    price: PropTypes.shape({
      amount: PropTypes.number,
      amount_vat_excl: PropTypes.number,
      vat_rate: PropTypes.number,
      currency: PropTypes.string,
    }),
    type: PropTypes.string,
  }),
};

InvoiceItemForm.defaultProps = {
  data: null,
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(InvoiceItemForm);

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  let stateToProps = {
    initialValues: {
      type: 'membership',
      price: {
        amount_vat_excl: 0,
        amount: 0,
        vat_rate: 20,
        currency: 'EUR',
      },
    },
    type: selector(state, 'type'),
    appspace: appspaceSelector(state),
  };
  if (props.data) {
    stateToProps = {
      ...stateToProps,
      initialValues: {
        ...props.data,
        ageRestriction:
          props.data.ageFrom > 0 ||
          (props.data.ageFrom === 0 && props.data.ageTo > 0),
        sexRestriction: props.data.sex && true,
        dateRestrictionByDate:
          getProp(props.data, ['validity', 'dateTo']) && true,
        dateRestrictionInDays:
          getProp(props.data, ['validity', 'days']) && true,
      },
    };
  }
  return stateToProps;
};

export default connect(mapStateToProps)(connected);
