import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  change,
  Field,
  formValueSelector,
  reduxForm,
  submit,
} from 'redux-form';
import { __ } from '../../../utilities';
import { bankAccountDefinition } from '../../FO/definitions';
import PersonChangeForm from './personChangeForm';

const PERSON_CHANGE_FORM_NAME = 'PERSON_CHANGE_FORM';

const PERSON_CHANGE_ACTION_TYPES = [
  {
    label: __('Pridanie novej oprávnenej osoby'),
    value: 'add',
  },
  {
    label: __('Odstránenie oprávnenej osoby'),
    value: 'remove',
  },
];

export const PERSON_POSITION_TYPES = [
  {
    label: __('Tréner'),
    value: 'coach',
  },
  {
    label: __('Triedny učiteľ'),
    value: 'class_teacher',
  },
  {
    label: __('Učiteľ'),
    value: 'teacher',
  },
];

class DSGEditRequestForm extends React.PureComponent {
  state = {
    modalOpened: false,
  };

  getActionTypeLabel = value => {
    const actionType = PERSON_CHANGE_ACTION_TYPES.find(i => i.value === value);
    return actionType ? actionType.label : value;
  };

  getPositionLabel = value => {
    const position = PERSON_POSITION_TYPES.find(i => i.value === value);
    return position ? position.label : value;
  };

  toggleModal = () => {
    this.setState({
      modalOpened: !this.state.modalOpened,
    });
  };

  dispatchUserChange = values => {
    const users = [...(this.props.users || [])];
    users.push({
      type: values.type.value,
      user: {
        ...values.user,
        type: values.user.type.value,
      },
    });
    this.props.dispatch(change(this.props.form, 'users', users));
    this.setState({
      modalOpened: false,
    });
  };

  removeUserFromList = idx => {
    const users = [...(this.props.users || [])].reduce((acc, u, uIdx) => {
      if (idx === uIdx) {
        return acc;
      }
      return [...acc, u];
    }, []);
    this.props.dispatch(change(this.props.form, 'users', users));
  };

  render() {
    const { bankAccounts, users = [] } = this.props;
    return (
      <React.Fragment>
        <Segment
          raised
          header={
            <SegmentHeader size="s" withSeparator>
              {__(
                'Zmena bankového účtu pre vyplatenie finančných prostriedkov na realizáciu projektu',
              )}
            </SegmentHeader>
          }
        >
          <Row>
            <Col xs={12}>
              <Field
                name="targetGroups.0.iban"
                component={FormField}
                label={__(
                  'Nový bankový účet na vyplatenie finančných prostriedkov za cieľovú skupinu 1',
                )}
                type="theselect"
                options={bankAccounts.map(i => ({
                  label: i.IBAN,
                  value: i.IBAN,
                }))}
              />
            </Col>
            <Col xs={12}>
              <Field
                name="targetGroups.1.iban"
                component={FormField}
                label={__(
                  'Nový bankový účet na vyplatenie finančných prostriedkov za cieľovú skupinu 2',
                )}
                type="theselect"
                options={bankAccounts.map(i => ({
                  label: i.IBAN,
                  value: i.IBAN,
                }))}
              />
            </Col>
          </Row>
        </Segment>
        <Segment
          raised
          header={
            <SegmentHeader
              size="s"
              withSeparator
              subtitle={
                <div style={{ marginTop: rem(5) }}>
                  <Button onClick={this.toggleModal} primary>
                    {__('Pridať zmenu')}
                  </Button>
                </div>
              }
            >
              {__('Zmena v zozname oprávnených osôb podľa prílohy č. 1 zmluvy')}
            </SegmentHeader>
          }
        >
          {users.length > 0 && (
            <BasicTable
              rows={users}
              columns={[
                { header: __('Typ zmeny') },
                { header: __('Meno a priezvisko') },
                { header: __('Typ oprávnenej osoby') },
                { header: '', width: 50 },
              ]}
              renderRow={(i, idx) => [
                this.getActionTypeLabel(i.type),
                i.user.name,
                this.getPositionLabel(i.user.type),
                <Button
                  onClick={() => {
                    this.removeUserFromList(idx);
                  }}
                  danger
                  icon="close"
                />,
              ]}
            />
          )}
        </Segment>
        {this.state.modalOpened && (
          <Modal isOpen={this.state.modalOpened} handleClose={this.toggleModal}>
            <ModalContent>
              <Header size="xs" withSeparator>
                {__(
                  'Zmena v zozname oprávnených osôb podľa prílohy č. 1 zmluvy',
                )}
              </Header>
              <PersonChangeForm
                form={PERSON_CHANGE_FORM_NAME}
                onSubmit={this.dispatchUserChange}
                actionTypes={PERSON_CHANGE_ACTION_TYPES}
                positions={PERSON_POSITION_TYPES}
              />
            </ModalContent>
            <ModalActions>
              <div>&nbsp;</div>
              <div>
                <Button onClick={this.toggleModal}>{__('Zavrieť')}</Button>
                &nbsp;
                <Button
                  primary
                  onClick={() => {
                    this.props.dispatch(submit(PERSON_CHANGE_FORM_NAME));
                  }}
                >
                  {__('Uložiť')}
                </Button>
              </div>
            </ModalActions>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

DSGEditRequestForm.propTypes = {
  bankAccounts: PropTypes.arrayOf(bankAccountDefinition).isRequired,
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      user: PropTypes.shape({
        sportnetId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
};

DSGEditRequestForm.defaultProps = {
  users: [],
};

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(props.form);
  return {
    users: selector(state, 'users'),
  };
};

export default compose(
  reduxForm({}),
  connect(mapStateToProps),
)(DSGEditRequestForm);
