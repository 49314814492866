import Icon from '@sportnet/ui/Icon';
import React from 'react';
import formatUserName from '@sportnet/utilities/formatUserName';

export const __ = toTranslate => {
  return toTranslate;
};

export const parseNumber = value => {
  return Number(String(value).replace(/,/g, '.').trimRight('.'));
};

export const getProp = (object, path, defaultValue) => {
  if (path === undefined || path === null) return defaultValue;
  let pathArray = path;
  if (typeof path === 'string') {
    pathArray = path.split('.');
  }
  const val = pathArray.reduce((o, x) => {
    return typeof o === 'undefined' || o === null ? defaultValue : o[x];
  }, object);
  if (typeof val === 'undefined' || val === null) {
    return defaultValue;
  }
  return val;
};

export function getStatusIcon(status, unknown = null) {
  switch (status) {
    case 'active':
      return <Icon name="check" title={status} />;
    case 'inactive':
      return <Icon name="close" title={status} />;
    default:
      if (typeof unknown === 'function') {
        return unknown(status);
      }
      return unknown;
  }
}

const statusMap = {
  NEW: { title: __('Odoslaná') },
  CANCELLED: { title: __('Zrušená') },
  APPROVED: { title: __('Prijatá') },
  PARTIALLY_CONFIRMED: { title: __('Čiastočne potvrdená') },
  CONFIRMED: { title: __('Potvrdená') },
  REJECTED: { title: __('Odmietnutá') },
  CLOSED: { title: __('Uzatvorená') },
};

export const getRequestStatusName = status => {
  return statusMap[status].title || status;
};

export const getRequestStatusCodelist = () => {
  return Object.keys(statusMap).map(key => {
    return {
      title: statusMap[key].title,
      value: key,
    };
  });
};

export const encodeSearchString = (params = {}) => {
  return `?${Object.keys(params)
    .sort()
    .reduce((acc, key) => {
      acc.push(`${key}=${params[key]}`);
      return acc;
    }, [])
    .join('&')}`;
};

export function parseQueryString(qs) {
  const result = {};
  const query = qs.replace(/^(\?|#)/, '');
  if (query.length === 0) {
    return result;
  }
  query.split('&').forEach(part => {
    const item = part.split('=');
    result[decodeURIComponent(item[0])] = decodeURIComponent(item[1]);
  });
  return result;
}

export const filterEmptyValuesFromObject = object => {
  return Object.keys(object).reduce((acc, key) => {
    const value = object[key];
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export function createDisplayName(user) {
  return formatUserName(user);
}

export const getStateName = state => {
  if (!state) return '';
  const lowerCaseState = state.toLowerCase();
  if (lowerCaseState === 'active') {
    return __('aktívny');
  }
  if (lowerCaseState === 'inactive') {
    return __('neaktívny');
  }
  return '';
};

const requestTypeMap = {
  'sport-org': __('Registrácia športovca'),
  'sport-expert-org': __('Registrácia športového odborníka'),
  'user-profile-change': __('Zmena osobných údajov'),
  'po-registry': __('Registrácia organizácie'),
  'po-data-change': __('Žiadosť o zmeny údajov organizácie'),
  'dsg-edit': __(
    'Nahlásenie zmeny údajov pre účely realizácie projektu "Dajme spolu gól"',
  ),
  'person-transfer-permanent': __('Žiadosť o prestup športovca'),
  'person-transfer-limited': __('Žiadosť o prestup športovca s obmedzením'),
  license: __('Žiadosť o vydanie licencie'),
};

export const getRequestTypeName = status => {
  return requestTypeMap[status] || '';
};

export const getRequestTypeCodelist = () => {
  return Object.keys(requestTypeMap).map(key => {
    return {
      title: requestTypeMap[key],
      value: key,
    };
  });
};

const setPropInObject = (originObject, path, iteration, setObject) => {
  if (path[iteration]) {
    return {
      ...originObject,
      [path[iteration]]: path[iteration + 1]
        ? {
            ...setPropInObject(
              getProp(originObject, [path[iteration]], {}),
              path,
              iteration + 1,
              setObject,
            ),
          }
        : setObject,
    };
  }
  return undefined;
};

export const setProp = (originObject, path, setObject) => {
  const result = setPropInObject(originObject, path, 0, setObject);
  return result;
};

export const formatToString = (...args) => {
  return args.filter(x => x).join(', ');
};

export const useCodelist = codelist => {
  const codelistLabelByValue = React.useMemo(() => {
    return codelist.reduce((acc, type) => {
      acc[type.value] = type.label || type.value;
      return acc;
    }, {});
  }, [codelist]);

  const getCodelistLabel = React.useCallback(
    value => {
      return codelistLabelByValue[value] || '';
    },
    [codelistLabelByValue],
  );

  return [getCodelistLabel, codelistLabelByValue];
};
