import React, { Fragment } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import moment from 'moment';
import Icon from '@sportnet/ui/Icon';

const WorkflowTitle = styled.h2`
  text-align: center;
  margin-top: 0;
`;

const WorkflowWrapper = styled.ul`
  margin: 0 0 ${rem(20)} 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
`;

const WorkflowItem = styled.li`
  margin: ${rem(10)};
  padding: 0;
  list-style: none;
  text-align: center;
  flex-grow: 1;
  font-size: ${rem(13)};
  ${({ status, theme }) => {
    if (status === 'completed') {
      return `
      ${WorkflowItemStep} {
        color: #fff;
        background: ${theme.color.primary};
        border-color: ${theme.color.primary};
      }
      ${WorkflowItemTitle} {
        font-weight:bold;
        color: #000;
      }
      `;
    } else if (status === 'active') {
      return `
      ${WorkflowItemStep} {
        color: ${theme.color.primary};
        border-color: ${theme.color.primary};
      }
      ${WorkflowItemTitle} {
        font-weight:bold;
        color: #000;
      }
      `;
    }
  }}
`;

const WorkflowItemStep = styled.div`
  margin: ${rem(5)};
  padding: ${rem(5)};
  font-size: ${rem(12)};
  width: ${rem(32)};
  height: ${rem(32)};
  border: 3px solid #bbb;
  display: inline-block;
  border-radius: ${rem(20)};
  text-align: center;
  font-weight: bold;
  color: #bbb;
  background: #fff;
`;
const WorkflowItemTitle = styled.div`
  font-size: ${rem(12)};
  font-weight: ${({ isNext }) => (isNext ? 'bold' : 'normal')};
  margin-bottom: ${rem(5)};
  color: #bbb;
`;
const WorkflowItemCompletition = styled.div`
  font-size: ${rem(11)};
`;

export default ({ workflow }) => {
  return (
    <Fragment>
      {workflow.title && <WorkflowTitle>{workflow.title}</WorkflowTitle>}
      <WorkflowWrapper>
        {workflow.transitions.map((transition, i) => {
          let status = 'pending';
          if (transition.completionDate) {
            status = 'completed';
          } else if (
            workflow.transitions[i - 1] &&
            !!workflow.transitions[i - 1].completionDate
          ) {
            status = 'active';
          }
          return (
            <WorkflowItem status={status} key={String(i)}>
              <WorkflowItemStep>{i + 1}</WorkflowItemStep>
              <WorkflowItemTitle>{transition.title}</WorkflowItemTitle>
              {transition.completionDate && (
                <WorkflowItemCompletition>
                  <Icon
                    name="check"
                    size="m"
                    color="green"
                    style={{ verticalAlign: 'middle' }}
                  />{' '}
                  {moment(transition.completionDate).format('LLL')}
                </WorkflowItemCompletition>
              )}
            </WorkflowItem>
          );
        })}
      </WorkflowWrapper>
    </Fragment>
  );
};
