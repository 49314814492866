import { createSelector } from 'reselect';
import { getProp } from '../../utilities';
import {
  aclSelector as AuthAclSelector,
  activeAppSpaceSelector,
  appInfoSelector,
  applicationDomainSelector,
} from '../Authorization/selectors';
import { ppoEntities } from '../PPO/List/selectors';

const errorDomainSelector = state => state.error;

export const errorMessageSelector = createSelector(
  errorDomainSelector,
  substate => substate.message,
);

export const codelistsSelector = createSelector(
  applicationDomainSelector,
  substate => substate.codeLists,
);

export const addressTypeCodelistSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate['address-type'] || [];
  },
);

export const legalFormsCodelistSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate['legal-form'] || [];
  },
);

export const bankAccountTypeCodelistSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate['bank-account-type'] || [];
  },
);

export const competenceCodelistSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate['sport-org-competence-type'] || [];
  },
);

export const transferTypeCodelistSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate['sport-org-tranfer-type'] || [];
  },
);

export const expertCompetenceCodelistSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate['sport-expert-competence-type'] || [];
  },
);

export const expertLegalFormsCodelistSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate['sport-expert-legal-form'] || [];
  },
);

export const licenceCodelistSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate['license-type'] || [];
  },
);

export const personDocumentCodelistSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate['person-document'] || [];
  },
);

export const sportCodelistSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate.sport || {};
  },
);

const codelistSelector = type =>
  createSelector(codelistsSelector, substate => {
    return substate[type || ''] || [];
  });

export const codelistEntrySelector = (codelistType, value) =>
  createSelector(codelistSelector(codelistType), codelist => {
    return codelist.find(type => type.value === value) || {};
  });

export const sportSectorsCodelistSelector = createSelector(
  sportCodelistSelector,
  activeAppSpaceSelector,
  (sportCodelist, appspace) => {
    const { org_profile: orgProfile = {} } = appspace;
    const sectorsObj = getProp(
      sportCodelist,
      [orgProfile.sport, 'sectors'],
      {},
    );
    return Object.keys(sectorsObj).map(key => sectorsObj[key]);
  },
);

export const sportSportSectorsCodelistSelector = sport =>
  createSelector(sportCodelistSelector, sportCodelist => {
    const sectorsObj = getProp(sportCodelist, [sport, 'sectors'], {});
    return Object.keys(sectorsObj).map(key => sectorsObj[key]);
  });

export const sportsCodelistSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate.sport || {};
  },
);

export const countryCodelistSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate.country || [];
  },
);

export const nationalityCodelistSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate.nationality || [];
  },
);

export const awardsCodelistSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate['award-type'] || [];
  },
);

export const organizationTypeSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate['organization-profile-type'] || [];
  },
);

export const ppoRelationTypeCodelistSelector = createSelector(
  codelistsSelector,
  substate => {
    return substate['ppo-relation-type'] || [];
  },
);

export const applicationRolesSelector = createSelector(
  appInfoSelector,
  appInfo => {
    return getProp(appInfo, ['roles'], []);
  },
);

export const applicationRolesCodelistSelector = createSelector(
  applicationRolesSelector,
  roles => roles.map(r => ({ label: r.name || r.role, value: r.role })),
);

const appsDomain = state => state.apps;

export const appsIsFetchingSelector = createSelector(
  appsDomain,
  domain => domain.isFetching || false,
);

export const appsSelector = createSelector(
  appsDomain,
  domain => domain.apps || [],
);

export const selectedAppDetailsSelector = selectedApps =>
  createSelector(appsDomain, domain => {
    return (domain.apps || []).filter(app => selectedApps[app._id]);
  });

const relatedPposDomainSelector = state => {
  return state.relatedPpos;
};

const relatedPposObjSelector = createSelector(
  activeAppSpaceSelector,
  relatedPposDomainSelector,
  (appspace, domain) => {
    const orgProfileId = getProp(appspace, ['org_profile', '_id']);
    return getProp(domain, [orgProfileId], {});
  },
);

const relatedPposSelector = createSelector(
  ppoEntities,
  relatedPposObjSelector,
  (entities, relatedPposObj) => {
    const ids = getProp(relatedPposObj, ['ids'], []);
    return ids.reduce((acc, id) => {
      const ppo = entities[id];
      if (ppo) {
        acc.push(ppo);
      } else if (relatedPposObj.ppos) {
        // pohladame u seba
        const ppo2 = relatedPposObj.ppos.find(({ _id }) => _id === id);
        if (ppo2) {
          acc.push(ppo2);
        }
      }
      return acc;
    }, []);
  },
);

export const pposSelector = createSelector(
  activeAppSpaceSelector,
  relatedPposSelector,
  (appspace, related) => {
    const result = [...related];
    const ppo = getProp(appspace, ['org_profile'], null);
    if (ppo) {
      result.push(ppo);
    }
    return result;
  },
);

export const myAndParentPPOsCodelistSelector = createSelector(
  ppoEntities,
  activeAppSpaceSelector,
  (byId, activeAppSpace) => {
    const appSpaceId = activeAppSpace.app_space;
    const orgProfile = byId[appSpaceId];
    let result = [];
    if (orgProfile) {
      result = [
        { label: orgProfile.name || orgProfile._id, value: orgProfile._id },
      ];
      const relations = (orgProfile.parent_relations || []).map(relation => ({
        label: relation.name || relation._id,
        value: relation._id,
      }));
      result.push(...relations);
    }

    return result;
  },
);

export const appspaceSelector = activeAppSpaceSelector;
export const aclSelector = AuthAclSelector;
