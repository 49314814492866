import { connect } from 'react-redux';
import { getProp } from '../../utilities';
import { sportsCodelistSelector } from '../App/selectors';

const SectorLabel = ({ sector, sportCodelist }) => {
  if (!sector) {
    return '-';
  }

  const labels = [
    sector.category,
    sector.itemId,
    <strong>{sector.sectorId}</strong>,
  ];
  if (sector.category === 'sport') {
    labels[0] = 'Šport';
    labels[1] = getProp(sportCodelist, [sector.itemId, 'label'], sector.itemId);
    labels[2] = (
      <strong key={`sector-${sector.sectorId}`}>
        {getProp(
          sportCodelist,
          [sector.itemId, 'sectors', sector.sectorId, 'label'],
          sector.sectorId,
        )}
      </strong>
    );
  }
  return labels.reduce((ll, l) => [...ll, ' / ', l]);
};

const mapStateToProps = (state, props) => {
  if (props.sector && props.sector.category === 'sport') {
    return { sportCodelist: sportsCodelistSelector(state) };
  }
  return {};
};

export default connect(mapStateToProps)(SectorLabel);
