import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';
import { withTheme } from 'styled-components';
import { parseNumber, __ } from '../../../utilities';
import { required } from '../../../validation';
import { FORM_NAME } from '../form';
import PriceFormPart from './priceFormPart';
import ValidityFormPart from './validityFormPart';

class MembershipItemForm extends React.PureComponent {
  handlePriceChange = e => {
    const {
      target: { name, value },
    } = e;
    const {
      price: { amount_vat_excl, vat_rate },
      dispatch,
    } = this.props;
    const parsedValue = parseNumber(value);
    if (name === 'price.amount_vat_excl') {
      dispatch(
        change(
          FORM_NAME,
          'price.amount',
          Number(
            parseFloat(parsedValue * ((100 + Number(vat_rate)) / 100)).toFixed(
              2,
            ),
          ),
        ),
      );
    } else if (name === 'price.amount') {
      dispatch(
        change(
          FORM_NAME,
          'price.amount_vat_excl',
          Number(
            parseFloat(parsedValue / ((100 + Number(vat_rate)) / 100)).toFixed(
              2,
            ),
          ),
        ),
      );
    } else if (name === 'price.vat_rate') {
      dispatch(
        change(
          FORM_NAME,
          'price.amount',
          Number(
            parseFloat(
              Number(amount_vat_excl) * ((100 + parsedValue) / 100),
            ).toFixed(2),
          ),
        ),
      );
    }
  };
  render() {
    const {
      ageRestriction,
      sexRestriction,
      dateRestrictionInDays,
      dateRestrictionByDate,
    } = this.props;
    return (
      <Row>
        <Col xs={12}>
          <Field
            name="ageRestriction"
            label={__('Položka obmedzená vekom')}
            type="checkbox"
            component={FormField}
          />
        </Col>
        {ageRestriction && (
          <Row>
            <Col xs={6}>
              <Field
                label={__('Vek od')}
                name="ageFrom"
                type="number"
                validate={[required]}
                parse={value => Number(value)}
                component={FormField}
              />
            </Col>
            <Col xs={6}>
              <Field
                label={__('Vek do')}
                name="ageTo"
                type="number"
                parse={value => (value ? Number(value) : null)}
                component={FormField}
              />
            </Col>
          </Row>
        )}
        <Col xs={12}>
          <Field
            name="sexRestriction"
            label={__('Položka obmedzená pohlavím')}
            type="checkbox"
            component={FormField}
          />
        </Col>
        {sexRestriction && (
          <Col xs={12}>
            <Field
              label={__('Pohlavie')}
              name="sex"
              type="theselectsimple"
              validate={[required]}
              component={FormField}
              options={[
                { label: 'Muž', value: 'M' },
                { label: 'Žena', value: 'F' },
              ]}
            />
          </Col>
        )}
        <PriceFormPart handlePriceChange={this.handlePriceChange} />
        <ValidityFormPart
          dateRestrictionByDate={dateRestrictionByDate}
          dateRestrictionInDays={dateRestrictionInDays}
          onCheckboxCheck={name => {
            this.props.dispatch(change(FORM_NAME, name, false));
          }}
        />
      </Row>
    );
  }
}

MembershipItemForm.propTypes = {
  price: PropTypes.shape({
    amount_vat_excl: PropTypes.number,
    amount: PropTypes.number,
    vat_rate: PropTypes.number,
    currency: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  ageRestriction: PropTypes.bool,
  sexRestriction: PropTypes.bool,
  dateRestrictionByDate: PropTypes.bool,
  dateRestrictionInDays: PropTypes.bool,
};

MembershipItemForm.defaultProps = {
  ageRestriction: false,
  sexRestriction: false,
  dateRestrictionInDays: false,
  dateRestrictionByDate: false,
};

const selector = formValueSelector('INVOICE_ITEM_FORM');

const mapStateToProps = state => {
  return {
    price: selector(state, 'price'),
    ageRestriction: selector(state, 'ageRestriction'),
    sexRestriction: selector(state, 'sexRestriction'),
    dateRestrictionByDate: selector(state, 'dateRestrictionByDate'),
    dateRestrictionInDays: selector(state, 'dateRestrictionInDays'),
  };
};

export default connect(mapStateToProps)(withTheme(MembershipItemForm));
