import {
  RECEIVE_ORGANIZATIONS_SUCCESS,
  RECEIVE_ORGANIZATION_FAILURE,
  RECEIVE_ORGANIZATION_SUCCESS,
  REQUEST_ORGANIZATION,
} from './constants';

export const organizationsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ORGANIZATION:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: true,
        },
      };
    case RECEIVE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          error: false,
        },
      };
    case RECEIVE_ORGANIZATION_FAILURE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          error: action.payload.e,
        },
      };
    default:
      return state;
  }
};

export const organizationsReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        ...action.payload.organizations.reduce((acc, next) => {
          return { ...acc, [next._id]: next };
        }, {}),
      };
    default:
      return state;
  }
};
