import {
  ArrayParam,
  NumberParam,
  StringParam,
} from '@sportnet/query-hoc/useQuery';

const namespace = 'FO';

export const REQUEST_USERS = `${namespace}/REQUEST_USERS`;
export const RECEIVE_USERS_SUCCESS = `${namespace}/RECEIVE_USERS_SUCCESS`;
export const RECEIVE_USERS_FAILURE = `${namespace}/RECEIVE_USERS_FAILURE`;

export const SET_SEARCH_QUERY = `${namespace}/SET_SEARCH_QUERY`;
export const SET_SEARCH_FILTER = `${namespace}/SET_SEARCH_FILTER`;

export const REQUEST_USER = `${namespace}/REQUEST_USER`;
export const RECEIVE_USER_FAILURE = `${namespace}/RECEIVE_USER_FAILURE`;
export const RECEIVE_USER_SUCCESS = `${namespace}/RECEIVE_USER_SUCCESS`;

export const RECEIVE_USER_ADDRESSES_SUCCESS = `${namespace}/RECEIVE_USER_ADDRESSES_SUCCESS`;
export const RECEIVE_USER_BANK_ACCOUNTS_SUCCESS = `${namespace}/RECEIVE_USER_BANK_ACCOUNTS_SUCCESS`;
export const RECEIVE_USER_ATHLETES_SUCCESS = `${namespace}/RECEIVE_USER_ATHLETES_SUCCESS`;
export const RECEIVE_USER_EXPERTS_SUCCESS = `${namespace}/RECEIVE_USER_EXPERTS_SUCCESS`;
export const RECEIVE_USER_AWARDS_SUCCESS = `${namespace}/RECEIVE_USER_AWARDS_SUCCESS`;

export const SET_ACTIVE_USER = `${namespace}/SET_ACTIVE_USER`;

export const RECEIVE_USER_ATHLETE_SUCCESS = `${namespace}/RECEIVE_USER_ATHLETE_SUCCESS`;
export const RECEIVE_USER_EXPERT_SUCCESS = `${namespace}/RECEIVE_USER_EXPERT_SUCCESS`;

export const RECEIVE_USER_LICENCES_SUCCESS = `${namespace}/RECEIVE_LICENCES_SUCCESS`;
export const RECEIVE_USER_LICENCES_FAILURE = `${namespace}/RECEIVE_LICENCES_FAILURE`;

export const RECEIVE_USER_FORMAL_PHOTOS_SUCCESS = `${namespace}/RECEIVE_FORMAL_PHOTOS_SUCCESS`;
export const RECEIVE_USER_FORMAL_PHOTOS_FAILURE = `${namespace}/RECEIVE_FORMAL_PHOTOS_FAILURE`;

export const RECEIVE_CUSTOM_FIELDS_SUCCESS = `${namespace}/RECEIVE_CUSTOM_FIELDS_SUCCESS`;
export const RECEIVE_USER_MEMBERSHIPS_SUCCESS = `${namespace}/RECEIVE_USER_MEMBERSHIPS_SUCCESS`;
export const RECEIVE_USER_REGNRS_SUCCESS = `${namespace}/RECEIVE_USER_REGNRS_SUCCESS`;
export const RECEIVE_USER_BIOS_SUCCESS = `${namespace}/RECEIVE_USER_BIOS_SUCCESS`;
export const RECEIVE_USER_MEDICAL_EXAMINATIONS_SUCCESS = `${namespace}/RECEIVE_USER_MEDICAL_EXAMINATIONS_SUCCESS`;
export const REQUEST_USER_MEDICAL_EXAMINATIONS = `${namespace}/REQUEST_USER_MEDICAL_EXAMINATIONS`;
export const RECEIVE_USER_MEDICAL_EXAMINATIONS_FAILURE = `${namespace}/RECEIVE_USER_MEDICAL_EXAMINATIONS_FAILURE`;

export const USERS_LIST = 'USERS_LIST';
export const USERS_LIST_QUERY_HOC_CONFIG = {
  parameters: {
    query: StringParam(''),
    offset: NumberParam(0),
    limit: NumberParam(100),
    athlet_type: ArrayParam(StringParam(''), [], ','),
    sport_expert_type: ArrayParam(StringParam(''), [], ','),
    license_type: ArrayParam(StringParam(''), [], ','),
    membershipValidTo: StringParam(''),
    membershipValidToIsInvalid: StringParam('', 'false'),
    membershipToPPO: StringParam(''),
    sorter: StringParam('-_id'),
  },
};
