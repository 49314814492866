import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import React from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { __ } from '../../../utilities';
import validation from '../../../utilities/validation';
import { appspaceSelector, countryCodelistSelector } from '../../App/selectors';
import { required } from '../../../validation';

class ContactInfoForm extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} s={6} m={3}>
            <Field
              label={__('ID profilu')}
              format={() => this.props.appspace.app_space}
              type="text"
              component={FormField}
              readonly
              disabled
            />
          </Col>
          <Col xs={12} s={6} m={3}>
            <Field
              label={__('Názov profilu')}
              name="contactInfo.name"
              type="text"
              component={FormField}
              required
            />
          </Col>
          <Col xs={12} s={6} m={3}>
            <Field
              label={__('Kontaktná osoba')}
              name="contactInfo.contactPerson"
              type="text"
              component={FormField}
            />
          </Col>
          <Col xs={12} s={6} m={3}>
            <Field
              label={__('Krajina')}
              name="contactInfo.country"
              type="theselectsimple"
              options={this.props.countries}
              validate={[required]}
              component={FormField}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} s={6} m={3}>
            <Field
              label={__('Ulica')}
              name="contactInfo.address.street"
              type="text"
              component={FormField}
            />
          </Col>
          <Col xs={12} s={6} m={3}>
            <Field
              label={__('Číslo')}
              name="contactInfo.address.number"
              type="text"
              component={FormField}
            />
          </Col>
          <Col xs={12} s={6} m={3}>
            <Field
              label={__('Mesto')}
              name="contactInfo.address.city"
              type="text"
              component={FormField}
            />
          </Col>
          <Col xs={12} s={6} m={3}>
            <Field
              label={__('PSČ')}
              name="contactInfo.address.zip"
              type="text"
              component={FormField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} s={6} m={3}>
            <Field
              label={__('E-mailová adresa')}
              name="contactInfo.email"
              type="text"
              component={FormField}
              validate={[validation.email]}
            />
          </Col>
          <Col xs={12} s={6} m={3}>
            <Field
              label={__('Web')}
              name="contactInfo.www"
              type="text"
              component={FormField}
            />
          </Col>
          <Col xs={12} s={6} m={3}>
            <Field
              label={__('Telefón')}
              name="contactInfo.phone"
              type="text"
              component={FormField}
            />
          </Col>
          <Col xs={12} s={6} m={3}>
            <Field
              label={__('Fax')}
              name="contactInfo.fax"
              type="text"
              component={FormField}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  appspace: appspaceSelector(state),
  countries: countryCodelistSelector(state),
}))(ContactInfoForm);
