import FormField from '@sportnet/ui/FormField/redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { __ } from '../../../../utilities';
import { required } from '../../../../validation';
import {
  competenceCodelistSelector,
  transferTypeCodelistSelector,
} from '../../../App/selectors';
import { codelistDefinition } from '../../definitions';

const AthletesForm = ({ data, competences, transferTypes, isOwner }) => {
  return (
    <>
      <Field
        component={FormField}
        type="theselectsimple"
        label={__('Typ príslušnosti')}
        disabled={data && !isOwner}
        required
        name="competence_type"
        validate={[required]}
        options={competences.map(competence => ({
          label: competence.label,
          value: competence.value,
        }))}
        data-testid="CRM_FO_Input_Affiliations_Type"
      />

      <Field
        component={FormField}
        type="theselectsimple"
        label={__('Typ transféru')}
        required
        disabled={data && !isOwner}
        validate={[required]}
        name="transfer_type"
        options={transferTypes.map(type => ({
          label: type.label,
          value: type.value,
        }))}
        data-testid="CRM_FO_Input_Affiliations_TypeTrans"
      />

      <Field
        component={FormField}
        label={__('Dátum od')}
        name="date_from"
        disabled={data && !isOwner}
        isClearable={false}
        validate={[required]}
        type="date"
        required
        data-testid="CRM_FO_Input_Affiliations_DateFro"
      />

      <Field
        component={FormField}
        label={__('Dátum do')}
        name="date_to"
        type="date"
        disabled={data && !isOwner}
        isClearable={false}
        data-testid="CRM_FO_Input_Affiliations_DateTo"
      />
    </>
  );
};

const mapStateToProps = state => ({
  competences: competenceCodelistSelector(state),
  transferTypes: transferTypeCodelistSelector(state),
});

AthletesForm.propTypes = {
  competences: PropTypes.arrayOf(codelistDefinition).isRequired,
  transferTypes: PropTypes.arrayOf(codelistDefinition).isRequired,
  data: PropTypes.shape({}),
  isOwner: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(AthletesForm);
