import { createSelector } from 'reselect';

const organizationDomainSelector = state => state.organization;

export const organizationProfileDomainSelector = createSelector(
  organizationDomainSelector,
  substate => substate.profile,
);

export const organizationAddressesDomainSelector = createSelector(
  organizationDomainSelector,
  substate => substate.addresses,
);

export const organizationBankAccountsDomainSelector = createSelector(
  organizationDomainSelector,
  substate => substate.bankAccounts,
);

export const organizationCustomFieldsDomainSelector = createSelector(
  organizationDomainSelector,
  substate => substate.customFields,
);

export const organizationProfileDetailSelector = createSelector(
  organizationProfileDomainSelector,
  substate => substate.data || {},
);

export const organizationAddressesListSelector = createSelector(
  organizationAddressesDomainSelector,
  substate => {
    const data = Object.keys(substate.data);
    if (data.length > 0) {
      return data.map(idx => {
        return substate.data[idx];
      });
    }
    return [];
  },
);

export const organizationBankAccountsListSelector = createSelector(
  organizationBankAccountsDomainSelector,
  substate => {
    const data = Object.keys(substate.data);
    if (data.length > 0) {
      return data.map(idx => {
        return substate.data[idx];
      });
    }
    return [];
  },
);

export const organizationCustomFieldsListSelector = createSelector(
  organizationCustomFieldsDomainSelector,
  substate => {
    const data = Object.keys(substate.data);
    if (data.length > 0) {
      return data.map(idx => {
        return substate.data[idx];
      });
    }
    return [];
  },
);

export const organizationIsFetching = createSelector(
  organizationProfileDomainSelector,
  organizationAddressesDomainSelector,
  organizationBankAccountsDomainSelector,
  organizationCustomFieldsDomainSelector,
  (profile, addresses, accounts, customFields) => {
    if (
      profile.isFetching ||
      addresses.isFetching ||
      accounts.isFetching ||
      customFields.isFetching
    ) {
      return true;
    }
    return false;
  },
);
