import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, Field, reduxForm } from 'redux-form';
import { formatUserName } from '@sportnet/utilities';
import Api from '../../../Api';
import { __ } from '../../../utilities';
import { required } from '../../../validation';
import { codelistDefinition } from '../../FO/definitions';

class PersonChangeForm extends React.PureComponent {
  state = {
    isFetchingUser: false,
  };

  fetchUser = async event => {
    if (!event.target.value) {
      return;
    }
    try {
      this.setState({
        isFetchingUser: true,
      });
      const user = await Api.getPublicUserProfile(event.target.value);
      this.props.dispatch(
        change(this.props.form, 'user', {
          sportnetId: user._id,
          name: formatUserName(user),
        }),
      );
    } catch (e) {
      alert(
        __('Nebolo možné získať detail užívateľa podľa zadaného sportnet ID'),
      );
      this.props.dispatch(change(this.props.form, 'user', {}));
    } finally {
      this.setState({
        isFetchingUser: false,
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Field
              label={__('Typ zmeny')}
              name="type"
              component={FormField}
              required
              validate={[required]}
              type="theselect"
              options={this.props.actionTypes}
            />
          </Col>
          <Col xs={12}>
            <Field
              label={__('Sportnet ID')}
              name="user.sportnetId"
              component={FormField}
              required
              validate={[required]}
              onBlur={this.fetchUser}
              loading={this.state.isFetchingUser}
            />
          </Col>
          <Col xs={12}>
            <Field
              label={__('Meno a priezvisko')}
              name="user.name"
              component={FormField}
              required
              validate={[required]}
              loading={this.state.isFetchingUser}
            />
          </Col>
          <Col xs={12}>
            <Field
              label={__('Typ oprávnenej osoby')}
              name="user.type"
              component={FormField}
              required
              validate={[required]}
              type="theselect"
              options={this.props.positions}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

PersonChangeForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  positions: PropTypes.arrayOf(codelistDefinition).isRequired,
  actionTypes: PropTypes.arrayOf(codelistDefinition).isRequired,
};

export default compose(reduxForm({}), connect())(PersonChangeForm);
