import {
  RECEIVE_APPS_FAILURE,
  RECEIVE_APPS_SUCCESS,
  REQUEST_APPS,
} from './constants';

export const myApplicationsReducer = (
  state = {
    data: [],
    isFetching: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_APPS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_APPS_SUCCESS:
      return {
        ...state,
        data: action.payload.apps,
        isFetching: false,
      };
    case RECEIVE_APPS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
      };
    default:
      return state;
  }
};
