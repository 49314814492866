import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import BasicTable from '@sportnet/ui/BasicTable';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Segment from '@sportnet/ui/Segment';
import Input from '@sportnet/ui/Input';
import Layout from '../../components/Layout';
import { __ } from '../../utilities';
import Api from '../../Api';

const CustomCodelist = ({
  codelistId,
  codelistName,
  match: {
    params: { appspace },
  },
}) => {
  const [items, setItems] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  useEffect(() => {
    setIsFetching(true);
    Api.getPPOCodelist(appspace, codelistId)
      .then(({ codelist }) => {
        setItems(codelist);
      })
      .catch(e => {
        alert(
          e.details
            ? e.details.userinfo || __('Pri získavaní číselníka nastala chyba')
            : e,
        );
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [appspace, codelistId, codelistName]);
  return (
    <Layout
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              basic
              primary
              disabled={!isDirty}
              loading={isSaving}
              onClick={() => {
                setIsSaving(true);
                Api.setPPOCodelist(appspace, codelistId, {
                  data: {
                    items: items.filter(({ _isVirtual }) => !_isVirtual),
                  },
                })
                  .then(({ codelist }) => {
                    setItems(codelist);
                    setIsDirty(false);
                  })
                  .catch(e => {
                    alert(
                      e.details
                        ? e.details.userinfo ||
                            __('Pri ukladaní číselníka nastala chyba')
                        : e,
                    );
                  })
                  .finally(() => {
                    setIsSaving(false);
                  });
              }}
            >
              {__('Uložiť zmeny')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext title={`${__('Číselník')}: ${codelistName || codelistId}`} />
      <Segment noBottomGutter>
        <Segment noBottomGutter raised loading={isFetching}>
          <BasicTable
            columns={[
              {
                id: 'label',
                header: __('Hodnota'),
              },
              {
                id: 'value',
                header: __('Názov (nepovinný)'),
              },
              {
                id: 'control',
                header: '',
              },
            ]}
            rows={[...items, { _isVirtual: true, value: '' }]}
            renderRow={(item, index) => {
              return [
                <Input
                  value={item.value}
                  disabled={isSaving}
                  placeholder={
                    index === items.length ? __('Nová hodnota') : __('Hodnota')
                  }
                  onChange={event => {
                    const newItems = [...items];
                    newItems[index] = {
                      ...item,
                      value: event.target.value,
                      label: item.label,
                    };
                    delete newItems[index]._isVirtual;
                    setItems(newItems);
                    setIsDirty(true);
                  }}
                />,
                <Input
                  value={item.label || ''}
                  disabled={isSaving}
                  placeholder={item.value || __('Názov (nepovinný)')}
                  onChange={event => {
                    const newItems = [...items];
                    newItems[index] = { ...item };
                    if (event.target.value) {
                      newItems[index].label = event.target.value;
                    } else {
                      delete newItems[index].label;
                    }
                    delete newItems[index]._isVirtual;
                    setItems(newItems);
                    setIsDirty(true);
                  }}
                />,
                !item._isVirtual && (
                  <Button
                    danger
                    icon="trash"
                    disabled={isSaving}
                    onClick={() => {
                      const newItems = [...items].filter(
                        ({ _isVirtual }) => !_isVirtual,
                      );
                      newItems.splice(index, 1);
                      setItems(newItems);
                      setIsDirty(true);
                    }}
                  />
                ),
              ];
            }}
            rowKey="_id"
          />
        </Segment>
      </Segment>
    </Layout>
  );
};

export default connect(state => {
  return {};
})(CustomCodelist);
