import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Segment from '@sportnet/ui/Segment';
import moment from 'moment';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, Form, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { __ } from '../../../utilities';
import {
  onlyIntegers,
  onlyPositiveNumbers,
  required,
} from '../../../validation';

const FORM_NAME = 'SEQUENCE_FORM';

const Description = styled.div`
  font-size: ${rem(13)};
  padding: ${rem(5)} 0;
  color: #3e3e3e;
`;

class SequenceForm extends PureComponent {
  render() {
    const { pattern, value, handleSubmit } = this.props;
    let formattedPattern = pattern;
    if (formattedPattern) {
      const nrpatternParsed = moment().format(pattern);
      const split = nrpatternParsed.match(new RegExp('([^#]*)(#+)$'));
      const intValue = Number(value);
      if (split && Number.isInteger(intValue) && intValue >= 0) {
        formattedPattern =
          split[1] + String(intValue + 1).padStart(split[2].length, '0');
      } else {
        formattedPattern = nrpatternParsed;
      }
    }
    return (
      <Segment>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                label={__('Formát')}
                name="pattern"
                required
                validate={[required]}
                component={FormFieldRedux}
              />
            </Col>
            <Col>
              <Field
                label={__('Aktuálne číslo')}
                name="value"
                required
                validate={[required, onlyIntegers, onlyPositiveNumbers]}
                component={FormFieldRedux}
              />
            </Col>
            <Col>
              <FormField
                label={__('Nasledujúce číslo dokumentu')}
                value={formattedPattern}
                readOnly
              />
            </Col>
            <Col>
              <Button type="submit" primary>
                {__('Uložiť')}
              </Button>
            </Col>
            <Col>
              <br />
              <Segment raised>
                <Description>
                  {__(
                    'Sekvencia číslovania používa niektoré písmená pre vygenerovanie časti dátumu/času vzniku dokumentu a znak "#" pre vygenerovania poradia.',
                  )}
                </Description>
                <Description>
                  {__(
                    'V prípade, že sa písmeno používa na vygenerovanie objektu dátumu a prajete si ho použiť ako bežné písmeno, je potrebné písmeno alebo súbor písmen obaliť do hranatých zátvoriek, napr. výsledkom "YYYY####" je 20180001 ale výsledkom "[YYYY]####" je YYYY0001.',
                  )}
                </Description>
                <Description>{__('Ide najmä o tieto písmená:')}</Description>
                <Description>
                  <b>M: </b>
                  {__('Poradie mesiaca v roku (1, 2, ...11, 12)')}
                  <br />
                  <b>MM: </b>
                  {__('Poradie mesiaca v roku s nulou (01, 02, ...11, 12)')}
                  <br />
                  <b>D: </b>
                  {__('Deň v mesiaci (1, 2, ...11, 12)')}
                  <br />
                  <b>DD: </b>
                  {__('Deň v mesiaci s nulou (01, 02, ...11, 12)')}
                  <br />
                  <b>DDD: </b>
                  {__('Deň v roku (1, 2, ...134, 135)')}
                  <br />
                  <b>DDDD: </b>
                  {__('Deň v roku s nulami (001, 002, ...134, 135)')}
                  <br />
                  <b>W: </b>
                  {__('Týždeň v roku (1, 2, ...34, 35)')}
                  <br />
                  <b>WW: </b>
                  {__('Týždeň v roku s nulami (01, 02, ...34, 35)')}
                  <br />
                  <b>YY: </b>
                  {__('Skrátený rok (18, 19, ...31, 32)')}
                  <br />
                  <b>YYYY: </b>
                  {__('Celý rok (2018, 2019, ...2031, 2032)')}
                  <br />
                </Description>
              </Segment>
            </Col>
          </Row>
        </Form>
      </Segment>
    );
  }
}

SequenceForm.propTypes = {
  pattern: PropTypes.string.isRequired,
  value: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
};

SequenceForm.defaultProps = {
  value: 0,
};

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, ownProps) => {
  const storeProps = {
    pattern: selector(state, 'pattern'),
    value: selector(state, 'value'),
  };
  if (ownProps.data) {
    storeProps.initialValues = ownProps.data;
  }
  return storeProps;
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(SequenceForm);

export default connect(mapStateToProps)(connected);
