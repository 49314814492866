import Segment from '@sportnet/ui/Segment';
import Tabber from '@sportnet/ui/Tabber';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { __ } from '../../../utilities';
import { matchDefinition } from '../../FO/definitions';
import { organizationProfileDetailSelector } from '../selectors';

class PODetailWrapper extends PureComponent {
  render() {
    const {
      activeTab,
      isFetching,
      match: {
        params: { appspace },
      },
    } = this.props;
    return (
      <div>
        <Tabber
          active={activeTab}
          isFetching={isFetching}
          onClickItem={itemName => {
            switch (itemName) {
              case __('Základné údaje'):
                this.props.history.push(`/admin/${appspace}/me/profile`);
                break;
              case __('Adresy'):
                this.props.history.push(`/admin/${appspace}/me/addresses`);
                break;
              case __('Bankové účty'):
                this.props.history.push(`/admin/${appspace}/me/bank-accounts`);
                break;
              case __('Ostatné'):
                this.props.history.push(`/admin/${appspace}/me/custom`);
                break;
              case __('Členstvo'):
                this.props.history.push(`/admin/${appspace}/me/membership`);
                break;
              case __('Kontaktné osoby'):
                this.props.history.push(
                  `/admin/${appspace}/me/contact-persons`,
                );
                break;
              default:
                break;
            }
          }}
          items={[
            __('Základné údaje'),
            __('Adresy'),
            __('Bankové účty'),
            __('Ostatné'),
            __('Členstvo'),
            __('Kontaktné osoby'),
          ]}
        >
          <Segment>{this.props.children}</Segment>
        </Tabber>
      </div>
    );
  }
}

PODetailWrapper.propTypes = {
  children: PropTypes.node,
  activeTab: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  match: matchDefinition.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

PODetailWrapper.defaultProps = {
  children: <div />,
  isFetching: false,
};

const mapStateToProps = state => ({
  org_profile: organizationProfileDetailSelector(state),
});

export default connect(mapStateToProps)(withRouter(PODetailWrapper));
