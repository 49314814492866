import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Segment from '@sportnet/ui/Segment';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import BaseTheme from '@sportnet/ui/Themes/baseTheme';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isSubmitting, SubmissionError, submit } from 'redux-form';
import { getProp } from '@sportnet/utilities';
import Api from '../../Api';
import Layout from '../../components/Layout';
import { __ } from '../../utilities';
import { appspaceSelector } from '../App/selectors';
import { matchDefinition } from '../FO/definitions';
import CommonForm, { FORM_NAME } from './CommonSettings/index';

class Settings extends React.PureComponent {
  state = {
    isFetching: false,
    profileLogoSrc: null,
    profileFaviconSrc: null,
    gdpr: '',
    theme: null,
    contactInfo: null,
  };
  componentDidMount() {
    this.getPPOSettings();
  }

  getPPOSettings = async (props = this.props) => {
    const {
      match: {
        params: { appspace },
      },
    } = props;
    this.setState({
      isFetching: true,
    });
    try {
      const result = {};
      await Promise.all([
        // GDPR
        Api.crmGetGDPRBody(appspace).then(res => {
          result.gdpr = res.gdpr;
        }),
        // Logo + favicon
        Api.organizationPPOProfile(appspace).then(ppo => {
          result.profileLogoSrc = ppo.logo_public_url || null;
          result.profileFaviconSrc = ppo.favicon_public_url || null;
        }),
        // Theme
        Api.organizationPPOTheme(appspace).then(theme => {
          result.theme = theme;
        }),
        // Contact info
        Api.organizationPPOContactInfo(appspace).then(contactInfo => {
          result.contactInfo = contactInfo;
        }),
      ]);
      this.setState(result);
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        window.alert(__('Nepodarilo sa získať základné údaje'));
      }, 0);
    } finally {
      this.setState({
        isFetching: false,
      });
    }
  };

  getThemeWithDefaults = theme => {
    return {
      color: {
        primary: getProp(theme, ['color', 'primary'], BaseTheme.color.primary),
      },
    };
  };

  submit = async data => {
    const {
      match: {
        params: { appspace },
      },
    } = this.props;
    const {
      logoUpload,
      logoDelete,
      gdpr,
      theme,
      contactInfo,
      faviconUpload,
      faviconDelete,
    } = data;
    const errors = {};
    await Promise.all([
      // Logo
      (() => {
        if (logoUpload) {
          return Api.crmUpdateProfileLogo(appspace, {
            upload: logoUpload,
          }).catch(() => {
            errors.logoUpload = __('Nahratie loga');
          });
        } else if (logoDelete) {
          return Api.crmDeleteProfileLogo(appspace).catch(() => {
            errors.logoDelete = __('Vymazanie loga');
          });
        }
        return null;
      })(),
      // Favicon
      (() => {
        if (faviconUpload) {
          return Api.crmUpdateProfileFavicon(appspace, {
            upload: faviconUpload,
          }).catch(() => {
            errors.faviconUpload = __('Nahratie obrázka favicon');
          });
        } else if (faviconDelete) {
          return Api.crmDeleteProfileFavicon(appspace).catch(() => {
            errors.faviconDelete = __('Vymazanie obrázka favicon');
          });
        }
        return null;
      })(),
      // Gdpr
      Api.crmUpdateGDPRBody(appspace, { data: { gdpr } }).catch(() => {
        errors.gdpr = __('Súhlas s GDPR');
      }),
      // Theme
      Api.crmUpdateTheme(appspace, { data: theme || {} }).catch(() => {
        errors.theme = __('Téma');
      }),
      // Contact info
      Api.crmUpdateContactInfo(appspace, {
        data: {
          ...(contactInfo || {}),
          address: { ...contactInfo?.address, country: contactInfo?.country },
        },
      }).catch(() => {
        errors.contactInfo = __('Kontaktné údaje');
      }),
    ]);
    // One or more updates failed
    if (Object.keys(errors).length > 0) {
      const submissionError = {
        _error: __('Uloženie nastavení sa nepodarilo'),
      };
      const text = `${__(
        'Uloženie týchto nastavení sa nepodarilo',
      )}:\n${Object.keys(errors)
        .map(key => errors[key])
        .join('\n')}`;
      setTimeout(() => {
        window.alert(text);
      }, 0);
      throw new SubmissionError(submissionError);
    }
  };

  handleSave = () => {
    this.props.dispatch(submit(FORM_NAME));
  };

  render() {
    const {
      gdpr,
      profileLogoSrc,
      profileFaviconSrc,
      isFetching,
      theme,
      contactInfo,
    } = this.state;
    const { submitting } = this.props;

    return (
      <Layout
        isFetching={isFetching}
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                loading={submitting}
                disabled={submitting || isFetching}
                primary
                onClick={this.handleSave}
              >
                {__('Uložiť')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        }
      >
        <AppContext title={__('Profil právnickej osoby')} />
        <Segment>
          <CommonForm
            profileLogoSrc={profileLogoSrc}
            profileFaviconSrc={profileFaviconSrc}
            initialValues={{
              gdpr,
              theme: this.getThemeWithDefaults(theme),
              contactInfo,
            }}
            onSubmit={this.submit}
          />
        </Segment>
      </Layout>
    );
  }
}

Settings.propTypes = {
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  match: matchDefinition.isRequired,
};

Settings.defaultProps = {
  submitting: false,
};

const mapStateToProps = state => ({
  appspace: appspaceSelector(state),
  submitting: isSubmitting(FORM_NAME)(state),
});

export default connect(mapStateToProps)(withRouter(Settings));
