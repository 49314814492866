import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import { appspaceSelector } from '../../../App/selectors';
import { formalPhotoDefinition } from '../../definitions';
import {
  getActiveUserFormalPhotosSelector,
  getActiveUserIsFetchingSelector,
} from '../../selectors';
import styled, { css } from 'styled-components';
import Wrapper from '../index';
import Segment from '@sportnet/ui/Segment';
import { useParams } from 'react-router-dom';
import Image from '@sportnet/ui/Image';
import { getUserFormalPhotos } from '../../actions';

const Faded = styled.span`
  color: ${({ theme }) => theme.color.fadedText};
`;

const ImageWrapper = styled.div`
  ${({ isPublic = false }) =>
    !isPublic &&
    css`
      img {
        opacity: 0.2;
      }
    `}
`;

const PhotosList = ({ dispatch, photos, isFetching }) => {
  const { appspace, sportnetId } = useParams();

  React.useEffect(() => {
    dispatch(getUserFormalPhotos(sportnetId, appspace));
  }, [sportnetId, appspace, dispatch]);

  return (
    <Layout>
      <Wrapper isFetching={isFetching} activeTab={__('Fotky')}>
        <Segment raised loading={isFetching}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Organizácia')}</Th>
                <Th>{__('Fotka')}</Th>
              </Tr>
            </Thead>
            {photos && (
              <Tbody>
                {photos.map(item => {
                  return (
                    <Tr>
                      <Td>
                        {' '}
                        {item.org_name}
                        <br />
                        <Faded>{item.org_profile_name}</Faded>
                      </Td>
                      <Td>
                        <ImageWrapper isPublic={item.public}>
                          <Image src={item.public_url} width={40} />
                        </ImageWrapper>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            )}
          </Table>
        </Segment>
      </Wrapper>
    </Layout>
  );
};

PhotosList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  photos: PropTypes.arrayOf(formalPhotoDefinition).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    photos: getActiveUserFormalPhotosSelector(state),
    isFetching: getActiveUserIsFetchingSelector(state),
    appspace: appspaceSelector(state),
  };
};

export default connect(mapStateToProps)(PhotosList);
