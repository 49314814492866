import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
// import Icon from '@sportnet/ui/Icon';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
// import Text from '@sportnet/ui/Text';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formatUserName, formatPrice } from '@sportnet/utilities';
import styled from 'styled-components';
import Api from '../../../Api';
import { __ } from '../../../utilities';
import { appspaceSelector } from '../../App/selectors';
import { format, add } from 'date-fns';

const TableWrapper = styled.div`
  max-height: ${rem(400)};
  overflow-y: auto;
`;

// const ErrorWrapper = styled.div`
//   display: flex;
//   align-items: center;
// `;

const CloseZFAsModal = ({
  isOpen,
  handleClose,
  onSuccess,
  onSuccessClose,
  appSpaceId,
  options,
  items,
}) => {
  const [submitting, setSubmitting] = React.useState(false);
  // const [resultByUserId, setResultByUserId] = React.useState({});
  const [createdDate, setCreatedDate] = React.useState(
    options.createdDate || new Date(),
  );
  const [payDueDate, setPayDueDate] = React.useState(
    options.payDueDate || new Date(),
  );
  const [deliveryDate, setDeliveryDate] = React.useState(
    options.deliveryDate || new Date(),
  );

  // React.useEffect(() => {
  //   setCurrentMembershipToPPO(membershipToPPO);
  // }, [membershipToPPO]);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await Api.commitOpenedInvoiceItems(appSpaceId, {
        data: {
          invoiceItemIds: items.map(({ itemId }) => itemId),
          createdDate,
          deliveryDate,
          payDueDate,
        },
      });
      alert(__('Faktúry boli vygenerované'));
      onSuccess();
    } catch (e) {
      console.error(e);
      window.alert(__('Vygenerovanie faktúr sa nepodarilo'));
    } finally {
      setSubmitting(false);
    }
  };

  const handleCloseWithCallbacks = () => {
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleCloseWithCallbacks}>
      <ModalContent>
        <Header size="s" withSeparator>
          {__('Vytvorenie faktúr zo zberných položiek')}
        </Header>
        <Row>
          <Col xs={12} m={4}>
            <FormField
              label={__('Dátum vystavenia')}
              name="createdDate"
              type="date"
              value={createdDate}
              onChange={setCreatedDate}
              disabled={submitting}
              required
            />
          </Col>
          <Col xs={12} m={4}>
            <FormField
              label={__('Dátum zdaniteľného plnenia')}
              name="deliveryDate"
              type="date"
              value={deliveryDate}
              onChange={setDeliveryDate}
              disabled={submitting}
              required
            />
          </Col>
          <Col xs={12} m={4}>
            <FormField
              label={__('Dátum splatnosti')}
              name="payDueDate"
              type="date"
              value={payDueDate}
              onChange={setPayDueDate}
              disabled={submitting}
              required
            />
          </Col>
        </Row>
        <TableWrapper>
          <BasicTable
            columns={[
              {
                id: 'buyer',
                header: `${__('Odberateľ')}`,
              },
              {
                id: 'item',
                header: `${__('Položka')}`,
              },
              {
                id: 'item',
                header: `${__('Dátum vzniku položky')}`,
              },
              {
                id: 'item',
                header: `${__('Cena bez DPH')}`,
              },
              {
                id: 'item',
                header: `${__('Cena s DPH')}`,
              },
            ]}
            rows={items}
            renderRow={({ item, ...invoice }) => {
              return [
                formatUserName(invoice.buyer),
                `${item.quantity}x ${item.name}`,
                `${
                  item.createdDT
                    ? format(new Date(item.createdDT), 'dd.MM.yyyy')
                    : '-'
                }`,
                `${formatPrice(
                  item.totalPrice.amount_vat_excl,
                  item.totalPrice.currency,
                )}`,
                `${formatPrice(
                  item.totalPrice.amount,
                  item.totalPrice.currency,
                )}`,
              ];
            }}
            rowKey="_id"
          />
        </TableWrapper>
      </ModalContent>

      <ModalActions>
        <Button onClick={handleCloseWithCallbacks} disabled={submitting}>
          {__('Zavrieť')}
        </Button>
        <Button
          loading={submitting}
          disabled={submitting}
          primary
          onClick={handleSubmit}
        >
          {__('Vygenerovať faktúry')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    appSpaceId: appspaceSelector(state).app_space,
  };
};

const mapDispatchToProps = {};

CloseZFAsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onSuccessClose: PropTypes.func.isRequired,
  appSpaceId: PropTypes.string.isRequired,
  options: PropTypes.shape({
    createdDate: PropTypes.instanceOf(Date),
    payDueDate: PropTypes.instanceOf(Date),
    deliveryDate: PropTypes.instanceOf(Date),
  }),
  theme: PropTypes.shape({
    color: PropTypes.shape({
      success: PropTypes.string.isRequired,
      danger: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

CloseZFAsModal.defaultProps = {
  membershipToPPO: null,
  options: {
    createdDate: new Date(),
    payDueDate: add(new Date(), { days: 14 }),
    deliveryDate: new Date(),
  },
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CloseZFAsModal,
);
