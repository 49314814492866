import { format } from 'date-fns/format';
import { sk } from 'date-fns/locale/sk';

const formatDate = (date, formatStr) => {
  return format(date, formatStr, {
    locale: sk,
  });
};

export default formatDate;
