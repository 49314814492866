import Button from '@sportnet/ui/Button';
import FormGroup from '@sportnet/ui/FormGroup';
import Input from '@sportnet/ui/Input';
import Label from '@sportnet/ui/Label/Label';
import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Api from '../../../../Api';
import { __ } from '../../../../utilities';
import { activeAppSpaceRelationsSelector } from '../../../Authorization/selectors';
import { matchDefinition } from '../../../FO/definitions';
import {
  createOrganizationCustomField,
  updateOrganizationCustomField,
} from '../../actions';

class CustomFieldForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if (this.props.parameters.field) {
      this.setInitialValues(this.props.parameters.field);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      (!this.props.parameters.field && nextProps.parameters.field) ||
      (nextProps.parameters.field &&
        nextProps.parameters.field._id !== this.props.parameters.field._id)
    ) {
      this.setInitialValues(nextProps.parameters.field);
    }
  }
  onChangeValue = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  setInitialValues = values => {
    Object.keys(values).forEach(key => {
      this.onChangeValue(key, values[key]);
    });
  };
  createCustomField = async () => {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;
    const { label, value } = this.state;
    if (this.props.relations.length) {
      await Api.crmMyOrganizationRequestChange(appspace, {
        data: {
          custom: [
            {
              label,
              value,
            },
          ],
        },
      });
    } else {
      await dispatch(
        createOrganizationCustomField(appspace, {
          label,
          value,
        }),
      );
    }
    this.props.closeSidebar();
  };
  editCustomField = async () => {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;
    const { _id, label, value } = this.state;
    if (this.props.relations.length) {
      await Api.crmMyOrganizationRequestChange(appspace, {
        data: {
          custom: [
            {
              _id,
              label,
              value,
            },
          ],
        },
      });
    } else {
      await dispatch(
        updateOrganizationCustomField(appspace, _id, {
          label,
          value,
        }),
      );
    }
    this.props.closeSidebar();
  };
  render() {
    const { parameters } = this.props;
    return (
      <Segment>
        <FormGroup>
          <Label>{__('Názov')}</Label>
          <Input
            name="label"
            value={this.state.label}
            onChange={e => {
              this.onChangeValue(e.target.name, e.target.value);
            }}
          />
        </FormGroup>

        <FormGroup>
          <Label>{__('Hodnota')}</Label>
          <Input
            name="value"
            value={this.state.value}
            onChange={e => {
              this.onChangeValue(e.target.name, e.target.value);
            }}
          />
        </FormGroup>

        {!parameters.field && (
          <Button primary onClick={this.createCustomField}>
            {__('Pridať položku')}
          </Button>
        )}
        {parameters.field && (
          <Button primary onClick={this.editCustomField}>
            {__('Upraviť položku')}
          </Button>
        )}
      </Segment>
    );
  }
}

CustomFieldForm.propTypes = {
  parameters: PropTypes.shape({
    field: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      _id: PropTypes.string,
    }),
  }).isRequired,
  closeSidebar: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: matchDefinition.isRequired,
  relations: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      related_ppo: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

const mapStateToProps = state => ({
  relations: activeAppSpaceRelationsSelector(state),
});

export default connect(mapStateToProps)(withRouter(CustomFieldForm));
