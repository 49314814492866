import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { __ } from '../../../../utilities';
import { required } from '../../../../validation';
import {
  expertCompetenceCodelistSelector,
  expertLegalFormsCodelistSelector,
} from '../../../App/selectors';
import { codelistDefinition, licenceDefinition } from '../../definitions';
import { getActiveUserLicencesSelector } from '../../selectors';
import { licenseRequiredSelector } from './selectors';

const ExpertsForm = ({
  data,
  competences,
  legalForms,
  licences,
  isOwner,
  isLicenseRequired,
}) => {
  return (
    <>
      <Field
        component={FormFieldRedux}
        disabled={data && !isOwner}
        required
        type="theselectsimple"
        label={__('Typ / funkcia')}
        name="competence_type"
        validate={[required]}
        options={competences.map(competence => ({
          label: competence.label,
          value: competence.value,
        }))}
        data-testid="CRM_FO_Input_Affiliations_TypExpert"
      />

      <Field
        component={FormFieldRedux}
        required
        disabled={data && !isOwner}
        type="theselectsimple"
        label={__('Právny titul')}
        name="legal_form"
        validate={[required]}
        options={legalForms.map(form => ({
          label: form.label,
          value: form.value,
        }))}
        data-testid="CRM_FO_Input_Affiliations_LegalForm"
      />

      <Field
        component={FormFieldRedux}
        required={isLicenseRequired}
        validate={isLicenseRequired ? [required] : []}
        disabled={data && !isOwner}
        type="theselectsimple"
        label={__('Licencia')}
        name="license_id"
        placeholder={__('Zo zoznamu v záložke Kvalifikácie/licencie')}
        data-testid="CRM_FO_Input_Affiliations_Licence"
        options={[
          ...(!isLicenseRequired
            ? [{ label: __('Bez odbornosti'), value: '' }]
            : []),
          ...licences.map(l => ({ label: l.name, value: l._id })),
        ]}
      />

      <Field
        type="date"
        required
        disabled={data && !isOwner}
        name="date_from"
        component={FormFieldRedux}
        label={__('Dátum od')}
        validate={[required]}
        data-testid="CRM_FO_Input_Affiliations_DateFroExpert"
      />

      <Field
        type="date"
        disabled={data && !isOwner}
        name="date_to"
        component={FormFieldRedux}
        label={__('Dátum do')}
        data-testid="CRM_FO_Input_Affiliations_ValidToExpert"
      />
    </>
  );
};

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(props.formName);
  const competenceType = selector(state, 'competence_type');
  return {
    competences: expertCompetenceCodelistSelector(state),
    legalForms: expertLegalFormsCodelistSelector(state),
    licences: getActiveUserLicencesSelector(state),
    isLicenseRequired: licenseRequiredSelector(competenceType)(state),
  };
};

ExpertsForm.propTypes = {
  competences: PropTypes.arrayOf(codelistDefinition).isRequired,
  legalForms: PropTypes.arrayOf(codelistDefinition).isRequired,
  licences: PropTypes.arrayOf(licenceDefinition).isRequired,
  data: PropTypes.shape({}),
  isOwner: PropTypes.bool.isRequired,
  isLicenseRequired: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ExpertsForm);
