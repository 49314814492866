import Button from '@sportnet/ui/Button';
import Icon from '@sportnet/ui/Icon';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import NotFound from '@sportnet/ui/NotFound';
import { formatUserName, formatPrice } from '@sportnet/utilities';
import BasicTable from '@sportnet/ui/BasicTable';
import PropTypes from 'prop-types';
import connectQueryHoc, { QueryHocTypes } from '@sportnet/query-hoc';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  commit,
  initialize,
  isCommiting,
  setParams,
} from '@sportnet/redux-list';
import Layout from '../../components/Layout';
import { __ } from '../../utilities';
import { appspaceDefinition } from '../App/definitions';
import { appspaceSelector } from '../App/selectors';
import { getInvoiceItems } from './actions';
import { invoiceItemsCurrentListSelector } from './selectors';
import Segment from '@sportnet/ui/Segment';
import CloseZFAsModal from './CloseZFAsModal';
import moment from 'moment';

export const LIST_NAME = 'INVOICES_LIST';

class InvoicesList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: {},
      expanded: [],
      invoiceItems: [],
      closeZFAItems: null,
    };
  }

  _allSelectedRows(entries) {
    return Object.entries(entries).map(([k, s]) => {
      const [invoiceId, itemId] = k.split('-');
      return {
        _id: k,
        invoiceId,
        itemId,
        selected: s,
      };
    });
  }

  _isAnyItemSelected() {
    return this._allSelectedRows(this.state.selected).some(
      v => v.itemId !== undefined && v.selected,
    );
  }

  selectRow = selectedRows => {
    let newSelectedRows = { ...selectedRows };
    const autoSelectHeaders = {};

    // ak sa meni stav hlavicky, tak podla toho prisposobim polozky
    newSelectedRows = this._allSelectedRows(newSelectedRows)
      .filter(r => r.itemId === undefined)
      .reduce((acc, row) => {
        // ak som zaklikol hlavicku, tak zmenim vsetky aj ostatne
        if (
          !(row._id in this.state.selected) ||
          this.state.selected[row._id] !== row.selected
        ) {
          return {
            ...acc,
            ...this.state.invoiceItems
              .filter(({ invoiceId }) => invoiceId === row.invoiceId)
              .reduce((a, b) => ({ ...a, [b._id]: row.selected }), {}),
          };
        }
        return acc;
      }, newSelectedRows);
    // teraz mam novy stav poloziek, podla nich prisposobim hlavicky
    newSelectedRows = this._allSelectedRows(newSelectedRows)
      .filter(r => r.itemId !== undefined)
      .reduce((acc, row) => {
        if (!row.selected) {
          return {
            ...acc,
            [row.invoiceId]: false, // odznacim hlavicku
          };
        }
        return acc;
      }, newSelectedRows);
    // mame dalsi novy stav poloziek
    // teraz si prechadzame polozky faktury. Ak su vsetky polozky zaznacene, tak oznacime aj samotnu fakturu
    this.state.invoiceItems.forEach(invoiceItem => {
      if (invoiceItem.item) {
        if (!(invoiceItem.invoiceId in autoSelectHeaders)) {
          autoSelectHeaders[invoiceItem.invoiceId] = true;
        }
        autoSelectHeaders[invoiceItem.invoiceId] =
          autoSelectHeaders[invoiceItem.invoiceId] &&
          !!newSelectedRows[invoiceItem._id];
      }
    });

    this.setState({ selected: { ...newSelectedRows, ...autoSelectHeaders } });
  };

  componentDidMount() {
    const {
      dispatch,
      appspace: { app_space: appspace },
      query,
    } = this.props;

    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          ...query,
          appspace,
        },
      }),
    );
    this.getInvoiceItems();
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.serializedQuery !== nextProps.serializedQuery) {
      nextProps.dispatch(
        setParams({
          listName: LIST_NAME,
          parameters: nextProps.query,
        }),
      );
      this.getInvoiceItems(nextProps);
    }
  }
  getInvoiceItems(props = this.props) {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      query: { q, type },
    } = props;
    dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          const res = await dispatch(
            getInvoiceItems(appspace, q, type[0] === 'DF' && appspace),
          );
          this.setState({
            invoiceItems: res.invoices
              .map(invoice => {
                return [
                  {
                    _id: `${invoice._id}`,
                    buyer: invoice.buyer,
                    invoiceId: invoice._id,
                    nrOfItems: invoice.items.length,
                    totalPrice: invoice.items.reduce((tprice, item) => {
                      return {
                        ...tprice,
                        ...item.totalPrice,
                        amount: (tprice.amount || 0) + item.totalPrice.amount,
                        amount_vat_excl:
                          (tprice.amount_vat_excl || 0) +
                          item.totalPrice.amount_vat_excl,
                      };
                    }, {}),
                  },
                  ...invoice.items.map(item => {
                    return {
                      _id: `${invoice._id}-${item._id}`,
                      item,
                      buyer: invoice.buyer,
                      invoiceId: invoice._id,
                      itemId: item._id,
                    };
                  }),
                ];
              })
              .reduce((flat, next) => [...flat, ...next], []),
          });
          return {
            results: res.invoices.map(i => i._id),
          };
        },
      }),
    );
  }
  render() {
    const {
      invoices,
      isFetching,
      query: { type },
    } = this.props;
    return (
      <Layout
        // topFixed={
        //   <FilterPanel>
        //     <QueryFilter
        //       value={q}
        //       onChange={e => {
        //         setParameter({
        //           q: e,
        //         });
        //       }}
        //     />
        //     <SelectFilter
        //       placeholder={__('Typ faktúry')}
        //       items={[
        //         {
        //           title: __('Došlé faktúry'),
        //           value: 'DF',
        //         },
        //         {
        //           title: __('Vyšlé faktúry'),
        //           value: 'VF',
        //         },
        //       ]}
        //       value={type}
        //       onChange={value => {
        //         setParameter({
        //           type: value,
        //         });
        //       }}
        //     />
        //   </FilterPanel>
        // }
        bottomFixed={
          type.includes('VF') && (
            <ContextBar>
              <ContextBarSpacer />
              <ContextBarItem>
                <Button
                  basic
                  primary
                  disabled={!this._isAnyItemSelected()}
                  onClick={() => {
                    this.setState({
                      closeZFAItems: this.state.invoiceItems.filter(
                        ii => ii.item && this.state.selected[ii._id],
                      ),
                    });
                  }}
                >
                  {__('Uzavrieť faktúry')}
                </Button>
              </ContextBarItem>
            </ContextBar>
          )
        }
      >
        <AppContext
          title={
            type[0] === 'DF'
              ? __('Došlé položky zberných faktúr')
              : __('Vyšlé položky zberných faktúr')
          }
        />
        <Segment noBottomGutter>
          <Segment noBottomGutter raised loading={isFetching}>
            {!isFetching && invoices.length === 0 ? (
              <NotFound
                title={__(
                  'Pre zvolený filter sa nenašli žiadne položky zberných faktúr',
                )}
                icon="search"
              />
            ) : (
              <BasicTable
                columns={[
                  {
                    id: 'control',
                    width: 1,
                  },
                  {
                    id: 'buyer_item',
                    header: __('Odberateľ / Položka'),
                  },
                  {
                    id: 'buyer_item',
                    header: __('Dátum vzniku položky'),
                  },
                  {
                    id: 'amount_vat_excl',
                    header: __('Cena bez DPH'),
                  },
                  {
                    id: 'amount',
                    header: __('Cena s DPH'),
                  },
                ]}
                rows={this.state.invoiceItems.filter(({ item, ...invoice }) => {
                  if (item) {
                    return this.state.expanded.includes(invoice.invoiceId);
                  }
                  return true;
                })}
                renderRow={({ item, ...invoice }) => {
                  if (item) {
                    return [
                      '',
                      (i => {
                        return (
                          <>
                            {`${i.quantity}x ${i.name}`}
                            {i.subtitle && (
                              <div style={{ fontSize: '80%' }}>
                                {i.subtitle}
                              </div>
                            )}
                          </>
                        );
                      })(item),
                      `${
                        item.createdDT
                          ? moment(item.createdDT).format('DD.MM.YYYY')
                          : '-'
                      }`,
                      `${formatPrice(
                        item.totalPrice.amount_vat_excl,
                        item.totalPrice.currency,
                      )}`,
                      `${formatPrice(
                        item.totalPrice.amount,
                        item.totalPrice.currency,
                      )}`,
                    ];
                  } else {
                    return [
                      <Icon
                        name={
                          this.state.expanded.includes(invoice.invoiceId)
                            ? 'arrow-down'
                            : 'arrow-right'
                        }
                      />,
                      <strong>
                        {formatUserName(invoice.buyer)} ({invoice.nrOfItems}{' '}
                        {invoice.nrOfItems === 1
                          ? __('položka')
                          : invoice.nrOfItems < 5
                          ? __('položky')
                          : __('položiek')}
                        )
                      </strong>,
                      null,
                      <strong>{`${formatPrice(
                        invoice.totalPrice.amount_vat_excl,
                        invoice.totalPrice.currency,
                      )}`}</strong>,
                      <strong>{`${formatPrice(
                        invoice.totalPrice.amount,
                        invoice.totalPrice.currency,
                      )}`}</strong>,
                    ];
                  }
                }}
                rowKey="_id"
                onSelect={this.selectRow}
                onClickRow={({ item, ...invoice }) => {
                  // hlavicka uz len robi collapse / hide
                  if (!item) {
                    if (this.state.expanded.includes(invoice._id)) {
                      this.setState({
                        expanded: this.state.expanded.filter(
                          i => i !== invoice._id,
                        ),
                      });
                    } else {
                      this.setState({
                        expanded: [...this.state.expanded, invoice._id],
                      });
                    }
                  } else {
                    this.selectRow({
                      ...this.state.selected,
                      [invoice._id]: !!!this.state.selected[invoice._id],
                    });
                  }
                }}
                selected={this.state.selected}
              />
            )}
          </Segment>
        </Segment>
        {this.state.closeZFAItems && (
          <CloseZFAsModal
            isOpen
            handleClose={() => {
              this.setState({ closeZFAItems: null });
            }}
            items={this.state.closeZFAItems}
            onSuccess={() => {
              this.setState({ selected: {}, closeZFAItems: null }, () => {
                this.getInvoiceItems();
              });
            }}
            onSuccessClose={() => {
              this.setState({ closeZFAItems: null });
            }}
          />
        )}
      </Layout>
    );
  }
}

InvoicesList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  appspace: appspaceDefinition.isRequired,
  query: PropTypes.shape({
    q: PropTypes.string.isRequired,
    type: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  serializedQuery: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      invoicenr: PropTypes.string,
      createdDate: PropTypes.string,
      buyer: PropTypes.shape({
        name: PropTypes.string,
        surname: PropTypes.string,
      }),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  appspace: appspaceSelector(state),
  invoices: invoiceItemsCurrentListSelector(LIST_NAME)(state),
  isFetching: isCommiting(LIST_NAME)(state),
});

const connected = connectQueryHoc({
  parameters: {
    q: {
      type: QueryHocTypes.String,
      defaultValue: '',
    },
    offset: {
      type: QueryHocTypes.Number,
      defaultValue: 0,
    },
    type: {
      type: QueryHocTypes.Array,
      defaultValue: [],
      delimiter: ',',
    },
  },
})(InvoicesList);

export default connect(mapStateToProps)(withRouter(connected));
