import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Segment from '@sportnet/ui/Segment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';
import { getProp, __ } from '../../../../utilities';
import { appspaceDefinition } from '../../../App/definitions';
import { appspaceSelector } from '../../../App/selectors';
import { required } from '../../../../validation';

const FORM_NAME = 'MEMBERSHIP_FORM';
export const STATUSES = [
  {
    label: __('Aktívny'),
    value: 'active',
  },
  {
    label: __('Neaktívny'),
    value: 'inactive',
  },
  {
    label: __('Zrušený'),
    value: 'cancelled',
  },
  {
    label: __('Vylúčený'),
    value: 'excluded',
  },
];

class MembershipForm extends PureComponent {
  render() {
    const {
      handleSubmit,
      onDelete,
      data,
      appspace: {
        app_space: appSpace,
        org_profile: { organization_name: organizationName, name },
      },
      submitting,
      deleting,
    } = this.props;
    const isOwner =
      !getProp(data, ['org_profile_id']) ||
      getProp(data, ['org_profile_id']) === appSpace;
    return (
      <Form onSubmit={handleSubmit}>
        <Segment>
          <FormField
            label={__('Organizácia')}
            disabled
            value={`${getProp(
              data,
              ['org_name'],
              organizationName,
            )} / ${getProp(data, ['org_profile_name'], name)}`}
          />

          <Field
            component={FormFieldRedux}
            name="valid_from"
            validate={[required]}
            required
            type="date"
            disabled={data && !isOwner}
            label={__('Platnosť od')}
          />

          <Field
            component={FormFieldRedux}
            name="valid_to"
            validate={[required]}
            required
            type="date"
            disabled={data && !isOwner}
            label={__('Platnosť do')}
          />

          <Field
            component={FormFieldRedux}
            type="theselect"
            options={STATUSES}
            parse={item => {
              return item ? item.value : '';
            }}
            format={value =>
              value ? STATUSES.find(o => o.value === value) || null : null
            }
            label={__('Stav členstva')}
            name="status"
            validate={[required]}
            disabled={data && !isOwner}
            required
          />

          {isOwner && (
            <div
              style={{ display: 'flex', 'justify-content': 'space-between' }}
            >
              {getProp(data, ['_id']) && (
                <Button
                  onClick={e => {
                    e.preventDefault();
                    onDelete(data);
                  }}
                  danger
                  type="button"
                  disabled={submitting || deleting}
                  loading={deleting}
                >
                  {__('Zmazať')}
                </Button>
              )}
              <Button
                type="submit"
                primary
                disabled={submitting || deleting}
                loading={submitting}
              >
                {__('Uložiť')}
              </Button>
            </div>
          )}
        </Segment>
      </Form>
    );
  }
}

MembershipForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  deleting: PropTypes.bool,
  appspace: appspaceDefinition.isRequired,
  data: PropTypes.shape({
    valid_to: PropTypes.string,
  }),
};

MembershipForm.defaultProps = {
  data: null,
  deleting: false,
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(MembershipForm);

const mapStateToProps = (state, props) => {
  const connectedProps = {
    appspace: appspaceSelector(state),
  };
  if (props.data) {
    connectedProps.initialValues = {
      ...props.data,
    };
  }
  return connectedProps;
};

export default connect(mapStateToProps)(connected);
