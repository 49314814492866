import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import SmarttagTags from '@sportnet/ui/SmarttagsControl/SmarttagTags';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import { StringParam, NumberParam } from '@sportnet/query-hoc/useQuery';
import React from 'react';
import { connect } from 'react-redux';
import { getListTotal, isCommiting } from '@sportnet/redux-list';
import Layout from '../../components/Layout';
import { __ } from '../../utilities';
import { loadTemplatesList, TEMPLATES_LIST_NAME } from './actions';
import { templateDefinition } from './definitions';
import { templatesCurrenListSelector } from './selectors';
import { recipientsToSmarttagFormat } from './utilities';
import { useAuth } from '@sportnet/auth-react';
import useQuery from '@sportnet/query-hoc/useQuery';
import { useLocation } from 'react-router-dom';
import { formatDate } from 'date-fns';
import config from '../../config';
import { initializeOrSetListParams } from '../App/actions';
import Skeleton from '@sportnet/ui/Skeleton';
import { useNavigate } from 'react-router-dom-v5-compat';

const LIST_LIMIT = 50;

const COURIER_LIST_QUERY_HOC_CONFIG = {
  parameters: {
    q: StringParam(''),
    offset: NumberParam(0),
    limit: NumberParam(LIST_LIMIT),
  },
};

const CourierTemplatesList = ({
  templates,
  total,
  isFetching,
  initializeOrSetListParams,
  loadTemplatesList,
}) => {
  const { ppo: appSpace } = useAuth();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const { query, setQuery } = useQuery(
    search,
    serializedQuery => navigate(`${pathname}${serializedQuery}`),
    COURIER_LIST_QUERY_HOC_CONFIG,
  );

  React.useEffect(() => {
    (async () => {
      await initializeOrSetListParams({
        listName: TEMPLATES_LIST_NAME,
        params: {
          ...query,
        },
      });
      loadTemplatesList({ appSpace });
    })();
  }, [appSpace, initializeOrSetListParams, loadTemplatesList, query]);

  const handleChangeOffset = e => {
    setQuery({
      offset: e,
    });
  };

  const renderRow = item => (
    <Tr
      key={item._id}
      onClick={() => {
        navigate(`/admin/${appSpace}/courier-templates/${item._id}`);
      }}
    >
      <Td>{formatDate(item.date_sent, config.DATE_FORMAT_WITH_TIME)}</Td>
      <Td>{item?.parameters?.message_parameters?.subject}</Td>
      <Td>
        <SmarttagTags
          items={recipientsToSmarttagFormat(
            item?.parameters?.recipient_parameters ?? {},
            5,
          )}
        />
      </Td>
    </Tr>
  );

  const getTableHead = () => (
    <Tr>
      <Th>{__('Dátum a čas')}</Th>
      <Th>{__('Predmet')}</Th>
      <Th>{__('Príjemcovia')}</Th>
    </Tr>
  );

  const renderLoaderRow = (_, idx) => (
    <Tr key={`loader-row-${idx}`}>
      <Td>
        <Skeleton width={80} height={17} />
      </Td>
      <Td>
        <Skeleton width={250} height={17} />
      </Td>
      <Td>
        <Skeleton width={250} height={17} />
      </Td>
    </Tr>
  );

  return (
    <Layout
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Paginator
              offset={query.offset}
              limit={query.limit}
              total={total || 0}
              onChangeOffset={handleChangeOffset}
            />
          </ContextBarItem>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              primary
              onClick={() => {
                navigate(`/admin/${appSpace}/courier-templates/new`);
              }}
            >
              {__('Nová správa')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext title={__('Kuriér')} />
      <Segment>
        <Segment raised>
          {isFetching ? (
            <Table>
              <Thead>{getTableHead()}</Thead>
              <Tbody>{Array.from(new Array(16)).map(renderLoaderRow)}</Tbody>
            </Table>
          ) : (
            <Table>
              <Thead>{getTableHead()}</Thead>
              <Tbody>{templates.map(renderRow)}</Tbody>
            </Table>
          )}
        </Segment>
      </Segment>
    </Layout>
  );
};

const mapStateToProps = state => ({
  templates: templatesCurrenListSelector(TEMPLATES_LIST_NAME)(state),
  total: getListTotal(TEMPLATES_LIST_NAME)(state),
  isFetching: isCommiting(TEMPLATES_LIST_NAME)(state),
});

const mapDispatchToProps = {
  initializeOrSetListParams,
  loadTemplatesList,
};

CourierTemplatesList.propTypes = {
  templates: PropTypes.arrayOf(templateDefinition),
  total: PropTypes.number,
  isFetching: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourierTemplatesList);
