import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { getProp } from '@sportnet/utilities';
import { getPositionLabel, Subtitle } from '.';
import { __ } from '../../../../utilities';
import validation from '../../../../utilities/validation';
import { universalRequestDefinition } from '../../definitions';

class DSGEDitManagerAppForm extends React.PureComponent {
  renderCreateAppBlock = sportnetId => {
    const { apps, registerApps, request } = this.props;
    if (request.status !== 'APPROVED') {
      return null;
    }
    return apps.map(app => {
      const register = getProp(
        registerApps,
        [app._id, sportnetId, 'register'],
        false,
      );
      const appName = app.name || app._id;
      const appRoles = app.roles || [];
      return (
        <Row key={app._id}>
          <Col xs={12}>
            <Field
              label={`${__('Priradiť aplikáciu')} ${appName}`}
              name={`registerApps.${app._id}.${sportnetId}.register`}
              type="checkbox"
              component={FormFieldRedux}
            />
          </Col>
          {register && (
            <React.Fragment>
              <Col xs={12}>
                <Field
                  label={`${__(
                    'Právo spravovať iných používateľov v aplikácií',
                  )} ${appName}`}
                  name={`registerApps.${app._id}.${sportnetId}.grant`}
                  type="checkbox"
                  component={FormFieldRedux}
                />
              </Col>
              <Col xs={12}>
                <Field
                  label={`${__('Rola v aplikácií')} ${appName}`}
                  name={`registerApps.${app._id}.${sportnetId}.role`}
                  type="theselectsimple"
                  component={FormFieldRedux}
                  validate={[validation.required]}
                  required
                  options={appRoles.map(role => ({
                    label: role.name || role.role,
                    value: role.role,
                  }))}
                />
              </Col>
            </React.Fragment>
          )}
        </Row>
      );
    });
  };

  render() {
    const { users = [] } = this.props;
    return users.map(user => (
      <Segment
        raised
        header={
          <SegmentHeader
            size="xs"
            withSeparator={this.props.request === 'APPROVED'}
            subtitle={
              <Subtitle>
                <b>{__('Nová oprávnená osoba')}</b>
                <br />
                {getPositionLabel(user.user.type)}
                <br />
                {user.user.email}
              </Subtitle>
            }
          >
            {user.user.name}
          </SegmentHeader>
        }
      >
        {this.renderCreateAppBlock(user.user._id)}
      </Segment>
    ));
  }
}

DSGEDitManagerAppForm.propTypes = {
  apps: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string,
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          role: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  registerApps: PropTypes.shape({}).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.shape({
        type: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  request: universalRequestDefinition.isRequired,
};

export default compose(reduxForm({}), connect())(DSGEDitManagerAppForm);
