import Button from '@sportnet/ui/Button';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import Message from '@sportnet/ui/Message';
import Header from '@sportnet/ui/Header';
import CheckBox from '@sportnet/ui/Checkbox';
import Label from '@sportnet/ui/Label/Label';
import { formatPrice } from '@sportnet/utilities';
import Api from '../../../../Api';
import { __ } from '../../../../utilities';

export const Faded = styled.span`
  color: ${({ theme }) => theme.color.fadedText};
`;

const ProlongModal = ({ appspace, parentRelations, onClose, onSuccess }) => {
  const [selectedPPO, setSelectedPPO] = useState(null);
  const [isLoadingPriceItem, setIsLoadingPriceItem] = useState();
  const [priceItems, setPriceItems] = useState({});
  const [isWorking, setIsWorking] = useState();
  const [isSuccess, setIsSuccess] = useState();

  useEffect(() => {
    if (!selectedPPO) {
      return;
    }
    // vynulujeme pre zvolenu PPO
    const membershipToPPO = selectedPPO;
    setIsLoadingPriceItem(true);
    setIsWorking(false);
    setIsSuccess(false);
    Api.crmMyOrganizationMembershipProlongInfo(appspace, membershipToPPO)
      .then(({ pricelistItem }) =>
        setPriceItems(pi => ({ ...pi, [membershipToPPO]: pricelistItem })),
      )
      .catch(e => {
        if (e.details && e.details.statusCode === 409) {
          setPriceItems(pi => ({ ...pi, [membershipToPPO]: null }));
        } else {
          alert(
            e.details
              ? e.details.userinfo ||
                  __('Pri získavaní podmienok členstva nastala chyba')
              : e,
          );
        }
      })
      .finally(() => {
        setIsLoadingPriceItem(false);
      });
  }, [appspace, selectedPPO]);

  const prolongMembership = async () => {
    if (!selectedPPO) {
      return;
    }
    setIsWorking(true);
    try {
      await Api.crmMyOrganizationMembershipProlong(appspace, selectedPPO);
      setIsSuccess(true);
      onSuccess();
    } catch (e) {
      alert(
        e.details
          ? e.details.userinfo || __('Pri predlžovaní členstva nastala chyba')
          : e,
      );
    } finally {
      setIsWorking(false);
    }
  };

  return (
    <Modal isOpen>
      <ModalContent>
        <Header size="s" withSeparator>
          {__('Predĺženie členstva organizácie')}
        </Header>
        <Segment
          raised
          header={
            <SegmentHeader size="xs">
              {__('1. Zvoľte organizáciu')}
            </SegmentHeader>
          }
        >
          {parentRelations.map(ppo => {
            return (
              <Label key={ppo._id}>
                <CheckBox
                  checked={selectedPPO === ppo._id}
                  disabled={isSuccess || isWorking}
                  onChange={e => {
                    setSelectedPPO(e.target.checked ? ppo._id : null);
                  }}
                />
                {ppo.name}
              </Label>
            );
          })}
        </Segment>
        {selectedPPO && (
          <Segment
            loading={isLoadingPriceItem}
            raised
            header={
              <SegmentHeader size="xs">
                {__('2. Podmienky členstva')}
              </SegmentHeader>
            }
          >
            {!isLoadingPriceItem && !priceItems[selectedPPO] && (
              <Message danger>
                {__('Zvolená organizácia nemá nastavené podmienky členstva')}
              </Message>
            )}
            {priceItems[selectedPPO] &&
              (({ name, price, validity }) => {
                return (
                  <>
                    <Message primary title={name}>
                      {`${formatPrice(price.amount, price.currency)} ${__(
                        's DPH',
                      )} (${formatPrice(
                        price.amount_vat_excl,
                        price.currency,
                      )} ${__('bez DPH')})`}
                    </Message>
                    <Message primary title={__('Platnosť')}>
                      {(v => {
                        if (v.dateTo) {
                          return `${__('Do dátumu')}: ${moment(v.dateTo).format(
                            'D.M.Y',
                          )}`;
                        }
                        if (v.days) {
                          return `${__('Predĺženie o')}: ${v.days} ${__(
                            'dní',
                          )}`;
                        }
                        return __('Neznáma platnosť');
                      })(validity)}
                    </Message>
                    {isSuccess && (
                      <Message success>
                        {__(
                          'Členstvo voči zvolenej organizácii bolo predĺžené',
                        )}
                      </Message>
                    )}
                  </>
                );
              })(priceItems[selectedPPO])}
          </Segment>
        )}
      </ModalContent>

      <ModalActions>
        {isSuccess ? (
          <>
            <div />
            <Button onClick={onClose} primary>
              {__('OK')}
            </Button>
          </>
        ) : (
          <>
            <Button onClick={onClose} disabled={isWorking}>
              {__('Zrušiť')}
            </Button>
            <Button
              disabled={!selectedPPO || !priceItems[selectedPPO] || isWorking}
              primary
              loading={isWorking}
              onClick={() => {
                if (
                  window.confirm(
                    __(
                      'Prajete si predĺžiť členstvo voči zvolenej organizácii',
                    ),
                  )
                ) {
                  prolongMembership();
                }
              }}
            >
              {__('Predĺžiť členstvo')}
            </Button>
          </>
        )}
      </ModalActions>
    </Modal>
  );
};

ProlongModal.propTypes = {
  appspace: PropTypes.string.isRequired,
  parentRelations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(ProlongModal);
