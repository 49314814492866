// Moment initialisation with locale
import Popup from '@sportnet/ui/Popup';
import 'moment/locale/sk';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import configureStore from './configureStore';
import App from './containers/App';
import { theme } from './theme';
import { CompatRouter } from 'react-router-dom-v5-compat';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    margin: 0;
    padding: 0;
    height: 100vh;
  }

  html {
    font-size: 100%;
    font-family: 'Barlow', sans-serif;
  }

  * {
    box-sizing: border-box;
  }
`;

const store = configureStore();
const rootElement = document.getElementById('root');
const root = createRoot(rootElement); // create a root

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Popup.PopupProvider>
          <BrowserRouter>
            <CompatRouter>
              <App />
            </CompatRouter>
          </BrowserRouter>
        </Popup.PopupProvider>
      </>
    </ThemeProvider>
  </Provider>,
);
