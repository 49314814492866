import baseTheme from '@sportnet/ui/Themes/baseTheme';
import { lighten } from 'polished';

const requestColor = {
  NEW: baseTheme.color.primary,
  CANCELLED: baseTheme.color.fadedText,
  APPROVED: 'rgb(254, 147, 36)',
  PARTIALLY_CONFIRMED: lighten(0.15, baseTheme.color.success),
  CONFIRMED: baseTheme.color.success,
  REJECTED: baseTheme.color.danger,
  CLOSED: 'rgba(36, 195, 254, 1)',
};

export const theme = {
  ...baseTheme,
  primary: '#57b752',
  requestColor,
  app: {
    textColor: '#333',
    fontName: 'Ubuntu',
    padding: 15,
    maxWidth: 1200,
  },
};
