import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Segment from '@sportnet/ui/Segment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';
import { getProp, __ } from '../../../../utilities';
import { appspaceSelector } from '../../../App/selectors';
import { required } from '../../../../validation';

const FORM_NAME = 'SHORTNAME_FORM';

const RegnrForm = ({
  handleSubmit,
  onDelete,
  data,
  appspace: {
    app_space: appSpace,
    org_profile: { organization_name: organizationName, name },
  },
  submitting,
  deleting,
}) => {
  const isOwner =
    !getProp(data, ['org_profile_id']) ||
    getProp(data, ['org_profile_id']) === appSpace;
  return (
    <Form onSubmit={handleSubmit}>
      <Segment>
        <FormField
          label={__('Organizácia')}
          info={__('Organizácia, ktorá určuje kód.')}
          disabled
          value={`${getProp(data, ['org_name'], organizationName)} / ${getProp(
            data,
            ['org_profile_name'],
            name,
          )}`}
        />

        <Field
          label={__('Kód')}
          disabled={data && !isOwner}
          validate={[required]}
          name="shortname"
          component={FormFieldRedux}
        />

        {isOwner && (
          <div style={{ display: 'flex', 'justify-content': 'space-between' }}>
            {getProp(data, ['_id']) && (
              <Button
                onClick={e => {
                  e.preventDefault();
                  onDelete(data);
                }}
                danger
                type="button"
                disabled={submitting || deleting}
                loading={deleting}
              >
                {__('Zmazať')}
              </Button>
            )}
            <Button
              type="submit"
              primary
              disabled={submitting || deleting}
              loading={submitting}
            >
              {__('Uložiť')}
            </Button>
          </div>
        )}
      </Segment>
    </Form>
  );
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(RegnrForm);

const mapStateToProps = (state, props) => {
  const connectedProps = {
    appspace: appspaceSelector(state),
  };
  if (props.data) {
    connectedProps.initialValues = {
      ...props.data,
    };
  }
  return connectedProps;
};

export default connect(mapStateToProps)(connected);
