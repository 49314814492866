import PropTypes from 'prop-types';

export const matchDefinition = PropTypes.shape({
  params: PropTypes.shape({
    sportnetId: PropTypes.string,
    appspace: PropTypes.string,
  }),
});

export const PPODefinintion = PropTypes.shape({
  _id: PropTypes.string,
  status: PropTypes.string,
  name: PropTypes.string,
  sport: PropTypes.string,
  type: PropTypes.string,
  organization_id: PropTypes.string,
});

export const userDefinition = PropTypes.shape({
  _id: PropTypes.string,
  username: PropTypes.string,
  surname: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  ppoRelation: PropTypes.shape({
    ppo: PropTypes.string.isRequired,
    flags: PropTypes.arrayOf(PropTypes.string),
  }),
});

export const codelistDefinition = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

export const addressDefinition = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

export const bankAccountDefinition = PropTypes.shape({
  _id: PropTypes.string,
  IBAN: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
});

export const athleteDefinition = PropTypes.shape({
  _id: PropTypes.string,
  date_from: PropTypes.string,
  date_to: PropTypes.string,
  org_name: PropTypes.string,
  org_profile_name: PropTypes.string,
  sport: PropTypes.string,
  sport_sector: PropTypes.string,
  status: PropTypes.string,
});

export const expertDefinition = PropTypes.shape({
  _id: PropTypes.string,
  org_name: PropTypes.string,
  org_profile_name: PropTypes.string,
  sport: PropTypes.string,
  sport_sector: PropTypes.string,
  competence_type: PropTypes.string,
  legal_form: PropTypes.string,
  date_from: PropTypes.string,
  date_to: PropTypes.string,
});

export const awardDefinition = PropTypes.shape({
  _id: PropTypes.string,
  sport: PropTypes.string,
  sport_sector: PropTypes.string,
  award_type: PropTypes.string,
  issued_date: PropTypes.string,
  issued_by: PropTypes.string,
});

export const licenceDefinition = PropTypes.shape({
  registration_date: PropTypes.string,
  verified_by_position: PropTypes.string,
  verified_by_person: PropTypes.string,
  issued_by_po: PropTypes.string,
  valid_to: PropTypes.string,
  valid_from: PropTypes.string,
  documentnr: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  org_profile_id: PropTypes.string,
  _id: PropTypes.string,
  org_profile_name: PropTypes.string,
  org_name: PropTypes.string,
});

export const medicalExaminationDefinition = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  doctor: PropTypes.string.isRequired,
  valid_from: PropTypes.string.isRequired,
  valid_to: PropTypes.string,
});

export const formalPhotoDefinition = PropTypes.shape({
  org_name: PropTypes.string,
  org_profile_name: PropTypes.string,
  public_url: PropTypes.string,
  token: PropTypes.string,
  public: PropTypes.bool.isRequired,
});
