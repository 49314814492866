import Segment from '@sportnet/ui/Segment';
import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Api from '../../../../Api';
import { __ } from '../../../../utilities';
import InvitationForm from './form';

const Alert = styled.div`
  font-size: 13px;
  text-align: center;
  padding: 20px 10px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.danger};
`;

class Invitation extends React.PureComponent {
  state = {
    gdprExists: true,
  };

  componentDidMount() {
    this.getGDPR();
  }

  getGDPR = async () => {
    const {
      match: {
        params: { appspace },
      },
    } = this.props;
    const { gdpr } = await Api.crmGetGDPRBody(appspace);
    if (!gdpr) {
      this.setState({
        gdprExists: false,
      });
    }
  };

  submit = async data => {
    const {
      onSubmitSuccess,
      match: {
        params: { appspace },
      },
    } = this.props;
    try {
      await Api.crmSendNewUserRequestInvitation(appspace, {
        data: {
          emails: String(data.emails)
            .split(/[\n\s,]+/)
            .filter(a => a),
          sector: data.sector,
          register_matricity: data.register_matricity,
        },
      });
      await onSubmitSuccess();
    } catch (e) {
      alert(
        __(
          'Pri odosielaní nastala chyba. Skontrolujte prosím emailové adresy.',
        ),
      );
    }
  };
  render() {
    const { gdprExists } = this.state;
    return (
      <React.Fragment>
        {!gdprExists && (
          <Segment raised>
            <Alert>
              {__(
                'Upozorňujeme Vás, že je potrebné v nastaveniach nastaviť znenie súhlasu s GDPR. V opačnom prípade sa v pozvánke zobrazí prázdny súhlas.',
              )}
            </Alert>
          </Segment>
        )}
        <InvitationForm onSubmit={this.submit} />
      </React.Fragment>
    );
  }
}

export default withRouter(Invitation);
