import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { __ } from '../../../../utilities';
import { legalFormsCodelistSelector } from '../../../App/selectors';
import { codelistDefinition } from '../../../FO/definitions';

const FORM_NAME = 'organization-profile';

const ProfileForm = ({ legalForms }) => {
  return (
    <Segment
      raised
      header={<SegmentHeader size="s">{__('Informácie')}</SegmentHeader>}
    >
      <Row>
        <Col xs={12} m={4}>
          <Field
            label={__('Názov spoločnosti')}
            name="full_name"
            component={FormFieldRedux}
            disabled
          />
        </Col>
        <Col xs={12} m={4}>
          <Field
            label={__('Skrátený názov')}
            name="name"
            component={FormFieldRedux}
            disabled
          />
        </Col>
        <Col xs={12} m={4}>
          <Field
            label={__('Štatutár')}
            name="statutory"
            component={FormFieldRedux}
            disabled
          />
        </Col>
        <Col xs={12} s={6} m={4}>
          <FormGroup>
            <Field
              label={__('Právna forma')}
              name="legal_form"
              component={FormFieldRedux}
              type="theselectsimple"
              options={legalForms}
              disabled
            />
          </FormGroup>
        </Col>
        <Col xs={12} s={6} m={4}>
          <Field
            label={__('IČO')}
            name="business_id"
            component={FormFieldRedux}
            disabled
          />
        </Col>
        <Col xs={12} s={6} m={4}>
          <Field
            label={__('DIČ/IčDPH')}
            name="vat_nr"
            component={FormFieldRedux}
            disabled
          />
        </Col>
        <Col xs={12} s={6} m={6} l={3}>
          <Field
            label={__('E-mailová adresa')}
            name="email"
            component={FormFieldRedux}
            disabled
          />
        </Col>
        <Col xs={12} s={6} m={6} l={3}>
          <Field
            label={__('Web')}
            name="www"
            component={FormFieldRedux}
            disabled
          />
        </Col>
        <Col xs={12} s={6} m={6} l={3}>
          <Field
            label={__('Telefón')}
            name="phone"
            component={FormFieldRedux}
            disabled
          />
        </Col>
        <Col xs={12} s={6} m={6} l={3}>
          <Field
            label={__('Fax')}
            name="fax"
            component={FormFieldRedux}
            disabled
          />
        </Col>
      </Row>
    </Segment>
  );
};

ProfileForm.propTypes = {
  legalForms: PropTypes.arrayOf(codelistDefinition).isRequired,
};

ProfileForm.defaultProps = {};

const mapStateToProps = state => {
  return {
    legalForms: legalFormsCodelistSelector(state),
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: FORM_NAME,
  }),
  React.memo,
)(ProfileForm);
