import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import Icon from '@sportnet/ui/Icon';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Text from '@sportnet/ui/Text';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formatUserName, getProp } from '@sportnet/utilities';
import styled, { withTheme } from 'styled-components';
import Api from '../../../Api';
import { __ } from '../../../utilities';
import {
  appspaceSelector,
  myAndParentPPOsCodelistSelector,
} from '../../App/selectors';
import { codelistDefinition, userDefinition } from '../definitions';

const TableWrapper = styled.div`
  max-height: ${rem(300)};
  overflow-y: auto;
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MembershipProlongModal = ({
  isOpen,
  handleClose,
  users,
  onSuccess,
  onSuccessClose,
  appSpaceId,
  membershipToPPO,
  membershipToPPOCodelist,
  theme,
}) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [submitSucceeded, setSubmitSucceeded] = React.useState(false);
  const [resultByUserId, setResultByUserId] = React.useState({});
  const [currentMembershipToPPO, setCurrentMembershipToPPO] =
    React.useState(membershipToPPO);

  React.useEffect(() => {
    setCurrentMembershipToPPO(membershipToPPO);
  }, [membershipToPPO]);

  const handleSubmit = async () => {
    if (!currentMembershipToPPO) {
      window.alert(
        __('Zadajte organizáciu, voči ktorej chcete predľžiť členstvo'),
      );
      return;
    }
    setSubmitting(true);
    try {
      const response = await Api.crmProlongUsersMembership(
        appSpaceId,
        currentMembershipToPPO,
        { data: { userIds: users.map(u => u._id) } },
      );
      const results = response.results.reduce((acc, result) => {
        acc[result.userId] = result;
        return acc;
      }, {});
      await onSuccess();
      setResultByUserId(results);
      setSubmitSucceeded(true);
    } catch (e) {
      window.alert(__('Predĺženie členstva sa nepodarilo'));
    } finally {
      setSubmitting(false);
    }
  };

  const handleCloseWithCallbacks = () => {
    if (submitSucceeded) {
      onSuccessClose();
    }
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleCloseWithCallbacks}>
      <ModalContent>
        <Header size="s" withSeparator>
          {__('Predĺženie členstva v organizácii')}
        </Header>
        <Row>
          <Col xs={12} m={6}>
            <FormField
              label={__('Organizácia')}
              name="membershipToPPO"
              type="theselectsimple"
              value={currentMembershipToPPO}
              onChange={setCurrentMembershipToPPO}
              options={membershipToPPOCodelist}
              disabled={submitting}
              required
            />
          </Col>
        </Row>
        <TableWrapper>
          <BasicTable
            columns={[
              {
                id: 'users',
                header: `${__('Používatelia')} (${users.length})`,
              },
              ...(submitSucceeded
                ? [
                    {
                      id: 'membership',
                      header: __('Stav predĺženia členstva'),
                    },
                  ]
                : []),
            ]}
            rows={users}
            renderRow={item => {
              const row = [
                <React.Fragment key="users">
                  {formatUserName(item)}
                  {` `}
                  <Text faded>{`(${item._id})`}</Text>
                </React.Fragment>,
              ];
              if (submitSucceeded) {
                const result = resultByUserId[item._id];
                let col = null;
                if (result) {
                  if (result.success) {
                    col = (
                      <Icon
                        key="check-circle"
                        name="check-circle"
                        color={theme.color.success}
                        title={__('Členstvo úspešne predĺžené')}
                      />
                    );
                  } else {
                    const errorMessage = getProp(
                      result,
                      ['error', 'userinfo'],
                      '',
                    );
                    col = (
                      <ErrorWrapper key={errorMessage}>
                        <Icon
                          name="error"
                          color={theme.color.danger}
                          title={errorMessage}
                        />
                        &nbsp;&nbsp;
                        <Text danger>{errorMessage}</Text>
                      </ErrorWrapper>
                    );
                  }
                }
                row.push(col);
              }
              return row;
            }}
            rowKey="_id"
          />
        </TableWrapper>
      </ModalContent>

      <ModalActions>
        <Button onClick={handleCloseWithCallbacks} disabled={submitting}>
          {__('Zavrieť')}
        </Button>
        <Button
          loading={submitting}
          disabled={submitting}
          primary
          onClick={handleSubmit}
        >
          {__('Predĺžiť členstvo')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    appSpaceId: appspaceSelector(state).app_space,
    membershipToPPOCodelist: myAndParentPPOsCodelistSelector(state),
  };
};

const mapDispatchToProps = {};

MembershipProlongModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(userDefinition).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onSuccessClose: PropTypes.func.isRequired,
  appSpaceId: PropTypes.string.isRequired,
  membershipToPPO: PropTypes.string,
  membershipToPPOCodelist: PropTypes.arrayOf(codelistDefinition).isRequired,
  theme: PropTypes.shape({
    color: PropTypes.shape({
      success: PropTypes.string.isRequired,
      danger: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

MembershipProlongModal.defaultProps = {
  membershipToPPO: null,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTheme,
)(MembershipProlongModal);
