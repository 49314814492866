import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import KeyValueTable from '../../../../../components/KeyValueTable';
import {
  addressTypeCodelistSelector,
  bankAccountTypeCodelistSelector,
  codelistEntrySelector,
  countryCodelistSelector,
} from '../../../../App/selectors';
import { formatToString, __ } from '../../../../../utilities';
import { universalRequestDefinition } from '../../../definitions';
import Segment from '@sportnet/ui/Segment';
import { compareAddresses, compareBankAccounts, compareOthers } from './utils';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';

const PoChange = ({
  addressTypes,
  bankAccountTypes,
  initialData,
  modifiedData,
  legalForm,
  countries,
}) => {
  const getForCompare = field => {
    return [initialData[field] || '', modifiedData[field] || ''];
  };

  const getLegalForm = legal_form => {
    const lf = legalForm(legal_form);
    return lf.label || lf.value || '';
  };

  const legalFormOld = getLegalForm(initialData.legal_form);
  const legalFormNew = getLegalForm(modifiedData.legal_form);

  const kvFields = [
    {
      key: __('Názov spoločnosti'),
      values: getForCompare('full_name'),
    },
    {
      key: __('Skrátený názov'),
      values: getForCompare('name'),
    },
    {
      key: __('IČO'),
      values: getForCompare('business_id'),
    },
    {
      key: __('DIČ/IČDPH'),
      values: getForCompare('vat_nr'),
    },
    {
      key: __('Právna forma'),
      values: [legalFormOld, legalFormNew],
    },
    {
      key: __('Štatutárny zástupca'),
      values: [
        formatToString(
          initialData.statutory || initialData.contact_person,
          initialData.email,
          initialData.phone,
        ),
        formatToString(
          modifiedData.statutory || initialData.contact_person,
          modifiedData.email,
          modifiedData.phone,
        ),
      ],
    },
    {
      key: __('www'),
      values: [initialData.www, modifiedData.www],
    },
  ];

  const addresses = compareAddresses(initialData, modifiedData, addressTypes);
  const bankAccounts = compareBankAccounts(
    initialData,
    modifiedData,
    bankAccountTypes,
  );
  const others = compareOthers(initialData, modifiedData);
  const fields = [...kvFields, ...addresses, ...bankAccounts, ...others];

  return (
    <Segment raised>
      <Row>
        <Col m={6}>
          <Header size="s">{__('Zmena údajov spoločnosti')}</Header>
          <KeyValueTable
            rows={fields}
            header={['', 'Aktuálne údaje', 'Nové údaje']}
          />
        </Col>
      </Row>
    </Segment>
  );
};

PoChange.propTypes = {
  request: universalRequestDefinition.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    request: { data: { initialData = {}, modifiedData = {} } = {} },
  } = ownProps;
  const legalForm = code => codelistEntrySelector('legal-form', code)(state);
  return {
    initialData,
    modifiedData,
    legalForm: legalForm,
    countries: countryCodelistSelector(state),
    addressTypes: addressTypeCodelistSelector(state),
    bankAccountTypes: bankAccountTypeCodelistSelector(state),
  };
};

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  React.memo,
)(withTheme(PoChange));
