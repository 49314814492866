import { getProp } from '@sportnet/utilities';
import API from '../../Api';
import {
  REQUEST_GROUP_USERS,
  RECEIVE_GROUP_USERS_FAILURE,
  RECEIVE_GROUP_USERS_SUCCESS,
  REQUEST_USER_GROUPS_SAVE,
  RECEIVE_USER_GROUPS_FAILURE,
  RECEIVE_USER_GROUPS_SUCCESS,
} from './constants';

const requestGroupUsers = () => ({
  type: REQUEST_GROUP_USERS,
});

const receiveGroupUsersSuccess = users => ({
  type: RECEIVE_GROUP_USERS_SUCCESS,
  payload: {
    users,
  },
});

const receiveGroupUsersFailure = error => ({
  type: RECEIVE_GROUP_USERS_FAILURE,
  payload: {
    error: error.description || error,
  },
});

export const getGroupUsers = (appspace, filter) => {
  return async dispatch => {
    dispatch(requestGroupUsers());
    try {
      const f = {
        offset: filter.offset,
        limit: filter.limit,
        q: filter.query,
      };
      if (getProp(filter, ['user_group_ids'], []).length > 0) {
        f.userGroupIds = filter.user_group_ids;
      }
      const response = await API.getUsersByAppSpaceGroup(appspace, f);
      dispatch(receiveGroupUsersSuccess(response.users));
      return response;
    } catch (e) {
      dispatch(receiveGroupUsersFailure(e.details || e));
    }
    return null;
  };
};

const requestSaveUserGroups = () => ({
  type: REQUEST_USER_GROUPS_SAVE,
});

const receiveSaveUserGroupsSuccess = () => ({
  type: RECEIVE_USER_GROUPS_SUCCESS,
});

const receiveSaveUserGroupsFailure = error => ({
  type: RECEIVE_USER_GROUPS_FAILURE,
  payload: {
    error: error.description || error,
  },
});

export const saveUserGroups = (appSpace, sportnetId, groupIds) => {
  return async dispatch => {
    try {
      dispatch(requestSaveUserGroups());
      await API.crmSetUserGroups(sportnetId, appSpace, {
        data: {
          groupIds,
        },
      });
      dispatch(receiveSaveUserGroupsSuccess());
      return true;
    } catch (e) {
      const error = e.details || e;
      dispatch(receiveSaveUserGroupsFailure(error));
    }
    return false;
  };
};

export const inviteUserToGroups = (appSpace, email, groupIds) => {
  return async dispatch => {
    try {
      await API.createInvitationRequestToGroups(appSpace, {
        data: { email, groupIds },
      });
      return true;
    } catch (e) {
      return false;
    }
  };
};
