import Button from '@sportnet/ui/Button';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Header from '@sportnet/ui/Header';
import BasicTable from '@sportnet/ui/BasicTable';
import Organization from '@sportnet/ui/Organization';
import { rem } from 'polished';
import Text from '@sportnet/ui/Text';
import Icon from '@sportnet/ui/Icon';
import { getProp } from '@sportnet/utilities';
import Api from '../../../Api';
import { __ } from '../../../utilities';

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Faded = styled.span`
  color: ${({ theme }) => theme.color.fadedText};
`;

const TableWrapper = styled.div`
  max-height: ${rem(300)};
  overflow-y: auto;
`;

const ProlongModal = ({ appspace, ppos, onClose, onFinish, theme }) => {
  const [isWorking, setIsWorking] = useState();
  const [isFinished, setIsFinished] = useState();
  const [result, setResult] = useState();
  const prolongMembership = async () => {
    if (!ppos.length) {
      return;
    }
    setIsWorking(true);
    try {
      const { results } = await Api.crmProlongPPOsMembership(appspace, {
        data: { PPOIds: ppos.map(({ _id }) => _id) },
      });
      setResult(results);
      setIsFinished(true);
      onFinish();
    } catch (e) {
      alert(
        e.details
          ? e.details.userinfo || __('Pri predlžovaní členstva nastala chyba')
          : e,
      );
    } finally {
      setIsWorking(false);
    }
  };

  return (
    <Modal isOpen>
      <ModalContent>
        <Header size="s" withSeparator>
          {__('Predĺženie členstva organizáciám')}
        </Header>
        <TableWrapper>
          <BasicTable
            columns={[
              {
                id: 'label',
                header: __('Organizácia'),
                width: 350,
              },
              {
                id: 'value',
                header: __('Stav'),
              },
            ]}
            rows={[...ppos]}
            renderRow={item => {
              return [
                <Organization
                  key="name"
                  organizationName={item.organization_name}
                  profileName={item.name}
                />,
                result
                  ? (r => {
                      if (r.success) {
                        return (
                          <Icon
                            key="check-circle"
                            name="check-circle"
                            color={theme.color.success}
                            title={__('Členstvo úspešne predĺžené')}
                          />
                        );
                      }
                      const errorMessage = getProp(
                        r,
                        ['error', 'userinfo'],
                        '',
                      );
                      return (
                        <ErrorWrapper key={errorMessage}>
                          <Icon
                            name="error"
                            color={theme.color.danger}
                            title={errorMessage}
                          />
                          &nbsp;&nbsp;
                          <Text danger>{errorMessage}</Text>
                        </ErrorWrapper>
                      );
                    })(result.find(({ PPOId }) => PPOId === item._id))
                  : null,
              ];
            }}
            rowKey="_id"
          />
        </TableWrapper>
      </ModalContent>

      <ModalActions>
        {isFinished ? (
          <>
            <div />
            <Button onClick={onClose} primary>
              {__('OK')}
            </Button>
          </>
        ) : (
          <>
            <Button onClick={onClose} disabled={isWorking}>
              {__('Zrušiť')}
            </Button>
            <Button
              disabled={isWorking}
              primary
              loading={isWorking}
              onClick={() => {
                if (
                  window.confirm(
                    __('Prajete si predĺžiť členstvo zvoleným organizáciám'),
                  )
                ) {
                  prolongMembership();
                }
              }}
            >
              {__('Predĺžiť členstvo')}
            </Button>
          </>
        )}
      </ModalActions>
    </Modal>
  );
};

ProlongModal.propTypes = {
  appspace: PropTypes.string.isRequired,
  ppos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    color: PropTypes.shape({
      success: PropTypes.string.isRequired,
      danger: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = () => {
  return {};
};

export default compose(connect(mapStateToProps), withTheme)(ProlongModal);
