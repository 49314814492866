import Button from '@sportnet/ui/Button';
import FormGroup from '@sportnet/ui/FormGroup';
import Input from '@sportnet/ui/Input';
import Label from '@sportnet/ui/Label/Label';
import Segment from '@sportnet/ui/Segment';
import SelectAutocomplete from '@sportnet/ui/TheSelectSimple';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import Api from '../../../../Api';
import { __ } from '../../../../utilities';
import { appspaceDefinition } from '../../../App/definitions';
import {
  appspaceSelector,
  bankAccountTypeCodelistSelector,
} from '../../../App/selectors';
import { activeAppSpaceRelationsSelector } from '../../../Authorization/selectors';
import {
  bankAccountDefinition,
  codelistDefinition,
} from '../../../FO/definitions';
import {
  createOrganizationBankAccount,
  editOrganizationBankAccount,
} from '../../actions';

class BankAccountForm extends PureComponent {
  state = {};

  componentDidMount() {
    if (this.props.parameters.bankAccount) {
      this.setInitialValues(this.props.parameters.bankAccount);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (!this.props.parameters.bankAccount &&
        nextProps.parameters.bankAccount) ||
      (nextProps.parameters.bankAccount &&
        nextProps.parameters.bankAccount._id !==
          this.props.parameters.bankAccount._id)
    ) {
      this.setInitialValues(nextProps.parameters.bankAccount);
    }
  }

  onChangeValue = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  setInitialValues = values => {
    Object.keys(values).forEach(key => {
      this.onChangeValue(key, values[key]);
    });
  };

  createBankAccount = async () => {
    const { app_space } = this.props.appspace;
    const { IBAN, status, type } = this.state;
    if (this.props.relations.length) {
      await Api.crmMyOrganizationRequestChange(app_space, {
        data: {
          bank_accounts: [
            {
              IBAN,
              status,
              type,
            },
          ],
        },
      });
    } else {
      await this.props.dispatch(
        createOrganizationBankAccount(app_space, {
          IBAN,
          status,
          type,
        }),
      );
    }
    this.props.closeSidebar();
  };

  editBankAccount = async () => {
    const {
      appspace: { app_space },
      parameters: { bankAccount },
    } = this.props;
    const { IBAN, status, type } = this.state;
    if (this.props.relations.length) {
      await Api.crmMyOrganizationRequestChange(app_space, {
        data: {
          bank_accounts: [
            {
              _id: bankAccount._id,
              IBAN,
              status,
              type,
            },
          ],
        },
      });
    } else {
      await this.props.dispatch(
        editOrganizationBankAccount(bankAccount._id, app_space, {
          IBAN,
          status,
          type,
        }),
      );
    }
    this.props.closeSidebar();
  };

  render() {
    const { parameters, bankAccountsTypes } = this.props;
    return (
      <Segment>
        <React.Fragment>
          <FormGroup>
            <Label>{__('IBAN')}</Label>
            <Input
              name="IBAN"
              value={this.state.IBAN}
              onChange={e => {
                this.onChangeValue(e.target.name, e.target.value);
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label>{__('Typ')}</Label>
            <SelectAutocomplete
              name="type"
              value={this.state.type}
              onChange={value => {
                this.onChangeValue('type', value);
              }}
              options={bankAccountsTypes.map(type => ({
                label: type.label,
                value: type.value,
              }))}
            />
          </FormGroup>

          <FormGroup>
            <Label>{__('Stav záznamu')}</Label>
            <SelectAutocomplete
              name="status"
              value={this.state.status}
              onChange={value => {
                this.onChangeValue('status', value);
              }}
              options={[
                { label: 'aktívny', value: 'active' },
                { label: 'neaktívny', value: 'inactive' },
              ]}
            />
          </FormGroup>

          {!parameters.bankAccount && (
            <Button primary onClick={this.createBankAccount}>
              {__('Pridať položku')}
            </Button>
          )}
          {parameters.bankAccount && (
            <Button primary onClick={this.editBankAccount}>
              {__('Upraviť položku')}
            </Button>
          )}
        </React.Fragment>
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  return {
    appspace: appspaceSelector(state),
    bankAccountsTypes: bankAccountTypeCodelistSelector(state),
    relations: activeAppSpaceRelationsSelector(state),
  };
};

BankAccountForm.propTypes = {
  appspace: appspaceDefinition.isRequired,
  parameters: PropTypes.shape({
    bankAccount: bankAccountDefinition,
  }).isRequired,
  bankAccountsTypes: PropTypes.arrayOf(codelistDefinition).isRequired,
  dispatch: PropTypes.func.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  relations: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      related_ppo: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default compose(connect(mapStateToProps), withRouter)(BankAccountForm);
