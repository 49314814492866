import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { matchDefinition } from '../definitions';
import { loadOrganization } from './actions';
import Addresses from './Addresses';
import BankAccounts from './BankAccounts';
import ContactPersons from './ContactPersons';
import Custom from './Custom';
import Profile from './Profile';
import Users from './Users';
import Membership from './Membership';
import Shortnames from './Shortnames';
import Sectors from './Sectors';

const Detail = ({
  match: {
    params: { appspace, organizationId },
  },
  loadOrg,
}) => {
  React.useEffect(() => {
    loadOrg(appspace, organizationId);
  }, [appspace, organizationId, loadOrg]);

  return (
    <>
      <Switch>
        <Route
          exact
          path="/admin/:appspace/organizations/:organizationId/profiles/:orgProfileId/profile"
          component={Profile}
        />
        <Route
          exact
          path="/admin/:appspace/organizations/:organizationId/profiles/:orgProfileId/addresses"
          component={Addresses}
        />
        <Route
          exact
          path="/admin/:appspace/organizations/:organizationId/profiles/:orgProfileId/bank-accounts"
          component={BankAccounts}
        />
        <Route
          exact
          path="/admin/:appspace/organizations/:organizationId/profiles/:orgProfileId/contact-persons"
          component={ContactPersons}
        />
        <Route
          exact
          path="/admin/:appspace/organizations/:organizationId/profiles/:orgProfileId/shortnames"
          component={Shortnames}
        />
        <Route
          exact
          path="/admin/:appspace/organizations/:organizationId/profiles/:orgProfileId/sectors"
          component={Sectors}
        />
        <Route
          exact
          path="/admin/:appspace/organizations/:organizationId/profiles/:orgProfileId/custom"
          component={Custom}
        />
        <Route
          exact
          path="/admin/:appspace/organizations/:organizationId/profiles/:orgProfileId/membership"
          component={Membership}
        />
        <Route
          exact
          path="/admin/:appspace/organizations/:organizationId/profiles/:orgProfileId/users"
          component={Users}
        />
        <Redirect
          from="*"
          to="/admin/:appspace/organizations/:organizationId/profiles/:orgProfileId/profile"
        />
      </Switch>
    </>
  );
};

const mapDispatchToProps = {
  loadOrg: loadOrganization,
};

Detail.propTypes = {
  loadOrg: PropTypes.func.isRequired,
  match: matchDefinition.isRequired,
};

export default connect(null, mapDispatchToProps)(Detail);
