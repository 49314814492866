import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import Sidebar from '@sportnet/ui/Sidebar';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import PropTypes from 'prop-types';
import connectQueryParameters from '@sportnet/query-hoc';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import { commit, initialize, isCommiting } from '@sportnet/redux-list';
import Api from '../../Api';
import Layout from '../../components/Layout';
import Segment from '@sportnet/ui/Segment';
import { __ } from '../../utilities';
import { appspaceSelector } from '../App/selectors';
import { matchDefinition } from '../FO/definitions';
import {
  createPriceList,
  deletePriceList,
  receivePriceListSuccess,
  updatePriceList,
} from './actions';
import InvoiceItemForm from './form';
import { pricelistItemsSelector } from './selectors';

export const LIST_NAME = 'INVOICE_ITEMS';

export const PRICELIST_TYPES_CODELIST = [
  { label: 'Členské fyzickej osoby', value: 'membership' },
  { label: 'Členské právnickej osoby', value: 'ppomembership' },
  { label: 'Poplatok za aplikáciu', value: 'application', sportnetOnly: true },
  {
    label: 'Percentuálny poplatok v aplikácii',
    value: 'applicationPcn',
    sportnetOnly: true,
  },
];

class PricelistItemsList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidebarVisible: false,
      data: null,
    };
  }
  async componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      query,
    } = this.props;

    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          ...query,
          appspace,
        },
      }),
    );
    await this.getList(this.props);
  }
  onSubmit = async data => {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;
    let formData = {
      name: data.name,
      type: data.type,
      sex: null,
      ageFrom: null,
      ageTo: null,
    };

    if (data.type === 'applicationPcn') {
      formData = {
        ...formData,
        pcn: data.pcn,
      };
    } else {
      formData = {
        ...formData,
        price: data.price,
        description: '',
        validity: null,
      };
      formData.price = data.price;

      if (data.dateRestrictionInDays) {
        formData.validity = {
          days: data.validity.days,
        };
      }
      if (data.dateRestrictionByDate) {
        formData.validity = {
          dateTo: data.validity.dateTo,
        };
      }
      if (!formData.validity) {
        throw new SubmissionError(
          {
            dateRestrictionInDays: __(
              'Vyberte platnosť určenú počtom dní alebo dátumom',
            ),
          },
          {
            dateRestrictionByDate: __(
              'Vyberte platnosť určenú počtom dní alebo dátumom',
            ),
          },
        );
      }
    }

    if (data.ageRestriction) {
      formData.ageFrom = data.ageFrom;
      formData.ageTo = data.ageTo;
    } else {
      formData.ageFrom = 0;
      formData.ageTo = null;
    }
    if (data.sexRestriction) {
      formData.sex = data.sex;
    }

    if (data.description) {
      formData.description = data.description;
    }

    if (data.applicationId) {
      formData.applicationId = data.applicationId;
    }
    if (data._id) {
      await dispatch(updatePriceList(appspace, data._id, formData));
    } else {
      await dispatch(createPriceList(appspace, formData));
    }
    await this.getList(this.props);
    this.setState({
      sidebarVisible: false,
      data: null,
    });
  };
  getList = async props => {
    const {
      dispatch,
      parameters,
      match: {
        params: { appspace },
      },
    } = props;
    await dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          const { pricelist } = await Api.crmPPOPricelist(appspace, parameters);
          dispatch(receivePriceListSuccess(pricelist));
          return {
            results: pricelist.map(item => item._id),
            total: pricelist.length,
            nextOffset: null,
          };
        },
      }),
    );
  };
  getItemType = value => {
    return (
      PRICELIST_TYPES_CODELIST.find(
        ({ value: codelistValue }) => codelistValue === value,
      ) || { label: value }
    ).label;
  };
  getItemSex = value => {
    switch (value) {
      case 'M':
        return __('Muži');
      case 'F':
        return __('Ženy');
      default:
        return null;
    }
  };
  deleteItem = async id => {
    const confirm = window.confirm(
      __('Skutočne si želáte odstrániť cenníkovú položku?'),
    );
    if (confirm) {
      const {
        dispatch,
        match: {
          params: { appspace },
        },
      } = this.props;
      await dispatch(deletePriceList(appspace, id));
      await this.getList(this.props);
      this.setState({
        sidebarVisible: false,
        data: null,
      });
    }
  };
  render() {
    const { items, isFetching } = this.props;
    const { sidebarVisible } = this.state;
    return (
      <Layout
        isFetching={isFetching}
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                primary
                onClick={() => {
                  this.setState({
                    sidebarVisible: true,
                  });
                }}
              >
                {__('Pridať cenníkovú položku')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        }
      >
        <AppContext title={__('Cenníkové položky')} />
        <Segment>
          <Segment raised>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Typ položky')}</Th>
                  <Th>{__('Názov položky')}</Th>
                  <Th>{__('Cena / Percento')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {items.map(item => {
                  return (
                    <Tr
                      key={item._id}
                      onClick={() => {
                        this.setState({
                          data: item,
                          sidebarVisible: true,
                        });
                      }}
                    >
                      <Td>{this.getItemType(item.type)}</Td>
                      <Td>{item.name}</Td>
                      <Td>
                        {item.type !== 'applicationPcn' ? (
                          <>
                            {item.price.amount} {item.price.currency}{' '}
                            {__('s DPH')}
                          </>
                        ) : (
                          <>
                            {item.pcn}
                            <span>&nbsp;%</span>
                          </>
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Segment>
        </Segment>
        <Sidebar
          visible={sidebarVisible}
          header={__('Cenníková položka')}
          onClose={() => {
            this.setState({
              sidebarVisible: false,
              data: null,
            });
          }}
        >
          <InvoiceItemForm
            deleteItem={this.deleteItem}
            data={this.state.data}
            onSubmit={this.onSubmit}
          />
        </Sidebar>
      </Layout>
    );
  }
}

PricelistItemsList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: matchDefinition.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      type: PropTypes.string,
      name: PropTypes.string,
      ageFrom: PropTypes.number,
      ageTo: PropTypes.number,
      sex: PropTypes.string,
    }),
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  appspace: appspaceSelector(state),
  items: pricelistItemsSelector(LIST_NAME)(state),
  isFetching: isCommiting(LIST_NAME)(state),
});

const connected = connectQueryParameters({})(PricelistItemsList);

export default connect(mapStateToProps)(withRouter(connected));
