import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import React from 'react';
import ReadOnlyTextArea from '../../../../../components/ReadOnlyTextArea';
import { __ } from '../../../../../utilities';
import { universalRequestDefinition } from '../../../definitions';
import Header from '@sportnet/ui/Header';
import Segment from '@sportnet/ui/Segment';

const UserNote = ({ request }) => {
  if (!request.user_note) return null;
  return (
    <Segment raised>
      <Row>
        <Col m={6}>
          <Header size="s">{__('Poznámka osoby: ')}</Header>
          <FormGroup>
            <ReadOnlyTextArea>{request.user_note}</ReadOnlyTextArea>
          </FormGroup>
        </Col>
      </Row>
    </Segment>
  );
};

UserNote.propTypes = {
  request: universalRequestDefinition.isRequired,
};

export default UserNote;
