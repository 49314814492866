import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import DotLabel from '@sportnet/ui/Label/DotLabel';
import Label from '@sportnet/ui/Label/Label';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { Spacer, StyledButton } from '../../../../../components/List';
import { getRequestStatusName, __ } from '../../../../../utilities';
import { matchDefinition } from '../../../../FO/definitions';
import {
  requestColorThemedefinition,
  universalRequestDefinition,
} from '../../../definitions';
import PaymentItem from '../PaymentItem';
import { aclSelector } from '../../../../App/selectors';
import { connect } from 'react-redux';

const ButtonsWrapper = styled.div`
  display: flex;
  ${StyledButton} {
    width: auto;
  }
`;

const StyledDotLabel = styled(DotLabel)`
  margin-left: ${rem(6)};
`;

const StatusManager = ({
  acl,
  request,
  theme,
  loading,
  onCancel,
  onNew,
  onClosed,
  onReject,
  onConfirm,
  match: {
    params: { appspace },
  },
}) => {
  const statusChangeEnabled = React.useMemo(() => {
    return !!acl?.crmChangeRequestStatus;
  }, [acl]);

  const renderChangeStatusBlock = () => {
    // zmenit status mozme vtedy ked:
    // 1. som vlastnikom a je stav confirmed alebo new
    // 2. je stav approved/partially a nachadzam sa v poli confirmedBy s flagom confirmed = false

    let changable = false;

    if (
      request.appSpace === appspace &&
      ['NEW', 'CONFIRMED'].includes(request.status)
    ) {
      changable = true;
    } else if (['PARTIALLY_CONFIRMED', 'APPROVED'].includes(request.status)) {
      changable = !!(request.confirmedBy || []).find(
        c => c.appSpace === appspace && !c.confirmed,
      );
    }

    if (changable) {
      return (
        <Row>
          <Col>
            <FormGroup>
              <Label style={{ display: 'inline-block' }}>
                {__('Zmeniť stav žiadosti na')}
              </Label>
              <ButtonsWrapper>
                {request.status === 'NEW' && (
                  <StyledButton
                    color={theme.requestColor.CANCELLED}
                    block
                    disabled={loading}
                    loading={loading}
                    onClick={onCancel}
                    size="xs"
                  >
                    {getRequestStatusName('CANCELLED')}
                  </StyledButton>
                )}
                {request.status === 'CANCELLED' && (
                  <StyledButton
                    color={theme.requestColor.NEW}
                    block
                    loading={loading}
                    disabled={loading}
                    onClick={onNew}
                  >
                    {getRequestStatusName('NEW')}
                  </StyledButton>
                )}
                {request.status === 'CONFIRMED' && statusChangeEnabled && (
                  <StyledButton
                    color={theme.requestColor.CLOSED}
                    block
                    loading={loading}
                    disabled={loading}
                    onClick={onClosed}
                  >
                    {getRequestStatusName('CLOSED')}
                  </StyledButton>
                )}
                {['APPROVED', 'PARTIALLY_CONFIRMED'].includes(
                  request.status,
                ) && (
                  <Fragment>
                    <StyledButton
                      color={theme.requestColor.REJECTED}
                      basic
                      block
                      loading={loading}
                      disabled={loading}
                      onClick={onReject}
                    >
                      {getRequestStatusName('REJECTED')}
                    </StyledButton>
                    <Spacer size={20} />
                    <StyledButton
                      color={theme.requestColor.CONFIRMED}
                      block
                      loading={loading}
                      disabled={loading}
                      onClick={onConfirm}
                    >
                      {getRequestStatusName('CONFIRMED')}
                    </StyledButton>
                  </Fragment>
                )}
              </ButtonsWrapper>
            </FormGroup>
          </Col>
        </Row>
      );
    }
    return null;
  };
  return (
    <Fragment>
      <Row>
        <Col>
          <FormGroup>
            <Label>
              {__('Aktuálny stav žiadosti: ')}
              <StyledDotLabel
                color={theme.requestColor[request.status]}
                size={30}
              >
                {getRequestStatusName(request.status, request.payment)}
              </StyledDotLabel>
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <PaymentItem request={request} />
      {renderChangeStatusBlock()}
    </Fragment>
  );
};

StatusManager.propTypes = {
  request: universalRequestDefinition.isRequired,
  theme: requestColorThemedefinition.isRequired,
  loading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onNew: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
  match: matchDefinition.isRequired,
};

StatusManager.defaultProps = {
  loading: false,
};

export default withTheme(
  withRouter(
    connect(state => ({
      acl: aclSelector(state),
    }))(StatusManager),
  ),
);
