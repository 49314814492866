import Button from '@sportnet/ui/Button';
import Segment from '@sportnet/ui/Segment';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import BasicTable from '@sportnet/ui/BasicTable';
import Filter from '@sportnet/ui/Filter';
import { FilterPanel } from '@sportnet/ui/FilterPanel';
import SelectFilter from '@sportnet/ui/FilterPanel/types/SelectFilter';
import NotFound from '@sportnet/ui/NotFound';
import { formatUserName, formatPrice } from '@sportnet/utilities';
import Paginator from '@sportnet/ui/Paginator';
import moment from 'moment';
import PropTypes from 'prop-types';
import connectQueryHoc, { QueryHocTypes } from '@sportnet/query-hoc';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  commit,
  getListTotal,
  initialize,
  isCommiting,
  setParams,
} from '@sportnet/redux-list';
import Api from '../../Api';
import Layout from '../../components/Layout';
import { __ } from '../../utilities';
import { appspaceDefinition } from '../App/definitions';
import { appspaceSelector } from '../App/selectors';
import { getInvoices } from './actions';
import { invoicesCurrentListSelector } from './selectors';

export const LIST_NAME = 'INVOICES_LIST';
const LIST_LIMIT = 100;

class InvoicesList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      downloading: null,
      exporting: false,
      filterValue: {},
      selectedItems: {},
    };
  }
  componentDidMount() {
    const {
      dispatch,
      appspace: { app_space: appspace },
      query,
    } = this.props;

    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          ...query,
          appspace,
        },
      }),
    );
    this.getInvoices();
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.serializedQuery !== nextProps.serializedQuery) {
      nextProps.dispatch(
        setParams({
          listName: LIST_NAME,
          parameters: nextProps.query,
        }),
      );
      this.getInvoices(nextProps);
    }
  }
  getInvoices(props = this.props) {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      query: { offset, q, type, deliveryDateFrom, deliveryDateTo },
    } = props;
    dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          const res = await dispatch(
            getInvoices(
              appspace,
              offset,
              LIST_LIMIT,
              q,
              type[0] === 'DF' && appspace,
              deliveryDateFrom
                ? moment(deliveryDateFrom).format('YYYY-MM-DD')
                : undefined,
              deliveryDateTo
                ? moment(deliveryDateTo).format('YYYY-MM-DD')
                : undefined,
            ),
          );
          return {
            total: res.total,
            results: res.invoices.map(i => i._id),
            nextOffset: res.next_offset,
          };
        },
      }),
    );
  }
  downloadPdf = async nr => {
    const {
      match: {
        params: { appspace },
      },
    } = this.props;
    try {
      this.setState({
        downloading: nr,
      });
      const response = await Api.getInvoicePdfByNr(appspace, nr);
      window.location = response.url;
    } catch (e) {
      console.error(e);
      alert(__('Ľutujeme, nastala neočakávaná chyba.'));
    } finally {
      this.setState({
        downloading: null,
      });
    }
  };

  stornoInvoice = async nr => {
    if (
      !window.confirm(
        __(
          'Prajete si stornovať faktúru? Nezabudnite urobiť aj príslušné opatrenia vo Vašom účtovníctve.',
        ),
      )
    ) {
      return;
    }
    const {
      match: {
        params: { appspace },
      },
    } = this.props;
    try {
      this.setState({
        stornoing: nr,
      });
      await Api.stornoInvoice(appspace, nr);
      this.getInvoices();
    } catch (e) {
      console.error(e);
      alert(__('Ľutujeme, nastala neočakávaná chyba.'));
    } finally {
      this.setState({
        stornoing: null,
      });
    }
  };

  stornoInvoices = async nrs => {
    if (
      !window.confirm(
        __(
          'Prajete si stornovať označené faktúry? Nezabudnite urobiť aj príslušné opatrenia vo Vašom účtovníctve.',
        ),
      )
    ) {
      return;
    }
    const {
      match: {
        params: { appspace },
      },
    } = this.props;
    for await (const nr of nrs) {
      this.setState({
        stornoing: nr,
      });
      try {
        await Api.stornoInvoice(appspace, nr);
      } catch (e) {
        console.error(e);
        alert(__('Ľutujeme, nastala neočakávaná chyba.'));
        break;
      }
    }
    this.getInvoices();
    this.setState({
      stornoing: null,
    });
  };

  exportToPohoda = async invoiceIds => {
    const {
      match: {
        params: { appspace },
      },
    } = this.props;
    try {
      this.setState({
        exporting: true,
      });
      const response = await Api.getInvoicesPohodaXmlByIds(appspace, {
        invoiceIds,
      });
      window.location = response.url;
    } catch (e) {
      console.error(e);
      alert(__('Ľutujeme, nastala neočakávaná chyba.'));
    } finally {
      this.setState({
        exporting: false,
      });
    }
  };

  render() {
    const {
      invoices,
      total,
      isFetching,
      query: { offset, type },
      setParameter,
    } = this.props;

    const { selectedItems, filterValue, exporting, stornoing } = this.state;

    return (
      <Layout
        topFixed={
          <>
            <FilterPanel>
              <SelectFilter
                placeholder={__('Typ faktúry')}
                items={[
                  {
                    title: __('Došlé faktúry'),
                    value: 'DF',
                  },
                  {
                    title: __('Vyšlé faktúry'),
                    value: 'VF',
                  },
                ]}
                value={type}
                onChange={value => {
                  setParameter({
                    type: value,
                  });
                }}
              />
              <Filter
                filters={[
                  {
                    type: 'query',
                    name: 'q',
                    placeholder: 'Vyhľadajte ...',
                    // disabled:true
                  },
                  {
                    type: 'date',
                    name: 'deliveryDateFrom',
                    label: 'Dátum zdaniteľného plnenia od',
                    showYearDropdown: true,
                    // disabled:true
                  },
                  {
                    type: 'date',
                    name: 'deliveryDateTo',
                    label: 'Dátum zdaniteľného plnenia do',
                    showYearDropdown: true,
                    // disabled:true
                  },
                ]}
                value={filterValue}
                onChange={filterValue => {
                  this.setState({
                    filterValue,
                  });

                  const p = {
                    q: undefined,
                    deliveryDateFrom: undefined,
                    deliveryDateTo: undefined,
                  };

                  if (filterValue.q) {
                    p.q = filterValue.q;
                  }

                  if (filterValue.deliveryDateFrom) {
                    p.deliveryDateFrom = moment(
                      filterValue.deliveryDateFrom,
                    ).format('YYYY-MM-DD');
                  }

                  if (filterValue.deliveryDateTo) {
                    p.deliveryDateTo = moment(
                      filterValue.deliveryDateTo,
                    ).format('YYYY-MM-DD');
                  }

                  setParameter(p);
                }}
              />
            </FilterPanel>
          </>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarItem>
              <Paginator
                offset={offset || 0}
                limit={LIST_LIMIT}
                total={total || 0}
                onChangeOffset={e => {
                  setParameter({
                    offset: e,
                  });
                }}
              />
            </ContextBarItem>
            {Object.values(selectedItems).filter(v => v === true).length >
              0 && (
              <>
                <ContextBarSpacer />
                <ContextBarItem>
                  <Button
                    onClick={() => {
                      const ids = Object.keys(selectedItems).filter(function (
                        key,
                      ) {
                        return selectedItems[key];
                      });

                      this.exportToPohoda(ids);
                    }}
                    loading={exporting}
                    disabled={exporting}
                    primary
                  >
                    {__('Exportovať do pohody')}
                  </Button>
                </ContextBarItem>
                <ContextBarItem>
                  <Button
                    onClick={() => {
                      const ids = Object.entries(selectedItems)
                        .filter(([, s]) => !!s)
                        .map(([id]) => id);
                      const nrs = invoices
                        .filter(
                          ({ _id, ppo }) =>
                            ids.includes(_id) &&
                            ppo === this.props.appspace.app_space,
                        )
                        .map(({ invoicenr }) => invoicenr);
                      this.stornoInvoices(nrs);
                    }}
                    loading={stornoing}
                    disabled={stornoing}
                    primary
                  >
                    {__('Stornovať')}
                  </Button>
                </ContextBarItem>
              </>
            )}
          </ContextBar>
        }
      >
        <AppContext title={__('Faktúry')} />
        {!isFetching && invoices.length === 0 ? (
          <NotFound
            title={__('Pre zvolený filter sa nenašli žiadne faktúry')}
            icon="search"
          />
        ) : (
          <Segment noBottomGutter>
            <Segment noBottomGutter raised loading={isFetching}>
              <BasicTable
                columns={[
                  { header: __('Číslo faktúry') },
                  { header: __('Dátum zdaniteľného plnenia') },
                  { header: __('Dátum splatnosti') },
                  { header: __('Subjekt') },
                  { header: __('Položky fakturácie') },
                  { header: '' },
                ]}
                rows={invoices}
                selected={selectedItems}
                onSelect={selectedItems => {
                  this.setState({
                    selectedItems,
                  });
                }}
                renderRow={invoice => [
                  <span
                    style={
                      invoice.storno ? { textDecoration: 'line-through' } : {}
                    }
                  >
                    {invoice.invoicenr}
                  </span>,
                  <>{moment(invoice.deliveryDate).format('DD.MM.YYYY')}</>,
                  <>{moment(invoice.payDueDate).format('DD.MM.YYYY')}</>,
                  <>{formatUserName(invoice.buyer)}</>,
                  <>
                    {invoice.items.slice(0, 3).map((item, index) => {
                      return (
                        <div key={`item-${index}`}>
                          {item.quantity}x {item.name}
                          {': '}
                          {formatPrice(
                            item.totalPrice.amount_vat_excl,
                            item.totalPrice.currency,
                          )}{' '}
                          {__('bez DPH')}
                        </div>
                      );
                    })}
                    {invoice.items.length > 3 && (
                      <div style={{ fontSize: '80%' }}>
                        <em>
                          +{invoice.items.length - 3}{' '}
                          {invoice.items.length === 4
                            ? __('ďalšia položka')
                            : invoice.items.length >= 8
                            ? __('ďalších položiek')
                            : __('ďalšie položky')}
                        </em>
                      </div>
                    )}
                    {invoice.storno && (
                      <div style={{ fontSize: '80%' }}>
                        <em style={{ color: 'red' }}>
                          STORNO:{' '}
                          {moment(invoice.stornoDate).format(
                            'DD.MM.YYYY HH:mm:ss',
                          )}
                          , {invoice.stornoBy.displayName}
                        </em>
                      </div>
                    )}
                  </>,
                  <>
                    <Button
                      primary
                      loading={this.state.downloading === invoice.invoicenr}
                      onClick={() => {
                        this.downloadPdf(invoice.invoicenr);
                      }}
                      icon="file-download"
                    ></Button>{' '}
                    {!invoice.storno &&
                      invoice.ppo === this.props.appspace.app_space && (
                        <Button
                          danger
                          loading={this.state.stornoing === invoice.invoicenr}
                          onClick={() => {
                            this.stornoInvoice(invoice.invoicenr);
                          }}
                          icon="close"
                        ></Button>
                      )}
                  </>,
                ]}
                rowStyle={invoice => {
                  return invoice.storno ? { color: '#AAA' } : undefined;
                }}
                rowKey="_id"
              />
            </Segment>
          </Segment>
        )}
      </Layout>
    );
  }
}

InvoicesList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  appspace: appspaceDefinition.isRequired,
  query: PropTypes.shape({
    q: PropTypes.string.isRequired,
    offset: PropTypes.number.isRequired,
    type: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  serializedQuery: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  total: PropTypes.number,
  setParameter: PropTypes.func.isRequired,
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      invoicenr: PropTypes.string,
      deliveryDate: PropTypes.string,
      payDueDate: PropTypes.string,
      buyer: PropTypes.shape({
        name: PropTypes.string,
        surname: PropTypes.string,
      }),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

InvoicesList.defaultProps = {
  total: 0,
};

const mapStateToProps = state => ({
  appspace: appspaceSelector(state),
  invoices: invoicesCurrentListSelector(LIST_NAME)(state),
  isFetching: isCommiting(LIST_NAME)(state),
  total: getListTotal(LIST_NAME)(state),
});

const connected = connectQueryHoc({
  parameters: {
    q: {
      type: QueryHocTypes.String,
      defaultValue: '',
    },
    offset: {
      type: QueryHocTypes.Number,
      defaultValue: 0,
    },
    type: {
      type: QueryHocTypes.Array,
      defaultValue: [],
      delimiter: ',',
    },
  },
})(InvoicesList);

export default connect(mapStateToProps)(withRouter(connected));
