import { createSelector } from 'reselect';
import { getProp } from '../../../utilities';

const requestDetailDomainSelector = state => state.requestDetail;

export const requestDetailIsFetchingSelector = createSelector(
  requestDetailDomainSelector,
  substate => substate.isFetching,
);

export const requestDetailSelector = createSelector(
  requestDetailDomainSelector,
  substate => getProp(substate, 'detail', {}),
);
