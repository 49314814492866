import { getListResults } from '@sportnet/redux-list';
import { createSelector } from 'reselect';

const entitiesDomain = state => state.entities;

const groupsDomain = createSelector(
  entitiesDomain,
  substate => substate.Groups,
);

export const groupsCurrentListSelector = listName =>
  createSelector(groupsDomain, getListResults(listName), (entities, ids) => {
    const list = (ids || []).reduce((acc, id) => {
      if (entities.groups[id]) {
        return [...acc, entities.groups[id]];
      }
      return acc;
    }, []);
    return list;
  });
