import Icon from '@sportnet/ui/Icon';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { __ } from '../../utilities';
import { FileObjectDefinition } from './definitions';
import Dropzone from './dropzone';
import FileLine from './file';

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(20)} ${rem(10)};
  border-radius: ${rem(4)};
  border: ${rem(1.5)} dashed
    ${({ error, theme }) => (error ? theme.color.danger : theme.separatorColor)};
  cursor: pointer;
  flex-grow: 1;
`;

const FileList = styled.div`
  & > div:first-child {
    margin-top: ${rem(5)};
  }
`;

const DGTitle = styled.div``;

class CompFileInput extends Component {
  state = {
    value: '',
  };

  onChange = files => {
    const multiple = this.props.multiple;
    const value = multiple ? [...this.props.value] : [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      value.push({
        _id: this.getId(),
        name: file.name,
        size: file.size,
        file,
        _new: true,
      });
      if (!multiple) {
        break;
      }
    }
    this.props.onChange(value);
    this.resetValue();
  };

  onInputChange = e => {
    this.onChange(e.target.files);
  };

  onFileClick = file => {
    if (this.props.onFileClick) {
      return () => this.props.onFileClick(file);
    }
    return null;
  };

  onDelete = file => e => {
    e.stopPropagation();
    const { value } = this.props;
    this.props.onChange(value.filter(val => val._id !== file._id));
    this.props.onDeleteCallback(file);
  };

  getId = () => {
    const id = this.nextId;
    this.nextId += 1;
    return String(id);
  };

  nextId = 0;

  resetValue = () => {
    this.setState({
      value: '',
    });
  };

  renderFile = file => {
    return (
      <FileLine
        key={file._id}
        file={file}
        onDelete={this.onDelete(file)}
        onClick={this.onFileClick(file)}
      />
    );
  };

  render() {
    const {
      inputRef,
      readOnly,
      id,
      className,
      accept,
      multiple,
      value,
      error,
    } = this.props;
    const inputId = id || this.context.id;
    return (
      <Wrapper>
        <Dropzone onDropFiles={this.onChange}>
          <Label htmlFor={inputId} error={error}>
            <Icon size="l" name="file-download" />
            <DGTitle>{__('Drag & Drop')}</DGTitle>
          </Label>
        </Dropzone>
        <FileInput
          value={this.state.value}
          type="file"
          className={className}
          innerRef={inputRef}
          readOnly={!!readOnly}
          id={inputId}
          accept={accept}
          multiple={multiple}
          onChange={this.onInputChange}
        />
        <FileList>{(value || []).map(this.renderFile)}</FileList>
      </Wrapper>
    );
  }
}

CompFileInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  inputRef: PropTypes.func,
  readOnly: PropTypes.bool,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(FileObjectDefinition).isRequired,
  onDeleteCallback: PropTypes.func,
  onFileClick: PropTypes.func,
  error: PropTypes.bool,
};

CompFileInput.defaultProps = {
  className: '',
  id: null,
  readOnly: false,
  inputRef: () => {},
  accept: '',
  multiple: false,
  onDeleteCallback: () => {},
  onFileClick: null,
  error: false,
};

CompFileInput.contextTypes = {
  id: PropTypes.string,
};

export default CompFileInput;
