const namespace = 'App';

export const CLEAR_ERROR = `${namespace}/CLEAR_ERROR`;
export const RECEIVE_APP_ERROR = `${namespace}/RECEIVE_APP_ERROR`;

export const REQUEST_APPS = `${namespace}/REQUEST_APPS`;
export const RECEIVE_APPS_SUCCESS = `${namespace}/RECEIVE_APPS_SUCCESS`;
export const RECEIVE_APPS_FAILURE = `${namespace}/RECEIVE_APPS_FAILURE`;

export const REQUEST_RELATED_PPOS = `${namespace}/REQUEST_RELATED_PPOS`;
export const RECEIVE_RELATED_PPOS_SUCCESS = `${namespace}/RECEIVE_RELATED_PPOS_SUCCESS`;
export const RECEIVE_RELATED_PPOS_FAILURE = `${namespace}/RECEIVE_RELATED_PPOS_FAILURE`;
