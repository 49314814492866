import BasicTable from '@sportnet/ui/BasicTable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getStatusIcon, useCodelist, __ } from '../../../../utilities';
import { bankAccountTypeCodelistSelector } from '../../../App/selectors';
import {
  bankAccountDefinition,
  codelistDefinition,
} from '../../../FO/definitions';

const Table = ({ items, bankAccountTypes }) => {
  const [getBankAccountTypeLabel] = useCodelist(bankAccountTypes);

  return (
    <BasicTable
      columns={[
        {
          id: 'type',
          header: __('Typ'),
          width: 200,
        },
        {
          id: 'iban',
          header: __('IBAN'),
        },
        {
          id: 'status',
          header: __('Stav'),
          width: 70,
        },
      ]}
      rows={items}
      renderRow={item => [
        getBankAccountTypeLabel(item.type),
        item.IBAN,
        getStatusIcon(item.status),
      ]}
      rowKey="_id"
    />
  );
};

const mapStateToProps = state => {
  return {
    bankAccountTypes: bankAccountTypeCodelistSelector(state),
  };
};

Table.propTypes = {
  items: PropTypes.arrayOf(bankAccountDefinition).isRequired,
  bankAccountTypes: PropTypes.arrayOf(codelistDefinition).isRequired,
};

export default connect(mapStateToProps)(Table);
