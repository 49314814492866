import SubmitButton from '@sportnet/ui/Button/Submit';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Segment from '@sportnet/ui/Segment';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { SubmissionError, submit, destroy } from 'redux-form';
import Api from '../../../Api';
import Layout from '../../../components/Layout';
import { __ } from '../../../utilities';
import { bankAccountDefinition } from '../../FO/definitions';
import { fetchOrganizationBankAccounts } from '../../PO/actions';
import {
  organizationBankAccountsListSelector,
  organizationIsFetching,
} from '../../PO/selectors';
import DSGEditRequestForm from './form';

const FORM_NAME = 'DSG_EDIT_REQUEST_FORM';
class DSGEditRequest extends React.PureComponent {
  state = {
    isSubmitting: false,
    isError: false,
  };

  componentDidMount() {
    this.loadBankAccounts();
  }

  loadBankAccounts = () => {
    this.props.dispatch(
      fetchOrganizationBankAccounts(this.props.match.params.appspace),
    );
  };

  submit = async values => {
    const transformedValues = {};

    const targetGroups = (values.targetGroups || []).map(i => ({
      iban: i.iban ? i.iban.value : '',
    }));

    const filteredTargetGroups = targetGroups.filter(i => i.iban);
    if (filteredTargetGroups.length) {
      transformedValues.targetGroups = targetGroups;
    }

    if ((values.users || []).length) {
      transformedValues.users = values.users;
    }

    if (
      Object.keys(transformedValues).length === 0 ||
      (!transformedValues.users && filteredTargetGroups.length === 0)
    ) {
      alert(__('Formulár neobsahuje žiadnu zmenu'));
      throw new SubmissionError({});
    }
    try {
      this.setState({
        isSubmitting: true,
        isError: false,
      });
      await Api.createDSGEditRequest(this.props.match.params.appspace, {
        data: transformedValues,
      });
      this.props.dispatch(destroy(FORM_NAME));
    } catch (e) {
      this.setState({
        isError: true,
      });
      alert(__('Žiadosť nebolo možné uložiť.'));
    } finally {
      this.setState({
        isSubmitting: false,
      });
    }
  };

  render() {
    const { isFetching, bankAccounts } = this.props;
    return (
      <Layout
        isFetching={isFetching}
        bottomFixed={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <SubmitButton
                primary
                isSubmitting={this.state.isSubmitting}
                isError={this.state.isError}
                successText={__('Úspešne odoslané')}
                onClick={() => {
                  this.props.dispatch(submit(FORM_NAME));
                }}
              >
                {__('Požiadať o zmenu')}
              </SubmitButton>
            </ContextBarItem>
          </ContextBar>
        }
      >
        <AppContext
          title={__(
            'Záväzná žiadosť/nahlásenie zmeny údajov pre účely realizácie projektu "Dajme spolu gól"',
          )}
        />
        <Segment>
          <DSGEditRequestForm
            onSubmit={this.submit}
            form={FORM_NAME}
            bankAccounts={bankAccounts}
            initialValues={{
              targetGroups: [{ iban: { value: '' } }, { iban: { value: '' } }],
            }}
          />
        </Segment>
      </Layout>
    );
  }
}

DSGEditRequest.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      appspace: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  bankAccounts: PropTypes.arrayOf(bankAccountDefinition).isRequired,
};

const mapStateToProps = state => ({
  isFetching: organizationIsFetching(state),
  bankAccounts: organizationBankAccountsListSelector(state),
});

export default compose(connect(mapStateToProps), withRouter)(DSGEditRequest);
