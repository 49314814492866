import Button from '@sportnet/ui/Button';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Col, { Row } from '@sportnet/ui/Grid';
import Label from '@sportnet/ui/Label/Label';
import Segment from '@sportnet/ui/Segment';
import moment from 'moment';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm, reset } from 'redux-form';
import styled, { withTheme } from 'styled-components';
import { __ } from '../../utilities';
import { required } from '../../validation';

const FORM_NAME = 'APPLICATION_ORDER_FORM';

const Status = styled.div`
  font-size: ${rem(15)};
  font-weight: bold;
  color: ${({ color, theme }) => color || theme.color.base};
`;

class ApplicationOrderForm extends React.PureComponent {
  componentWillReceiveProps(nextProps) {
    if (
      this.props.activeItem &&
      this.props.activeItem._id !== nextProps.activeItem._id
    ) {
      nextProps.dispatch(reset(FORM_NAME));
    }
  }

  getCurrentStatus = () => {
    const { activeItem, theme } = this.props;
    if (!activeItem.validTo) {
      return <Status>{__('Bez obmedzenia')}</Status>;
    }
    if (moment().isBefore(moment(activeItem.validTo))) {
      return (
        <Status color={theme.color.success}>{`${__('Aktívna do:')} ${moment(
          activeItem.validTo,
        ).format('DD.MM.YYYY')}`}</Status>
      );
    }
    return <Status color={theme.color.danger}>{__('Neaktívna')}</Status>;
  };

  render() {
    const { items, activeItem, handleSubmit, submitting } = this.props;
    if (!activeItem) {
      return null;
    }
    return (
      <Segment>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label>{__('Aktuálny stav')}</Label>
                {this.getCurrentStatus()}
              </FormGroup>
            </Col>
          </Row>
          {items.length > 0 && activeItem.validTo && (
            <Row>
              <Col>
                <Field
                  label={__('Výber varianty')}
                  component={FormFieldRedux}
                  name="itemId"
                  type="theselectsimple"
                  required
                  validate={[required]}
                  options={items.map(item => ({
                    label: `${item.name} (${item.price.amount} ${
                      item.price.currency
                    } ${__('s DPH')})`,
                    value: item._id,
                  }))}
                />
              </Col>
              <Col>
                <Button type="submit" loading={submitting} primary>
                  {__('Zaplatiť')}
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </Segment>
    );
  }
}

ApplicationOrderForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
      }),
    }),
  ).isRequired,
  activeItem: PropTypes.shape({
    _id: PropTypes.string,
    validTo: PropTypes.string,
  }),
  theme: PropTypes.shape({}).isRequired,
};

ApplicationOrderForm.defaultProps = {
  activeItem: null,
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(ApplicationOrderForm);

export default connect()(withTheme(connected));
