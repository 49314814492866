import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { __ } from '../../../../utilities';
import validation from '../../../../utilities/validation';
import { applicationRolesSelector } from '../../../App/selectors';

export const formName = 'SportExpertOrgManager';

const Form = styled.form`
  width: 100%;
`;

const ReduxForm = ({ handleSubmit }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col m={6}>
          <Field
            label={__('Licenciu overil')}
            name="verifiedByPerson"
            type="text"
            component={FormFieldRedux}
            validate={[validation.required]}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col l={6} xl={3}>
          <Field
            component={FormFieldRedux}
            label={__('Platí od')}
            name="validFrom"
            type="date"
            isClearable={false}
            required
            data-testid="CRM_FO_Input_Affiliations_DateTo"
          />
        </Col>
        <Col l={6} xl={3}>
          <Field
            component={FormFieldRedux}
            label={__('Platí do')}
            name="validTo"
            isClearable={false}
            validate={[validation.required]}
            type="date"
            required
            data-testid="CRM_FO_Input_Affiliations_DateFro"
          />
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = state => {
  return {
    roles: applicationRolesSelector(state),
  };
};

ReduxForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  showFields: PropTypes.bool.isRequired,
};

ReduxForm.defaultProps = {};

export default connect(mapStateToProps)(
  reduxForm({
    form: formName,
    enableReinitialize: true,
  })(ReduxForm),
);
