import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Message from '@sportnet/ui/Message';
import Segment from '@sportnet/ui/Segment';
import Sidebar from '@sportnet/ui/Sidebar';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Api from '../../../../Api';
import Layout from '../../../../components/Layout';
import { getProp, getStatusIcon, __ } from '../../../../utilities';
import {
  aclSelector,
  addressTypeCodelistSelector,
  countryCodelistSelector,
} from '../../../App/selectors';
import { activeAppSpaceRelationsSelector } from '../../../Authorization/selectors';
import {
  addressDefinition,
  codelistDefinition,
  matchDefinition,
} from '../../../FO/definitions';
import {
  fetchOrganizationAddresses,
  fetchOrganizationProfile,
} from '../../actions';
import {
  organizationAddressesListSelector,
  organizationIsFetching,
} from '../../selectors';
import Wrapper from '../index';
import AddressesForm from './form';

class AddressesList extends PureComponent {
  state = {
    sidebarVisible: false,
    address: null,
    parentProfile: null,
    parentProfileIsFetching: false,
    updateDisabled: true,
    createDisabled: true,
    dataNotApproved: false,
  };

  componentDidMount() {
    const { appspace } = this.props.match.params;
    this.loadOrganizationProfile();
    this.props.dispatch(fetchOrganizationAddresses(appspace)).then(data => {
      this.setState({ dataNotApproved: data._dataNotApproved });
    });
    this.setAclAccess(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setAclAccess(nextProps);
  }

  setAclAccess = props => {
    if (typeof this.state.disabled === 'undefined' && props.acl) {
      this.setState({
        updateDisabled: !props.acl.crmMyOrganizationRequestChange,
        createDisabled: !props.acl.crmMyOrganizationRequestChange,
      });
    }
  };

  getAddressType = type => {
    const item = this.props.addressTypes.find(addressType => {
      return addressType.value === type;
    });
    return getProp(item, ['label']);
  };

  getCountry = countryCode => {
    const item = this.props.countries.find(country => {
      return country.value === countryCode;
    });
    return getProp(item, ['label']);
  };

  loadOrganizationProfile = async () => {
    const {
      match: {
        params: { appspace },
      },
      relations,
    } = this.props;
    this.setState({
      parentProfileIsFetching: true,
    });
    const organization = await this.props.dispatch(
      fetchOrganizationProfile(appspace),
    );
    const state = {
      parentProfileIsFetching: false,
    };
    if (organization) {
      state.orgLogoSrc = organization.logo_public_url || null;
      state.orgFaviconSrc = organization.favicon_public_url || null;
    }
    if (relations.length) {
      try {
        const parentProfile = await Api.organizationPPOProfile(
          relations[0].related_ppo,
        );
        if (parentProfile) {
          state.parentProfile = parentProfile;
        }
      } catch (e) {
        //
      }
    }
    this.setState(state);
  };

  render() {
    const { addresses, isFetching } = this.props;
    const { address, sidebarVisible, parentProfileIsFetching } = this.state;
    return (
      <Layout
        bottomFixed={
          !this.state.createDisabled && (
            <ContextBar>
              <ContextBarSpacer />
              <ContextBarItem>
                <Button
                  onClick={() => {
                    this.setState({
                      sidebarVisible: true,
                    });
                  }}
                  primary
                >
                  {__('Pridať záznam')}
                </Button>
              </ContextBarItem>
            </ContextBar>
          )
        }
      >
        <AppContext title={__('Moja organizácia')} />
        <Wrapper
          isFetching={isFetching || parentProfileIsFetching}
          activeTab={__('Adresy')}
        >
          {!this.state.createDisabled && this.state.parentProfile && (
            <Message
              warning
              title={__('Zmeny podliehajú schvaľovaciemu procesu')}
            >
              {`${__(
                'Pridaním alebo upravením adresy sa zmeny zašlú na schválenie organizácii',
              )} ${this.state.parentProfile.name}`}
            </Message>
          )}
          {this.state.dataNotApproved === true && this.state.parentProfile && (
            <Message danger title={__('Údaje čakajú na schválenie')}>
              {`${__('Zmeny boli odoslané na schválenie organizácii')} ${
                this.state.parentProfile.name
              }`}
            </Message>
          )}
          <Segment raised>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Typ')}</Th>
                  <Th>{__('Adresa')}</Th>
                  <Th>{__('Stav')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {addresses.map(item => {
                  return (
                    <Tr
                      key={item._id}
                      {...(!this.state.updateDisabled && {
                        onClick: () => {
                          this.setState({
                            sidebarVisible: true,
                            address: item,
                          });
                        },
                      })}
                    >
                      <Td>{this.getAddressType(item.type)}</Td>
                      <Td>
                        {`${item.street} ${
                          item.conscription_number
                            ? `${item.conscription_number}/`
                            : ''
                        }${item.number},`}
                        &nbsp;
                        {item.zip}&nbsp;
                        {item.city},&nbsp;
                        {this.getCountry(item.country)}
                      </Td>
                      <Td>{getStatusIcon(item.status)}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Segment>
        </Wrapper>
        <Sidebar
          onClose={() => {
            this.setState({ sidebarVisible: false, address: null });
          }}
          visible={sidebarVisible}
          header={__('Adresa')}
        >
          {sidebarVisible && (
            <AddressesForm
              closeSidebar={() => {
                this.setState({ sidebarVisible: false, address: null });
              }}
              parameters={{ address }}
            />
          )}
        </Sidebar>
      </Layout>
    );
  }
}

AddressesList.propTypes = {
  addresses: PropTypes.arrayOf(addressDefinition).isRequired,
  addressTypes: PropTypes.arrayOf(codelistDefinition).isRequired,
  countries: PropTypes.arrayOf(codelistDefinition).isRequired,
  match: matchDefinition.isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    addresses: organizationAddressesListSelector(state),
    countries: countryCodelistSelector(state),
    addressTypes: addressTypeCodelistSelector(state),
    isFetching: organizationIsFetching(state),
    relations: activeAppSpaceRelationsSelector(state),
    acl: aclSelector(state),
  };
};

export default connect(mapStateToProps)(AddressesList);
