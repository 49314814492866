import Button from '@sportnet/ui/Button';
import FormGroup from '@sportnet/ui/FormGroup';
import Input from '@sportnet/ui/Input';
import Label from '@sportnet/ui/Label/Label';
import Segment from '@sportnet/ui/Segment';
import SelectAutocomplete from '@sportnet/ui/TheSelectSimple';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import styled from 'styled-components';
import AddressApi from '../../../../AddressApi';
import Api from '../../../../Api';
import { __ } from '../../../../utilities';
import { appspaceDefinition } from '../../../App/definitions';
import {
  addressTypeCodelistSelector,
  appspaceSelector,
  countryCodelistSelector,
} from '../../../App/selectors';
import { activeAppSpaceRelationsSelector } from '../../../Authorization/selectors';
import { addressDefinition, codelistDefinition } from '../../../FO/definitions';
import {
  createOrganizationAddress,
  editOrganizationAddress,
} from '../../actions';

const AutocompeteWrapper = styled.div`
  font-size: ${rem(14)};
`;

class AddressForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      streets: [],
      cities: [],
      zips: [],
      street: '',
      city: '',
      zip: '',
      country: 'SK',
      countryQuery: 'Slovakia',
    };
    this.onChangeValue = this.onChangeValue.bind(this);
    this.createAddress = this.createAddress.bind(this);
    this.editAddress = this.editAddress.bind(this);
    this.getStreets = this.getStreets.bind(this);
    this.getCitiesByCity = this.getCitiesByCity.bind(this);
    this.getCitiesByZip = this.getCitiesByZip.bind(this);
  }
  componentDidMount() {
    if (this.props.parameters.address) {
      this.setInitialValues(this.props.parameters.address);
    }
    AddressApi.setBaseUrl(process.env.REACT_APP_ADDRESS_API_BASE_URL);
  }
  componentWillReceiveProps(nextProps) {
    if (
      (!this.props.parameters.address && nextProps.parameters.address) ||
      (nextProps.parameters.address &&
        nextProps.parameters.address._id !== this.props.parameters.address._id)
    ) {
      this.setInitialValues(nextProps.parameters.address);
    }
  }

  onChangeValue(name, value) {
    this.setState({
      [name]: value,
    });
  }

  setInitialValues(values) {
    Object.keys(values).forEach(key => {
      this.onChangeValue(key, values[key]);
    });
  }

  getCitiesByCity(query) {
    AddressApi.citySearchAction({
      query,
    }).then(res => {
      this.setState({
        cities: res.items.map(item => {
          const { city, zip } = item;
          return {
            ...item,
            label: `${city} (${zip})`,
          };
        }),
        zips: res.items.map(item => {
          return {
            ...item,
            label: item.zip,
          };
        }),
      });
    });
  }

  getCitiesByZip(query) {
    AddressApi.zipSearchAction({
      query,
    })
      .then(res => {
        this.setState({
          zips: res.items.map(item => {
            return {
              ...item,
              label: item.zip,
            };
          }),
          cities: res.items.map(item => {
            const { city, zip } = item;
            return {
              ...item,
              label: `${city} (${zip})`,
            };
          }),
        });
      })
      .catch(e => {
        console.error(e);
      });
  }

  getStreets(query) {
    const f = { query };
    if (this.state.city !== '') {
      f.city = this.state.city;
    }
    AddressApi.streetSearchAction(f).then(res => {
      this.setState({
        streets: res.items.map(item => {
          const { city, street, zip } = item;
          return {
            ...item,
            label: `${street}, ${city} ${zip}`,
          };
        }),
      });
    });
  }

  async createAddress() {
    const { app_space } = this.props.appspace;
    const {
      city,
      country,
      number,
      conscription_number,
      status,
      street,
      type,
      zip,
    } = this.state;
    if (this.props.relations.length) {
      await Api.crmMyOrganizationRequestChange(app_space, {
        data: {
          addresses: [
            {
              city,
              country,
              number,
              conscription_number,
              status,
              street,
              type,
              zip,
            },
          ],
        },
      });
    } else {
      await this.props.dispatch(
        createOrganizationAddress(app_space, {
          city,
          country,
          number,
          conscription_number,
          status,
          street,
          type,
          zip,
        }),
      );
    }
    this.props.closeSidebar();
  }

  async editAddress() {
    const {
      appspace: { app_space },
      parameters: { address },
    } = this.props;
    const {
      city,
      country,
      number,
      conscription_number,
      status,
      street,
      type,
      zip,
    } = this.state;
    if (this.props.relations.length) {
      await Api.crmMyOrganizationRequestChange(app_space, {
        data: {
          addresses: [
            {
              _id: address._id,
              city,
              country,
              number,
              conscription_number,
              status,
              street,
              type,
              zip,
            },
          ],
        },
      });
    } else {
      await this.props.dispatch(
        editOrganizationAddress(address._id, app_space, {
          city,
          country,
          number,
          conscription_number,
          status,
          street,
          type,
          zip,
        }),
      );
    }
    this.props.closeSidebar();
  }

  render() {
    const { parameters, addressTypes } = this.props;
    return (
      <Segment>
        <React.Fragment>
          <FormGroup>
            <Label>{__('Ulica')}</Label>
            <AutocompeteWrapper>
              <Input
                value={this.state.street}
                name="street"
                onChange={e => {
                  this.setState({
                    street: e.target.value,
                  });
                }}
              />
            </AutocompeteWrapper>
          </FormGroup>

          <FormGroup>
            <Label>{__('Orientačné č. domu')}</Label>
            <Input
              value={this.state.number}
              name="number"
              onChange={e => {
                this.onChangeValue(e.target.name, e.target.value);
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label>{__('Súpisné č. domu')}</Label>
            <Input
              value={this.state.conscription_number}
              name="conscription_number"
              onChange={e => {
                this.onChangeValue(e.target.name, e.target.value);
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label>{__('Mesto')}</Label>
            <AutocompeteWrapper>
              <Input
                value={this.state.city}
                name="city"
                onChange={e => {
                  this.setState({
                    city: e.target.value,
                  });
                }}
              />
            </AutocompeteWrapper>
          </FormGroup>

          <FormGroup>
            <Label>{__('PSČ')}</Label>
            <AutocompeteWrapper>
              <Input
                value={this.state.zip}
                name="zip"
                onChange={e => {
                  this.setState(
                    {
                      zip: e.target.value,
                    },
                    () => {
                      this.getCitiesByZip(e.target.value);
                    },
                  );
                }}
              />
            </AutocompeteWrapper>
          </FormGroup>

          <FormGroup>
            <Label>{__('Štát')}</Label>
            <AutocompeteWrapper>
              <Input
                value={this.state.countryQuery}
                name="country"
                onChange={e => {
                  this.setState({
                    countryQuery: e.target.value,
                  });
                }}
              />
            </AutocompeteWrapper>
          </FormGroup>
          <FormGroup>
            <Label>{__('Typ')}</Label>
            <SelectAutocomplete
              name="type"
              value={this.state.type}
              onChange={value => {
                this.onChangeValue('type', value);
              }}
              options={addressTypes.map(type => ({
                label: type.label,
                value: type.value,
              }))}
            />
          </FormGroup>

          <FormGroup>
            <Label>{__('Stav záznamu')}</Label>
            <SelectAutocomplete
              name="status"
              value={this.state.status}
              onChange={value => {
                this.onChangeValue('status', value);
              }}
              options={[
                { label: 'aktívny', value: 'active' },
                { label: 'neaktívny', value: 'inactive' },
              ]}
            />
          </FormGroup>

          {!parameters.address && (
            <Button primary onClick={this.createAddress}>
              {__('Pridať položku')}
            </Button>
          )}
          {parameters.address && (
            <Button primary onClick={this.editAddress}>
              {__('Upraviť položku')}
            </Button>
          )}
        </React.Fragment>
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  return {
    appspace: appspaceSelector(state),
    addressTypes: addressTypeCodelistSelector(state),
    countries: countryCodelistSelector(state),
    relations: activeAppSpaceRelationsSelector(state),
  };
};

AddressForm.propTypes = {
  appspace: appspaceDefinition.isRequired,
  dispatch: PropTypes.func.isRequired,
  parameters: PropTypes.shape({
    address: addressDefinition,
  }).isRequired,
  addressTypes: PropTypes.arrayOf(codelistDefinition).isRequired,
  countries: PropTypes.arrayOf(codelistDefinition).isRequired,
  closeSidebar: PropTypes.func.isRequired,
  relations: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      related_ppo: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default compose(connect(mapStateToProps), withRouter)(AddressForm);
