import Loader from '@sportnet/ui/Loader';
import Segment from '@sportnet/ui/Segment';
import Sidebar from '@sportnet/ui/Sidebar';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import SportnetUiAppContext from '@sportnet/ui/TheLayout/AppContext';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Api from '../../Api';
import Layout from '../../components/Layout';
import { LoaderContainer } from '../../components/List';
import { __ } from '../../utilities';
import { fetchApps } from './actions';
import ApplicationOrderForm from './form';
import {
  applicationsDataSelector,
  applicationsIsFetchingSelector,
} from './selectors';
import getApiError from '../../utilities/getApiError';
import { useAuth } from '@sportnet/auth-react';
import formatDate from '../../utilities/formatDate';
import config from '../../config';

const AppsList = ({ data, isFetching, fetchApps }) => {
  const { ppo: appSpace } = useAuth();
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [fetchingPriceList, setFetchingPriceList] = React.useState(false);
  const [pricelist, setPricelist] = React.useState([]);
  const [activeItem, setActiveItem] = React.useState();

  React.useState(() => {
    if (appSpace) {
      fetchApps(appSpace);
    }
  }, [appSpace]);

  const getApplicationPriceList = async activeItem => {
    setSidebarVisible(true);
    setFetchingPriceList(true);
    setActiveItem(activeItem);

    try {
      const { pricelist } = await Api.crmPPOPricelist(appSpace, {
        applicationId: activeItem._id,
      });
      setPricelist(pricelist);
    } catch (e) {
      const err = getApiError(e);
      alert(
        `Chyba získania price listu: ${err.details.statusCode} ${err.details.name} ${err.message}`,
      );
    } finally {
      setFetchingPriceList(false);
    }
  };

  const submit = async data => {
    try {
      const res = await Api.crmAppPayment(appSpace, data.itemId, {
        callbackUrl: `${process.env.REACT_APP_BASE_URL}/admin/${appSpace}/payment-callback`,
      });
      window.location = res.redirect;
    } catch (e) {
      const err = getApiError(e);
      alert(
        `Chyba získania price listu: ${err.details.statusCode} ${err.details.name} ${err.message}`,
      );
    }
  };

  return (
    <Layout>
      <Segment noBottomGutter>
        <Segment raised loading={isFetching && data.length === 0}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Aplikácia')}</Th>
                <Th>{__('Platnosť do')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map(item => {
                return (
                  <Tr
                    key={item._id}
                    onClick={() => {
                      getApplicationPriceList(item);
                    }}
                  >
                    <Td>{item.app_id}</Td>
                    <Td>
                      {item.validTo
                        ? formatDate(item.validTo, config.DATE_FORMAT)
                        : __('Bez obmedzenia')}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Segment>
      </Segment>
      <Sidebar
        visible={sidebarVisible}
        header={__('Predĺženie platnosti aplikácie')}
        onClose={() => {
          setSidebarVisible(false);
        }}
      >
        {fetchingPriceList && (
          <LoaderContainer>
            <Loader size="xl" />
          </LoaderContainer>
        )}
        <ApplicationOrderForm
          onSubmit={submit}
          activeItem={activeItem}
          items={pricelist}
        />
      </Sidebar>
      <SportnetUiAppContext title={__('Moje aplikácie')} />
    </Layout>
  );
};

AppsList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      validTo: PropTypes.string,
    }),
  ).isRequired,
};

const mapDispatchToProps = {
  fetchApps,
};

const mapStateToProps = state => ({
  data: applicationsDataSelector(state),
  isFetching: applicationsIsFetchingSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppsList);
