import { getListParameters, initialize, setParams } from '@sportnet/redux-list';
import Api from '../../Api';
import {
  CLEAR_ERROR,
  RECEIVE_APPS_FAILURE,
  RECEIVE_APPS_SUCCESS,
  RECEIVE_APP_ERROR,
  RECEIVE_RELATED_PPOS_FAILURE,
  RECEIVE_RELATED_PPOS_SUCCESS,
  REQUEST_APPS,
  REQUEST_RELATED_PPOS,
} from './constants';

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const receiveAppError = error => {
  console.error(error); // eslint-disable-line
  return {
    type: RECEIVE_APP_ERROR,
    payload: {
      error,
    },
  };
};

export const requestApps = () => ({
  type: REQUEST_APPS,
  payload: {},
});

export const receiveAppsSuccess = apps => ({
  type: RECEIVE_APPS_SUCCESS,
  payload: {
    apps,
  },
});

export const receiveAppsFailure = error => ({
  type: RECEIVE_APPS_FAILURE,
  payload: {
    error,
  },
});

export const loadApps = () => {
  return async dispatch => {
    dispatch(requestApps());
    try {
      const response = await Api.getPublicApps();
      dispatch(receiveAppsSuccess(response.apps));
    } catch (error) {
      dispatch(receiveAppsFailure(error));
    }
  };
};

// Related Organizations

const requestRelatedPpos = appspace => ({
  type: REQUEST_RELATED_PPOS,
  payload: {
    appspace,
  },
});

const receiveRelatedPposSuccess = (appspace, ids, ppos) => ({
  type: RECEIVE_RELATED_PPOS_SUCCESS,
  payload: {
    appspace,
    ids,
    ppos,
  },
});

const receiveRelatedPposFailure = (appspace, error) => ({
  type: RECEIVE_RELATED_PPOS_FAILURE,
  payload: {
    appspace,
    error,
  },
});

export const getRelatedPpos = appspace => {
  return async dispatch => {
    dispatch(requestRelatedPpos(appspace));
    try {
      const response = await Api.crmListRelatedPPOs(appspace);
      dispatch(
        receiveRelatedPposSuccess(
          appspace,
          response.items.map(item => item._id),
          response.items,
        ),
      );
    } catch (e) {
      console.error(e);
      dispatch(receiveRelatedPposFailure(e.details || e));
    }
  };
};

export const initializeOrSetListParams = ({ listName, params }) => {
  return async (dispatch, getState) => {
    const reduxListParams = getListParameters(listName)(getState());
    if (Object.keys(reduxListParams).length === 0) {
      return dispatch(
        initialize({
          listName,
          initialParams: params,
        }),
      );
    }
    return dispatch(
      setParams({
        listName,
        parameters: params,
      }),
    );
  };
};
