import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import {
  FilterPanel,
  QueryFilter,
  SelectFilter,
} from '@sportnet/ui/FilterPanel';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import Sidebar from '@sportnet/ui/Sidebar';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import moment from 'moment';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import connectQueryHoc, { QueryHocTypes } from '@sportnet/query-hoc';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  commit,
  getListTotal,
  initialize,
  isCommiting,
  setParams,
} from '@sportnet/redux-list';
import { getProp } from '@sportnet/utilities';
import { withTheme } from 'styled-components';
import Layout from '../../components/Layout';
import { Status } from '../../components/List';
import {
  getRequestStatusCodelist,
  getRequestStatusName,
  getRequestTypeCodelist,
  getRequestTypeName,
  __,
} from '../../utilities';
import { appspaceSelector } from '../App/selectors';
import { loadRequestsList } from './actions';
import { PersonRequest, TransferRequest } from './Create';
import {
  requestColorThemedefinition,
  searchParamsDefinition,
  universalRequestDefinition,
} from './definitions';
import { requestsCurrenListSelector } from './selectors';

const LIST_LIMIT = 40;
const LIST_NAME = 'REQUESTS_LIST';

class Requests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestStatusCodelist: getRequestStatusCodelist(),
      requestTypeCodelist: getRequestTypeCodelist(),
    };
    this.handleCloseSidebar = this.handleCloseSidebar.bind(this);
    this.handleClickRequest = this.handleClickRequest.bind(this);
    this.handleClickCreateRequest = this.handleClickCreateRequest.bind(this);
    this.handleClickCreateTransferRequest =
      this.handleClickCreateTransferRequest.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      query,
    } = this.props;
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          ...query,
          appspace,
        },
      }),
    );
    this.getRequests();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.serializedQuery !== nextProps.serializedQuery) {
      nextProps.dispatch(
        setParams({
          listName: LIST_NAME,
          parameters: nextProps.query,
        }),
      );
      this.getRequests(nextProps);
    }
  }

  onSubmitSuccess = () => {
    this.handleCloseSidebar();
    return this.getRequests();
  };

  getRequests = (props = this.props) => {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      query,
    } = props;
    return dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          const res = await dispatch(
            loadRequestsList(appspace, LIST_LIMIT, query),
          );
          if (res) {
            return {
              total: res.total,
              results: res.requests.map(i => i._id),
              nextOffset: res.next_offset,
            };
          }
          return {};
        },
      }),
    );
  };

  handleClickRequest(requestId) {
    const {
      history,
      location,
      match: {
        params: { appspace },
      },
    } = this.props;
    history.push({
      ...location,
      pathname: `/admin/${appspace}/request/${requestId}`,
    });
  }

  handleClickCreateRequest() {
    const {
      history,
      location,
      match: {
        params: { appspace },
      },
    } = this.props;
    history.push({
      ...location,
      pathname: `/admin/${appspace}/create-request/person`,
    });
  }

  handleClickCreateTransferRequest() {
    const {
      history,
      location,
      match: {
        params: { appspace },
      },
    } = this.props;
    history.push({
      ...location,
      pathname: `/admin/${appspace}/create-request/transfer`,
    });
  }

  handleCloseSidebar() {
    const {
      history,
      location,
      match: {
        params: { appspace },
      },
    } = this.props;
    history.push({
      ...location,
      pathname: `/admin/${appspace}/requests`,
    });
  }

  getIdentificator = request => {
    if (
      request.request_type === 'sport-org' ||
      request.request_type === 'sport-expert-org'
    ) {
      return getProp(request, ['data', 'sportnet_user', 'idnr'], '');
    }
    if (request.request_type === 'po-registry') {
      return getProp(request, ['data', 'po', 'organization', 'business_id']);
    }
    return '';
  };

  getOrganization = request => {
    if (request.request_type === 'po-registry') {
      return (
        getProp(request, ['data', 'po', 'profile', 'name']) ||
        getProp(request, ['data', 'po', 'organization', 'full_name'])
      );
    }
    return '';
  };

  render() {
    const {
      theme,
      isFetching,
      match: {
        params: { createRequestType },
      },
      total,
      requestsList,
      setParameter,
      query: { offset, q, statuses, requestTypes },
    } = this.props;
    const { requestStatusCodelist, requestTypeCodelist } = this.state;

    return (
      <Layout
        bottomFixed={
          <ContextBar>
            <ContextBarItem>
              <Paginator
                limit={LIST_LIMIT}
                offset={offset}
                total={total || 0}
                onChangeOffset={value =>
                  setParameter({
                    offset: value,
                  })
                }
              />
            </ContextBarItem>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button onClick={this.handleClickCreateRequest} primary>
                {__('Nová pozvánka osoby')}
              </Button>
            </ContextBarItem>
            <ContextBarItem>
              <Button onClick={this.handleClickCreateTransferRequest} primary>
                {__('Nová žiadosť o prestup')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        }
        topFixed={
          <FilterPanel>
            <QueryFilter
              value={q}
              onChange={value =>
                setParameter({
                  q: value,
                })
              }
            />
            <SelectFilter
              placeholder={__('Stav žiadosti')}
              value={statuses}
              onChange={value =>
                setParameter({
                  statuses: value,
                })
              }
              items={[
                {
                  title: __('Stav žiadosti'),
                  value: requestStatusCodelist,
                },
              ]}
            />
            <SelectFilter
              placeholder={__('Typ žiadosti')}
              value={requestTypes}
              onChange={value =>
                setParameter({
                  requestTypes: value,
                })
              }
              items={[
                {
                  title: __('Typ žiadosti'),
                  value: requestTypeCodelist,
                },
              ]}
            />
          </FilterPanel>
        }
      >
        <AppContext title={__('Žiadosti')} />
        <Segment noBottomGutter>
          <Segment raised loading={isFetching}>
            <Table>
              <Thead>
                <Tr>
                  <Th center style={{ width: rem(185) }}>
                    {__('Stav')}
                  </Th>
                  <Th>{__('Typ')}</Th>
                  <Th>{__('IČO / Rodné číslo')}</Th>
                  <Th>{__('Dátum vytvorenia')}</Th>
                  <Th>{__('Organizácia')}</Th>
                  <Th>{__('Osoba')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {requestsList.map(request => {
                  const id = request._id;
                  return (
                    <Tr key={id} onClick={() => this.handleClickRequest(id)}>
                      <Td center>
                        <Status color={theme.requestColor[request.status]}>
                          {getRequestStatusName(request.status)}
                        </Status>
                      </Td>
                      <Td>{getRequestTypeName(request.request_type)}</Td>
                      <Td>{this.getIdentificator(request)}</Td>
                      <Td>
                        {moment(request.created_date).format('DD.MM.YYYY')}
                      </Td>
                      <Td>{this.getOrganization(request)}</Td>
                      <Td>{request.ownerUser.display_name}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Segment>
        </Segment>

        {(requestType => {
          if (requestType === 'person') {
            return (
              <Sidebar
                onClose={this.handleCloseSidebar}
                visible={true}
                header={__('Nová pozvánka osoby')}
              >
                <PersonRequest onSubmitSuccess={this.onSubmitSuccess} />
              </Sidebar>
            );
          } else if (requestType === 'transfer') {
            return (
              <Sidebar
                onClose={this.handleCloseSidebar}
                visible={true}
                header={__('Nová žiadosť o prestup')}
              >
                <TransferRequest onSubmitSuccess={this.onSubmitSuccess} />
              </Sidebar>
            );
          }
          return null;
        })(createRequestType)}
      </Layout>
    );
  }
}

Requests.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  requestsList: PropTypes.arrayOf(universalRequestDefinition).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      appspace: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
  query: searchParamsDefinition.isRequired,
  theme: requestColorThemedefinition.isRequired,
  isFetching: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  serializedQuery: PropTypes.string.isRequired,
  setParameter: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    appspace: appspaceSelector(state),
    requestsList: requestsCurrenListSelector(LIST_NAME)(state),
    isFetching: isCommiting(LIST_NAME)(state),
    total: getListTotal(LIST_NAME)(state),
  };
};

const routered = withRouter(Requests);
const themed = withTheme(routered);
const connected = connectQueryHoc({
  parameters: {
    q: {
      type: QueryHocTypes.String,
      defaultValue: '',
    },
    requestTypes: {
      type: QueryHocTypes.Array,
      defaultValue: [],
      delimiter: ',',
    },
    statuses: {
      type: QueryHocTypes.Array,
      defaultValue: [],
      delimiter: ',',
    },
    offset: {
      type: QueryHocTypes.Number,
      defaultValue: 0,
    },
  },
})(themed);
export default connect(mapStateToProps)(connected);
