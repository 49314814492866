import AvatarForm from '@sportnet/ui/AvatarForm';
import PhotoEditor from '@sportnet/ui/PhotoEditor';
import rem from 'polished/lib/helpers/rem';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const StyledAvatarForm = styled(AvatarForm)`
  justify-content: center;
  margin-bottom: ${({ theme }) => rem(theme.grid.gutterWidth)};
  & > div:first-child {
    box-shadow: none;
    border-radius: initial;
  }
`;

class PhotoUpload extends Component {
  state = {
    uploadSrc: null,
    workingFile: null,
    isDeleted: false,
    photoEditorOpened: false,
  };

  componentWillReceiveProps(netProps) {
    if (this.props.src !== netProps.src) {
      this.setState({
        uploadSrc: null,
        isDeleted: false,
      });
    }
  }

  onUploadClick = () => {
    this.el.value = '';
    this.el.click();
  };

  onUpload = file => {
    this.setState({
      workingFile: URL.createObjectURL(file),
      isDeleted: false,
      photoEditorOpened: true,
    });
  };

  onDelete = () => {
    this.props.onDelete();
    this.setState({
      uploadSrc: 'deleted',
      isDeleted: true,
    });
  };

  handlePhotoEditorClose = () => {
    this.setState({ photoEditorOpened: false });
  };

  handlePhotoEditorSubmit = (data, blobPromise) => {
    blobPromise.then(blob => {
      this.props.onUpload(blob);
      this.setState({
        uploadSrc: URL.createObjectURL(blob),
        photoEditorOpened: false,
      });
    });
  };

  render() {
    const { src, label, availableAspectRatios } = this.props;
    const { uploadSrc, workingFile, isDeleted, photoEditorOpened } = this.state;
    const pictureSrc = uploadSrc || src;
    return (
      <React.Fragment>
        <StyledAvatarForm
          label={label}
          src={isDeleted ? '' : pictureSrc}
          onClickDelete={this.onDelete}
          onFileChange={this.onUpload}
        />
        {photoEditorOpened && (
          <PhotoEditor
            src={workingFile}
            onClose={this.handlePhotoEditorClose}
            onSubmit={this.handlePhotoEditorSubmit}
            availableAspectRatios={availableAspectRatios}
          />
        )}
      </React.Fragment>
    );
  }
}

PhotoUpload.propTypes = {
  src: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  label: PropTypes.string,
  availableAspectRatios: PropTypes.arrayOf(PropTypes.string),
};

PhotoUpload.defaultProps = {
  src: null,
  label: null,
  availableAspectRatios: [null],
};

export default PhotoUpload;
