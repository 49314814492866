import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { __ } from '../../../../../utilities';
import { codelistEntrySelector } from '../../../../App/selectors';
import { codelistDefinition } from '../../../../FO/definitions';
import { universalRequestDefinition } from '../../../definitions';
import KeyValueTable from '../../../../../components/KeyValueTable';
import Col, { Row } from '@sportnet/ui/Grid';
import Header from '@sportnet/ui/Header';
import Segment from '@sportnet/ui/Segment';

const sexes = {
  M: __('Muž'),
  F: __('Žena'),
};

const CommonRequestInfo = ({
  request,
  nationality,
  citizenship,
  birthcountry,
}) => {
  const {
    userProfile,
    data: { isChildRegistration, sportnet_user: sportnetUser } = {},
  } = request;

  const profile = isChildRegistration ? sportnetUser : userProfile;

  if (!profile) return null;
  const getAddressType = type => {
    switch (type) {
      case 'HOME':
        return __('Bydlisko');
      case 'POSTAL':
        return __('Poštová');
      case 'INVOICE':
        return __('Fakturačná');
      default:
        return type;
    }
  };
  const getAddressStatus = status => {
    switch (status) {
      case 'active':
        return __('Aktívna');
      case 'inactive':
        return __('Neaktívna');
      default:
        return status;
    }
  };
  const getKVAddresses = () => {
    if (profile.addresses) {
      return profile.addresses.map(address => {
        return {
          key: getAddressType(address.type),
          values: [
            `${address.street} ${address.number}, ${address.zip} ${address.city}`,
            getAddressStatus(address.status),
          ],
        };
      });
    }
    return [];
  };
  const kvFields = [
    {
      key: __('Meno: '),
      values: [profile.name || ''],
    },
    {
      key: __('Priezvisko: '),
      values: [profile.surname || ''],
    },
    {
      key: __('Titul pred: '),
      values: [(profile.titles || {}).before || ''],
    },
    {
      key: __('Titul za: '),
      values: [(profile.titles || {}).after || ''],
    },
    {
      key: __('Identifikačné číslo: '),
      values: [profile.idnr || ''],
    },
    {
      key: __('Rodné priezvisko: '),
      values: [profile.family_name || ''],
    },
    {
      key: __('Telefón: '),
      values: [profile.phone || ''],
    },
    {
      key: __('Email: '),
      values: [profile.email || ''],
    },
    {
      key: __('Pohlavie: '),
      values: [sexes[profile.sex] || ''],
    },
    {
      key: __('Dátum narodenia: '),
      values: [
        profile.birthdate ? moment(profile.birthdate).format('DD.MM.YYYY') : '',
      ],
    },
    {
      key: __('Miesto narodenia: '),
      values: [profile.birthplace || ''],
    },
    {
      key: __('Krajina narodenia: '),
      values: [birthcountry.label || ''],
    },
    {
      key: __('Národnosť: '),
      values: [nationality.label || ''],
    },
    {
      key: __('Štátna príslušnosť: '),
      values: [citizenship.label || ''],
    },
    {
      key: __('Veľkosť obuvi (UK): '),
      values: [profile.shoesize || ''],
    },
    {
      key: __('Veľkosť oblečenia: '),
      values: [profile.dresssize || ''],
    },
  ];
  return (
    <Segment raised>
      <Row>
        <Col m={6}>
          <Header size="s">
            {__(isChildRegistration ? 'Osoba' : 'Aktuálny profil osoby')}
          </Header>
          <KeyValueTable rows={kvFields} />
          <h4>{__('Adresy')}</h4>
          <KeyValueTable rows={getKVAddresses()} />
        </Col>
      </Row>
    </Segment>
  );
};

CommonRequestInfo.propTypes = {
  request: universalRequestDefinition.isRequired,
  nationality: codelistDefinition.isRequired,
  citizenship: codelistDefinition.isRequired,
  birthcountry: codelistDefinition.isRequired,
};

const mapStateToProps = (state, props) => {
  const { request } = props;
  const { data: { sportnet_user: sportnetUser = {} } = {} } = request;
  return {
    nationality: codelistEntrySelector(
      'nationality',
      sportnetUser.nationality,
    )(state),
    citizenship: codelistEntrySelector(
      'country',
      sportnetUser.citizenship,
    )(state),
    birthcountry: codelistEntrySelector(
      'country',
      sportnetUser.birthcountry,
    )(state),
  };
};

export default connect(mapStateToProps)(CommonRequestInfo);
