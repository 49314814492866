import { RECEIVE_TEMPLATES_SUCCESS } from './constants';

export const templatesReducer = (
  state = {
    users: {},
    activeUserId: null,
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.payload.templates.reduce((acc, next) => {
          return { ...acc, [next._id]: next };
        }, {}),
      };
    default:
      return state;
  }
};
