import Segment from '@sportnet/ui/Segment';
import Input from '@sportnet/ui/Input';
import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Api from '../../../../Api';
import { __, createDisplayName } from '../../../../utilities';
import FormField from '@sportnet/ui/FormField';
import Button from '@sportnet/ui/Button';
import { rem } from 'polished';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import moment from 'moment';
import { connect } from 'react-redux';
import { sportCodelistSelector } from '../../../../containers/App/selectors';

const PersonLabelWrapper = styled('div')`
  display: flex;
  padding: ${rem(5)} ${rem(5)} ${rem(5)} 0;
  flex-direction: column;
`;

const PersonLabel = ({ person }) => {
  return (
    <Fragment>
      <div>{createDisplayName(person)}</div>
      <div>
        <i style={{ fontSize: '11px' }}>
          {__('narodený')}
          {': '}
          {person.birthyear}
          {', '}
          {__('vek')} {person.age}
        </i>
      </div>
    </Fragment>
  );
};

const selectOptionFromPerson = person => {
  return {
    value: person._id,
    label: createDisplayName(person),
    person,
  };
};

const NewTransfer = ({
  history,
  location,
  sportCodelist,
  match: {
    params: { appspace, targetPersonId },
  },
  onSubmitSuccess,
}) => {
  const [currentPPO, setCurrentPPO] = useState();
  const [currentPPOsectorsOptions, setCurrentPPOsectorsOptions] = useState([]);
  const [selectedSector, setSelectedSector] = useState();
  const [sectorPPOs, setSectorPPOs] = useState([]);
  const [selectedPPO, setSelectedPPO] = useState(null);
  const [isLoadingPPOs, setIsLoadingPPOs] = useState();
  // ziskanie aktualne prihlasenej PPO a jej sektorov
  useEffect(() => {
    Api.organizationPPOProfile(appspace)
      .then(ppo => {
        setCurrentPPO(ppo);
      })
      .catch(e => {
        alert(
          `Chyba: ${
            e.details
              ? e.details.userinfo ||
                `${e.details.name}: ${e.details.description}`
              : e.message
          }`,
        );
      });
  }, [appspace]);
  useEffect(() => {
    setCurrentPPOsectorsOptions(
      (currentPPO?.sectors || []).map(({ _id, sectorId, itemId, category }) => {
        let label = _id;
        switch (category) {
          case 'sport':
            const labelItem = sportCodelist[itemId]?.label || itemId;
            const labelSector = sportCodelist[itemId]
              ? sportCodelist[itemId].sectors[sectorId]?.label || sectorId
              : sectorId;
            label = `Šport: ${labelItem} / ${labelSector}`;
            break;
          default:
            label = _id;
        }
        return {
          value: _id,
          label,
        };
      }),
    );
  }, [currentPPO, sportCodelist]);
  // vyhladanie PPOciek podla zvoleneho sektora
  useEffect(() => {
    if (selectedSector) {
      setIsLoadingPPOs(true);
      Api.organizationPPOProfiles({
        sectors: [selectedSector._id],
        type: 'club',
      })
        .then(({ items }) => {
          setSectorPPOs(items);
          // zvolime prvu PPO
          if (items.length) {
            setSelectedPPO(items[0]);
          }
        })
        .catch(e => {
          alert(
            'Chyba: ' + (e.details?.userinfo || e.details?.name || e.message),
          );
        })
        .finally(() => {
          setIsLoadingPPOs(false);
        });
    }
  }, [selectedSector]);

  // vyhladavanie osob
  const [sportnetIdInputValue, setSportnetIdInputValue] = useState();
  const [lastPersonSearchValue, setLastPersonSearchValue] = useState(null);
  const [searchedPersons, setSearchedPersons] = useState({});
  const [searchedPersonsCurrent, setSearchedPersonsCurrent] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [isLoadingPerson, setIsLoadingPerson] = useState(false);
  const [targetPerson, setTargetPerson] = useState();
  const searchPersons = async v => {
    const q = (v || '').trim();
    if (q.length >= 3) {
      setLastPersonSearchValue(q);
      if (!searchedPersons[q]) {
        return Api.organizationPPOUsers(selectedPPO._id, {
          q,
          affiliationActive: true,
          affiliationCategory: 'sport_orgs',
          offset: 0,
          limit: 100,
        }).then(({ users }) => {
          setSearchedPersons(prev => ({ ...prev, [q]: users }));
          return users;
        });
      } else {
        return searchedPersons[q];
      }
    }
    return [];
  };
  useEffect(() => {
    setSearchedPersonsCurrent(searchedPersons[lastPersonSearchValue] || []);
  }, [lastPersonSearchValue, searchedPersons, selectedPPO]);
  // vyhladanie osoby
  useEffect(() => {
    if (targetPersonId) {
      setIsLoadingPerson(true);
      Api.getPublicUserProfile(targetPersonId)
        .then(person => {
          setTargetPerson(person);
          setIsLoadingPerson(false);
        })
        .catch(e => {
          if (
            e.details &&
            e.details.statusCode &&
            e.details.statusCode === 404
          ) {
            alert(__('Osoba so zadaným SportNet ID neexistuje'));
          }
          history.push({
            ...location,
            pathname: `/admin/${appspace}/create-request/transfer`,
          });
        });
    } else {
      setIsLoadingPerson(false);
    }
  }, [targetPersonId, appspace, history, location]);

  const [transferParams, setTransferParams] = useState({
    type: 'permanent',
    dateFrom: moment().startOf('date'),
    dateTo: null,
    sector: undefined,
  });

  useEffect(() => {
    setTransferParams(p => ({
      ...p,
      targetPerson: targetPerson
        ? {
            sportnetId: targetPerson._id,
            displayName: createDisplayName(targetPerson),
          }
        : undefined,
      sector: (currentPPO?.sectors || [])[0] || undefined,
    }));
  }, [targetPerson, currentPPO]);

  const [isSendingRequest, setIsSendingRequest] = useState();
  const sendRequest = () => {
    const {
      type: transferType,
      sector: { matricityPPO, ...sector }, // Odstranime matricityPPO z objektu sektora
      ...data
    } = transferParams;
    setIsSendingRequest(true);
    Api.createPersonTransferRequest(appspace, transferType, {
      data: { ...data, sector },
    })
      .then(() => {
        onSubmitSuccess && onSubmitSuccess();
      })
      .catch(e => {
        if (e.details) {
          switch (e.details.name) {
            case 'INVALID_DATE_TO':
              alert(__('Pri prestupe s obmedzením je nutné zadať dátum do'));
              break;
            case 'INVALID_CURRENT_SPORT_ORGS':
              alert(
                __(
                  'Osoba nemá aktívnu príslušnosť pre zadaný sektor, preto nie je možné vytvoriť žiadosť o prestup.',
                ),
              );
              break;
            default:
              alert(
                __(
                  `Nie je možné vytvoriť žiadosť o prestup: ${
                    e.details.userinfo ||
                    `${e.details.name}: ${e.details.description}`
                  }`,
                ),
              );
              break;
          }
        } else {
          alert(__('Vyskytla sa neočakávaná chyba'));
        }
      })
      .finally(() => {
        setIsSendingRequest(false);
      });
  };

  // 1. sportnetId + displayName
  //   - zadas priamo
  //   - alebo vyhladas ppo + vyhladas osobu v tejto ppo
  // 2. typ transferu (permanent / limited)
  // 3. datum od (datum do ak je limited)
  // 4. disciplina
  // 5. button
  if (!currentPPO) {
    return <Segment loading />;
  } else if (!targetPersonId) {
    return (
      <Fragment>
        <FormField
          label={__('Zadajte SportNet ID osoby')}
          info={__('Zadajte SportNet ID osoby ak ho poznáte')}
          placeholder={__('SportNet ID')}
          value={sportnetIdInputValue}
          onChange={e => {
            setSportnetIdInputValue(e.target.value);
          }}
        >
          <input />
          <Input.Button
            primary
            icon="check"
            onClick={() => {
              history.push({
                ...location,
                pathname: `/admin/${appspace}/create-request/transfer/${sportnetIdInputValue}`,
              });
            }}
          />
        </FormField>
        <FormField
          label={__('Alebo zvoľte sektor a organizáciu')}
          info={__(
            'Zvoľte sektor, následne organizáciu, aby ste mohli vyhľadať osobu',
          )}
          type="theselectsimple"
          options={currentPPOsectorsOptions}
          onChange={val => {
            setSelectedSector(
              val ? currentPPO.sectors.find(({ _id }) => _id === val) : null,
            );
            setSelectedPPO(null);
            setSelectedPerson(null);
            setSearchedPersons({});
            setLastPersonSearchValue('');
          }}
          value={selectedSector ? selectedSector._id : null}
        />
        {selectedSector && (
          <FormField
            loading={isLoadingPPOs}
            label={__('Zvoľte organizáciu')}
            type="theselectsimple"
            options={sectorPPOs.map(({ _id: value, name: label }) => ({
              value,
              label,
            }))}
            onChange={val => {
              setSelectedPPO(
                val ? sectorPPOs.find(({ _id }) => _id === val) : null,
              );
              setSelectedPerson(null);
              setSearchedPersons({});
              setLastPersonSearchValue('');
            }}
            value={selectedPPO ? selectedPPO._id : null}
          />
        )}
        {selectedSector && selectedPPO && (
          <FormField
            label={__('Vyhľadajte osobu')}
            type="asyncselect"
            placeholder={__('Zadajte aspoň 3 znaky mena a priezviska')}
            options={searchedPersonsCurrent.map(selectOptionFromPerson)}
            onChange={val => {
              setSelectedPerson(
                val
                  ? searchedPersonsCurrent.find(({ _id }) => _id === val.value)
                  : null,
              );
            }}
            value={
              selectedPerson ? selectOptionFromPerson(selectedPerson) : null
            }
            renderLabel={v => (
              <PersonLabelWrapper>
                <PersonLabel person={v.person} />
              </PersonLabelWrapper>
            )}
            loadOptions={searchPersons}
            filterOptions={null}
          />
        )}
        {selectedPerson && (
          <Button
            primary
            onClick={() => {
              history.push({
                ...location,
                pathname: `/admin/${appspace}/create-request/transfer/${selectedPerson._id}`,
              });
            }}
          >
            {__('Pokračovať')}
          </Button>
        )}
      </Fragment>
    );
  } else {
    const transferTypes = [
      { value: 'permanent', label: __('Prestup') },
      { value: 'limited', label: __('Prestup s obmedzením') },
    ];
    return (
      <Fragment>
        <Segment
          loading={isLoadingPerson}
          secondary
          header={
            <SegmentHeader
              size="xs"
              onDispose={() => {
                history.push({
                  ...location,
                  pathname: `/admin/${appspace}/create-request/transfer`,
                });
              }}
            >
              {__('Osoba')}
            </SegmentHeader>
          }
        >
          {targetPerson && <PersonLabel person={targetPerson}></PersonLabel>}
        </Segment>
        <FormField
          label={__('Typ prestupu')}
          type="theselect"
          required={true}
          options={transferTypes}
          disabled={isSendingRequest}
          value={transferTypes.find(
            ({ value }) => value === transferParams.type,
          )}
          onChange={v => {
            setTransferParams(p =>
              v
                ? {
                    ...p,
                    type: v.value,
                    dateTo: v.value === 'permanent' ? null : p.dateTo,
                  }
                : p,
            );
          }}
        />
        <FormField
          label={__('Sektor')}
          type="theselect"
          required={true}
          disabled={isSendingRequest}
          options={currentPPOsectorsOptions}
          value={currentPPOsectorsOptions.find(
            ({ value }) => value === (transferParams.sector || {})._id,
          )}
          onChange={v =>
            setTransferParams(p =>
              v
                ? {
                    ...p,
                    sector: ((currentPPO || {}).sectors || []).find(
                      ({ _id }) => _id === v.value,
                    ),
                  }
                : p,
            )
          }
        />
        <FormField
          label={__('Dátum od')}
          type="date"
          disabled={isSendingRequest}
          value={transferParams.dateFrom}
          onChange={v =>
            setTransferParams(p => ({
              ...p,
              dateFrom: v ? v.startOf('date') : moment().startOf('date'),
            }))
          }
        />
        {transferParams.type === 'limited' && (
          <FormField
            label={__('Dátum do')}
            type="date"
            disabled={isSendingRequest}
            value={transferParams.dateTo}
            onChange={v =>
              setTransferParams(p => ({
                ...p,
                dateTo: v ? v.startOf('date') : null,
              }))
            }
          />
        )}
        <Button primary onClick={sendRequest} disabled={isSendingRequest}>
          {__('Odoslať žiadosť o prestup')}
        </Button>
      </Fragment>
    );
  }
};

export default connect(state => {
  return {
    sportCodelist: sportCodelistSelector(state),
  };
})(withRouter(NewTransfer));
