import { createSelector } from 'reselect';
import { getProp } from '@sportnet/utilities';

export const applicationDomainSelector = state => state.application;

export const activeAppSpaceIdSelector = createSelector(
  applicationDomainSelector,
  substate => substate.activeAppSpaceId || '',
);

export const appSpacesSelector = createSelector(
  applicationDomainSelector,
  substate => substate.appSpaces,
);

export const activeAppSpaceSelector = createSelector(
  applicationDomainSelector,
  substate => substate.activeAppSpace || null,
);

export const notActiveAppSpacesSelector = createSelector(
  appSpacesSelector,
  activeAppSpaceIdSelector,
  (appSpaces, activeId) => {
    if (activeId) {
      return appSpaces.filter(appSpace => appSpace.app_space !== activeId);
    }
    return appSpaces;
  },
);

export const appInfoSelector = createSelector(
  applicationDomainSelector,
  substate => substate.appInfo,
);

export const aclSelector = createSelector(
  applicationDomainSelector,
  substate => substate.acl,
);

export const appsSelector = createSelector(
  applicationDomainSelector,
  substate => substate.apps,
);

export const authUserSelector = createSelector(
  applicationDomainSelector,
  substate => substate.authUser,
);

export const accessTokenSelector = createSelector(
  applicationDomainSelector,
  substate => substate.accessToken,
);

export const activeAppSpaceProfileSelector = createSelector(
  activeAppSpaceSelector,
  substate => {
    if (substate) {
      return substate.org_profile;
    }
    return null;
  },
);

export const activeAppSpaceRelationsSelector = createSelector(
  activeAppSpaceProfileSelector,
  substate => getProp(substate || {}, ['relations'], []),
);
