import Button from '@sportnet/ui/Button';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Segment from '@sportnet/ui/Segment';
import Sidebar from '@sportnet/ui/Sidebar';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Layout from '../../../../components/Layout';
import { getProp, getStatusIcon, __ } from '../../../../utilities';
import {
  aclSelector,
  addressTypeCodelistSelector,
  countryCodelistSelector,
} from '../../../App/selectors';
import { createAddress, editAddress, fetchUserAddresses } from '../../actions';
import {
  addressDefinition,
  codelistDefinition,
  matchDefinition,
} from '../../definitions';
import {
  getActiveUserAddressesSelector,
  getActiveUserIsFetchingSelector,
} from '../../selectors';
import Wrapper from '../index';
import AddressesForm from './form';
import { useParams } from 'react-router-dom';
import { useAuth } from '@sportnet/auth-react';
import getApiError from '../../../../utilities/getApiError';

const AddressesList = ({
  addressTypes,
  addresses,
  isFetching,
  acl,
  dispatch,
  countries,
}) => {
  const [updateDisabled, setUpdateDisabled] = React.useState();
  const [createDisabled, setCreateDisabled] = React.useState();
  const [sidebarVisible, setSidebarVisible] = React.useState();
  const [address, setAddress] = React.useState();

  const { ppo: appSpace } = useAuth();
  const { sportnetId } = useParams();

  React.useEffect(() => {
    if (acl) {
      setUpdateDisabled(!acl.crmUserAddressesUpdate);
      setCreateDisabled(!acl.crmUserAddressesCreate);
    }
  }, [acl]);

  React.useEffect(() => {
    if (!(sportnetId && appSpace)) {
      return;
    }
    dispatch(fetchUserAddresses(sportnetId, appSpace));
  }, [sportnetId, appSpace, dispatch]);

  const onSubmit = async data => {
    try {
      const {
        _id,
        city,
        country,
        number,
        conscription_number: cNumber,
        status,
        street,
        type,
        zip,
      } = data.address;
      const formData = {
        city,
        country,
        number,
        conscription_number: cNumber,
        status,
        street,
        type,
        zip,
      };
      if (_id) {
        await dispatch(editAddress(appSpace, sportnetId, _id, formData));
      } else {
        await dispatch(createAddress(appSpace, sportnetId, formData));
      }
      setSidebarVisible(false);
      setAddress(null);
    } catch (e) {
      const err = getApiError(e);
      alert(
        `${__('Adresu sa nepodarilo uložiť')} - Error: ${
          err.details.statusCode
        } ${err.details.name} ${err.message}`,
      );
    }
  };

  const getAddressType = type => {
    const item = addressTypes.find(addressType => {
      return addressType.value === type;
    });
    return item?.label;
  };

  const handleAddAdressClick = () => {
    setSidebarVisible(true);
  };

  const handleSidebarClose = () => {
    setSidebarVisible(false);
    setAddress(null);
  };

  const getCountry = countryCode => {
    const item = countries.find(country => {
      return country.value === countryCode;
    });
    return getProp(item, ['label']);
  };

  return (
    <Layout
      bottomFixed={
        !createDisabled && (
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                primary
                onClick={handleAddAdressClick}
                data-testid="CRM_FO_Input_Adresy_New"
              >
                {__('Pridať adresu')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        )
      }
    >
      <Wrapper isFetching={isFetching} activeTab={__('Adresy')}>
        <Segment raised loading={isFetching}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Typ')}</Th>
                <Th>{__('Adresa')}</Th>
                <Th>{__('Stav')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {addresses.map(item => {
                const trProps = {
                  key: item._id,
                };
                if (!updateDisabled) {
                  trProps.onClick = () => {
                    setSidebarVisible(true);
                    setAddress(item);
                  };
                }
                return (
                  <Tr {...trProps}>
                    <Td>{getAddressType(item.type)}</Td>
                    <Td>
                      {`${item.street} ${
                        item.conscription_number
                          ? `${item.conscription_number}/`
                          : ''
                      }${item.number},`}
                      &nbsp;
                      {item.zip}&nbsp;
                      {item.city},&nbsp;
                      {getCountry(item.country)}
                    </Td>
                    <Td>{getStatusIcon(item.status)}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Segment>
      </Wrapper>
      {!updateDisabled && (
        <Sidebar
          onClose={handleSidebarClose}
          visible={sidebarVisible}
          header={__('Adresa')}
        >
          {sidebarVisible && (
            <AddressesForm parameters={{ address }} onSubmit={onSubmit} />
          )}
        </Sidebar>
      )}
    </Layout>
  );
};

AddressesList.propTypes = {
  addresses: PropTypes.arrayOf(addressDefinition).isRequired,
  addressTypes: PropTypes.arrayOf(codelistDefinition).isRequired,
  countries: PropTypes.arrayOf(codelistDefinition).isRequired,
  dispatch: PropTypes.func.isRequired,
  match: matchDefinition.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  addresses: getActiveUserAddressesSelector(state),
  isFetching: getActiveUserIsFetchingSelector(state),
  countries: countryCodelistSelector(state),
  addressTypes: addressTypeCodelistSelector(state),
  acl: aclSelector(state),
});

export default connect(mapStateToProps)(AddressesList);
