import BasicTable from '@sportnet/ui/BasicTable';
import Organization from '@sportnet/ui/Organization';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getProp } from '@sportnet/utilities';
import { useCodelist, __ } from '../../../utilities';
import {
  organizationTypeSelector,
  sportsCodelistSelector,
  ppoRelationTypeCodelistSelector,
} from '../../App/selectors';
import { codelistDefinition } from '../../FO/definitions';
import { PPODefinition } from '../definitions';
import moment from 'moment';
import Image from '@sportnet/ui/Image';
import styled from 'styled-components';

const OrganizationWithLogo = styled.div`
  display: flex;
  *:first-child {
    margin-right: 8px;
  }
  img {
    object-fit: contain;
  }
`;

const Table = ({
  items,
  onClickItem,
  types,
  ppoRelationTypes,
  sports,
  onSelect,
  selected,
}) => {
  const [getOrgTypeLabel] = useCodelist(types);
  const [getPPORelationTypeLabel] = useCodelist(ppoRelationTypes);

  return (
    <BasicTable
      columns={[
        {
          id: 'name',
          header: __('Organizácia'),
        },
        {
          id: 'type',
          header: __('Kód'),
        },
        {
          id: 'type',
          header: __('Typ organizácie'),
        },
        {
          id: 'type',
          header: __('Typ príslušnosti'),
        },
        {
          id: 'sport',
          header: __('Šport'),
        },
        {
          id: 'membership',
          header: __('Členstvo do'),
        },
      ]}
      rows={items}
      onClickRow={onClickItem}
      renderRow={item => [
        <OrganizationWithLogo>
          <Image src={item.logo_public_url} width={35} height={35} />
          <Organization
            key="name"
            organizationName={item.organization_name}
            profileName={item.name}
          />
        </OrganizationWithLogo>,
        (item.shortNames || []).map(({ shortname }) => {
          return shortname;
        }),
        getOrgTypeLabel(item.type),
        getPPORelationTypeLabel(item.relation.type),
        getProp(sports, [item.sport, 'label'], item.sport),
        item.relation.member_until
          ? moment(item.relation.member_until).format('DD.MM.YYYY')
          : '?',
      ]}
      rowKey="_id"
      onSelect={onSelect}
      selected={selected}
    />
  );
};

const mapStateToProps = state => {
  return {
    types: organizationTypeSelector(state),
    sports: sportsCodelistSelector(state),
    ppoRelationTypes: ppoRelationTypeCodelistSelector(state),
  };
};

Table.propTypes = {
  onClickItem: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.shape({}),
  items: PropTypes.arrayOf(PPODefinition).isRequired,
  types: PropTypes.arrayOf(codelistDefinition).isRequired,
  sports: PropTypes.shape({}).isRequired,
};

Table.defaultProps = {
  onSelect: () => {},
  selected: {},
};

export default connect(mapStateToProps)(Table);
