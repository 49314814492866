/* eslint-disable */
import 'isomorphic-fetch';

class Api {
  constructor() {
    this.baseUrl = null;
    this.token = null;
  }

  serializeQueryParams(parameters) {
    const str = [];
    for (let p in parameters) {
      if (parameters.hasOwnProperty(p)) {
        str.push(
          `${encodeURIComponent(p)}=${encodeURIComponent(parameters[p])}`,
        );
      }
    }
    return str.join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split('&').forEach(function(part) {
      let item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
        const parameter = parameters.$queryParameters[parameterName];
        queryParameters[parameterName] = parameter;
      });
    }
    return queryParameters;
  }

  /**
   * HTTP Request
   * @method
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   */
  request(
    method,
    url,
    body,
    headers,
    queryParameters,
    form,
    checkFor401 = true,
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    // contentTypeHeader[0] because nearly every header's value is set using array
    const contentTypeHeader = headers['Content-Type'];
    if (contentTypeHeader && contentTypeHeader[0] === 'multipart/form-data') {
      delete headers['Content-Type'];
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    return fetch(urlWithParams, {
      method,
      headers,
      body,
    })
      .then(response => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.ok) {
          if (
            response.headers.get('Content-Type').includes('application/json')
          ) {
            return response.json();
          } else if (
            response.headers.get('Content-Type').includes('application/pdf')
          ) {
            return response.blob();
          }
          return {};
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch(error => {
        return error.response.json().then(error_details => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
   * Set base url
   * @method
   * @param {string} baseUrl
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
   * Redirects a user to a given url
   * @method
   * @param {string} url
   */
  redirect(url) {
    window.location = url;
  }

  /**
   * Autocomplete for ZIP
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.query - ZIP Query
   */
  zipSearchAction(parameters = {}) {
    let path = '/zip';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['query'] !== undefined) {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Autocomplete for city
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.query - City query
   * @param {number} parameters.limit - Limit
   */
  citySearchAction(parameters = {}) {
    let path = '/city';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['query'] !== undefined) {
      queryParameters['query'] = parameters['query'];
    }

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Autocomplete for street
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.city - City to search in. Be aware this value has to be in proper exact format. For example. not kosice but Košice
   * @param {string} parameters.query - Street query
   * @param {number} parameters.limit - Limit
   */
  streetSearchAction(parameters = {}) {
    let path = '/street';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['city'] !== undefined) {
      queryParameters['city'] = parameters['city'];
    }

    if (parameters['query'] !== undefined) {
      queryParameters['query'] = parameters['query'];
    }

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
}

export default new Api();
