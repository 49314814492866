import { RECEIVE_GROUPS_SUCCESS } from './constants';

export const GroupsReducer = (
  state = {
    groups: {},
  },
  action,
) => {
  switch (action.type) {
    case RECEIVE_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload.groups.reduce((acc, next) => {
          return { ...acc, [next._id]: next };
        }, {}),
      };
    default:
      return state;
  }
};
