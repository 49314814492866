import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';
import Api from '../../../../Api';
import { getProp, setProp, __ } from '../../../../utilities';
import validation from '../../../../utilities/validation';
import {
  aclSelector,
  countryCodelistSelector,
  nationalityCodelistSelector,
} from '../../../App/selectors';
import { updateUserProfile } from '../../actions';
import {
  codelistDefinition,
  matchDefinition,
  userDefinition,
} from '../../definitions';
import { getActiveUserSelector } from '../../selectors';

export const FORM_NAME = 'PROFILE_FORM';

class ProfileDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      disabled: undefined,
    };
    this.getFormattedBirthDate = this.getFormattedBirthDate.bind(this);
  }

  componentDidMount() {
    this.setInitialUser(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setInitialUser(nextProps);
  }

  setInitialUser = props => {
    if (!getProp(this.state, ['user', '_id']) && props.user) {
      this.setState({
        user: props.user,
      });
    }
    if (typeof this.state.disabled === 'undefined' && props.acl) {
      this.setState({
        disabled: !props.acl.crmUpdateUserProfile,
      });
    }
  };

  getFormattedBirthDate(date) {
    if (date === '') {
      return null;
    }
    return moment(date);
  }

  handleClickEndAllRelations = () => {
    const {
      confirm,
      match: {
        params: { sportnetId, appspace },
      },
    } = this.props;
    confirm(__('Táto operácia je nenávratná. Naozaj chcete pokračovať?')).then(
      () => {
        Api.crmEndUserRelation(sportnetId, appspace);
      },
      () => {},
    );
  };

  handleChange = e => {
    const path = e.target.name.split('.');
    this.setState({
      ...this.state,
      user: setProp(this.state, ['user', ...path], e.target.value).user,
      userEdited: setProp(this.state, ['userEdited', ...path], e.target.value)
        .userEdited,
    });
  };

  submit = () => {
    const {
      dispatch,
      match: {
        params: { sportnetId, appspace },
      },
    } = this.props;
    const submitData = { ...this.state.userEdited };
    submitData.titles = {
      before: getProp(
        submitData,
        ['titles', 'before'],
        getProp(this.props.user, ['titles', 'before'], ''),
      ),
      after: getProp(
        submitData,
        ['titles', 'after'],
        getProp(this.props.user, ['titles', 'after'], ''),
      ),
    };
    dispatch(updateUserProfile(sportnetId, appspace, submitData));
  };

  render() {
    const { countryCodelist, nationalityCodelist, handleSubmit } = this.props;
    const { disabled } = this.state;

    const isUpdate = getProp(this.state, ['user', '_id']) && this.props.user;

    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} m={6}>
            <Field
              component={FormFieldRedux}
              label={__('Sportnet ID')}
              name="_id"
              disabled
              data-testid="CRM_FO_Input_Profil_SportnetID"
            />
          </Col>
          <Col xs={12} m={6}>
            <Field
              label={__('Používateľské meno')}
              name="username"
              disabled
              component={FormFieldRedux}
              data-testid="CRM_FO_Input_Profil_UserName"
            />
          </Col>
          <Col xs={4} m={2}>
            <Field
              label={__('Titul pred')}
              name="titles.before"
              disabled={disabled}
              component={FormFieldRedux}
            />
          </Col>
          <Col xs={8} m={4}>
            <Field
              component={FormFieldRedux}
              label={__('Meno')}
              name="name"
              disabled={disabled}
              data-testid="CRM_FO_Input_Profil_Name"
            />
          </Col>
          <Col xs={8} m={4}>
            <Field
              component={FormFieldRedux}
              label={__('Priezvisko')}
              name="surname"
              disabled={disabled}
              data-testid="CRM_FO_Input_Profil_Surname"
            />
          </Col>
          <Col xs={4} m={2}>
            <Field
              component={FormFieldRedux}
              label={__('Titul za')}
              name="titles.after"
              disabled={disabled}
            />
          </Col>
          <Col xs={12} m={6}>
            <Field
              component={FormFieldRedux}
              label={__('E-mailová adresa')}
              name="email"
              disabled={disabled}
              validate={[
                validation.email,
                ...(isUpdate ? [] : [validation.required]),
              ]}
              data-testid="CRM_FO_Input_Profil_Email"
            />
          </Col>
          <Col xs={12} m={4}>
            <Field
              component={FormFieldRedux}
              label={__('Rodné priezvisko')}
              name="family_name"
              disabled={disabled}
            />
          </Col>
          <Col xs={12} m={2}>
            <Field
              component={FormFieldRedux}
              label={__('Pohlavie')}
              name="sex"
              options={[
                { label: __('Mužské'), value: 'M' },
                { label: __('Ženské'), value: 'F' },
              ]}
              disabled={disabled}
              type="theselectsimple"
            />
          </Col>
          <Col xs={12} m={3}>
            <Field
              component={FormFieldRedux}
              label={__('Identifikačné číslo')}
              disabled={disabled}
              name="idnr"
              validate={[...(isUpdate ? [] : [validation.required])]}
              data-testid="CRM_FO_Input_Profil_IDnumber"
            />
          </Col>
          <Col xs={12} m={3}>
            <Field
              component={FormFieldRedux}
              type="date"
              label={__('Dátum narodenia')}
              name="birthdate"
              isClearable={!disabled}
              disabled={disabled}
              data-testid="CRM_FO_Input_Profil_DateOfB"
            />
          </Col>
          <Col xs={12} m={3}>
            <Field
              component={FormFieldRedux}
              type="date"
              label={__('Dátum úmrtia')}
              name="deathdate"
              isClearable={!disabled}
              disabled={disabled}
            />
          </Col>
          <Col xs={12} m={3}>
            <Field
              component={FormFieldRedux}
              label={__('Telefónne číslo')}
              name="phone"
              disabled={disabled}
            />
          </Col>
          <Col xs={12} m={4}>
            <Field
              component={FormFieldRedux}
              label={__('Krajina narodenia')}
              type="theselectsimple"
              disabled={disabled}
              options={countryCodelist.map(i => ({
                label: i.label || i.value,
                value: i.value,
              }))}
              name="birthcountry"
              data-testid="CRM_FO_Input_Profil_CountryOfB"
            />
          </Col>
          <Col xs={12} m={4}>
            <Field
              component={FormFieldRedux}
              label={__('Národnosť')}
              type="theselectsimple"
              disabled={disabled}
              options={nationalityCodelist.map(i => ({
                label: i.label || i.value,
                value: i.value,
              }))}
              name="nationality"
            />
          </Col>
          <Col xs={12} m={4}>
            <Field
              component={FormFieldRedux}
              label={__('Štátna príslušnosť')}
              type="theselectsimple"
              disabled={disabled}
              options={countryCodelist.map(i => ({
                label: i.label || i.value,
                value: i.value,
              }))}
              name="citizenship"
              data-testid="CRM_FO_Input_Profil_Citizenship"
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

ProfileDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: matchDefinition.isRequired,
  user: userDefinition.isRequired,
  confirm: PropTypes.func.isRequired,
  countryCodelist: PropTypes.arrayOf(codelistDefinition).isRequired,
  nationalityCodelist: PropTypes.arrayOf(codelistDefinition).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    user: getActiveUserSelector(state),
    acl: aclSelector(state),
    countryCodelist: countryCodelistSelector(state),
    nationalityCodelist: nationalityCodelistSelector(state),
    initialValues: props.data || {},
  };
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(ProfileDetail);

export default connect(mapStateToProps)(connected);
