import { RECEIVE_INVOICES_SUCCESS } from './contants';

export const invoicesReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_INVOICES_SUCCESS:
      return {
        ...state,
        ...action.payload.invoices.reduce((acc, next) => {
          return { ...acc, [next._id]: next };
        }, {}),
      };
    default:
      return state;
  }
};
