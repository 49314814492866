import FormField from '@sportnet/ui/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { __ } from '../../../utilities';
import { onlyNumbers, required } from '../../../validation';

export const convertToNumber = value => {
  if (value === '') {
    return 0;
  }
  const number = value.replace(/[^0-9.]/g, '');
  const sections = number.split('.');

  if (sections[0] !== '0' && sections[0] !== '00') {
    sections[0] = sections[0].replace(/^0+/, '');
  } else {
    sections[0] = '0';
  }

  if (sections[1]) {
    return Number(`${sections[0]}.${sections[1].slice(0, 2)}`);
  } else if (value.indexOf('.') !== -1) {
    return `${sections[0]}.`;
    // Otherwise, return only section
  }
  return Number(sections[0]);
};

class PriceFormPart extends React.PureComponent {
  render() {
    const { handlePriceChange } = this.props;
    return (
      <Row>
        <Col xs={6}>
          <Field
            label={__('Cena bez DPH')}
            name="price.amount_vat_excl"
            normalize={value => value && convertToNumber(value)}
            validate={[onlyNumbers]}
            component={FormField}
            onChange={handlePriceChange}
          />
        </Col>
        <Col xs={6}>
          <Field
            label={__('Sadzba DPH')}
            name="price.vat_rate"
            normalize={value => value && convertToNumber(value)}
            validate={[onlyNumbers]}
            component={FormField}
            onChange={handlePriceChange}
          />
        </Col>
        <Col xs={6}>
          <Field
            label={__('Cena s DPH')}
            name="price.amount"
            normalize={value => value && convertToNumber(value)}
            component={FormField}
            onChange={handlePriceChange}
          />
        </Col>
        <Col xs={6}>
          <Field
            label={__('Mena')}
            name="price.currency"
            component={FormField}
            type="theselectsimple"
            validate={[required]}
            options={[{ label: 'EUR', value: 'EUR' }]}
          />
        </Col>
      </Row>
    );
  }
}

PriceFormPart.propTypes = {
  handlePriceChange: PropTypes.func.isRequired,
};

export default connect()(PriceFormPart);
