import PropTypes from 'prop-types';

export const templateParametersDefinition = PropTypes.shape({
  message_parameters: PropTypes.shape({
    subject: PropTypes.string,
    perex: PropTypes.string,
    widgets: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  recipient_parameters: PropTypes.shape({
    users: PropTypes.arrayOf(PropTypes.string),
    userGroups: PropTypes.arrayOf(PropTypes.string),
    apps: PropTypes.arrayOf(
      PropTypes.shape({
        appId: PropTypes.string,
        roles: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
  }),
});

export const templateDefinition = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  appSpace: PropTypes.string.isRequired,
  sender: PropTypes.string,
  date_sent: PropTypes.string.isRequired,
  parameters: templateParametersDefinition.isRequired,
});

export const messageDefinition = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  user: PropTypes.shape({}).isRequired,
  date_first_read: PropTypes.string,
});
