import Segment from '@sportnet/ui/Segment';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Api from '../../../../Api';
import Layout from '../../../../components/Layout';
import { __ } from '../../../../utilities';
import { aclSelector } from '../../../App/selectors';
import {
  orgEntitySelector,
  orgIsErrorSelector,
  orgIsFetchingSelector,
} from '../selectors';
import Tabber, { TABS } from '../Tabber';
import BasicTable from '@sportnet/ui/BasicTable';
import Checkbox from '@sportnet/ui/Checkbox';
import SectorLabel from '../../../SectorLabel';

export const Faded = styled.span`
  color: ${({ theme }) => theme.color.fadedText};
`;

const ConditionalStrong = styled.span`
  font-weight: ${({ isStrong }) => (isStrong ? 'bold' : 'normal')};
`;

const SectorsList = ({
  org,
  match: {
    params: { appspace, organizationId, orgProfileId },
  },
  isFetching,
}) => {
  const [isFetchingSectors, setIsFetchingSectors] = useState(false);
  const [sectors, setSectors] = useState([]);

  useEffect(() => {
    setIsFetchingSectors(true);
    // najskor nacitame detail matricnej PPO a jej sektory
    Api.organizationPPOProfile(appspace)
      .then(matricityPPO => {
        return Api.crmPPOSectors(appspace, orgProfileId).then(({ sectors }) => {
          // zmergujeme sektory a pridame meta informacie
          // - _selectable = ci sa moze vobec zvolit = patri aj do appspace
          // - _selected = ci je selectnuty
          // najskor prejdeme vsetky sektory matricnej organizacie
          const finalSectors = matricityPPO.sectors.map(s => ({
            ...s,
            _selectable: true,
            _selected: false,
            matricityPPO: matricityPPO._id,
            matricityPPOName: matricityPPO.name,
            matricityPPOOrgName: matricityPPO.organization_name,
          }));
          // teraz prejdeme sektory zvolenej organizacie
          for (const sector of sectors) {
            // ak je sektor medzi finalnymi, len ho pridame a oznacime
            const finalSectorIndex = finalSectors.findIndex(
              ({ _id }) => _id === sector._id,
            );
            if (finalSectorIndex >= 0) {
              finalSectors[finalSectorIndex] = {
                ...finalSectors[finalSectorIndex],
                ...sector,
                _selected: true,
              };
            } else {
              finalSectors.push({
                ...sector,
                _selectable: false,
                _selected: true,
              });
            }
          }
          setSectors(finalSectors);
        });
      })
      .catch(e => {
        alert(
          e.details
            ? e.details.userinfo || __('Pri získavaní sektorov nastala chyba')
            : e,
        );
      })
      .finally(() => {
        setIsFetchingSectors(false);
      });
  }, [appspace, orgProfileId]);

  const [isUpdating, setIsUpdating] = React.useState(false);
  const submitSectors = async newSectors => {
    setIsUpdating(true);
    Api.crmPPOSectorsUpdate(appspace, orgProfileId, {
      data: {
        sectors: newSectors
          .filter(s => s._selectable && s._selected)
          .map(s => ({
            _id: s._id,
            category: s.category,
            itemId: s.itemId,
            sectorId: s.sectorId,
          })),
      },
    })
      .catch(e => {
        alert(
          e.details
            ? e.details.userinfo || __('Pri ukladaní sektorov nastala chyba')
            : e,
        );
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return (
    <Layout>
      <AppContext
        title={org && org.full_name}
        backUrl={`/admin/${appspace}/organizations`}
        breadcrumbs={[
          {
            name: __('Späť na zoznam'),
            url: `/admin/${appspace}/organizations`,
          },
        ]}
      />
      <Tabber activeTab={TABS.sectors} />
      <Segment>
        <Segment raised loading={isFetching || isFetchingSectors || isUpdating}>
          <BasicTable
            columns={[
              {
                id: 'control',
                header: '',
              },
              {
                id: 'value',
                header: __('Sektor'),
              },
              {
                id: 'label',
                header: __('Matričná organizácia'),
              },
            ]}
            rows={[...sectors]}
            renderRow={item => {
              return [
                <Checkbox
                  disabled={!item._selectable}
                  checked={item._selected}
                  onChange={e => {
                    const checked = e.target.checked;
                    setSectors(prevState => {
                      const selectedSectorIndex = prevState.findIndex(
                        s => s._id === item._id,
                      );
                      prevState[selectedSectorIndex]._selected = checked;
                      const newSectors = [...prevState];
                      submitSectors(newSectors);
                      return newSectors;
                    });
                  }}
                />,
                <>
                  <SectorLabel sector={item} />
                  <br />
                  <Faded>{item._id}</Faded>
                </>,
                <ConditionalStrong isStrong={item.matricityPPO === appspace}>
                  {item.matricityPPOOrgName}
                  <br />
                  <Faded>{item.matricityPPOName}</Faded>
                </ConditionalStrong>,
              ];
            }}
            rowKey="_id"
            rowStyle={item => {
              return item.matricityPPO !== appspace
                ? { opacity: 0.4 }
                : undefined;
            }}
          />
        </Segment>
      </Segment>
    </Layout>
  );
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { organizationId },
    },
  },
) => ({
  org: orgEntitySelector(state, organizationId),
  didFailed: orgIsErrorSelector(state, organizationId),
  isFetching: orgIsFetchingSelector(state, organizationId),
  acl: aclSelector(state),
});

export default connect(mapStateToProps)(SectorsList);
